import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {parsePhoneNumber} from "../../../helpers/helpersListCountryCode";
import {maskPhoneCountry, SELECT_OPTIONS_PHONE} from "../../../constants";
import InputMask from "react-input-mask";
import "../input-style.css"

const DEFAULT_CODE_COUNTRY = '+38 '
/**
 * .
 *
 // * @param {array || null} setting_hide_code_country -  entries hide county
 * @param {}  -
 * @returns  {}  -
 */
const FiledPhoneMask = ({onChange, label = null, value = '', setting_code_country = null, setting_hide_code_country=null, ...resetInput}) => {
    const [inputMask, setInputMask] = useState()
    const [codeCountry, setCodeCountry] = useState(DEFAULT_CODE_COUNTRY);

    useEffect(() => {
        let find_code = Object.keys(maskPhoneCountry)?.find(key => key.trim().includes(`+${setting_code_country}`?.trim()))
        if (setting_code_country && maskPhoneCountry[find_code]) {
            setCodeCountry(find_code)
        }
    }, [setting_code_country]);

    const isHasSettingCodeCountry = useMemo(() => {
        let find_code = Object.keys(maskPhoneCountry)?.find(key => key.trim().includes(`+${setting_code_country}`?.trim()))
        return (setting_code_country && maskPhoneCountry[find_code]);
    }, [setting_code_country]);


    const options_phone_list = useMemo(()=>{
        if(!setting_hide_code_country){
            return SELECT_OPTIONS_PHONE
        }
       return  Object.entries(SELECT_OPTIONS_PHONE).reduce((acc, [key, value]) => {
            if (value !== setting_hide_code_country) {
                acc[key] = value;
            }
            return acc;
        }, {});
    }, [setting_hide_code_country])

    const onChangeTel = useCallback((event) => {
        setInputMask(event.target.value);
        onChange(`${codeCountry}${event.target.value}`)
    }, [onChange, codeCountry, setInputMask, inputMask]);
    const onChangeCodeSelect = (event) => {
        let key = event.target.value;
        setCodeCountry(key)
    }
    useEffect(() => {
        if (value) {
            let phone = parsePhoneNumber(value);
            setCodeCountry(phone.countryCode);
            setInputMask(phone.phoneNumberWithoutCode);

        }
    }, [value])
    return (
        <div className={' form-field form-group  '}>
            {label && <label className={"label"}>{label}</label>}
            <div className=" filed-phone-mask">
                <select name="" id="" value={codeCountry} className={''} onChange={onChangeCodeSelect}
                        disabled={isHasSettingCodeCountry}>
                    {Object.entries(options_phone_list).map(([key, value]) => {
                        return <option value={value.code} key={key}
                                       selected={codeCountry === value.code}>({value.label}) {value.code}</option>
                    })}
                </select>
                <InputMask
                    className={''}
                    value={inputMask}
                    mask={maskPhoneCountry[codeCountry]}
                    placholder={maskPhoneCountry[codeCountry]}
                    onChange={onChangeTel}
                    type={'tel'}
                    required={true}
                    {...resetInput}
                />
            </div>
        </div>
    );
};

export default FiledPhoneMask;