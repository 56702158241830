function ChpuClear(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           version="1.1" id="Слой_1"
           x="0px" y="0px" viewBox="0 0 100 100"
           width={30}
      >

        <polyline className=""
                  stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
                  style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
                  points="13.1,1.9 36.3,1.9 64.1,1.9 78.4,1.9 87.2,1.9 "/>
        <line className=""
              stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
              style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
              x1="1.3" y1="98.9" x2="99" y2="98.9"/>
        <rect x="36.3" y="1.9" className=""
              stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
              style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
              width="27.8" height="11.8"/>
        <rect x="39.5" y="13.7" className=""
              stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
              style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
              width="21.3" height="17.2"/>
        <polygon className=""
                 stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
                 style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
                 points="66,30.9 66,42 60,49.1 40.3,49.1 34.3,42 34.3,30.9 "/>
        <polygon className=""
                 stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
                 style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
                 points="55.6,49.1 55.6,74.9 50.2,79.3 44.8,74.9 44.8,49.1 "/>
        <line className=""
              stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
              style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
              x1="55.6" y1="64" x2="44.8" y2="72.3"/>
        <line className=""
              stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
              style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
              x1="55.6" y1="55.7" x2="44.8" y2="64"/>
        <path className=""
              stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
              style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
              d="M99,75.5H70.1c0,5-4.1,9.1-9.2,9.1h0h-22c-5.1,0-9.2-4.1-9.2-9.1H1.3"/>
        <line className=""
              stroke={props.active ? "rgb(66, 98, 255)": "#000000"}
              style={{fill:"none",strokeWidth:"2",strokeLinecap:"round",strokeLinejoin:"round"}}
              x1="34.3" y1="40" x2="59.4" y2="40"/>
</svg>
  );
}

export default ChpuClear;
