import React from 'react';

const Part = ({name='', id_furn, w=0,h=0,product_id, z=0}) => {
    return (
        <tr className={'tr-bg-pack'}>
            <td>{id_furn ?? '-'}</td>
            <td>{name}</td>
            <td>{h?? '-'}</td>
            <td>{w??'-'}</td>
            <td>{z??'-'}</td>
            <td>{product_id??'-'}</td>
        </tr>
    );
};

export default Part;