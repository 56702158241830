import {HELP_ARTICLE_GET_BY_ID_TOGGLE_MODAL, HELP_ARTICLE_SEND_COMMENT_MAIL} from "../actions/help-article-action";

const SLICE_STEP = {
    isLoading: false,
    isError: false,
    error: null,
}


const initialState = {
    help_article_modal: {
        is_open: false,
        content: null,
        ...SLICE_STEP

    },
    send_mail: {
        ...SLICE_STEP,
    }

}

export const helpArticleReducer = (state = initialState, action) => {
    switch (action.type) {
        case HELP_ARTICLE_GET_BY_ID_TOGGLE_MODAL: {
            return {
                ...state,
                help_article_modal: {
                    ...state.help_article_modal,
                    ...action.payload
                }
            }
        }
        case HELP_ARTICLE_SEND_COMMENT_MAIL: {
            return {
                ...state, send_mail: {...action.payload}
            }
        }
        default: {
            return {
                ...state
            }
        }
    }

}

