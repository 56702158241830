import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getRemainsModal, getStockOrdersDT} from "../../../selectors/cells-leftovers-selector";
import {onToggleRemainModal} from "../../../store/reducers/ModalReducer";
import Modal from "../../../components/Modals/BasicModal";
import Container from "../../../components/Container";

const RemainsListModal = ({getT}) => {
    const {listViewOrder} = useSelector(getRemainsModal);
    const dispatch = useDispatch();
    const stockOrders = useSelector(getStockOrdersDT);
    const listOrdersCurrent = stockOrders.filter(s => Number(s.id) == Number(listViewOrder.order_in));
    const onToggleModal = () => {
        dispatch(onToggleRemainModal({key: 'listViewOrder', dt: {isOpen: false, idOrder: null}}));
    }

    return <>
        <div className="container-stock">
            <Modal title={getT('Зарезервированные остатки')} open={listViewOrder.isOpen}
                   onClose={() => onToggleModal()}>
                <Container>
                    {Array.isArray(listOrdersCurrent) && listOrdersCurrent.length >= 1 && listOrdersCurrent[0].hasOwnProperty('goods_stock') && Array.isArray(listOrdersCurrent[0].goods_stock) && listOrdersCurrent[0].goods_stock.length >= 1 ? (

                        <div className={'w-100'}>
                            <h3 style={{margin: '0px 0px 20px 0px'}} className="">{getT('Заказ')}
                                <p style={{margin:'0px'}}>{listOrdersCurrent[0].mame}</p></h3>
                            <div className="table">
                                <table className={"stock-table"}>
                                    <thead>
                                    <tr>
                                        <td>{getT("Название")}</td>
                                        <td>{getT("Размер (мм)")}</td>
                                        <td>{getT("Площадь")}</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {listOrdersCurrent[0].goods_stock.map(l => {
                                        return (<tr>
                                            <td>{l.name}</td>
                                            <td>
                                                <span title={'x'}> {l.x}</span>
                                                <strong> x </strong>
                                                <span title={'y'}> {l.y}</span>
                                                {l.z && <strong> x </strong>}
                                                <span title={'z'}> {l.z}</span>

                                            </td>
                                            <td>{l.count}</td>

                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <h3 style={{margin: "0 auto"}}>{getT("Остатков на складе нет")}</h3>
                    )}

                </Container>
            </Modal>
        </div>
    </>
}

export default RemainsListModal;