import {
    MATERIAL_SELECTED_FILTER_CHANGE_FILTER,
    MATERIAL_SELECTED_FILTER_SET_DATA,
    MATERIAL_SELECTED_FILTER_TOGGLE_OPEN_PAGE
} from "../actions/material-selected-filter-actions";


const SLICE_STEP = {
    isLoading: false,
    isError: false,
    error: null,
}
const MATERIAL_TYPES = [
    {
        id: "part",
        name: "Плитный материал"
    },
    {
        id: "band",
        name: "Кромка"
    }, {
        id: "tovar",
        name: "Товар"
    }
]
export const MATERIAL_SELECTED_INITIAL_FILTER = {
    text: '',
    type: MATERIAL_TYPES?.[0]?.id ?? 'part',
    producer: [],
    folders: [],
    x: [], y: [], z: []
}

const initialState = {
    material_types: MATERIAL_TYPES,
    filter_values: MATERIAL_SELECTED_INITIAL_FILTER,
    pagination: {from: 0, count: 0},
    filter_data: {
        type: MATERIAL_TYPES?.[0]?.id ?? 'part',
        producers: null,
        x: null, y: null, z: null,
        tree: null
    },
    goods: null,
    isOpenPage: false

}

export const MaterialSelectedFilterReducer = (state = initialState, action) => {
    switch (action.type) {

        case MATERIAL_SELECTED_FILTER_SET_DATA: {
            return {
                ...state,
                filter_data: action?.payload?.filter_data,
                pagination: action?.payload?.pagination,
                goods: action.payload.goods,
            }
        }
        case MATERIAL_SELECTED_FILTER_TOGGLE_OPEN_PAGE: {
            return {
                ...state,
                isOpenPage: action.payload.isOpen
            }
        }
        case MATERIAL_SELECTED_FILTER_CHANGE_FILTER: {
            return {
                ...state,
                filter_values: action.payload
            }
        }

        default: {
            return {
                ...state
            }
        }
    }
}