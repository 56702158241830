import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getLocalLang} from "../../../selectors/settings-selector";
import {getPlansDT} from "../../../selectors/cells-leftovers-selector";
import {
    actionsCellsLeftovers,
    onDeletePlanDT,
    onSetInfoPlanDT,
    onSetPlansDT,
    onUpdatePlanDT,
    setEditPlanForm
} from "../../../store/reducers/StorageCellsLeftovers";
import EditPlan from "./EditPlan";
import {onTogglePlanModal} from "../../../store/reducers/ModalReducer";
import ShowOrder from "../../../Icons/ShowOrder";
import Edit from "../../../Icons/TableActions/Edit";
import Delete from "../../../Icons/Actions/Delete";
import InfoPlane from "./InfoPlane";

const Plane = ({getT, firmId}) => {

    const dispatch = useDispatch();


    // const idFirm = useSelector(getIdFirm)
    const idFirm = firmId
    const lang = useSelector(getLocalLang)
    const plansDt = useSelector(getPlansDT)
    const onToggleModal = (isOpen) => {
        dispatch(onTogglePlanModal({key: 'edit', dt: {isOpen}}))
    }
    const onToggleInfoModal = (isOpen) => {
        dispatch(onTogglePlanModal({key: 'info', dt: {isOpen}}))
    }
    const onOpenFullInfo = (id) => {
        dispatch(onSetInfoPlanDT(id))
        // dispatch(onTogglePlanModal({key: 'planModal', dt: {edit: {isOpen}}}))
    }
    const onDeletePlane = (id) => {
        dispatch(onDeletePlanDT(id))
    }
    const onEditPlane = (dt, name) => {
        const data = setEditPlanForm(dt);
        dispatch(actionsCellsLeftovers.setInitialValuesForm({form: 'planEditForm', initialValue: data}))
        onToggleModal(true);
    }
    const onChangeComment = (dt) => {
        const data = setEditPlanForm(dt);
        dispatch(onUpdatePlanDT(data))
    }

    useEffect(() => {
        dispatch(onSetPlansDT())
    }, [])

    return <div className={"settings-section"}>
        {Array.isArray(plansDt) && plansDt.length >= 1 ? (<>
                <div className="head">
                    <div className="filed">
                        <h2>{getT('Планируемые остатки ')}</h2>
                    </div>

                </div>
                <div className="table">
                    <table className={"table-wrapper main-parts-table"}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>№ {getT("Заказа")}</th>
                            <th>{getT("Статус заказа")}</th>
                            <th>{getT("Материал")}</th>
                            <th>{getT("Размер (мм)")}</th>
                            <th>{getT("Коментарий")}</th>
                            <th>{getT("Действие")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {plansDt.map((l, idx) => {
                            return (<tr>
                                <td>{idx + 1}</td>
                                <td>{l.id}</td>
                                <td>{l.status[lang]}</td>
                                <td>{l.goods['name'][lang]} {l.goods['producer'][lang]}</td>
                                <td>
                                    <span title={'x'}> {l.x}</span>
                                    <strong> x </strong>
                                    <span title={'y'}> {l.y}</span>
                                    {l.z && <strong> x </strong>}
                                    <span title={'z'}> {l.z}</span>

                                </td>
                                <td><EditComment getT={getT}
                                    reason_why_not={l.reason_why_not}
                                                 onSend={(reason_why_not) => onChangeComment({...l, reason_why_not})}/>
                                </td>
                                <td>
                                    <div className={'settings-actions '}>
                                        <span
                                            onClick={() => onOpenFullInfo(l.orders_goods_stock_rest_plan_id)}><ShowOrder/></span>
                                        {(l.order == null || l.order == '') && <>
                                <span className={'cursor-pointer edit'}
                                      onClick={() => onEditPlane(l, l.name[lang])}><Edit/></span>
                                            <span className={'cursor-pointer'}
                                                  onClick={() => onDeletePlane(l.orders_goods_stock_rest_plan_id)}><Delete/></span>
                                        </>
                                        }
                                    </div>
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
                <EditPlan getT={getT} onToggleModal={onToggleModal}/>
                <InfoPlane getT={getT} onToggleInfoModal={onToggleInfoModal}/>
            </>
        ) : (
            <>
                <h3 style={{textAlign: 'center'}}>{getT("Планируемые остатки отсутствуют")}</h3>
            </>
        )}
    </div>
}

const EditComment = ({getT, reason_why_not, onSend}) => {
    const [comment, setComment] = useState(reason_why_not);
    const [isEdit, setIsEdit] = useState(false);
    return <>
        {!isEdit && <span>{reason_why_not}
            <span onClick={()=>setIsEdit(true)} className={'cursor-pointer d-block edit-icon-def'}>
            <Edit/>
        </span></span>}
        {isEdit && <>
            <div className="field field-change-comment">
                <span className="close cursor-pointer" onClick={()=>{
                    setIsEdit(false);
                }}>
                        <Delete/>
                    </span>
                <input type="text" className="input-val d-block w-100" value={comment}
                       onChange={(e) => setComment(e.target.value)}
                />
                <div className="settings-actions ">
                <span className="btn btn-maxCCenter min-padd"  onClick={()=>{
                    onSend(comment);
                    setIsEdit(false);
                }}>{getT('Сохранить')}</span>

                </div>

            </div>
        </>}

    </>
}
export default Plane;