import React, {useEffect} from 'react';
import AddPartModalContainer from "../../components/Modals/AddPartModal/AddPartModalContainer";
import UploadImportModal from "../../components/Modals/UploadProjectModal/UploadImportModal";
import ClientMaterialsBands from "../../components/Modals/ClientMaterialsBands/ClientMaterialsBands";
import OfflineImport from "../../components/Modals/OfflineImport";
import CombineMaterialModal from "../../components/Modals/CombineMatarial/CombineMaterialModal";
import TemplatePazMaterial from "../../components/Modals/TemplatePazModal/TemplatePazMaterial";
import FileManager from "../../components/Modals/FileManager";

import UploadProjectModalContainer from "../../components/Modals/UploadProjectModal/UploadProjectModalContainer";
import TabContainer from "./TabContainer";
import {useDispatch, useSelector} from "react-redux";
import {getOrder} from "../../selectors/order-selector";
import {changeOrderCurrencyDataAC, initialDetailTemplatePaz} from "../../store/reducers/OrderReducer";
import ProjectMaterials from "../../components/ProjectMaterials/ProjectMaterials";
import "./HomeStyle.css"
import NotWholeRemnants from "../../components/Modals/StockMaterialClient/NotWholeRemnants";
import StockMaterialClients from "../../components/Modals/StockMaterialClient/StockMaterialClients";
import BatchMachiningHolesModalContainer
    from "../../components/Modals/BatchMachiningHoles/BatchMachiningHolesModalContainer";
import ErrorBoundary from "../../components/ErrorBoundary";

const HomeContainer = () => {
    const order = useSelector(getOrder);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!order) return
        const firmCurrency = order?.production_constants?.['general.currency'];
        const order_currency = order?.currency;

        if (order && (order_currency !== firmCurrency)) {
            dispatch(changeOrderCurrencyDataAC(firmCurrency));
        }
    }, [dispatch, order]);

    useEffect(() => {
        if (!order) return
        if (order?.gr?.length) {
            dispatch(initialDetailTemplatePaz());
        }
    }, [])

    if (!order) return null
    return (
        <>
            <TabContainer/>
            <UploadProjectModalContainer key={'UploadProjectModalContainer'}/>
            <AddPartModalContainer key={'AddPartModalContainer'}/>
            <UploadImportModal key={'UploadImportModal'}/>

            <ProjectMaterials key={'ProjectMaterials'}/>
            <ErrorBoundary>
                <ClientMaterialsBands key={'ClientMaterialsBands'}/>
            </ErrorBoundary>

            <OfflineImport key={'OfflineImport'}/>
            <CombineMaterialModal key={'CombineMaterialModal'}/>
            <TemplatePazMaterial key={'TemplatePazMaterial'}/>
            <FileManager key={'file-manager-modal'}/>
            {/*Не целые остатки Компани*/}
            <NotWholeRemnants/>
            {/*Остатки клиента*/}
            <StockMaterialClients/>
            {/*Групповая обработка*/}
            <BatchMachiningHolesModalContainer/>

        </>
    );
};

export default HomeContainer;