import header from "./cutting/header";
import edge from "./cutting/Edge/edge";
import cutting_view from "./cutting/cutting_view/cutting_view";
import panel from "./panel";
import getT from "./getT";

export class CuttingHtml {
  constructor() { }

  generateContentHtml({ order, setting, projectDetail }) {
    const { partsPreviewCutting:partsPreview } = projectDetail;
    if(!order.hasOwnProperty('cutting_card') || !setting?.cutting_card?.hasOwnProperty('cards') || order?.cutting_card === null || !!order?.cutting_card?.length ) return `<h3>${getT('Карта кроя отсутствует')}</h3>`
    return `
       <div class="container">
      ${header({ order })}
      
      ${setting?.mode ? ' ' : edge({ order, cutting: setting.cutting_card, isVisible: setting.general })}
      
      ${cutting_view({ order, cutting: setting.cutting_card, 
      partsPreview : Array.isArray(partsPreview) ? partsPreview : [], visibleTotalXnc: setting.xnc, 
      mode: setting.mode , modeDetailing: setting.modeDetailing})} 
   </div>
    `
  }

}