export const MATERIAL_SELECTED_FILTER_SET_DATA = 'MATERIAL_SELECTED_FILTER/SET_DATA';
export const MATERIAL_SELECTED_FILTER_TOGGLE_OPEN_PAGE = 'MATERIAL_SELECTED_FILTER/TOGGLE_OPEN_PAGE';

export const MATERIAL_SELECTED_FILTER_CHANGE_FILTER = 'MATERIAL_SELECTED_FILTER/CHANGE_FILTER';
// export const MATERIAL_SELECTED_FILTER_SET_REMAINS_STOCK_DT = 'MATERIAL_SELECTED_FILTER/SET_REMAINS_STOCK_DT';

export const materialSelectedFilterSetDataAC = ({filter_data, goods, pagination})=>{
    return {
        type: MATERIAL_SELECTED_FILTER_SET_DATA, payload: {filter_data, goods, pagination:pagination}
    }
}

export const materialSelectedFilterTogglePageAC = ({isOpen})=>{
    return {
        type: MATERIAL_SELECTED_FILTER_TOGGLE_OPEN_PAGE, payload: {isOpen: isOpen}
    }
}
export const materialSelectedFilterChangeFilterAC = (payload)=>{
    return {
        type: MATERIAL_SELECTED_FILTER_CHANGE_FILTER, payload:payload
    }
}
