import React from 'react'
import {useDispatch} from 'react-redux';
import {onOpenInstructionModal} from "../../../../store/reducers/ModalReducer";
import Delete from "../../../../Icons/Actions/Delete";
import Edit from "../../../../Icons/TableActions/Edit";
import {
    deleteToolsSettingsFirm,
    onSendTools, setEditMillForms,
} from "../../../../store/reducers/SettingsReducer";
import {actions} from '../../../../store/reducers/SettingsReducer'
import MillForm from "./MillForm";

const Mill = ({getT, millTools, isAccessAction}) => {
    const dispatch = useDispatch();

    const onToggleInstModal = (data) => {
        dispatch(onOpenInstructionModal(data));
        dispatch(actions.onResetFormValue('mill'))
    }

    const onBeforeSubmitCU = (valuesForm) => {
        dispatch(onSendTools(valuesForm, null, 'mill'));

    };
    const onUpdateToolsSubmit = (valuesForm, id) => {
        dispatch(onSendTools(valuesForm, id, 'mill'));
    }
    const onDeleteTools = (id) => {
        dispatch(deleteToolsSettingsFirm(id));
    }
    const onUpdateTools = (data) => {
        const initialValue = setEditMillForms(data);
        dispatch(actions.setInitialValuesForm({form: 'mill', initialValue: initialValue}));
        dispatch(onOpenInstructionModal({key: 'mill', dt: true}));
    }
    return <div className={'tools-wrap'}>
        <div className="section-header">
            <h2 className="title">{getT('Фрезерование')}</h2>
            {isAccessAction && <button className="btn"
                                       onClick={() => onToggleInstModal({
                                           key: 'mill',
                                           dt: true
                                       })}>{getT('Создать')}</button>
            }

        </div>
        <div className="table">

            {Array.isArray(millTools) && millTools.length >= 1 ?
                <table className={'table-wrapper main-parts-table'}>
                    <thead>
                    <th>{getT('Вкл')}/{getT('Выкл')}</th>
                    <th>{getT('Диаметр ')}</th>
                    <th>{getT('Максимальная глубина')}</th>
                    <th>{getT('Сторона')}</th>
                    <th>{getT('Тип фрезы')}</th>
                    {isAccessAction && <th>{getT('Действие')}</th>}
                    </thead>
                    {millTools.map((b, idx) => {
                        return <tr key={idx}>
                            <td>{Number(b.on) == 1 ? getT('Вкл') : getT('Выкл')}</td>
                            <td>{Number(b.d)}</td>
                            <td>{Number(b.z_max)}</td>
                            <td>{b.mill_back != null && (Number(b.mill_back) == 1) ? getT('Обратная') : getT('Лицевая')}</td>
                            <td>
                                {b.mill_left != null  && (Number(b.mill_left) == 1)  && getT('Левая')}
                                {b.mill_right != null  && (Number(b.mill_right) == 1)  && getT('Правая')}
                            </td>
                            {isAccessAction && <td>
                                <div className="settings-actions">
                                    <span className={'edit'} onClick={() => onUpdateTools(b)}><Edit/> </span>
                                    <span className="delete"
                                          onClick={() => onDeleteTools(b.user_xnc_tools_id)}><Delete/></span>
                                </div>
                            </td>
                            }
                        </tr>
                    })}
                </table>
                : ''}
        </div>
        <MillForm getT={getT}
        onToggleInstModal={onToggleInstModal}
        onBeforeSubmit={onBeforeSubmitCU}
        onUpdateToolsSubmit={onUpdateToolsSubmit}
        />
    </div>
}

export default Mill;