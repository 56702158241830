import {useEffect, useRef} from "react";

export const PackedType = {
    sendData: 'senddata',
    sendReqData: 'sendreqdata', // initial data
    sendDataNoPaint: 'senddatanopaint', // initial data
    answerResultData: 'answerResultData',
    sendChangeData: 'sendChangeData',// edit,
    answerData: 'answerData',
    answerMergeData: 'answerMergeData',
    answerTransferData: 'answerTransferData',
    answerEditData: 'answerEditData',
    answerPaintData: 'answerPaintData'
}
export default function ({setResultBoxApi = null}) {
    const iframeCurrent = useRef(null);
    const messageResolvers = useRef({});
    const getMessageFromApi = (e) => {
        switch (e.data.type) {
            case PackedType.answerResultData: {
                sendMessageToApi(e.data.mes, PackedType.sendData);
                let result = null;
                if (e.data.mes) {
                    result = JSON.parse(e.data.mes);
                }
                if (setResultBoxApi) setResultBoxApi(result);
                messageResolvers.current = result;
                localStorage.setItem('box3d_result', JSON.stringify(result))

                break
            }
            case PackedType.sendDataNoPaint:
            case PackedType.answerTransferData:
            case PackedType.answerPaintData:
            case PackedType.answerMergeData: {

                let result = null;
                if (e.data.mes) {
                    result = JSON.parse(e.data.mes)
                }
                if (setResultBoxApi) setResultBoxApi(result);
                messageResolvers.current = result;
                localStorage.setItem('box3d_result', JSON.stringify(result))
                break
            }

            default: {
            }
        }


        return false;

    }


    function sendMessageToApi(mes, type) {
        let returnProc = {'mes': mes, 'type': type};
        if (iframeCurrent.current) {

            //@ts-ignore
            iframeCurrent.current.contentWindow.postMessage(returnProc, '*');
        }
    }

    function sendMessageAsyncToApi(mes, type) {
        let returnProc = {'mes': mes, 'type': type};
        if (iframeCurrent.current) {

            //@ts-ignore
            iframeCurrent.current.contentWindow.postMessage(returnProc, '*');
            return new Promise((resolve, reject) => {
                let count_timer = 0;
                const interval = setInterval(() => {

                    count_timer++;
                    let result = messageResolvers.current ? Object.keys(messageResolvers.current)?.length : 0;
                    // Check the main condition
                    // return resolve({})
                    if (result && count_timer <= 100) {
                        clearInterval(interval);
                        resolve(messageResolvers.current);
                        messageResolvers.current = null;

                    }

                    // If the counter reaches 5 without meeting the condition, reject the promise
                    if (count_timer > 100) {
                        clearInterval(interval);
                        reject({message: "Произошла ошибка при создании упаковки!"});
                    }
                }, 1000);
            });
        }
    }

    function iframeLoaded(payload) {

        switch (payload.type) {
            case PackedType.sendReqData : {
                sendMessageToApi(JSON.stringify(payload.data), payload.type);
                return;
            }
            case PackedType.sendData: {
                sendMessageToApi(JSON.stringify(payload.data), payload.type);

                return;
            }
            default: {
                console.log('not found case', payload.type)
            }
        }
    }


    useEffect(() => {
        window.addEventListener("message", getMessageFromApi);
        return () => {
            window.removeEventListener("message", getMessageFromApi);
        };
    }, []);

    useEffect(() => {


    }, [iframeCurrent]);


    return {
        iframeLoaded: (payload) => iframeLoaded(payload),
        sendMessageToApi,
        sendMessageAsyncToApi,
        iframeCurrent,
    }
}