import { tools } from './tools.js';
import { translateLib } from './translate.js';

export const cutTos = {
  cutTos(
    cutTos, partX, partY, partZ, language, typeSvg
  ) {
    let a = '';
    let hovers = '';
    let translateFunc = translateLib.translate(language);

    let length = 60;
    let offset = 5;
    let fontSize = 100;
    let fill = 'blue';

    for (let cutTo of cutTos) {
      let overs = '';
      let operationId = cutTo.id;
      let top = tools.rnd(cutTo.t || 0);
      let right = tools.rnd(cutTo.r || 0);
      let bottom = tools.rnd(cutTo.b || 0);
      let left = tools.rnd(cutTo.l || 0);

      let cuts = {
        t: {
          rect: [0, top, partX, partY - top],
          arrow: (
            `M ${partX / 2 - length / 2} ${top - length * 2 - offset} `
            + `L ${partX / 2 + length / 2} ${top - length * 2 - offset} `
            + `L ${partX / 2} ${top - offset} Z`
          ),
          text: [top, partX / 2 + length / 2, top - offset - length],
          textAnchor: 'start',
          alignmentBaseline: 'middle',
        },
        r: {
          rect: [0, 0, partX - right, partY],
          arrow: (
            `M ${partX - right + offset + length * 2} ${partY / 2 - length / 2} `
            + `L ${partX - right + offset + length * 2} ${partY / 2 + length / 2} `
            + `L ${partX - right + offset} ${partY / 2} Z`
          ),
          text: [right, partX - right + offset + length, partY / 2 + length / 2],
          textAnchor: 'middle',
          alignmentBaseline: 'hanging',
        },
        b: {
          rect: [0, 0, partX, partY - bottom],
          arrow: (
            `M ${partX / 2 - length / 2} ${partY - bottom + offset + length * 2}`
            + `L ${partX / 2 + length / 2} ${partY - bottom + offset + length * 2} `
            + `L ${partX / 2} ${partY - bottom + offset} Z`
          ),
          text: [bottom, partX / 2 - length / 2, partY - bottom + offset + length],
          textAnchor: 'end',
          alignmentBaseline: 'middle',
        },
        l: {
          rect: [left, 0, partX - left, partY],
          arrow: (
            `M ${left - offset - length * 2} ${partY / 2 - length / 2} `
            + `L ${left - offset - length * 2} ${partY / 2 + length / 2} `
            + `L ${left - offset} ${partY / 2} Z`
          ),
          text: [left, left - offset - length, partY / 2 - length / 2],
          textAnchor: 'middle',
          alignmentBaseline: 'baseline'
        }
      };
      let cutToId = `cut-to_${operationId}`;
      let cutToGroupInner = '';

      for (let [side, cut] of Object.entries(cuts)) {
        if (cutTo[side]) {
          let cutRect = cut.rect;
          let cutArrow = cut.arrow;
          let cutText = cut.text;
          let textAnchor = cut.textAnchor;
          let alignmentBaseline = cut.alignmentBaseline;

          cutToGroupInner += tools.rect(
            ...cutRect, {
            fill: tools.cutToColor,
            stroke: 'none'
          }
          );

          if (typeSvg === 'cart') {
            overs += tools.path(
              cutArrow, { fill: fill }
            );
            overs += tools.text(
              ...cutText, {
              frez_text: '',
              'font-size': '15px',
              fill: fill,
              'text-anchor': textAnchor,
              'alignment-baseline': alignmentBaseline
            }
            );
          }
        }
      }

      cutToGroupInner += overs;
      let cutToGroup = tools.g([
        cutToGroupInner
      ], { hover_show: cutToId });

      a += cutToGroup;
      hovers += this.cutTosTable(
        top, right, bottom, left, cutToId, translateFunc, operationId
      );
    }
    return [a, hovers];
  },
  cutTosTable(
    top, right, bottom, left, cutToId, translateFunc, operationId
  ) {
    let T = translateFunc;
    return tools.g([
      tools.operationTable([
        tools.text(
          (
            operationId !== null
              ? tools.operationIdTspan(`ID: ${operationId}`)
              : ''
          )
          + tools.tspan(
            T(translateLib.CUT_TO_POPUP_NAME), {
            x: 0,
            dy: operationId ? '1.3em' : ''
          }
          )
          + (top ? tools.tspan(
            `${T(translateLib.CUT_TO_T)}: ${tools.rnd(top)}`, {
            x: 0, dy: '1.2em'
          }
          ) : '')
          + (right ? tools.tspan(
            `${T(translateLib.CUT_TO_R)}: ${tools.rnd(right)}`, {
            x: 0, dy: '1.2em'
          }
          ) : '')
          + (bottom ? tools.tspan(
            `${T(translateLib.CUT_TO_B)}: ${tools.rnd(bottom)}`, {
            x: 0, dy: '1.2em'
          }
          ) : '')
          + (left ? tools.tspan(
            `${T(translateLib.CUT_TO_L)}: ${tools.rnd(left)}`, {
            x: 0, dy: '1.2em'
          }
          ) : '')
        )
      ], { operation_id_bg: true, table_id: cutToId })
    ], { id: cutToId });
  }
}
