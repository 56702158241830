import {instances} from "../api_axios_settings";
import {rejectTransform, resolveTransform} from "../utils_api";
import {URI_API_EXTTUNELL} from "../uri_api_action";

const EDGE_EDIT_ROUND_GET = 'edge_edit_round_get';
const EDGE_EDIT_HANDLER_GET = 'edge_edit_handle_get';
const EDGE_EDIT_FASKA = 'edge_edit_faska_get';
const EDGE_EDIT_HANDLE_PAINTING_GET = "edge_edit_handle_painting_get";
const EDGE_EDIT_SREZ_GET = "edge_edit_srez_get";

/**
 * обработка торцов
 * @method getRound  -   получить скругление торцов
 * @method getHandle  -   получить ручки
 */
export const EdgeEditAPI = {
    getRound() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: EDGE_EDIT_ROUND_GET
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getHandler() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: EDGE_EDIT_HANDLER_GET
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getHandlerPainting() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: EDGE_EDIT_HANDLE_PAINTING_GET
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getFaska() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: EDGE_EDIT_FASKA
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getSrez() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: EDGE_EDIT_SREZ_GET
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },


}