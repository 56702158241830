import React from "react";
import "./Checbox.css";

const Checbox = ({ name, onClick, checked }) => {
  return (
    <>
      <label className="switch">
        <input
          onChange={() => {}}
          checked={checked}
          name={name}
          type="checkbox"
        />
        <span onClick={onClick} id={name} className="slider round"></span>
      </label>
    </>
  );
};

export default Checbox;
