import ApiService from "../../api/api";
import {changeAlertStatusAC, changeLoaderStatusAC, checkErrorsAPI} from "./CommonReducer";
import {onChangeFileManagerPhotosModalDT} from "./ModalReducer";


const api = new ApiService();
const initial = {
    furnitureDT: []
}
const actionType = {
    initialFurniture: 'FURNITURE/INITIAL_DT',
    deleteFurnitureDT: 'FURNITURE/DELETE_DT',
    addedPhotoFurniture: 'FURNITURE/ADDED_PHOTOS',
    deletePhotoFurniture: 'FURNITURE/DELETE_PHOTOS',
}

export const actionsFurniture = {
    onInitial: (dt) => {
        return {
            type: actionType.initialFurniture,
            payload: {dt}
        }
    },
    onDelete: (id) => {
        return {
            type: actionType.deleteFurnitureDT,
            payload: {id}
        }
    },
    addedPhotos: (dt, article_id) => {
        return {
            type: actionType.addedPhotoFurniture,
            payload: {dt, article_id}
        }
    },
    deletePhoto: (article_id, pic_article_id) => {
        return {
            type: actionType.deletePhotoFurniture,
            payload: {
                article_id, pic_article_id
            }
        }

    }
}
const FurnitureReducer = (state = initial, action) => {
    switch (action.type) {
        case actionType.initialFurniture: {
            return {
                ...state,
                furnitureDT: action.payload.dt
            }
        }
        case actionType.deleteFurnitureDT: {
            const updateFurnitureDt = state.furnitureDT.filter(f => Number(f.article_id) !== Number(action.payload.id))
            return {
                ...state,
                furnitureDT: updateFurnitureDt
            }
        }
        case actionType.addedPhotoFurniture: {
            const updatePictureFurniture = state.furnitureDT.map(f => {
                if (Number(f.article_id) === Number(action.payload.article_id)) {
                    let copyPicture = f.picture_all.data;
                    copyPicture.push(...action.payload.dt);
                    f.picture_all = {
                        ...f.picture_all,
                        data: copyPicture
                    }
                }
                return f;
            });
            return {
                ...state,
                furnitureDT: updatePictureFurniture
            }
        }
        case actionType.deletePhotoFurniture: {
            const updateFurniture = state.furnitureDT.map(f => {
                if (Number(f.article_id) === Number(action.payload.article_id)) {
                    const updatePhotos = f.picture_all.data.filter(p => Number(p.pic_article_id) !== Number(action.payload.pic_article_id));
                    f.picture_all = {
                        ...f.picture_all,
                        data: updatePhotos
                    }
                }
                return f;
            })
            return {
                ...state,
                furnitureDT: updateFurniture
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const onInitialFurnitureDT = () => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api.getBoxes();

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            dispatch(actionsFurniture.onInitial(response));
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        dispatch(checkErrorsAPI('', 'api: /boxes, method: get (onInitialFurnitureDT)'))
        // dispatch(changeLoaderStatusAC(false));
        console.log(e)
    }
}
export const onDeleteFurniture = (url) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._deleteFurniture(url);
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.res === 'ok' && response.delete_article) {
            dispatch(actionsFurniture.onDelete(response.id_del))
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        dispatch(checkErrorsAPI('', 'api:url+&api=1, m:G, (onDeleteFurniture) '))
        console.log(e)
    }
}

export const addedPhotosFurniture = (files, article_id) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));

    try {
        const dt = [
            {name: 'article_id', value: article_id},
            {name: 'action', value: 'add_file'}
        ]
        const response = await api.importProject(files, `boxes_files/`, dt);
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            const dt = response.map(r => ({
                link: r.link, pic_article_id: r.pic_article_id,
                name: r.name,
                article: article_id,
            }));
            dispatch(actionsFurniture.addedPhotos(dt, article_id));
            dispatch(onChangeFileManagerPhotosModalDT({isOpen: false, article_id: null}));
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        console.log(e);
        dispatch(checkErrorsAPI('', 'api:boxes_files, M:G, (addedPhotosFurniture)'))
        // dispatch(changeLoaderStatusAC(false));
    }

}

export const onDeletePhotoFurniture = (furniture) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._deletePhotoFurniture(Number(furniture.pic_article_id));

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.res === 'ok') {
            dispatch(actionsFurniture.deletePhoto(furniture.article, furniture.pic_article_id));
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(checkErrorsAPI('', 'api:/boxes_files, M:D, (onDeletePhotoFurniture)'))

        console.log(e)
        // dispatch(changeLoaderStatusAC(false));
    }
}
export default FurnitureReducer;
