import React, { useState} from 'react';
import ChoiceGroup from "./Steps/ChoiceGroup";
import ChoicePart from "./Steps/ChoicePart";
import IconArrow from "../../../Icons/IconArrow";

const CreateCombine = ({onChange, selectGroup, group, getT, material, titleMaterial, onCreateCombineMaterial}) => {
    const [step, setStep] = useState({
        isCreate: false
    });


    const setChangeVisibleStep = (key, value) => {
        setStep(prevState => ({...prevState, [key]: value}))
    }

    if (!onChange || !getT || !Array.isArray(group) || !Array.isArray(material) || !(material?.length)) return false;

    return (
        <div>
            {step?.isCreate ?
                <div className="">
                    <div className="combine-prev-step" onClick={() => setChangeVisibleStep('isCreate', false)}>
                        <IconArrow/>{getT('Назад')}</div>
                    <label htmlFor="" className={'label'}>{getT('Название материала')}</label>
                    <input type="text" value={titleMaterial} className={'form-input'}
                           onChange={(e) => onChange({key: 'titleMaterial', value: e.target?.value})}/>
                    <button className={'btn'} onClick={onCreateCombineMaterial}>{getT('Создать материал')}</button>
                </div>
                : <>
                    <ChoiceGroup onChange={onChange} group={group} getT={getT} selectGroup={selectGroup}/>
                    {selectGroup ?
                        <ChoicePart getT={getT} selectGroup={selectGroup} group={group} onChange={onChange}
                                    onNextStep={(value) => setChangeVisibleStep('isCreate', value)}
                                    material={material}/> : null}
                </>}

        </div>
    );
};

export default CreateCombine;