import React from 'react';

import "./StockMaterialClients.css"
import Modal from "../BasicModal";
import Container from "../../Container";
import {useDispatch, useSelector} from "react-redux";
import {changeStockMaterialClientModalValue} from "../../../store/reducers/ModalReducer";
import {addStockMaterialClientThunk} from "../../../store/reducers/MaterialsReducer";
import {useLang} from "../../../context/langProvider";

const StockMaterialClients = () => {
    const {getT} = useLang()
    const dispatch = useDispatch();
    const open = useSelector(state => state.modal.stockMaterialClient.isOpen);
    const stock = useSelector(state => state.modal.stockMaterialClient.stock);
    const material = useSelector(state => state.order.order.material);
    const onClose = () => {
        dispatch(changeStockMaterialClientModalValue('isOpen', false))
    }
    const onGetMaterial = (id) => {
        dispatch(addStockMaterialClientThunk(null, {
            code: null,
            goods_id: id,
            name: null,
            producer: null,
            x: null,
            y: null,
            z: null,
        }, null, 1))
    }
    const checkIsMaterial = (id) => {
        const isMaterial = material.filter(m => Number(m.goods_id) === Number(id));
        return isMaterial.length === 0 ? <span
            className={"btn"}
            onClick={() => onGetMaterial(id)}>{getT("Добавить")}</span> : ''
    }
    return (
        <div className="container-stock">
            <Modal title={getT("Остатки клиента")} open={open} onClose={onClose}>
                <Container>
                    {stock.length >= 1 ? (
                        <table className={"stock-table"}>
                            <thead>
                            <tr>
                                <td>{getT("Название")}</td>
                                <td>{getT("Размер (мм)")}</td>
                                <td>{getT("Кол-во")}</td>
                                <td>{getT("Дата")}</td>
                                <td>{getT("Действие")}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {stock.map(l => {
                                return (<tr>
                                    <td>[{l.goods_id}]{l.name}</td>
                                    <td> {l.x} <strong>x </strong>
                                        {l.y}
                                    </td>
                                    <td>{l.count}</td>
                                    <td>{l.date_in}</td>
                                    <td>{checkIsMaterial(l.goods_id)}</td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    ) : (
                        <h3 style={{margin: "0 auto"}}>{getT("Остатков клиента на складе нет")}</h3>
                    )}

                </Container>
            </Modal>
        </div>
    )
}

export default StockMaterialClients;
