import React, {useCallback, useState} from 'react';
import Tabs from "../Tabs";
import TelegramLogo from "../../../images/messengers/telegram_logo.png";
import QrCode from "../../QrCode";
import ViberLogo from "../../../images/messengers/viber_logo.png";
import {useDetectClickOutside} from "react-detect-click-outside";
import {useSelector} from "react-redux";
import {getProductionTelegramQr, getProductionViberQr} from "../../../selectors/production-selectors";
import ImageSetting from "./ImageSetting";
import IconQuestion from "../../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../store/constats/help_article_constants";

const BurgerMenu = (props) => {
    const {getT, activeTab, onTabChange, onOpenSettingPage} = props;

    const productionTelegramQr = useSelector(getProductionTelegramQr) || ''
    const productionViberQr = useSelector(getProductionViberQr) || ''

    const [burgerMenu, setBurgerMenu] = useState(false)
    const refBurgerMenu = useDetectClickOutside({
        onTriggered: () => setBurgerMenu(false)
    });

    const socialEntry = [
        {src: TelegramLogo, qrProps: productionTelegramQr},
        {src: ViberLogo, qrProps: productionViberQr},
    ]

    const onToggleTab = useCallback((key) => {
        setBurgerMenu(false);
        onTabChange(key)
    }, [setBurgerMenu, onTabChange])

    return (
        <div className="burger-menu-wrap" ref={refBurgerMenu}>
            <IconQuestion onClick={props.handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.menu_project)} style={{marginLeft: '6px'}}/>

            <button className={burgerMenu ? "burger-btn open" : "burger-btn"} onClick={() => {
                setBurgerMenu(!burgerMenu);
            }}>
                <i className="bars"></i>
                <i className="bars"></i>
                <i className="bars"></i>
            </button>

            {burgerMenu &&
                <div className={"container-menu"}>
                    <div className="tabs-burger">
                        <Tabs getT={getT} active={activeTab} onChange={onToggleTab} handlerHelpArticle={props.handlerHelpArticle}/>
                    </div>

                    <div className="icons-burger header-icons">
                        {socialEntry?.filter(social => social?.qrProps)?.map(social => {
                            return <div className={'messengers_icons_header_container viber_header_icon'}>
                                <img src={social.src} alt=""/>
                                <div className="messengers_icons_header_qrs_code_container">
                                    <QrCode qrProps={{value: social.qrProps, size: 80}}
                                            cName={'messengers_icons_header_qrs_code'}/>

                                    <small>{getT('Отсканируйте с помощью мобильного телефона')}</small>
                                </div>
                            </div>
                        })}

                        <div className="shoping-card setting-firm" onClick={() => {
                            setBurgerMenu(false);
                            onOpenSettingPage()
                        }
                        }>
                            <ImageSetting title={getT('Обновить настройки фирмы')}/>
                        </div>

                    </div>
                </div>}
        </div>
    );
};

export default BurgerMenu;