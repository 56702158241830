import ReactGA from "react-ga4";

const helpersGetItemsOrder = (order)=>{
    try {
        let material = order?.calculate?.material?.filter(e => Number(e.count) > 0)?.map(item => {
            return {
                item_name: item?.name,
                item_id: item?.id,
                price: Number(item?.base_price)?.toFixed(2),
                quantity: item?.count
            }
        }) || [];
        let service = order?.calculate.service?.length ?  order?.calculate.service?.map(item => {

            return {
                item_name: item?.name,
                item_id: item?.service_id,
                price: Number(item?.base_price)?.toFixed(2),
                quantity: item?.count
            }
        }) : [];
        let goods = order?.calculate?.length ? order?.calculate?.map(item => {

            return {
                item_name: item?.name,
                item_id: item?.id,
                price: Number(item?.base_price)?.toFixed(2),
                quantity: item?.count
            }
        }): [];
        let extra = order?.extra?.length ? order?.extra?.map(item => {
            return {
                item_name: item?.descr,
                item_id: item?.id,
                price: Number(item?.summ)?.toFixed(2),
                quantity: 1
            }
        }) : [];

        return [...material, ...service, goods, extra]
    }catch (e) {
        console.error('helpersGetItemsOrder', e?.message)
        return []
    }
}

const loginIn = () => ReactGA.event('login', {method: "standard"});
const signUp = () => ReactGA.event('sign_up', {method: "standard"});


const pageView = () => {
    let location = window.location.pathname + window.location.search;
}

const fileDownload = ({
                          file_name = '',
                          file_extension = '', link_url = '', link_text = ''
                      }) => {
    ReactGA.event("file_download", {
        file_name: file_name,
        file_extension: file_extension,
        link_url: link_url, link_text: link_text
    })
}

const customEvent = (action = "klik_form", params = {}) => {
    ReactGA.gtag('event', action, params)
}
const viewCart = (order) => {
let items = helpersGetItemsOrder(order)
    ReactGA.gtag('event', 'view_cart', {
        items: items
    })
}
const beginCheckout = (order) => {
    let items = helpersGetItemsOrder(order)
    ReactGA.gtag('event', 'begin_checkout', {
       items: items
    })
}

const purchase = ({order, total}) => {
    let items = helpersGetItemsOrder(order)
    ReactGA.gtag('event', 'purchase', {
        transaction_id: order.code,
        items: items,
        value: total,
        currency: order?.currency
    })
}
const gaEvents = {
    pageView: pageView,
    fileDownload: fileDownload,
    customEvent: customEvent,
    loginIn, signUp, viewCart, beginCheckout, purchase
}
export {gaEvents}


