import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../BasicModal";
import Container from "../../Container";
import {changeAddClientMaterialModalValue} from "../../../store/reducers/ModalReducer";
import {
    _constructorMaterial,
    changeClientMaterialValuesAC,
    cleanMaterialsFormDataAC,
    getMaterialsTreeDataThunk,
    getProducersThunk
} from "../../../store/reducers/MaterialsReducer";
import './ClientMaterialsBands.css';
import MaterialParts from './MaterialParts';
import {addOrderMaterialAC} from "../../../store/reducers/OrderReducer";
import {random} from "../../../helpers/helpers";
import {useLang} from "../../../context/langProvider";
import {getAddClientMaterial} from "../../../selectors/modal-selector";
import {getAllProduction} from "../../../selectors/production-selectors";
import {getCurrencies, getDrop} from "../../../selectors/common-selector";
import {gaEvents} from "../../../gaEvents";

const ClientMaterialsBands = () => {

    const isOpen = useSelector(getAddClientMaterial);

    return isOpen ? <Component isOpen={isOpen}/> : null;
};

const Component = ({isOpen}) => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const close = () => dispatch(changeAddClientMaterialModalValue("isOpen", false));
    const productionCONST = useSelector(getAllProduction);
    const form = useSelector((state) => state.materials.clientMaterial);

    const changeValue = (input, value) => dispatch(changeClientMaterialValuesAC(input, value));


    const getType = (type) => {
        return type === form.typeMaterial;
    }

    const addMaterial = () => {
        let material = _constructorMaterial(form, parseInt(Math.random() * 100000000000, 10));
        dispatch(addOrderMaterialAC(material));
        gaEvents.customEvent('add_to_cart', {
            items: [{
                item_name: material?.name,
                item_id: material?.goods_id,
                price: material?.price,
                currency: material?.currency,
                quantity: 1,
                clientMaterial: true,
                typeMaterial: material?.typeMaterial
            }]

        })
        dispatch(cleanMaterialsFormDataAC())
    };


    useEffect(() => {
        dispatch(getMaterialsTreeDataThunk());
        dispatch(getProducersThunk());
    }, []);


    ///////
    const plateTypes = useSelector((state) => {
        if (state.materials.tree.length === 0) return [];
        let plates_type = [];
        const plates = state.materials.tree.filter((e) => {
            if (Number(e.id) === 4) {
                return e;
            }
        })[0].childs;
        plates.forEach((e) => {
            if (e.hasOwnProperty('childs') && e.childs.length > 0) {
                plates_type = plates_type.concat(e.childs);
            } else {
                plates_type.push(e)
            }
        });
        return plates_type;
    });
    const bandTypes = useSelector((state) => {
        if (state.materials.tree.length === 0) return [];
        return state.materials.tree.filter((e) => {
            if (Number(e.id) === 7) {
                // if(e.name===getT("Кромка")){
                return e;
            }
        })[0].childs;
    });
    const producers = useSelector((state) => state.materials.producers);
    const currencies = useSelector(getCurrencies);
    const drops = useSelector(getDrop);

    const renderDropOptions = () => {
        if (form.typeMaterial === 'part') {
            return drops.map(e => {
                return <option key={e.id} value={e.id}>{getT(e.name)}</option>
            })
        }
        if (form.typeMaterial === 'band') {
            return drops.filter(e => String(e.id) === '1').map(e => {
                return <option key={e.id} value={e.id}>{getT(e.name)}</option>
            })
        }
    }
    return <Modal title={getT('Материал/кромка клиента')} open={isOpen} onClose={() => close()} cName={'modal-xl'}>
        <Container>
            <div className={'client-materials-adds-main-container '}>
                <form className="add-material add-client-material mb-2" id={'client-material-add-form'}
                      onSubmit={(event) => {
                          event.preventDefault();
                          addMaterial();
                      }}>
                    <div className="form-group-rx-sm-02 form-group-rx-md-03">
                        <div className="form-field">
                            <label className="label label-sm" htmlFor="producer">
                                {getT('Тип материала')}:
                            </label>
                            <select className="form-input-sm"
                                    value={form.typeMaterial}
                                    defaultValue={form.typeMaterial}
                                    onChange={(event) => {
                                        changeValue('typeMaterial', event.target.value);
                                        if (event.target.value === 'band') {
                                            changeValue('x', 10000);
                                        } else {
                                            changeValue('x', '');
                                        }
                                        changeValue('name', `Материал #${random(2, 2, 1)}`)
                                    }}
                                    required={'required'}
                            >
                                <option value={null}>--- {getT('Выбрать')} ---</option>
                                <option value="part">{getT('Плитный материал')}</option>
                                <option value="band">{getT('Кромка')}</option>
                            </select>
                        </div>
                        <div className="form-field">
                            <label className="label label-sm" htmlFor="producer">
                                {getType('part') && <> {getT('Тип плиты')}</>}
                                {getType('band') && <>{getT('Тип кромки')}</>}
                                :
                            </label>
                            <select className="form-input-sm"
                                    value={form.addedType}
                                // defaultValue={form.addedType}
                                    onChange={(event) => {
                                        changeValue('addedType', event.target.value);

                                    }}
                                    required={'required'}
                            >
                                <option value={''} disabled={true} selected={true}>--- {getT('Выбрать')} ---</option>
                                {getType('part') && <>
                                    {plateTypes.map(e => {
                                        return <option key={e.id} value={e.id}>{e.name}</option>
                                    })} </>}
                                {getType('band') && <>
                                    {bandTypes.map(e => {
                                        return <option key={e.id} value={e.id}>{e.name}</option>
                                    })} </>}
                            </select>
                        </div>

                        <div className="form-field">
                            <label className="label label-sm" htmlFor="producer">
                                {getT('Производитель')}:
                            </label>
                            <select className="form-input-sm"
                                    value={form.producer}
                                // defaultValue={form.producer}
                                    onChange={(event) => {
                                        changeValue('producer', event.target.value);
                                        changeValue('name', `${getT('Материал')} #${random(2, 2, 1)}`)
                                    }}
                                    required={'required'}
                            >
                                <option value={''} disabled={true} selected={true}>--- {getT('Выбрать')} ---</option>
                                {producers?.sort((a, b) => {
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                }).map(e => {
                                    return <option key={e.id} value={e.id}>{e.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-field">
                            <label className="label label-sm" htmlFor="material-type">
                                {getType('part') && <>{getT('Название материала')}</>}
                                {getType('band') && <>{getT('Название кромки')}</>}
                            </label>
                            <input
                                type="text"
                                className="form-input-sm"
                                placeholder=""
                                value={`${form.name}`}
                                onChange={(event) => changeValue('name', event.target.value)}
                                required={'required'}
                            />
                        </div>

                        <div className="form-field" style={{gridColumn: "span 2"}}>
                            <label className="label label-sm" htmlFor="material-type">
                                {getT('Введите описание материала')}:
                            </label>
                            <textarea
                                className="form-input-sm"
                                placeholder=""
                                value={form.description}
                                onChange={(event) => changeValue('description', event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-field">
                        <label className={'form-input-checkbox-label'} htmlFor={'add_materials_part_davalch'}>
                            <input type="checkbox"
                                   value={form.isDavalchMaterial}
                                   checked={form.isDavalchMaterial ? 'checked' : ''}
                                   className={'form-input-checkbox'}
                                   id={'add_materials_part_davalch'}
                                   onChange={(event) => changeValue('isDavalchMaterial', event.target.checked)}
                            />
                            <span style={{
                                fontWeight: 'bold',
                                color: "#605d81"
                            }}>{getT('Давальческий материал')}</span>
                        </label>

                    </div>
                    <div className="form-group-rx-sm-02 form-group-rx-md-03">
                        {!form.isDavalchMaterial && <>
                            <div className="form-field">
                                <label className="label label-sm" htmlFor="producer">
                                    {getT('Валюта')}:
                                </label>
                                <select className="form-input-sm"
                                        value={form.currency}
                                    // defaultValue={form.currency}
                                        onChange={(event) => changeValue('currency', event.target.value)}
                                        required={'required'}
                                >
                                    <option value={''} selected={true} disabled={true}>--- {getT('Выбрать')} ---
                                    </option>
                                    {currencies.map(e => {
                                        return <option key={e.id} value={e.id}>{e.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="form-field">
                                <label className="label label-sm" htmlFor="material-type">
                                    {getT('Цена')}:
                                </label>
                                <input
                                    type="number"
                                    className="form-input-sm"
                                    min={0.01}
                                    step={0.01}
                                    placeholder=""
                                    value={form.price}
                                    onChange={(event) => changeValue('price', event.target.value)}
                                    required={'required'}
                                />
                            </div>
                        </>}
                        <div className="form-field">
                            <label className="label label-sm" htmlFor="producer">
                                {getT('Тип продажи')}:
                            </label>
                            <select className="form-input-sm"
                                    value={form.drop}
                                // defaultValue={form.drop}
                                    onChange={(event) => changeValue('drop', event.target.value)}
                                    required={'required'}
                            >
                                <option value={''} disabled={true} selected={true}>--- {getT('Выбрать')} ---</option>
                                {renderDropOptions()}
                            </select>
                        </div>
                        {getType('part') && <>
                            <div className="form-field">
                                <label className="label label-sm" htmlFor="material-type">
                                    {getT('Длина')}:
                                </label>
                                <input
                                    type="number"
                                    className="form-input-sm"
                                    min={productionCONST['production.min_size_material_client']}
                                    max={productionCONST['production.max_size_material_client']}
                                    step={1}
                                    placeholder=""
                                    value={form.x}
                                    onChange={(event) => changeValue('x', event.target.value)}
                                    required={'required'}
                                />
                            </div>
                        </>}
                        <div className="form-field">
                            <label className="label label-sm" htmlFor="material-type">
                                {getT('Ширина')}:
                            </label>
                            <input
                                type="number"
                                className="form-input-sm"
                                min={getType('part') ? productionCONST['production.min_size_material_client'] : 0}
                                max={getType('part') ? productionCONST['production.max_size_material_client'] : 99999}
                                step={1}
                                placeholder=""
                                value={form.y}
                                onChange={(event) => changeValue('y', event.target.value)}
                                required={'required'}
                            />
                        </div>
                        <div className="form-field">
                            <label className="label label-sm" htmlFor="material-type">
                                {getT('Толщина')}:
                            </label>
                            <input
                                type="number"
                                className="form-input-sm"
                                min={getType('part') ? 2.5 : 0.4}
                                max={getType('part') ? productionCONST['production.max_z_part'] : 2.0}
                                step={0.1}
                                placeholder=""
                                value={form.z}
                                onChange={(event) => changeValue('z', event.target.value)}
                                required={'required'}
                            />
                        </div>

                        {getType('part') && <>
                            <div className="form-field">
                                <label className="label label-sm" htmlFor="material-type">
                                    {getT('Количество')}:
                                </label>
                                <input
                                    type="number"
                                    className="form-input-sm"
                                    min={1}
                                    step={1}
                                    placeholder=""
                                    value={form.count}
                                    onChange={(event) => changeValue('count', event.target.value)}
                                    required={'required'}
                                />
                            </div>
                            <div className="form-field">
                                <label className="label label-sm" htmlFor="producer">
                                    {getT('Порядок использования материала')}:
                                </label>
                                <select className="form-input-sm"
                                        value={form.mat_calc_type}
                                    // defaultValue={form.mat_calc_type}
                                        onChange={(event) => changeValue('mat_calc_type', event.target.value)}
                                        required={'required'}
                                >
                                    <option value={null} disabled={true} selected={true}>--- {getT('Выбрать')} ---
                                    </option>
                                    <option value="1">{getT('Остатки в первую очередь')}</option>
                                    <option value="2">{getT('Листы в первую очередь')}</option>
                                </select>
                            </div>
                        </>}
                        <div className="form-field">
                            <label className="label label-sm" htmlFor="material-type">
                                {getT('Код 1С (не обязательно)')}:
                            </label>
                            <input
                                type="text"
                                className="form-input-sm"
                                placeholder=""
                                value={form.user_code_material_1c}
                                onChange={(event) => changeValue('user_code_material_1c', event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-field" style={{marginTop: '15px'}}>
                        <input className="form-input submit" type="submit" value={getT('Добавить')}/>
                    </div>
                </form>
                {getType('part') && <> <MaterialParts getT={getT}/> </>}

            </div>
        </Container>
    </Modal>
};

export default ClientMaterialsBands;

