import React from 'react';
import './Alert.css';
import {useDispatch, useSelector} from "react-redux";
import {changeAlertStatusAC} from '../../store/reducers/CommonReducer';
import CloseModal from "../../Icons/Actions/Close";

const Alert = () => {

    const dispatch = useDispatch();
    const closeAlert = () => dispatch(changeAlertStatusAC(false, ''));
    const message = useSelector((state) => state.commons.alert.message);
    const success = useSelector((state) => state.commons.alert.success);

    return <div className={success ? "service_alert_container_main success":"service_alert_container_main"}>
        <div className={"alert_container__header"}>
            <CloseModal onClick={() => closeAlert()} />
        </div>
        <div className={"alert_container__message"}>
            {message}
        </div>  
    </div>;
}

export default Alert;