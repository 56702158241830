import React, {useCallback} from "react";
import Container from "../Container";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../Modals/BasicModal";
import {changeMemoryDTOrderProjectModalValue} from "../../store/reducers/ModalReducer";
import "./MemoreDTOrderModal.css"

const MemoryDTOrderModal = ({getT, orderDate, stageLSDate, onSaveProject, isOpen}) => {
    const dispatch = useDispatch();
    const onClose = useCallback(() => {
        dispatch(changeMemoryDTOrderProjectModalValue("isOpen", false));
    }, [dispatch]);

    if (!isOpen || !orderDate) return null
    return <Modal title={getT("Какую версию проекта нужно загрузить") + "?"} open={isOpen}
                  onClose={onClose}>
        <Container>
            <div className="memory-modal-body">
                <p>{getT("Данные  памяти и диска различаются, выберите по дате/времени какую из версий нужно загрузить") + "!"}</p>
                <ul className="list">
                    <li style={{marginBottom: "15px"}}>
                        <strong>{orderDate}</strong> -{getT(" данные в памяти")}
                    </li>
                    <li>
                        <strong>{stageLSDate}</strong> - {getT(" данные с диска")}
                    </li>
                </ul>
                <div className="warning-modal__btns">
                    <button className="btn btn-sv" onClick={() => {
                        onSaveProject(true, true)

                    }
                    }>{getT("Загрузить версию из памяти")}
                    </button>
                    <button className="btn btn-sv invers" onClick={() => {
                        onSaveProject(true, false)
                    }
                    }>{getT("Загрузить версию из диска")}
                    </button>
                </div>
            </div>
        </Container>
    </Modal>
}
export default MemoryDTOrderModal
