import React from 'react';
import {useSelector} from "react-redux";
import {useLang} from "../../../context/langProvider";

const OrderSuccess = () => {
    const {getT} = useLang();

    const order = useSelector((state) => state.order.order)

    return <div className="ordering-wrapper order-confirm-success">
        <div className="ordering-header">
            <h1>{getT('Заказ успешно подтвержден!')}</h1>
        </div>
        <div className="order-confirm-success-content">
            <div className="row">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle" width={154}
                     height={154}
                     className="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path fill="lightgreen"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path>
                </svg>
            </div>
            <div className="row">
                <h3>{getT('Спасибо за заказ!')}</h3>
            </div>
            <div className="row">
                <div>{getT('Номер Вашего заказа')}: {order.code} | <b>{order.id}</b> | ({order.title})</div>
            </div>
        </div>
        <div className="ordering-footer">
            <div className="total-content">
                <a href="/account" className="content-btn">{getT('В личный кабинет')}</a>
            </div>
        </div>
    </div>;
}

export default OrderSuccess;