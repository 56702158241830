import {instances} from "./api_axios_settings";
import {URI_API_CUTTING} from "./uri_api_action";
import {rejectTransform, resolveTransform} from "./utils_api";

export const CuttingAPI = {
    getCuttingByOrderId(orderId) {
        return instances.get(`${URI_API_CUTTING}/${orderId}`)
            .then(res => {
                let response = resolveTransform(res);
                if (response?.error) return Promise.resolve(response)
                return Promise.resolve(response)
            })
            .catch(error => rejectTransform(error))
    },
    getCuttingGlLinkByOrderId(orderId) {
        return instances.get(`${URI_API_CUTTING}/${orderId}/gl`)
            .then(res => {
                let response = resolveTransform(res);
                if (response?.error) return Promise.resolve(response)
                return Promise.resolve(response)
            })
            .catch(error => rejectTransform(error))
    },
}