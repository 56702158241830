import React from 'react';
import MainApi from "../../api/MainApi";
import {BASE_PATH_URL_PACKAGE_BOX} from "./package_const";

const API = new MainApi();

const PackageIframe = ({iframeLoaded, iframeCurrent, iframeError = null}) => {

    const handlerError = (error) => {
        if (iframeError) iframeError(error)
    }

    return (
            // https://api2.ifurn.pro/vendor/box3d/api.html
            <iframe ref={iframeCurrent} id="api" src={BASE_PATH_URL_PACKAGE_BOX}
                    style={{
                        width: '100%',
                        height: 'calc(100vh - 65px - 25px - 40px)',
                        border: 'none',
                        background: '#eee'
                    }}
                    onLoad={()=> {
                        iframeLoaded();
                    }}
                    onError={handlerError}
            >

            </iframe>
    );
};

export default PackageIframe;