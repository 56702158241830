import React from 'react';
import {connect} from "react-redux";
import AddPartModal from "../AddPartModal/AddPartModal";
import {changeAddPartModalValue} from "../../../store/reducers/ModalReducer";
import {addNewPartThunk, changeAddPartFormValuesAC} from "../../../store/reducers/OrderReducer";


class AddPartModalContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return !this.props.isOpen ? null : <AddPartModal open={this.props.isOpen}
                             onClose={this.props.closeModal}
                             form={this.props.form}
                             changeValue={this.props.changeValue}
                             addPart={this.props.addPart}
                             materials={this.props.materials}
        />;
    }
}

let mapStateToProps = (state) => {
    return {
        isOpen: state?.modal?.addPart?.isOpen || false,
        form: state.order.addPartForm,
        materials: state?.order?.order?.material || []
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        closeModal: () => dispatch(changeAddPartModalValue('isOpen', false)),
        changeValue: (input, value) => dispatch(changeAddPartFormValuesAC(input, value)),
        addPart: (part_id) => dispatch(addNewPartThunk(false, part_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPartModalContainer);
