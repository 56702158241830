import React, {useState} from 'react';
import Edit from "../../../../../Icons/TableActions/Edit";
import UserDataEditForm from "./UserDataEditForm";
import Close from "../../../../../Icons/Actions/Close";
import IconConfirm from "../../../../../Icons/IconConfirm";
import IconNotConfirm from "../../../../../Icons/IconNotConfirm";
import Card from "../../../../../components/Card";
import ConfirmAuthModal from "../../../../../components/Modals/ConfirmAuthModal";
import {useDispatch, useSelector} from "react-redux";
import {getMessageChannelsDefaultSelected} from "../../../../../selectors/cabinet-sector";
import {confirmMessageChannelEmailOrPhone} from "../../../../../store/reducers/AuthReducer";

const styleIcon = {
    marginLeft: "8px"
}
const UserData = ({getT, userDT}) => {
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const message_channel_default = useSelector(getMessageChannelsDefaultSelected)
    const handlerCLoseEdit = () => setIsEdit(false);
    const handlerConfirm = ({value, send_password, message_channel = null, password = null}) => (e) => {
        dispatch(confirmMessageChannelEmailOrPhone({value, send_password, message_channel, password}))
    }
    return (
        <>
            <Card className={'user-data'} key={'user-data-card'}>
                <div className="user-data-panel">
                    {isEdit
                        ? <Close isBlack={true} width={'25px'} height={"25px"} style={{marginLeft: '8px', padding: '0'}}
                                 onClick={handlerCLoseEdit}/>
                        :
                        <Edit onClick={() => setIsEdit(true)} className={"cursor-pointer"} width={'22px'}
                              height={"22px"} style={{marginLeft: '8px'}}/>}
                </div>


                {isEdit ?
                    <UserDataEditForm userDT={userDT} getT={getT} onClose={handlerCLoseEdit}/>
                    :
                    <ul className={'list-none'}>
                        <li className={"d-flex items-center"}>
                        <span className="label">
                              <strong style={{marginRight: "8px"}}>{getT("Пользователь")}:</strong>
                        </span>
                            {userDT?.name}
                        </li>
                        <li className={"d-flex items-center"}>
                        <span className="label">
                              <strong style={{marginRight: "8px"}}>{getT("Email")}:</strong>
                        </span>
                            {userDT?.email}
                            {!!(userDT?.email) && <>
                                {!!userDT?.email_confirmed ?
                                    <span style={styleIcon} title={getT("Подтвержден")}><IconConfirm/></span>
                                    :
                                    <span style={styleIcon} title={getT("Подтвердите почту")} onClick={handlerConfirm({
                                        value: userDT?.email,
                                        send_password: 1
                                    })}><IconNotConfirm/></span>}
                            </>}
                        </li>
                        <li className={"d-flex items-center"}>
                        <span className="label">
                              <strong style={{marginRight: "8px"}}>{getT("Телефон")}:</strong>
                        </span>
                            {userDT?.phone}
                            {!!(userDT?.phone) && <>
                                {!!userDT?.phone_confirmed ?
                                    <span style={styleIcon} title={getT("Подтвержден")}><IconConfirm/></span>
                                    :
                                    !message_channel_default ?
                                        <span className={'clr-red fs-12'}
                                              style={styleIcon}>{getT("Подключите чат-бот")}</span>
                                        :
                                        <span style={styleIcon} title={getT("Подтвердите телефон")}
                                              onClick={
                                                  handlerConfirm({
                                                      value: userDT?.phone,
                                                      send_password: 1,
                                                      message_channel: message_channel_default
                                                  })
                                              }><IconNotConfirm/></span>
                                }</>}

                        </li>
                    </ul>
                }
            </Card>
            <ConfirmAuthModal key={'ConfirmAuthModal'}/>
        </>
    );
};

export default UserData;