import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFurnitureDT} from "../../selectors/furnityre-selector";
import {onInitialFurnitureDT} from "../../store/reducers/FurnitureReducer";
import {Card} from "./Card";
import FileManagerPhotos from "../../components/Modals/FileManagerPhotos";

const Furniture = ({getT}) => {
    const dispatch = useDispatch();
    const furnitureDT = useSelector(getFurnitureDT);
    useEffect(() => {
        dispatch(onInitialFurnitureDT());
    }, []);


    return <div className={"settings-section w-100"}>
        <div className="head">
            <div className="filed">
                <h2>{getT('Мебельный модуль')}</h2>
            </div>
        </div>
        <div className="main_content">
            <div className="card-container furniture-card-container">
                {Array.isArray(furnitureDT) && furnitureDT.length >=1 &&
                furnitureDT.map((furniture, idx) =>{
                    return <Card getT={getT} box={furniture} key={idx}/>
                })
                }
            </div>

        </div>
        <FileManagerPhotos getT={getT}/>
    </div>
}
export default Furniture;