import React, {useEffect} from "react";
import './AddPartModal.css';
import Container from "../../Container";
import Modal from "../BasicModal";
import {useSelector} from "react-redux";
import {focusedNexElementDetailing, random} from "../../../helpers/helpers";
import {useLang} from "../../../context/langProvider";
import {getSelectedMaterialOfDetailing} from "../../../selectors/order-selector";

// const defaultOptions = ["default", "one", "two", "three"];
const AddPartModal = ({open, onClose, form = {}, changeValue, addPart, materials = []}) => {
    const {getT, lang} = useLang();
    const selectedMaterial = useSelector(getSelectedMaterialOfDetailing);
    const CONST = useSelector(state => state.order.order.production_constants);
    useEffect(() => {
        if (CONST.hasOwnProperty('production.paz_side_torec_off') && Number(CONST['production.paz_side_torec_off']) === 1) {
            changeValue('name', '');
        } else if (Number(CONST['production.new_detail_name_no_create']) === 1) {
            changeValue('name', '');
        } else {
            changeValue('name', random(1, 1, 1));
        }

    }, [open]);
    return <Modal title={getT("Добавление новой детали")} open={open} onClose={() => onClose(false)}>
        <Container>
            <form className="add-material" onSubmit={(event) => {
                event.preventDefault();
                let part_id = Number(document.querySelector('.part')?.getAttribute('data-id')) || false
                addPart(part_id);
                onClose(false);
                setTimeout(() => {
                    if (selectedMaterial.goods_id === form.material) {
                        focusedNexElementDetailing({classN: 'focused', row: (0), col: 0, cb: null})
                    }
                }, 1000);
            }}>
                <div className="form-group-rx-sm-02">
                    <div className="form-field">
                        <label className="label" htmlFor="material-type">
                            {getT('Название детали')}:
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder=""
                            value={form.name}
                            onChange={(event) => changeValue('name', event.target.value)}
                            required={'required'}
                        />
                    </div>
                    <div className="form-field">
                        <label className="label" htmlFor="producer">
                            {getT('Материал')}:
                        </label>
                        <select className="form-input"
                                value={form.material}
                                onChange={(event) => changeValue('material', event.target.value)}
                                required={true}
                        >
                            <option value={null} disabled={true} selected={true} className={'d-none'}></option>
                            {materials.map((e) => (
                                <option key={e.goods_id}
                                        value={e.goods_id}>{e.hasOwnProperty('translate') && e['translate'][lang] ? e['translate'][lang] : e.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-group-rx-sm-02 form-group-rx-md-03">
                    <div className="form-field inrow">
                        <label className="label" htmlFor="material-type">
                            {getT('Длина')}:
                        </label>
                        <input
                            type="number"
                            min={0}
                            max={9999}
                            className="form-input"
                            placeholder=""
                            value={form.x}
                            onChange={(event) => changeValue('x', event.target.value)}
                            required={'required'}
                        />
                    </div>
                    <div className="form-field inrow">
                        <label className="label" htmlFor="material-type">
                            {getT('Ширина')}:
                        </label>
                        <input
                            type="number"
                            min={0}
                            max={9999}
                            className="form-input"
                            placeholder=""
                            value={form.y}
                            onChange={(event) => changeValue('y', event.target.value)}
                            required={'required'}
                        />
                    </div>
                    <div className="form-field">
                        <label className="label" htmlFor="login-username">
                            {getT('Количество')}:
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder=""
                            value={form.count}
                            onChange={(event) => changeValue('count', event.target.value)}
                            required={'required'}
                        />
                    </div>
                </div>
                <div className="form-field">
                    <input className="form-input submit" type="submit" value={getT('Добавить')}/>
                </div>
            </form>
        </Container>
    </Modal>
}

export default AddPartModal;
