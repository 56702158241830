import {
    SEARCH_GOODS_FILTER_FORM_UPDATE,
    SEARCH_GOODS_RESULT_OF_FILTER_DATA,
    SEARCH_GOODS_TOGGLE_OPEN_PAGE
} from "../actions/search-goods-action";

export const SEARCH_GOODS_SHOW_FROM_POSITION = {
    show_50: 50, // default
    show_100: 100,
    show_200: 200,

}
export const SEARCH_GOODS_INITIAL_FILTER_FORM = {
    text_search: "",
    // part: 1,
    // tovar: 0,
    // band: 0,
    type_search: "part",
    producer: null,//1,2,
    type_material: [],//1,2,
    x: null,//1,2,
    y: null,//1,2,
    z: null, //1,2,
    only_with_count: 0, //[0||1]
    only_with_price: 0, //[0||1],
    minimal_quantity_for_order_less_than: null, //float,
    availible_stock_more_than: null, //float
    band_connection_only: 0, //[0||1],
    with_pictures_only: 0, //[0,1]
    goods_stock_free_in_stock: 0, //[0||1]
    goods_stock_area_more_than: null, //[0||1]
    goods_stock_count_more_than: null, //[0||1]
    drop_restriction: "", //['sheet','m2'] (Optional)
    count_on_page: SEARCH_GOODS_SHOW_FROM_POSITION.show_50,
    show_from_position: 0, //current position for pagination
}
export const SEARCH_GOODS_INITIAL_RESULT_OF_FILTER_DATA = {
    filter_values: null,
    entry_goods: null,
    count_data: null,
    total_count: null,
    show_from_position: null,
    limit: null
}

const initialState = {
    filter_form: SEARCH_GOODS_INITIAL_FILTER_FORM,
    search_goods_result_filter_data: SEARCH_GOODS_INITIAL_RESULT_OF_FILTER_DATA,
    isOpenPage: false
}


const SearchGoodsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_GOODS_FILTER_FORM_UPDATE:{
            return {
                ...state,
                filter_form: {
                    ...state?.filter_form,
                    ...action.payload
                }
            }
        }
        case SEARCH_GOODS_TOGGLE_OPEN_PAGE: {
            return {
                ...state,
                isOpenPage: action.payload
            }
        }
        case SEARCH_GOODS_RESULT_OF_FILTER_DATA: {
            return {
                ...state,
                search_goods_result_filter_data: {
                    ...state.search_goods_result_filter_data,
                    ...action.payload
                }
            }
        }
        default: {
            return state
        }
    }
}
export default SearchGoodsReducer