export const getProductionLang = state => state.order?.order?.production_constants?.['production.language_set_one'] || '';
export const getProductionDetailsLightDefault = state => state.order?.order?.production_constants?.['production.is_light_details_default'];

export const getProductionLogo = state => state.order?.order?.production_constants?.['react.logo_in_svg'];

export const getProductionViberQr = state => state.order?.order?.production_constants?.['production.link_bot_viber'];
export const getProductionTelegramQr = state => state.order?.order?.production_constants?.['production.link_bot_telegram'];

export const getProductionAutoDetailChangeSizeOff = state => state.order?.order?.production_constants?.['production.auto_detail_change_size_off'];

export const getProductionNecessary = state => state.order?.order?.production_constants?.['production.blank_is_nesessary'];

export const getProductionMinDeltaEdgeMaterial = state => state?.order?.order?.production_constants['production.min_delta_edge_material'] || 0;

export const getSrezTorcaDefiniteAngle = state => state?.order?.order?.production_constants['production.srez_torca_definite_angle'] || '';

export const getDoNotUseResetsCompanyDuringTheOrder = state => state?.order?.order?.production_constants['production.do_not_use_rests_company_during_the_order'] || 0;

export const getIsProductionOldInterface = state => state?.order?.order?.production_constants?.['production.old_interface_on'] || 0;
export const getIsEdgeMaterialByDefaultAlwaysOn = state => state?.order?.order?.production_constants?.['production.edge_material_by_default_always_on'] || 0;

export const getAllProduction = state => state?.order?.order?.production_constants || {};
export const getProductionValueOfKey = (key) => state => state?.order?.order?.production_constants[key] || '';
// export const getProductionXncBoreZenkDiam = state=>  8;
export const getProductionXncBoreZenkDiam = state => state?.order?.order?.production_constants['production.xnc_bore_zenk_diam'] || 0;
export const getProductionXncBoreZenkOn = state => state?.order?.order?.production_constants['production.xnc_bore_zenk_on'] || 0;
export const getProductionProjectMakeStartName = state => state?.order?.order?.production_constants['production.project_make_start_name'] || 0;
export const getProductionPazTemplateOffInInterface = state => {
    let value = state?.order?.order?.production_constants['production.paz_template_off_in_interface'] || 0;
    return !!Number(value)
}

export const getProductionCalcConfirmOnlyOnePhoneIsNeeded = state => {
    let value = state?.order?.order?.production_constants['probuction.calc_confirm_only_one_phone_is_needed'] || 0;
    return !!Number(value)
}
export const getProductionHidePointsOfSalesInConfirmation = state => {
    let value = state?.order?.order?.production_constants['production.hide_points_of_sales_in_confirmation'] || 0;
    return !!Number(value)
}

const setting_def_order_display_keys = {
    // Быстрый режим кромки
    band: 'production.is_light_details_default',
    // Материал
    material: 'production.paramtres_interface_group_by_material',
    band_column: 'production.paramtres_interface_edge_is_needed',
    //'Материал и изделие'
    material_column: 'production.paramtres_interface_material_and_product_in_detail',
    //Название
    names: 'production.paramtres_interface_name_detail',
    //Корректировка ЧПУ
    cnc_override: 'production.paramtres_xnc_is_needed',
    picture: 'production.paramtres_interface_draw',
    //Использовать ли значки обработок торцов в кратком режиме
    edge_edit_in_brief_mode: 'production.edge_edit_in_brief_mode_details_list',
    //
    material_product_in_small_view: 'production.material_product_in_small_view_details_list',

}


export const getSettingDefaultOrderDisplay = (state) => {
    let result_setting = {};
    let production = state?.order?.order?.production_constants;
// Перебираем ключи из obj1
    for (let key in setting_def_order_display_keys) {
        let production_key = setting_def_order_display_keys[key]
        // Проверяем, есть ли ключ из obj1 в obj2
        if (production && production?.hasOwnProperty(production_key)) {
            // console.log(production_key, production[production_key])
            // Если ключ найден, записываем значение из obj1 в obj2
            result_setting[key] = Number(production[production_key]);
        }
    }
    return result_setting
}

export const getVatAddPriceIfMoreThan = (state) => {
    let value = state?.order?.order?.production_constants?.['production.VAT_add_price_if_more_than_0'] || 0;
    return Number(value);
}
export const getVatAddedTaxShow = (state) => {
    /* 0  отображать через / 1 через + */
    let value = state?.order?.order?.production_constants?.['production.vat_added_tax_show_needed'] || 1;
    return !Number(value);
}
/**
 * getProcessingEdgeEditSettings.
 *          edge_edit_interface_on: edge_edit_interface_on, - Включить обработку торцов
 *         edge_handler_on: edge_handler_on,Включить врезные ручки
 *         edge_faska_on: edge_faska_on, Включить обработку фаски детали
 *         edge_srez_on: edge_srez_on, Включить срез торца
 *         edge_round_on: edge_round_on Включить фрезерование торца
 */
export const getProcessingEdgeEditSettings = (state) => {
    /*  доступно ли в интефейсе обработка торцов 0 = выкл 1=вкл*/
    let edge_edit_interface_on = state?.order?.order?.production_constants?.['production.edge_edit_on'] || 0;
    let edge_handler_on = state?.order?.order?.production_constants?.['production.edge_Edit_handles_on'] || 0;
    let edge_faska_on = state?.order?.order?.production_constants?.['production.edge_Edit_faska_on'] || 0;
    let edge_srez_on = state?.order?.order?.production_constants?.['production.edge_Edit_srez_on'] || 0;
    let edge_round_on = state?.order?.order?.production_constants?.['production.edge_Edit_rounding_on'] || 0;
    return {
        edge_edit_interface_on: Number(edge_edit_interface_on),
        operation: {
            hand: Number(edge_handler_on),
            faska: Number(edge_faska_on),
            srez: Number(edge_srez_on),
            round: Number(edge_round_on)
        }
    }
}