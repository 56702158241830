import Modal from "../BasicModal";
import Container from "../../Container";
import {useDispatch, useSelector} from "react-redux";
import {getPlanModal} from "../../../selectors/cells-leftovers-selector";
import React from "react";
import Edit from "../../../Icons/TableActions/Edit";
import Delete from "../../../Icons/Actions/Delete";
import {onTogglePlanModal} from "../../../store/reducers/ModalReducer";
import {
    actionsCellsLeftovers,
    onDeletePlanStockMaterialDT,
    setEditPlanForm
} from "../../../store/reducers/StorageCellsLeftovers";
import {useLang} from "../../../context/langProvider";

const ListPlane = () => {
    const {getT, lang} = useLang();

    const dispatch = useDispatch();
    const {materialList} = useSelector(getPlanModal);
    const onClose = () => {
        dispatch(onTogglePlanModal({key: 'materialList', dt: {isOpen: false, planeStock: [], goods_id: null}}))
    }
    const onEditPlane = (dt, name) => {
        const data = setEditPlanForm(dt);
        dispatch(actionsCellsLeftovers.setInitialValuesForm({form: 'planEditForm', initialValue: data}))
        dispatch(onTogglePlanModal({key: 'edit', dt: {isOpen: true}}))
    }
    const onDeletePlane = (goods_id, id) => {
        dispatch(onDeletePlanStockMaterialDT(goods_id, id));
    }
    return <>
        <div className="container-stock">
            <Modal open={materialList.isOpen} title={getT('Планируемые поступления')} onClose={onClose}>
                <Container>
                    <div className="w-100">
                        {materialList && materialList.hasOwnProperty('planeStock') && Array.isArray(materialList.planeStock) && materialList.planeStock.length >= 1 ?
                            <>
                                <table className={"stock-table"}>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{getT("№ заказа")}</th>
                                        <th>{getT("Статус заказа")}</th>
                                        <th>{getT("Материал")}</th>
                                        <th>{getT("Размер (мм)")}</th>
                                        <th>{getT("Действие")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {materialList.planeStock.map((l, idx) => {
                                        return (<tr>
                                            <td>{idx + 1}</td>
                                            <td title={l.name_order}>{l.id}</td>
                                            <td>{l.status.hasOwnProperty(lang) ? l.status[lang] : ''}</td>
                                            <td>{l.goods.name.hasOwnProperty(lang) ? l.goods['name'][lang] : ''} {l.goods.producer.hasOwnProperty(lang) ? l.goods['producer'][lang] : ''}</td>
                                            <td style={{whiteSpace: 'nowrap'}}>
                                                <span title={'x'}> {l.x}</span>
                                                <strong> x </strong>
                                                <span title={'y'}> {l.y}</span>
                                                {l.z && <strong> x </strong>}
                                                <span title={'z'}> {l.z}</span>

                                            </td>
                                            <td>
                                                <div className={'settings-actions'}>
                                                    {(l.order == null || l.order == '') && <>
                                <span className={'cursor-pointer edit'}
                                      onClick={() => onEditPlane(l, l.name[lang])}><Edit/></span>
                                                        <span className={'cursor-pointer'}
                                                              onClick={() => onDeletePlane(materialList.goods_id, l.orders_goods_stock_rest_plan_id)}><Delete/></span>
                                                    </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </>
                            : <h3 style={{
                                margin: '0px',
                                textAlign: 'center'
                            }}>{getT('Планируемые поступления отсутствуют')}</h3>}
                    </div>
                </Container>

            </Modal>
        </div>
    </>
}
export default ListPlane;