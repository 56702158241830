import React from 'react';
import {useDispatch} from "react-redux";
import {useLang} from "../../../../context/langProvider";
import InputForm from "../../../../components/InputFields/InputForm/InputForm";
import {resetRegisterPasswordThunk} from "../../../../store/reducers/RegisterReducer";

const MasterPassword = ({isDisplayLabel = true, values, handleChange}) => {
    const dispatch = useDispatch();
    const {getT} = useLang();
    // const {values, handleChange} = useFormikContextProviderLogin();
    const onResetPassword = () => {
        let login = values.isRadioLoginEmail ? values.email : values.phone
        dispatch(resetRegisterPasswordThunk("", {login:login}))
    }
    return (
        <div className={'mb-2'}>
            <InputForm
                cNameField={"mb-1"}
                cName={'filed-border'} id="master_password"
                label={isDisplayLabel ? getT('Мастер пароль') : null}
                autoComplete={'off'}
                name="master_password"
                type="password" value={values.master_password}
                onChange={handleChange}/>

            <div className="forgot-password form-group cursor-pointer " onClick={onResetPassword}>
                {getT('Забыли пароль?')}
            </div>

        </div>
    );
};

export default MasterPassword;