

export function filterObjectByKeys(obj, keysToKeep) {
    return Object.keys(obj).reduce((acc, key) => {
        if (keysToKeep.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}

export function unFilterObjectByKeys(obj, keysToKeep) {
    return Object.keys(obj).reduce((acc, key) => {
        if (!keysToKeep.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}
export function isEmail(value) {
    // Check if the value is a string
    if (typeof value !== 'string') {
        return false;
    }

    // Basic email pattern check (you can enhance this pattern based on your needs)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
}
