import React, {useMemo} from 'react';
import total from "../../ShopingCard/TotalPrice";
import {formatNumbersThousands} from "../../../helpers/helpers";
import {getCalculateAmountAndVat, getCalculateVat} from "../../ShopingCard/shoping_card_helper";

const DataOfProject = ({
                           getT,
                           client_name = 'Пупкин Иван',
                           date,
                           firm,
                           packing,
                           order_id,
                           currency,
                           unit,
                           parts,
                           manager_name, production_vat, production_vat_display_short
                       }) => {
    const dataPackingOfProject = useMemo(() => {
        let result = {fullSumm: 0, weight: 0}
        if (Array.isArray(packing) && !!packing?.length) {
            let data_result_array = packing?.map((pack,) => {
                return pack?.packs?.reduce((acc, item) => {
                    return {
                        fullSumm: Number(acc.fullSumm) + Number(item.fullSumm),
                        weight: Number(acc.weight) + Number(item?.packWeight ?? 0)
                    }
                }, result)
            });
            let amount = data_result_array?.reduce((acc, item) => ({
                fullSumm: Number(acc.fullSumm) + Number(item.fullSumm),
                weight: Number(acc.weight) + Number(item.weight)
            }), result);

            return amount
        }
        return result
    }, [packing]);
    const dateAndTime = useMemo(() => {
        let result = {date: null, time: null};
        if (!date) return result;
        let transform_date = new Date(date)
        return {
            date: transform_date?.toLocaleDateString(),
            time: transform_date?.toLocaleTimeString()
        }

    }, date);
    const part_count = useMemo(() => {
        if (!Array.isArray(parts) || !parts?.length) return 0;
        return parts?.reduce((total, part) => Number(part?.count) + total, 0)
    }, parts)
    const total_vat = useMemo(() => {
        return getCalculateVat(dataPackingOfProject.fullSumm, production_vat)
    }, [dataPackingOfProject, production_vat]);
    const amount_total_vat = useMemo(() => {
        return getCalculateAmountAndVat(dataPackingOfProject.fullSumm, total_vat);
    }, [total_vat, dataPackingOfProject]);
    return (<div className={'section-data-project '}>
        <div className="item">
            <strong>{getT('Фирма')}: </strong> {firm}
        </div>
        <div className="item">
            <strong>№ {getT('Заказ')}: </strong> {order_id}
        </div>
        <div className="item">
            <strong>{getT('Дата заказа')}: </strong>{dateAndTime?.date}
        </div>
        <div className="item">
            <strong>{getT('Время')}: </strong>{dateAndTime?.time}
        </div>
        <div className="item">
            <strong>{getT('Клиент')}: </strong>{client_name}
        </div>
        <div className="item">
            <strong>{getT('Менеджер')}: </strong>{manager_name}
        </div>
        <div className="item">
            <strong>{getT('Количество деталей')}: </strong>{part_count}
        </div>
        <div className="item">
            <strong>{getT('Вес')} </strong>{formatNumbersThousands(dataPackingOfProject?.weight)} {getT(unit)}
        </div>
        <div className="item">
            <strong>{getT('Сумма')} </strong>
            <span className="" >  {formatNumbersThousands(dataPackingOfProject.fullSumm)} </span>

            {!!production_vat ?
                production_vat_display_short ?
                    <span className={"amount-total-vat"} style={{fontSize: `${production_vat_display_short ? '14px' : 'inherit'}`}}>
                        {currency} / {amount_total_vat} {currency} {getT("с НДС")} </span>
                    :
                <> + {total_vat} {getT("НДС")} = {amount_total_vat} {currency}</>:currency}
        </div>

    </div>);
};

export default DataOfProject;