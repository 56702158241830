import React from 'react'
import {Sides} from "../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../../store/reducers/SettingsReducer";
import Modal from "../../../../components/Modals/BasicModal";
import {BoreForms, isOpenInstBore} from "../../../../selectors/instruction-selector";

const BoreForm = ({getT, onToggleInstModal, onBeforeSubmit, onUpdateToolsSubmit, type}) => {
    const sidesThrough = ['bore_f', 'bore_bb'];
    const dispatch = useDispatch();
    const onChangeValues = ({key, dt}) => {
        dispatch(actions.onChangeInstrumentsBoreForm({key, dt}))
    };

    const valuesForm = useSelector(BoreForms)
    const isOpen = useSelector(isOpenInstBore);
    const isSelected = (key) => {
        const seleceted = valuesForm.side.filter(s => {
            if (Number(s[key]) == 1) {
                return true
            }
        })
        return seleceted && Array.isArray(seleceted) && seleceted.length >= 1? 'selected' : ''
    }
    const sidesActual = () => {
        if (valuesForm.typeSide.bore_in) {
            return Sides.map(s => <option
               selected={(isSelected(Object.keys(s)))}
                value={Object.keys(s)}>{getT(s[Object.keys(s)])}</option>)
        } else {
            return Sides.map(s => {
                if (sidesThrough.includes(Object.keys(s)[0])) {
                    return <option selected={(isSelected(Object.keys(s)))} value={Object.keys(s)}>{getT(s[Object.keys(s)])}</option>
                }

            })
        }
    };



    return <><Modal title={getT('Форма добавления/редактирование инструмента')} open={isOpen}
                    onClose={() => onToggleInstModal({key: 'bore', dt: false})}>
        <form action="" className={"inst__bore-form"} onSubmit={(e) => {

            e.preventDefault();
            if (valuesForm.isEdit.edit) {
                onUpdateToolsSubmit(valuesForm, valuesForm.isEdit.id);
            } else {
                onBeforeSubmit(valuesForm)
            }

        }}>
            <div className="field-section">
                <div className="field field-on">
                    <label className={"form-input-checkbox-label"} htmlFor="OnOff">
                        <input type="checkbox"
                               id={"OnOff"}
                               className={"form-input-checkbox"}
                               checked={Boolean(valuesForm.on) ? 'checked' : ''}
                               value={valuesForm.on}
                               onChange={(e) => {
                                   onChangeValues({key: 'on', dt: Number(e.target.checked)})
                               }}/>
                        <span>{getT('Вкл')}/{getT('Выкл')}</span>
                    </label>
                </div>

                <div className="field sides-radio">
                    <label className="field-radio" htmlFor={"boreThrough"}
                           // onChange={(e) => onChangeValues({
                           //     key: 'typeSide',
                           //     dt: {[e.target.value]: 1, bore_in: 0}
                           // })}
                        >
                        <input type="radio" id="boreThrough"
                               name="sideType"
                               value="bore_through"
                               checked={Number(valuesForm.typeSide.bore_through) === 1 ? 'checked' : ''}
                               disabled={'disabled'}
                        />
                        <span className={"checkmark "}>{getT('сквозное')}</span>
                    </label>
                    <label className="field-radio" htmlFor={"boreIn"}
                           // onChange={(e) => onChangeValues({
                           //     key: 'typeSide',
                           //     dt: {[e.target.value]: 1, bore_through: 0}
                           // })}
                    >
                        <input type="radio" id="boreIn"
                               name="sideType"
                               value="bore_in"
                               disabled={'disabled'}
                               checked={Number(valuesForm.typeSide.bore_in) === 1 ? 'checked' : ''}
                        />
                        <span className={"checkmark "}>{getT('глухое')}</span>
                    </label>
                </div>
            </div>
            <div className="field select-sides">
                <label htmlFor="" className={"field-title label"}>{getT('Сторона')}</label>
                <select name="side" id="1" multiple={'multiple'}
                        required={'required'}

                        onChange={(e) => {
                            let target = e.target;
                            let valueArray = Array.from(target.selectedOptions, option => option.value);

                            const values = valueArray.map(v => ({[v]: 1}));
                            onChangeValues({key: 'side', dt: values})
                        }
                        }
                >
                    {sidesActual()}
                </select>
            </div>
            <div className="field-section">

                <div className="field">
                    <label htmlFor="" className={"field-title label"}>{getT('Диаметр')}</label>
                    <input className={"input-val"} type="number"
                           step={0.01}
                           min={0} max={100}
                           value={valuesForm.d}
                           required={'required'}
                           onChange={(e) => {
                               let value = Number(e.target.value) > 100 ? 100 : e.target.value
                               onChangeValues({key: 'd', dt: value})
                           }}/>
                </div>
                <div className="field">
                    <label htmlFor="" className={"field-title label"}>{getT('Максимальная глубина')}</label>
                    <input className={"input-val"} type="number"
                           min={0} max={100}
                           step={0.01}
                           value={valuesForm.z_max}
                           required={'required'}
                           onChange={(e) => {
                               let value = Number(e.target.value) > 100 ? 100 : e.target.value
                               onChangeValues({key: 'z_max', dt: value})
                           }}/>
                </div>
            </div>
            <button className={"btn"} type={"submit"}>{getT('Отправить')}</button>

        </form>
    </Modal>
    </>
}

export default BoreForm;