import React, {useEffect, useState} from 'react';
import './Pagination.css';

/**
 * Pagination.
 *
 * @param {number} props.count - кол-во элементов
 * @param {changeHandler} props.changeHandler -
 * @param {page} props.page -
 * @param {pageSize} props.pageSize - кол-во
 * @returns  {jsx}  -
 */

const Pagination = (props) => {
    let page_active = props?.page || 1;
    const [active, setActive] = useState(page_active);
    const pageSize = props?.pageSize || 10;
    const setActivePage = (page) => {
        setActive(page);
        props.changeHandler(page);
    };

    useEffect(() => {
        let page = props?.page || null
        if (!page) return
        if (Number(page) !== Number(active)) {
            setActive(page_active)
        }
    }, [props])
    const renderLinks = () => {
        let links = [];
        let countPage = Math.floor(props.count / pageSize);
        if (props.count % pageSize !== 0) {
            countPage++;
        }
        for (let i = 1; i <= Number(countPage); i++) {
            if (
                i === 1 ||
                i === countPage ||
                (i > (active - 3) && i < (active + 3))

            ) {
                if (i === countPage) links.push(<div className={'page_link_separator'}></div>);
                links.push(<a className={'page-link ' + (Number(active) === Number(i) ? 'active' : '')}
                              onClick={() => setActivePage(i)}> {i} </a>);
                if (i === 1) links.push(<div className={'page_link_separator'}></div>);
            }

        }
        return links;
    }

    return <>
        <div className="pagination_container_main">
            {renderLinks()}
        </div>
    </>
}

export default Pagination;