function DeleteRoundRed(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="38" height="38" rx="19" fill="#FF3F53"/>
      <path d="M25.4173 12.584L12.584 25.4173" stroke="#F3F4F8" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M12.584 12.584L25.4173 25.4173" stroke="#F3F4F8" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  );
}

export default DeleteRoundRed;
