import React from 'react';
import './InfoPages.css';
import {Link} from "react-router-dom";
import LogoLogin from "../../Icons/Logo";
import About from "./Pages/About";
import Contacts from "./Pages/Contacts";
import Privacy from "./Pages/Privacy";
import Offerta from "./Pages/Offerta";
import BasicStaticMenu from "../../components/BasicStaticMenu/BasicStaticMenu";
import {PaymentCardSecurityPolicy} from "./Pages/PaymentCardSecurityPolicy";
import {RefundTerms} from "./Pages/RefundTerms";
import {PriceList} from "./Pages/PriceList";
import {useSelector} from "react-redux";
import {getRuleSettingsProject} from "../../selectors/common-selector";
import Language from "../../components/Language";
import {useLang} from "../../context/langProvider";

const InfoPages = ({uri}) => {
    const {getT, lang} = useLang();

    const ruleSettingProject = useSelector(getRuleSettingsProject);
    // const productionLang = lang || ruleSettingProject?.lang || '';
    const productionLang =  ruleSettingProject?.lang || '';

    const page = uri.match.params.page;

    const getPageContent = () => {
        switch (page) {
            case 'about':
                return <About getT={getT}/>;
            case 'contacts':
                return <Contacts getT={getT}/>;
            case 'privacy':
                return <Privacy getT={getT}/>
            case 'offerta':
                return <Offerta getT={getT}/>
            case 'payment_card_security_policy':
                return <PaymentCardSecurityPolicy getT={getT}/>
            case 'refund_terms':
                return <RefundTerms getT={getT}/>
            case 'price_list':
                return <PriceList getT={getT}/>
        }
    }

    return <div className={'wrapper info-pages-main-wrapper'}>
        <div className="header">
            <div className="logo">
                <Link className="link main-logo" to="/">
                    <LogoLogin/>
                </Link>
            </div>
            <BasicStaticMenu getT={getT}/>
            <Language production={productionLang} isSaveProject={false}/>
            {!localStorage.getItem('ifurn_service_main_user_token') && <div className="register-btn">
                <Link className="link register" to="/register">
                    {getT('Регистрация/Вход')}
                </Link>
            </div>}
        </div>
        <div className="info-pages-main-content">
            {getPageContent()}
        </div>
    </div>;
};

export default InfoPages;