import React, {useEffect} from 'react'
import Bore from "./Bore";
import Paz from './Paz'
import {useDispatch, useSelector} from "react-redux";
import {getTools} from "../../../selectors/instruction-selector";
import {getToolsSettingsFirm} from "../../../store/reducers/SettingsReducer";
import Mill from "./Mill";
import {isAccessEdit} from "../help";

const Instruments = ({getT, firmId = null})=>{

    const dispath = useDispatch();
    useEffect(()=>{
        dispath(getToolsSettingsFirm());
    }, []);
    const idFirm = firmId;



    const tools = useSelector(getTools);
    const boreTools = tools.filter(t=> Number(t.equipment.bore) == 1);
    const pazTools = tools.filter(t=> Number(t.equipment.paz) == 1 );
    const millTools = tools.filter(t=> Number(t.equipment.mill) == 1);

    return <div className={"settings-section"}>
        <Bore getT={getT} boreTools={boreTools.filter(b => Number(b['bore_in']) == 1)} isAccessAction={isAccessEdit(idFirm)} type={'bore_in'}/>
        <Bore getT={getT} boreTools={boreTools.filter(b => Number(b['bore_through']) == 1)} isAccessAction={isAccessEdit(idFirm)} type={'bore_through'}/>
        <Paz getT={getT} pazTools={pazTools} isAccessAction={isAccessEdit(idFirm)}/>
        <Mill getT={getT} millTools={millTools} isAccessAction={isAccessEdit(idFirm)}/>
    </div>
}

export default Instruments;