import React, {useState} from 'react';

import "./StockMaterialClients.css"
import Modal from "../BasicModal";
import Container from "../../Container";
import {useDispatch, useSelector} from "react-redux";
import {
    changeNotWholeRemnantsModalValue,
} from "../../../store/reducers/ModalReducer";
import {addStockMaterialClientThunk} from "../../../store/reducers/MaterialsReducer";
import {useLang} from "../../../context/langProvider";

const NotWholeRemnants = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const open = useSelector(state => state.modal.notWholeRemnants.isOpen);
    const stock = useSelector(state => state.modal.notWholeRemnants.stock);
    const material = useSelector(state => state.order.order.material);
    const onClose = () => {
        dispatch(changeNotWholeRemnantsModalValue('isOpen', false))
    }
    const onGetMaterial = (id) => {
        dispatch(addStockMaterialClientThunk(null, {
            code: null,
            goods_id: id,
            name: null,
            producer: null,
            x: null,
            y: null,
            z: null,
        }, null, 1))
    }
    const checkIsMaterial = (id) => {
        const isMaterial = material.filter(m => Number(m.goods_id) === Number(id));
        return isMaterial.length === 0 ? <span
            className={"btn"}
            onClick={() => onGetMaterial(id)}>{getT("Добавить")}</span> : ''
    };

    function compare(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    stock.sort(compare);

    const uniqueStock = stock.reduce((acc, current) => {
        const x = acc.find(item => Number(item.goods_id) === Number(current.goods_id));
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
    const stocks = uniqueStock.map(s => {
        return stock.filter(i => {
            if (Number(i.goods_id) === Number(s.goods_id)) {
                return i;
            }
        })
    });
    return (
        <div className="container-stock">
            <Modal title={getT("Не целые остатки Компании")} open={open} onClose={onClose}>
                <Container>
                    <div className="" style={{width: "100%"}}>
                        {stocks.flat().length >= 1 ? (
                            stocks.map((row, index) => {
                                return <div className="stock-wrap">
                                    <ItemStock getT={getT} row={row} checkIsMaterial={checkIsMaterial}/>
                                </div>
                            })
                        ) : (
                            <h3 style={{textAlign: "center"}}>{getT("Остатков клиента на складе нет")}</h3>
                        )}
                    </div>
                </Container>
            </Modal>
        </div>
    )
}

const ItemStock = ({getT, row, checkIsMaterial}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (<>
        <div className={isOpen ? "stock__header open" : "stock__header"} onClick={() => setIsOpen(!isOpen)}>
            <span>{`[${row[0].goods_id}] ${row[0].name}`} </span>
        </div>
        {isOpen && <div className="stock__body">
            <table className={"stock-table"}>
                <thead>
                <tr>
                    {/*<td>Название</td>*/}
                    <td>{getT("Размер (мм)")}</td>
                    <td>{getT("Кол-во")}</td>
                    <td>{getT("Дата")}</td>
                    <td>{getT("Действие")}</td>
                </tr>
                </thead>
                <tbody>
                {row.map(l => {
                    return (<tr>
                        {/*<td>[{l.goods_id}]{l.name}</td>*/}
                        <td> {l.x} <strong>x </strong>
                            {l.y}
                        </td>
                        <td>{l.count}</td>
                        <td>{l.date_in}</td>
                        <td>{checkIsMaterial(l.goods_id)}</td>
                    </tr>)

                })}
                </tbody>
            </table>
        </div>
        }
    </>)
}


export default NotWholeRemnants;
