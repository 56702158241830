import React from "react";

const IconSubmenu = ({onClick}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon"
         onClick={onClick}
         style={{width: '20px', height: '20px',verticalAlign: 'middle', fill: "black",overflow: 'hidden'}}
         viewBox="0 0 1024 1024" version="1.1">
      <path
        d="M959.998532 0H63.999661C28.655621 0 0 28.652009 0 63.999661s28.655621 63.999661 63.999661 63.999662h895.997065c35.34404 0 63.999661-28.652009 63.999662-63.999662S995.340766 0 959.998532 0zM959.998532 896.000677H64.001468C28.655621 896.000677 0 924.652686 0 959.998532c0 35.347653 28.655621 64.001468 63.999661 64.001468h895.997065c35.34404 0 63.999661-28.652009 63.999662-64.001468 0.001806-35.345846-28.655621-63.997855-63.997856-63.997855zM417.434644 465.604626L93.780555 218.581069C55.365948 189.262566 0 216.655642 0 264.979153v494.039888c0 48.327123 55.365948 75.716586 93.780555 46.398084 107.883492-82.341788 215.76879-164.678157 323.654089-247.019944 30.608141-23.359795 30.608141-69.436372 0-92.792555zM959.998532 599.040659c35.34404 0 63.999661 28.652009 63.999662 63.999662 0 35.34404-28.655621 64.001468-63.999662 64.001467H460.801897c-35.347653 0-63.999661-28.655621-63.999662-64.001467 0-35.347653 28.652009-63.999661 63.999662-63.999662h499.196635zM959.998532 424.961147c35.34404 0 63.999661-28.655621 63.999662-64.001468 0-35.345846-28.655621-64.001468-63.999662-64.001467H460.801897c-35.347653 0-63.999661 28.653815-63.999662 64.001467 0 35.34404 28.652009 64.001468 63.999662 64.001468h499.196635z"
        fill="#000" />
    </svg>
  );
};

export default IconSubmenu;