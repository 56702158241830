import ApiService from "../../api/api";
import {changeLoaderStatusAC, checkErrorsAPI, typeErrorApi} from "./CommonReducer";
import {saveOrderProjectDataThunk, setLangOrderState} from "./OrderReducer";

const APP_CHANGE_LANGUAGES = 'APP/CHANGE_LANGUAGES'
const APP_SET_WORD = "APP/SET_WORD"

const api = new ApiService();
let initial = {
    localLang: localStorage.getItem('lang') ?? 'ua',
    collectionWord: []
}
export const TranslationReducer = (state = initial, action) => {
    switch (action.type) {
        case APP_CHANGE_LANGUAGES: {
            return {
                ...state,
                localLang: action.payload
            }
        }
        case APP_SET_WORD: {
            let words = [...state.collectionWord];
            words.push(action.payload);
            return {
                ...state,
                collectionWord: [...new Set(words)]
            }

        }
        default: {
            return {...state}
        }
    }
}
export const actions = {
    changeLang: (lang) => ({type: APP_CHANGE_LANGUAGES, payload: lang}),
    addWord: (word) => ({type: APP_SET_WORD, payload: word})
}

export const changeLangApp = (lang, isSaveProject = true) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        if (isSaveProject) {
            const isUpdate = await dispatch(saveOrderProjectDataThunk(true, false, true));
            if(!isUpdate) throw new Error("api:/projects/${version}/, M:PUT")

        }
        api.changeLanguage(lang)
            .then(res => {
                if (res.result) {
                    localStorage.setItem('lang', res.lang);
                    dispatch(actions.changeLang(res.lang));
                    dispatch(setLangOrderState(res.lang));
                    window.location.reload();
                } else {
                    throw new Error(res)
                }
            })

    } catch (e) {
        dispatch(checkErrorsAPI("", e.message));
        dispatch(changeLoaderStatusAC(false));
    }
}
