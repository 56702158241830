import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './CheckProjectModal.css';
import Modal from "../BasicModal";
import Container from "../../Container";
import {changeCheckProjectModalValue} from '../../../store/reducers/ModalReducer';
import {setProjectCalcErrorsAC} from "../../../store/reducers/CommonReducer";
import {getOrderPartSizeType} from "../../../selectors/order-selector";
import {CONSTANT_PART_SIZE_GABARIT} from "../../../constants";
import {onBeforeOpenOperationChangePartSizeType} from "../../../store/reducers/OrderReducer";
import {useLang} from "../../../context/langProvider";

const CheckProjectModal = () => {
    const isOpen = useSelector((state) => state.modal.checkProject.isOpen);
    return isOpen ? <Component/> : null

}
const Component = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const close = () => dispatch(changeCheckProjectModalValue('isOpen', false))
    const errors = useSelector((state) => state.commons.errors);
    const errors_calc = useSelector((state) => state.commons.errors_calc);

    const renderDetailsErrors = () => {
        let details = [];
        for (var key in errors) {
            details.push(<ErrorRow getT={getT} keyZ={key} errorsAll={errors} key={'error+row' + key}/>)
        }
        for (var key in errors_calc) {
            details.push(<ErrorRow getT={getT} keyZ={key} errorsAll={errors_calc} key={'error+row' + key}/>)
        }
        return details;
    }


    return <Modal title={getT('Ошибки в проекте')} open={true} onClose={() => {
        close();
        dispatch(setProjectCalcErrorsAC(null));
    }}>
        <Container>
            <div className={'check_project_modal_container_main'}>
                {renderDetailsErrors()}
            </div>
        </Container>
    </Modal>
}
const ErrorRow = ({errorsAll, keyZ, getT}) => {
    const dispatch = useDispatch();
    const part = useSelector((state) => {
        return state.order.order.part.find(p => Number(p.id) === Number(keyZ));
    });
    const partSize = useSelector(getOrderPartSizeType)
    const renderErrorsDetail = (errors) => {
        let error_text = [];
        for (var key in errors) {
            error_text.push(
                <p key={`item_${key}`}>
                    {errors[key]}
                </p>
            )
        }
        return error_text;
    }
    return part ? <div onClick={() => {
            let isActivateChangePartSize = partSize === CONSTANT_PART_SIZE_GABARIT;
            dispatch(onBeforeOpenOperationChangePartSizeType(!isActivateChangePartSize, partSize, part, true))
        }} className={'check-project-error-item-detail'} key={`container-mistakes`}>
            <p key={`item_${keyZ}`}><b>{getT('Деталь')} №{keyZ}</b></p>
            {renderErrorsDetail(errorsAll[keyZ])}
        </div> :
        <div key={`item_${keyZ}`} className={'check-project-error-item-detail'} style={{cursor: 'auto'}}><p>
            <b>{getT('Деталь')} №{keyZ}</b>
        </p>
            {renderErrorsDetail(errorsAll[keyZ])}</div>


}

export default CheckProjectModal;
