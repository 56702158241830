export const StatusedOrder = state => {
    return state.commons.statuses;
}
export const getCabinetUsserFirm = state => {
    return state.auth.user.firm;
}
export const getAuthUserDT = (state) => {
    return state.auth.user;
}
export const getAuthClientFirmsDT = (state) => {
    return state.auth.client_firms;
}
export const getAuthManagerDT = (state) => {
    return state.auth.manager;
}

export const getCustomerBalanceModal = (state) => {
    return state.modal.customerBalanceModal;
}

export const getOrdersCount = (state) => state.auth.orders.count;
export const getPayRequestInvoiceModal = (state) => state.modal.payModal;

export const getMessageChannelsSelected = (state) => state?.auth?.message_channel
export const getMessageChannelsDefaultSelected = (state) => state?.auth?.message_channel?.default_message_channel;
export const getConfirmAccountChannelsModalSelected = (state) => state?.auth?.confirmAccountChannelsModal
export const getNetworkLoginSelected = (state) => state?.auth?.network_login