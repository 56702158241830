import * as React from "react";

function NounDeleteNote(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" version="1.1" viewBox="0 0 700 700">
        <g>
          <path d="m560 303.33h-81.668v-11.668c0-6.4414-5.2266-11.668-11.668-11.668-6.4414 0-11.668 5.2266-11.668 11.668l0.003906 11.668h-81.668c-19.297 0-35 15.703-35 35v23.332c0 6.4414 5.2266 11.668 11.668 11.668h13.648l33.227 177.15c1.0273 5.5312 5.8594 9.5195 11.457 9.5195h116.67c5.6016 0 10.43-3.9883 11.457-9.5195l33.227-177.15h13.648c6.4414 0 11.668-5.2266 11.668-11.668v-23.332c0-19.297-15.703-35-35-35zm-44.684 233.34h-97.301l-30.637-163.33h158.57zm56.352-186.67h-210v-11.668c0-6.4414 5.25-11.668 11.668-11.668h186.67c6.418 0 11.668 5.2266 11.668 11.668z"/>
          <path d="m443.31 513.33c0.51172 0 0.98047-0.023437 1.4922-0.09375 6.3945-0.79297 10.922-6.625 10.125-13.02l-11.668-93.332c-0.81641-6.418-6.8359-10.922-13.043-10.125-6.3945 0.79297-10.922 6.625-10.125 13.02l11.668 93.332c0.72656 5.9023 5.7656 10.219 11.551 10.219z"/>
          <path d="m488.55 513.24c0.48828 0.070313 0.98047 0.09375 1.4688 0.09375 5.7852 0 10.828-4.3164 11.574-10.219l11.668-93.332c0.79297-6.3945-3.7578-12.227-10.125-13.02-6.2773-0.79297-12.273 3.7109-13.043 10.125l-11.668 93.332c-0.79688 6.3945 3.7539 12.227 10.125 13.02z"/>
          <path d="m350 490h-210c-6.418 0-11.668-5.2266-11.668-11.668v-443.33c0-6.4414 5.25-11.668 11.668-11.668h35v25.48c-13.559 4.8281-23.332 17.664-23.332 32.852 0 19.301 15.703 35.004 35 35.004s35-15.703 35-35c0-15.191-9.7773-28.023-23.332-32.852l-0.003907-25.484h70v25.48c-13.559 4.8281-23.332 17.664-23.332 32.852 0 19.301 15.703 35.004 35 35.004s35-15.703 35-35c0-15.191-9.7773-28.023-23.332-32.852v-25.484h70v25.48c-13.559 4.8281-23.332 17.664-23.332 32.852-0.003907 19.301 15.699 35.004 34.996 35.004s35-15.703 35-35c0-15.191-9.7773-28.023-23.332-32.852v-25.484h70v25.48c-13.559 4.8281-23.332 17.664-23.332 32.852 0 19.301 15.703 35.004 35 35.004s35-15.703 35-35c0-15.191-9.7773-28.023-23.332-32.852l-0.003907-25.484h35c6.418 0 11.668 5.2266 11.668 11.668v233.33c0 6.4414 5.2266 11.668 11.668 11.668 6.4414 0 11.668-5.2266 11.668-11.668v-233.33c-0.003907-19.297-15.707-35-35.004-35h-373.33c-19.297 0-35 15.703-35 35v443.33c0 19.297 15.703 35 35 35h210c6.4414 0 11.668-5.2266 11.668-11.668 0-6.4375-5.2266-11.664-11.668-11.664zm-163.33-396.67c-6.418 0-11.668-5.2266-11.668-11.664 0-6.4414 5.25-11.668 11.668-11.668s11.668 5.2266 11.668 11.668c-0.003907 6.4375-5.2539 11.664-11.668 11.664zm93.332 0c-6.418 0-11.668-5.2266-11.668-11.664 0-6.4414 5.25-11.668 11.668-11.668s11.668 5.2266 11.668 11.668c0 6.4375-5.25 11.664-11.668 11.664zm93.332 0c-6.418 0-11.668-5.2266-11.668-11.668 0.003907-6.4375 5.2539-11.664 11.668-11.664 6.418 0 11.668 5.2266 11.668 11.668 0 6.4375-5.25 11.664-11.668 11.664zm93.336 0c-6.418 0-11.668-5.2266-11.668-11.668 0-6.4375 5.25-11.664 11.668-11.664s11.668 5.2266 11.668 11.668c-0.003907 6.4375-5.2539 11.664-11.668 11.664z"/>
        </g>
      </svg>
  );
}

export default NounDeleteNote;
