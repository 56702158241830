import React from 'react';
import InputForm from "../../InputFields/InputForm/InputForm";
import {useFormik} from "formik";

const CreateProjectForm = ({handlerSubmit, getT, initial_title, handlerCancel}) => {
    const formik = useFormik({
        initialValues:
            {
                title: initial_title,
            },
        onSubmit: (values) => {
            handlerSubmit(values)
        }
    });
    return (
        <form className="" id={""} onSubmit={formik.handleSubmit}>
            <div className="row mb-2">
                    <InputForm label={getT("Название проекта")}
                               type={"text"}
                               value={formik.values.title}
                               onChange={formik.handleChange}
                               name={'title'}
                               required={true}
                               maxlength={50}
                    />

            </div>

            <div className="d-flex justify-center" style={{gap: '12px'}}>
                    <button className="btn" type={"submit"}>{getT('Создать')}</button>
                    <button className="btn btn-danger" type={"button"} onClick={handlerCancel}>{getT('Отменить')}</button>
            </div>
        </form>
    );
};

export default CreateProjectForm;