import React from 'react';
import {authLookupAC} from "../../../../../../store/actions/auth-actions";
import {useDispatch} from "react-redux";
import {useFormikContextProviderRegister} from "../../../../SignUp";
import useAuthLookup from "../../../../../../HOOK/useAuthLookup";
import {authThunkAccountAccess} from "../../../../../../store/reducers/AuthReducer";
import {useLang} from "../../../../../../context/langProvider";
import PhoneRegistration from "./PhoneRegistration";
import EmailRegistration from "./EmailRegistration";

const LoginRegistration = ({
                               handlerStartSeconds,
                               seconds,
                               isButtonDisabled,
                               onHandlerSwitch,
                               project_rules_name,
                               setting_phone_code_country = null
                           }) => {
    const dispatch = useDispatch();
    const {getT} = useLang();
    const {
        auth_lookup,
        isAuthFind,
        isNewUser,
        isSendShortPassword,
    } = useAuthLookup();

    const handlerOnSwitchTabRegister = (login) => {
        //** переключить форму на регистрацию */
        onHandlerSwitch(false, login)
    }
    const onReqAccountAccess = async ({send_password = 0, message_channel = null}) => {
        if (send_password) {
            handlerStartSeconds(true);
        }
        let login = values.email || values.phone;
        //** если пользователя нету переключаем таб на регестрацию*/
        await dispatch(authThunkAccountAccess(
            {login: login, send_password: send_password, message_channel: message_channel}, () => {
                handlerOnSwitchTabRegister(login);
            }))

        setFieldValue('password', '');
        setFieldValue('master_password', '');

    }
    const {resetForm, setFieldValue, values, setErrors} = useFormikContextProviderRegister()
    const handlerSwitchLogin = (isLoginType) => {
        setErrors({})
        dispatch(authLookupAC(null));
        handlerStartSeconds(false);
        resetForm();
        setFieldValue('isRadioLoginEmail', isLoginType)
    }
    const onHandlerEdit = () => {
        dispatch(authLookupAC(null))
        setFieldValue('isEditEmailOrPhone', true);
        handlerStartSeconds(false);
    }
    return (
        <div>
            <h2 className={'form-title d-flex items-center justify-center'}>{getT('Регистрация')} {project_rules_name ?? ''} </h2>
            <div className={'form-sm card-form'} role="presentation">
                <div className="choice-type-login d-flex flex-row">
                    <div className="item d-flex align-items-center">
                        <input type="radio" value="1" id={'email'} checked={values.isRadioLoginEmail}
                               className={'flex-auto'}
                               onChange={() => handlerSwitchLogin(true)}
                        />
                        <label htmlFor="email">
                            {getT('Почта')}
                        </label>
                    </div>
                    <div className="item d-flex align-items-center">
                        <input type="radio" value="0" id={'tel'} checked={!values.isRadioLoginEmail}
                               className={'flex-auto'}
                               onChange={() =>
                                   handlerSwitchLogin(false)}
                        />
                        <label htmlFor="tel">
                            {getT('Телефон')}
                        </label>
                    </div>

                </div>
                <EmailRegistration onHandlerEdit={onHandlerEdit}
                                   onReqAccountAccess={onReqAccountAccess} isButtonDisabled={isButtonDisabled}
                                   seconds={seconds}/>
                <PhoneRegistration onHandlerEdit={onHandlerEdit} seconds={seconds} isButtonDisabled={isButtonDisabled}
                                   onReqAccountAccess={onReqAccountAccess}
                                   setting_phone_code_country={setting_phone_code_country}/>
            </div>
        </div>
    );
};

export default LoginRegistration;