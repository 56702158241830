import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../../store/reducers/SettingsReducer";
import Modal from "../../../../components/Modals/BasicModal";
import {isOpenInstMill, MillForms} from "../../../../selectors/instruction-selector";

const MillForm = ({getT, onToggleInstModal, onBeforeSubmit, onUpdateToolsSubmit}) => {
    const dispatch = useDispatch();
    const onChangeValues = ({key, dt}) => {
        dispatch(actions.onChangeInstrumentsMillForm({key, dt}))
    };

    const valuesForm = useSelector(MillForms)
    const isOpen = useSelector(isOpenInstMill);


    return <><Modal title={getT('Форма добавления/редактирование инструмента')} open={isOpen}
                    onClose={() => onToggleInstModal({key: 'mill', dt: false})}>
        <form action="" className={"inst__bore-form"} onSubmit={(e) => {
            e.preventDefault();
            if (valuesForm.isEdit.edit) {
                onUpdateToolsSubmit(valuesForm, valuesForm.isEdit.id);
            } else {
                onBeforeSubmit(valuesForm)
            }

        }}>
            <div className="field-section">
                <div className="field field-on">
                    <label className={"form-input-checkbox-label"} htmlFor="OnOff">
                        <input type="checkbox"
                               id={"OnOff"}
                               className={"form-input-checkbox"}
                               checked={Boolean(valuesForm.on) ? 'checked' : ''}
                               value={valuesForm.on}
                               onChange={(e) => {
                                   onChangeValues({key: 'on', dt: Number(e.target.checked)})
                               }}/>
                        <span>{getT('Вкл')}/{getT('Выкл')}</span>
                    </label>
                </div>
                <div className="field">
                    <label style={{paddingTop: '0px', marginTop: '0px'}} className={"field-title label"} htmlFor="">{getT('Название')}</label>
                    <input className={"input-val"} type="number"
                           step={0.01}
                           min={0} max={1000}
                           value={valuesForm.name}
                           onChange={(e) => onChangeValues({
                               key: 'name',
                               dt: e.target.value
                           })}
                    />
                </div>

            </div>
            <div className="field-section">
                <div className="">
                    <div>   <label style={{paddingTop: '0px', marginTop: '0px'}} htmlFor="" className={"field-title" +
                    " label"}>{getT('Сторона')}</label></div>
                    <label className="field-radio" htmlFor={"millBackFace"}
                           onChange={(e) => onChangeValues({
                               key: 'mill_back',
                               dt: Number(0)
                           })}>
                        <input type="radio" id="millBackFace"
                               name="millBack"
                               value="0"
                               checked={Number(valuesForm.mill_back) === 0 ? 'checked' : ''}
                        />
                        <span className={"checkmark "}>{getT('Лицевая')}</span>
                    </label>
                    <label className="field-radio" htmlFor={"millBackBB"}
                           onChange={(e) => onChangeValues({
                               key: 'mill_back',
                               dt: Number(1)
                           })}>
                        <input type="radio" id="millBackBB"
                               name="millBack"
                               value="1"
                               checked={Number(valuesForm.mill_back) === 1 ? 'checked' : ''}
                        />
                        <span className={"checkmark "}>{getT('Обратная')}</span>
                    </label>

                </div>
                <div className="field">
                    <div>   <label style={{paddingTop: '0px', marginTop: '0px'}} htmlFor="" className={"field-title" +
                    " label"}>{getT('Тип фрезы')}</label></div>
                    <label className="field-radio" htmlFor={"millSideLeft"}
                           onChange={(e) => onChangeValues({
                               key: 'type',
                               dt: {mill_left: 1, mill_right: 0}
                           })}>
                        <input type="radio" id="millSideLeft"
                               name="millSide"
                               value="0"
                               checked={Number(valuesForm.type.mill_left) === 1 ? 'checked' : ''}
                        />
                        <span className={"checkmark "}>{getT('Левая')}</span>
                    </label>
                    <label className="field-radio" htmlFor={"millSideRight"}
                           onChange={(e) => onChangeValues({
                               key: 'type',
                               dt: {mill_left: 0, mill_right: 1}
                           })}>
                        <input type="radio" id="millSideRight"
                               name="millSide"
                               value="1"
                               checked={Number(valuesForm.type.mill_right) === 1 ? 'checked' : ''}
                        />
                        <span className={"checkmark "}>{getT('Правая')}</span>
                    </label>
                </div>

            </div>

            <div className="field-section">

                <div className="field">
                    <label htmlFor="" className={"field-title label"}>{getT('Диаметр')}</label>
                    <input className={"input-val"} type="number"
                           step={0.01}
                           min={0} max={100}
                           value={valuesForm.d}
                           required={'required'}
                           onChange={(e) => {
                               let value = Number(e.target.value) > 100 ? 100 : e.target.value

                               onChangeValues({key: 'd', dt: value})
                           }}/>
                </div>
                <div className="field">
                    <label htmlFor="" className={"field-title label"}>{getT('Максимальная глубина')}</label>
                    <input className={"input-val"} type="number"
                           min={0} max={100}
                           step={0.01}
                           value={valuesForm.z_max}
                           required={'required'}
                           onChange={(e) => {
                               let value = Number(e.target.value) > 100 ? 100 : e.target.value
                               onChangeValues({key: 'z_max', dt: value})
                           }}/>
                </div>
            </div>
            <button className={"btn"} type={"submit"}>{getT('Отправить')}</button>

        </form>
    </Modal>
    </>
}

export default MillForm;