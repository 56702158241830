import getT from "../../getT"
export default function ({xnc, type_name=false}){
  return (
     ` <tr>
        <th></th>
        <th>${getT('количество деталей со сверлениями')}</th>
        <th>${getT('количество сверлений')}</th>
        <th></th>
        <th></th>
      </tr>
      <tr>
        <th> ${type_name? type_name :  getT(xnc?.type_name)}</th>
        <td>${+Number(xnc?.part_bore_count ?? '').toFixed(2)}</td>
        <td>${+Number(xnc?.bore_count ?? '').toFixed(2)}</td>
        <td></td>
        <td></td>
      </tr>
  `
  )
}