import React from 'react';
import CreateForm from "./CreateForm";
import {useDispatch, useSelector} from "react-redux";
import {getAdditionalOrderGoodsServiceSelected} from "../../../selectors/order-selector";
import {getPartGoodsServiceModalSelected} from "../../../selectors/modal-selector";
import Modal from "../BasicModal";
import "./style.css"
import {useLang} from "../../../context/langProvider";
import {orderPartSetGoodServiceTypeAC} from "../../../store/actions/order-actions";
import {modalToggleOrderPartGoodsServiceAC} from "../../../store/actions/modal-action";
import {formatDate} from "../../../helpers/helpers";

const AdditionGoodsServicePartModal = React.memo( () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const goods_service_part_order = useSelector(getAdditionalOrderGoodsServiceSelected);
    const modal_data = useSelector(getPartGoodsServiceModalSelected);
    if(!modal_data.isOpen) return  null
    const handlerSubmit = (values)=>{
        let goods_service= values?.goods_service?.filter(item=> item?.id)?.map(item=>({
            ...item.item,
            count: item?.count,
            value_detail: item?.value_detail,
            date: formatDate(new Date())
        }));
        dispatch(orderPartSetGoodServiceTypeAC({part_id: modal_data?.part?.id, goods_service: goods_service}))
        dispatch(modalToggleOrderPartGoodsServiceAC({isOpen: false, part: null, sizeType: 'saw', product_count: 1}))
    }
    const handlerClose = ()=> dispatch(modalToggleOrderPartGoodsServiceAC({isOpen: false, part: null, sizeType: 'saw', product_count: 1}))
    return (
        <Modal title={getT("Дополнительные услуги к детали")} open={modal_data.isOpen} onClose={handlerClose}>
            <div className="goods-service modal-body">
            <CreateForm getT={getT}
                        goods_service_part_order={goods_service_part_order?.GoodsServiceForPartByFirm}
                        handlerSubmit={handlerSubmit}
                        part={modal_data.part} sizeType={modal_data.sizeType}
                        product_count={modal_data.product_count}
            />
            </div>
        </Modal>
    );
})

export default AdditionGoodsServicePartModal;