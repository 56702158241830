import React, {useEffect, useState} from 'react';
import MaterialChoiceContainer from "./MaterialChoiceContainer";
import {useDispatch, useSelector} from "react-redux";
import {getChoiceMaterial} from "../../../selectors/cells-leftovers-selector";
import {actionsCellsLeftovers, onSetStockOrdersDT} from "../../../store/reducers/StorageCellsLeftovers";
import Delete from "../../../Icons/Actions/Delete";
import RemainCreateForm from "./Forms/RemainCreateForm";
import PlanCreateForm from "./Forms/PlanCreateForm";
import {getLocalLang} from "../../../selectors/settings-selector";

const CustomerBalancesMaterial = ({getT}) => {
    const dispatch = useDispatch();

    const material = useSelector(getChoiceMaterial)
    const lang = useSelector(getLocalLang)

    const [step, setStep] = useState({
        beforeStart: true,
        start: false,
        create: false,
        padding: false,
        end: false
    });
    const [typeStock, setTypeStock] = useState({

        stock: false,
        stockPlane: false
    });

    const onChangeStep = (key) => {
        setStep(() => {
            return {
                beforeStart: false,
                start: false,
                create: false,
                padding: false,
                end: false,
                [key]: true
            }
        })
    }
    const onChangeTypeStock = (key) => {
        setTypeStock(() => {
            return {
                stock: false,
                stockPlane: false,
                [key]: true
            }
        })
    }

    const onDeleteMaterialsGoods = () => {
        dispatch(actionsCellsLeftovers.onSetChoiceMaterial(null));
        onChangeStep('beforeStart');
    }
    const onSuccessStep = () => {
        onChangeStep('end');
    }
    useEffect(()=>{
        dispatch(onSetStockOrdersDT());
    }, [])
    return <>
        <div className="section-header">
            <h2 className="title">{getT('Создание  остатка  без заказа')}</h2>
            {step.beforeStart && <button className={'btn'} onClick={() => onChangeStep('start')}>{getT('Выбрать материал')}</button>}
        </div>

        <div className="section-stocks">
            {step.start &&
            <MaterialChoiceContainer getT={getT} onComeBackBefore={(key = 'beforeStart') => onChangeStep(key)}/>}
            {
                !step.beforeStart && !step.end && material && material.hasOwnProperty('goods_id') &&
                <div className={'choice-material__current'}>
                    <label className={'label'} htmlFor="">{getT('Материал')}</label>
                    <div className="name">
                        <storng>{material.hasOwnProperty('translate') ? material['translate'][lang] : material.name}</storng>
                        <span className={'cursor-pointer'} onClick={() => onDeleteMaterialsGoods()}><Delete/></span>
                    </div>
                </div>
            }
            {step.create && <div className={'choice-type-stock_btns'}>
                <button className={'btn'} onClick={() => {
                    onChangeStep('padding');
                    onChangeTypeStock('stock')
                }}>{getT('Создать остаток')}</button>
                {/*<button className={'btn'} onClick={() => {*/}
                    {/*onChangeStep('padding');*/}
                    {/*onChangeTypeStock('stockPlane')*/}
                {/*}}>{getT('Создать планируемый остаток')}</button>*/}
            </div>}
            {step.padding && typeStock.stock && <><RemainCreateForm getT={getT} goods={material.goods_id}
                                                                    name={material.hasOwnProperty('translate') ? material.translate[lang] : material.name}
                                                                    onSuccessStep={onSuccessStep}/></>}
            {step.padding && typeStock.stockPlane && <><PlanCreateForm getT={getT} goods={material.goods_id}
                                                                       onSuccessStep={onSuccessStep}/></>}
            {step.end && <div className="section-header" style={{flexDirection: 'column'}}>
                <h3>{getT('Остаток успешно создан')}</h3>
                <button onClick={() => onChangeStep('beforeStart')} className={'btn'}>{getT('Добавить еще')}</button>
            </div>}
        </div>

    </>
}

export default CustomerBalancesMaterial;