import React from "react";

import "./Tabs.css";
import {useSelector} from "react-redux";
import {getProductionPazTemplateOffInInterface} from "../../../selectors/production-selectors";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../store/constats/help_article_constants";
import IconQuestion from "../../../Icons/IconQuestion";

const Tabs = ({ active, onChange, getT, handlerHelpArticle }) => {
  const paz_template_off = useSelector(getProductionPazTemplateOffInInterface);
  let paz_template_off_interface = !Number(paz_template_off)
  return (
    <div className="tab-container">
      {[
        { key: "details", name: getT("Детали"), display: true, helper_article:HELP_ARTICLE_CONSTANTS_IDS.details },
        { key: "goods", name: getT("Товары"), display: true, helper_article: HELP_ARTICLE_CONSTANTS_IDS.goods},
        { key: "products", name: getT("Группы"), display: true, helper_article: HELP_ARTICLE_CONSTANTS_IDS.groups },
        { key: "boxes", name: getT("Изделия") , display: true, helper_article:  HELP_ARTICLE_CONSTANTS_IDS.modules_header},
        { key: "template_paz", name: getT("Шаблоны пазования"), display: paz_template_off_interface, helper_article: HELP_ARTICLE_CONSTANTS_IDS.grooving_patterns },
      ]?.filter(item=> item.display).map(({ name, key, helper_article }) => (
        <div
          key={key}
          className={`tab helper-article${key === active ? " is-active" : ""}`}
          onClick={() => onChange(key)}
        >
          {name}
          {helper_article &&  <IconQuestion style={{top: '0px', right: '0px'}} onClick={handlerHelpArticle(helper_article)}/>}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
