import React, {useMemo} from 'react';
import IconTelegram from "../../../../Icons/Social/IconTelegram";
import IconViber from "../../../../Icons/Social/IconViber";
import IconWA from "../../../../Icons/Social/IconWA";
import {useDispatch, useSelector} from "react-redux";
import {getMessageChannelsSelected} from "../../../../selectors/cabinet-sector";
import {filterObjectByKeys, unFilterObjectByKeys} from "../../../../helpers/utils";
import QRCode from "react-qr-code";
import Card from "../../../../components/Card";
import InputRadio from "../../../../components/InputFields/InputRadio";
import InputSwitch from "../../../../components/InputFields/InputSwitch";
import {
    checkConnectMessageChannelsThunk,
    setDefaultMessageChanelThunk,
    toggleEnabledMessageChannelThunk
} from "../../../../store/thunks/message-channels-thunk";
import {useLang} from "../../../../context/langProvider";

const ICONS_MESSAGE = {
    telegram: IconTelegram,
    viber: IconViber,
    whatsapp: IconWA,
}
const styleIcon = {
    marginRight: '8px'
}
const styleQr = {height: "auto", maxWidth: "55px", width: "55px"}
const MessageChannels = () => {
    const {getT} = useLang();
    const message_channels = useSelector(getMessageChannelsSelected);
    const dispatch = useDispatch();
    const connected_channel = useMemo(() => {
        if (!message_channels) return {}
        let {full_message_channel_list, connected_message_channel_list} = message_channels
        if (Object.keys(full_message_channel_list).length && Object.keys(connected_message_channel_list)) {
            let keys = Object.keys(connected_message_channel_list);
            return filterObjectByKeys(full_message_channel_list, keys)
        }
        return {}
    }, [message_channels])
    const un_connected_channel = useMemo(() => {
        if (!message_channels) return {}
        let {full_message_channel_list, connected_message_channel_list} = message_channels
        if (Object.keys(full_message_channel_list).length && Object.keys(connected_message_channel_list)) {
            let keys = Object.keys(connected_message_channel_list);
            return unFilterObjectByKeys(full_message_channel_list, keys)
        } else if (Object.keys(full_message_channel_list).length) {
            return full_message_channel_list
        }
        return {}
    }, [message_channels]);
    const onSwitchMessageDefault = (key) => () => {
        dispatch(setDefaultMessageChanelThunk({default: key}))
    }
    const toggleEnabledMessage = (key) => (e) => {
        let enabled = Number(e.target.checked);
        dispatch(toggleEnabledMessageChannelThunk({
            default: key, enabled: enabled
        }))
    }
    const handlerCheckRegisterBot = (message_channel) => () => {
        dispatch(checkConnectMessageChannelsThunk(message_channel));
    }
    return (
        <div>
            {(!!Object.keys(connected_channel).length || !!Object.keys(un_connected_channel).length) &&
                <div className={'connected-channel-wrap form-group'}>
                    <Card  label={<>{getT("Чат-боты")}</>} className={'cabinet-connected-channel'}>
                        <ul className={'list-none'}>
                            {Object.keys(connected_channel).map(key => {
                                const isDefault = connected_channel[key].default;
                                const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                                const name = connected_channel[key].name;
                                const isEnabled = connected_channel[key].enabled;
                                return (
                                    <li className={`item d-flex items-center justify-between  ${isDefault ? 'default' : ' '}`}>
                                        <InputRadio onChange={onSwitchMessageDefault(key)} checked={isDefault} id={key}
                                                    disabled={isEnabled}
                                                    label={
                                                        <div className={'d-flex items-center'}>
                                                            {Icon ?
                                                                <Icon style={styleIcon}/> : ''}
                                                            {name}</div>
                                                    }/>
                                        <InputSwitch onChange={toggleEnabledMessage(key)} disabled={isDefault}
                                                     checked={isEnabled}
                                                     label={getT("вкл/выкл мессенджер")}/>

                                    </li>)

                            })}
                            {Object.keys(un_connected_channel).map(key => {
                                const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                                const name = un_connected_channel[key].name;
                                const link = un_connected_channel[key].link;
                                return (
                                    <li className={`item d-flex justify-between `}>
                                        <div className="d-flex items-center">
                                            {Icon ? <Icon style={styleIcon}/> : name ? name : ''}
                                            <a href={link} className="d-flex" target={'_blank'}>
                                                <QRCode
                                                    size={55}
                                                    style={styleQr}
                                                    value={link}
                                                    viewBox={`0 0 55 55 `}
                                                />
                                            </a>
                                        </div>

                                        <spna className="resend-action cursor-pointer"
                                              onClick={handlerCheckRegisterBot(key)}> {getT("Проверить регистрацию")}
                                        </spna>
                                    </li>)

                            })}
                        </ul>
                    </Card>
                </div>}
        </div>
    );
};

export default MessageChannels;