import React, {useState} from 'react';
import {setActiveMaterialsCategoryAC} from "../../../store/reducers/MaterialsReducer";
import {useDispatch, useSelector} from "react-redux";
import Goback from "../../../Icons/Actions/GoBack";
import Pagination from "../../../components/Pagination/Pagination";
import NoImage from "../../../Icons/NoImage";
import Search from "../../../Icons/Search";
import Filter from "../../../components/Filters/NightstandsFilter/Filter";
import GoBackArrow from "../../../Icons/Actions/GoBackArrow";

import FolderIcon from "../../../Icons/Actions/FolderIcon";
import CloseModal from "../../../Icons/Actions/Close";
import {getLocalLang} from "../../../selectors/settings-selector";

const MaterialContainerList = ({getT, tree, activeCategory, materials, filters, onGetMaterials, searchInput, changeSearchInput, changeFilterValue, cleanFilters, onAddMaterial, onComeBackBefore}) => {
    const dispatch = useDispatch();
    const [openFilter, setOpenFilter] = useState(false);
    const [isNavigation, setIsNavigation] = useState(false);
    const lang = useSelector(getLocalLang)
    const toggleOpenFilter = () => setOpenFilter(!openFilter);
    const onSetActiveCategories = (id, name) => {
        dispatch(setActiveMaterialsCategoryAC(id, name));

    }
    const onGetMaterialsFilter = () => {
        onGetMaterials();
        if (window.innerWidth <= 768) {
            setOpenFilter(false);
        }
    }
    const onToggleIsNavigation = () => {
        setIsNavigation(!isNavigation);
    }
    const renderNavigation = (tree) => {
        return tree ? tree.map(e => <MaterialsNavItem name={e.name}
                                                      childs={e.childs}
                                                      id={e.id}
                                                      setActiveCategory={onSetActiveCategories}
                                                      activeCategory={activeCategory.id}
        />) : [];
    };
    return <>
        <div className="nightstands-wrapper modal-page">
            <div className="nightstands-container">
                <aside className="aside">
                    <div className="goback">
                        <div onClick={() => onComeBackBefore()}>
                            <Goback/> <span className="goback-text">{getT('Назад')}</span>
                        </div>
                    </div>
                    <div className="aside-nav">
                        {/*<h3 className="aside-nav-title"*/}
                            {/*onClick={() => onSetActiveCategories(null, getT('Все материалы'))}>{getT('Все материалы')}</h3>*/}
                        <nav role="navigation" style={{marginTop: '25px'}}>
                            {renderNavigation(tree)}
                        </nav>
                    </div>
                </aside>
                <div className="main_content">
                    <div className="search-bar search-bar__materials">
                        <div className={"back-go"}
                             onClick={() => onComeBackBefore()}>
                            <GoBackArrow/>
                        </div>
                        <div className="search-bar-field">
                            <Search handlerClick={onGetMaterials}/>
                            <form className={'main_modal_pages_search_form'} onSubmit={(event) => {
                                event.preventDefault();
                                onGetMaterials();
                            }}>
                                <input type="text"
                                       placeholder={getT('Поиск')}
                                       onChange={(event) => changeSearchInput(event.target.value)}
                                       value={searchInput}
                                />
                            </form>
                        </div>
                        <div className="material-menu-btn"
                             onClick={() => onToggleIsNavigation()}>
                            <FolderIcon/>
                        </div>
                        <div onClick={toggleOpenFilter} className="search-btn">
                            <svg
                                width={20}
                                height={18}
                                viewBox="0 0 20 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18.335 1.5H1.668l6.667 7.883v5.45l3.333 1.667V9.383L18.335 1.5z"
                                    stroke="#4262FF"
                                    strokeWidth={1.5}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="search-btn-text">{getT('Фильтр')}</span>
                        </div>


                    </div>
                    {isNavigation && <NavigationMaterial>
                        <div className={"navigation-material__tabled"}>

                            <div className="close" onClick={() => setIsNavigation(false)} style={{display: "block"}}>
                                <CloseModal/>
                            </div>
                            {/*<h3 className="aside-nav-title"*/}
                                {/*onClick={() => onSetActiveCategories(null, getT('Все материалы'))}>{getT('Все материалы')}</h3>*/}
                            <nav className={"navigation-menu"} role="navigation">
                                {renderNavigation(tree, onSetActiveCategories)}
                            </nav>
                        </div>
                    </NavigationMaterial>}
                    {openFilter && (
                        <div className="fiters-container">
                            <div className="filters-container-head">
                                <Goback onClick={() => setOpenFilter(false)}/>
                                <GoBackArrow onClick={() => setOpenFilter(false)}/>
                                <h1 className={"filters-container-header__title"}>{getT("Фильтр")}</h1>
                            </div>
                            <Filter changeFilterValue={changeFilterValue} filters={filters}
                                    getMaterials={onGetMaterialsFilter} cleanFilters={cleanFilters}/>
                        </div>
                    )}
                    <div className="card-container">
                        {materials && materials.data && materials.count > 0 ?
                            <>
                                {materials.data.map(e => {
                                    if (e.type === 'part') {
                                        return <Card getT={getT} onAddMaterial={onAddMaterial}
                                                     material={e} lang={lang}/>
                                    }
                                })
                                }
                            </>
                            :
                            <p>
                                {getT('Подходящие материалы не найдены')}
                            </p>
                        }
                    </div>

                    <Pagination count={materials && materials.count} changeHandler={onGetMaterials}/>
                </div>
            </div>
        </div>
    </>
}
const NavigationMaterial = (props) => {
    return (
        <div className="aside-navigation">
            {props.children}
        </div>
    )
}

const MaterialsNavItem = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const renderNavigation = (tree) => {
        return tree ? tree.map(e => <MaterialsNavItem name={e.name}
                                                      childs={e.childs}
                                                      id={e.id}
                                                      setActiveCategory={props.setActiveCategory}
                                                      isActive={props.setActiveCategory}
                                                      activeCategory={props.activeCategory}
        />) : [];
    };
    return <div className={'materials_nav_item'}>
        <div
            className={'materials_nav_item_name ' + (Number(props.activeCategory) === Number(props.id) ? 'active' : '')}
            onClick={() => {
                props.setActiveCategory(props.id, props.name);
                setIsOpen(!isOpen);
            }}
        >{props.name}</div>
        {isOpen ?
            <>
                <div className={'materials_nav_item_childs'}>
                    {props.childs && props.childs.length > 0 ? renderNavigation(props.childs) : []}
                </div>
            </>
            :
            ''
        }
    </div>
}

const Card = ({getT, material, lang, onAddMaterial}) => {
    const dispatch = useDispatch();

    const renderDropLabels = () => {
        return material.drop_labels && material.drop_labels.map((e, i) => {
            return <span className="card-price-item">
                        <span>{Number(e.price).toFixed(2)} {material.currency.name}</span> / {e.name} {i + 1 === material.drop_labels.length ? '' : ','}
                    </span>
        })
    }
    const renderCodeLabels = () => {
        return material.code_labels && material.code_labels.map((e, i) => {
            return <>{e}{i + 1 === material.drop_labels.length ? '' : ', '}</>
        })
    }
    return <>
        <div className={'card-wrapper card-materials '}>
            <div className="card-body">
                <div className="card-img">
                    {material.image ? <>
                        <img src={material.image}/>
                    </> : <NoImage/>}
                </div>
                <h3 className="card-title"> {(material.hasOwnProperty('translate') && material.translate[lang]) ? material.translate[lang] : material.name}
                    {/*{manager && props.material.name}*/}
                    {material.firm_name &&
                    <p style={{fontSize: "12px", margin: "0px"}}>({material.firm_name})</p>}


                </h3>
                <p className="card-sizes">
                    {getT('Размеры')}: {material.x} х {material.y} х {material.z} {getT("мм")}
                </p>
                <p className="card-sizes">
                    {getT('Код')}: {renderCodeLabels()}
                </p>
                <div className="card-prices">
                    {renderDropLabels()}
                </div>
            </div>
            <button className="add-to-card" onClick={() => {
                onAddMaterial(material)
                // props.addMaterial(props.material);
            }}>{getT('Добавить  материал')}</button>
        </div>
    </>
}

export default MaterialContainerList