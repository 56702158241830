import {HelpArticleAPI} from "../../api/HelpArticleAPI";
import {helpArticleGetByIdToggleModalAC, helpArticleSendCommentMailAC} from "../actions/help-article-action";
import {dispatcherErrorThunk} from "./common-thunk";

import ApiService from "../../api/api";
import {changeAlertStatusAC} from "../reducers/CommonReducer";
import {getTranslateMessageAPI} from "../../helpers/helpers";
const Api = new ApiService()

export const helpArticleGetByIdToggleModalThunk = (id)=>async dispatch =>{
    try{
        dispatch(helpArticleGetByIdToggleModalAC({isLoading: true}));
        const content_modal = await HelpArticleAPI.getById(id);
        dispatch(helpArticleGetByIdToggleModalAC({isLoading: false, is_open: true, error: null, isError: null, content: content_modal}));
    }catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(helpArticleGetByIdToggleModalAC({isLoading: false, is_open: false, error: msg, isError: true, content: null}));
        dispatch(dispatcherErrorThunk(e))
    }
}

export const helpArticleSendCommentMailThunk = (text)=>async dispatch =>{
    try{
        dispatch(helpArticleSendCommentMailAC({isLoading: true}));
        const res = await Api._sendLetter({text});
        dispatch(helpArticleSendCommentMailAC({isLoading: false,  error: null, isError: null}));
        dispatch(changeAlertStatusAC(true, `${getTranslateMessageAPI('Успешно')}`, true));

        return Promise.resolve(true)

    }catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(helpArticleGetByIdToggleModalAC({isLoading: false, is_open: false, error: msg, isError: true, content: null}));
        dispatch(dispatcherErrorThunk(e))
        return Promise.resolve(false)
    }
}

