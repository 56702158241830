import React, {useCallback, useMemo, useState} from 'react';
import MaterialProject from "../../../Icons/MaterialProject";
import {useSelector} from "react-redux";
import {getExportLibraryMaterial} from "../../../selectors/common-selector";
import IconArrow from "../../../Icons/IconArrow";


const video_url = 'https://www.youtube.com/watch?v=BTynMk2IfHo';
const script_url = 'https://api.ifurn.pro/iFurnPro_basis_export.zip';

const styleWrap ={
    width: '100%',
    justifyContent: 'space-between'
}
const styleList = {
    margin: '-10px 0 0',
    padding: '0'
}

const styleLi ={
    margin: '5px 0px'
}
const ExportLibraryMaterial = ({getT}) => {
    const export_data = useSelector(getExportLibraryMaterial);

    const onOpenHandler = useCallback(() => {
        if (export_data && export_data?.file) {
            window.open(export_data?.file, '_blank');
        }

    }, [export_data]);

    function _b64DecodeUnicode(str) {
        return decodeURIComponent(atob(str).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
    }

    const hintText = useMemo(() => {
        if (export_data && export_data?.help) return _b64DecodeUnicode(export_data?.help);
        return null
    }, [export_data]);
    const [isShow, setIsShow] = useState(false);


    return (
        <>
        <div className="singl-action-item tooltip-def" style={styleWrap}>
            <div className="singl-action-item " style={{margin: '0px'}} onClick={()=>{setIsShow(!isShow)}}>
                <MaterialProject/>
                {getT('Базис')}
                {hintText && <>
                    <div className="icon" style={{marginLeft: '5px'}}>
                        i
                    </div>
                    <span className="tooltiptext">{hintText}</span>
                </>}

            </div>
            <IconArrow top={isShow} onCLickHandler={()=>{setIsShow(!isShow)}} cName={''}/>
        </div>
            {isShow && <ul style={styleList}>
                {export_data?.file &&  <li style={styleLi}><a href={export_data?.file} target={'_blank'} download={true}>{getT('Экспорт библиотеки')}</a></li>}
                <li style={styleLi}><a href={video_url} className="" target={'_blank'}>{getT("Видеоинструкция")}</a></li>
                <li style={styleLi}><a href={script_url} className="" download={true} target={'_blank'}>{getT("Скачать скрипт")}</a></li>
            </ul>}
        </>
    );
};

export default ExportLibraryMaterial;