import { IBoxesReducerState } from "./interfaces";
import { AnyAction } from "redux";
import {
  CHANGE_FILTER_VALUE,
  CLEAR_FILTERS, SET_BOX_GENERAL, SET_BOX_PARAMS, SET_BOX_PARTS,
  SET_BOXES, SET_EDITOR_BOX_ID,
  TOGGLE_CONSTRUCTOR_IS_OPEN,
  TOGGLE_IS_LOADING, TOGGLE_LIST_IS_OPEN, UPDATE_BOX_GENERAL_VALUE, UPDATE_BOX_PARTS_CHECKED
} from "./actionTypes";

let initialState: IBoxesReducerState = {
  isLoading: false,
  isOpenConstructor: false,
  isOpenList: false,
  boxEditorId: null,
  boxEditorProjectId: null,
  boxList: [],
  boxParams: [],
  boxParts: [],
  boxGeneral: [],
  filters: {
    x: null,
    y: null,
    z: null,
  },
};

const BoxesReducer = (state = initialState, action: AnyAction ) => {
  switch(action.type) {
    case TOGGLE_LIST_IS_OPEN:
      return {
        ...state,
        isOpenList: action.status
      };
    case TOGGLE_CONSTRUCTOR_IS_OPEN:
      return {
        ...state,
        isOpenConstructor: action.status
      };
    case TOGGLE_IS_LOADING:
      return {
        ...state,
        isLoading: action.status
      };
    case SET_BOXES:
      return {
        ...state,
        boxList: action.boxes
      };
    case CHANGE_FILTER_VALUE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.input]: action.value
        }
      };
    case SET_BOX_PARAMS:
      return {
        ...state,
        boxParams: action.params
      };
    case SET_EDITOR_BOX_ID:
      return {
        ...state,
        boxEditorProjectId: action.projectId || null,
        boxEditorId: action.id
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: {...initialState.filters}
      };
    case SET_BOX_PARTS:{
      return {
        ...state,
        boxParts: action.payload
      }
    }
    case UPDATE_BOX_PARTS_CHECKED:{
      const updateDT = state.boxParts.map(p=> {
        if(Number(p.id_part_article) === Number(action.payload.partId)){
          return{
            ...p,
            isChecked: action.payload.isChecked
          }
        }
        return {
          ...p
        }
      })
      return {
        ...state,
        boxParts: updateDT
      }
    }
    case SET_BOX_GENERAL:{
      return {
        ...state,
        boxGeneral: action.payload
      }
    }
    case UPDATE_BOX_GENERAL_VALUE:{
      const updateValue = state.boxGeneral.map(general=>{
        if(Number(general.article_symbol_general_id) === Number(action.payload.article_symbol_general_id)){
          return{
            ...general,
            value: action.payload.value
          }
        }
        return general;
      })
      return {
        ...state,
        boxGeneral: updateValue
      }
    }
    default:
      return state
  }
}

export default BoxesReducer;
