import Modal from "../../../components/Modals/BasicModal";
import React from 'react';
import {useSelector} from "react-redux";
import {getCellFullInfo, getCellsModal} from "../../../selectors/cells-leftovers-selector";
import {getLocalLang} from "../../../selectors/settings-selector";

const CellInfo = ({onToggleInfoModal, getT}) => {
    const {info} = useSelector(getCellsModal);
    const cellInfo = useSelector(getCellFullInfo);
    const lang = useSelector(getLocalLang);

    return <Modal title={info && info.title}
                  open={info.isOpen} onClose={() => onToggleInfoModal(false)}>
        <div className={"inst__bore-form"}>

            {cellInfo && Array.isArray(cellInfo) && cellInfo.length >= 1 ? (
                <div className="overflow-scrolled">
                    <table className={"stock-table"}>
                        <thead>
                        <tr>
                            <th>{getT("Название")}</th>
                            <th>{getT("Размер (мм)")}</th>
                            <th>{getT("Площадь")}</th>


                        </tr>
                        </thead>
                        <tbody>
                        {cellInfo.map(cell => {
                            return (<tr>
                                <td> {cell.hasOwnProperty('goods') && cell.goods?.name[lang]} [{cell.hasOwnProperty('goods') && cell.goods?.producer[lang]}]</td>
                                <td style={{whiteSpace: 'nowrap'}}>
                                    <span title={'x'}> {cell.hasOwnProperty('x') && cell?.x}</span>
                                    <strong> x </strong>
                                    <span title={'y'}> {cell.hasOwnProperty('y') && cell?.y}</span>
                                    {cell.hasOwnProperty('z') && <strong> x </strong>}
                                    <span title={'z'}> {cell.hasOwnProperty('z') && cell?.z}</span>

                                </td>
                                <td style={{whiteSpace: 'nowrap'}}>{cell?.area_goods_stock} {cell?.goods?.unit[lang]}</td>
                            </tr>)
                        })}

                        </tbody>
                    </table>
                </div>
            ) : <h3 style={{textAlign: 'center'}}>{getT('Информации по ячейки отсутствует')}</h3>}
        </div>
    </Modal>
}

export default CellInfo;