import getT from "../../getT"

export default function ({xnc, type_name=false}){
  return (
    ` <tr>
        <th></th>
         <th>${getT('количество деталей со срезом торца')}</th>
                <th>${getT('количество срезов торца')}</th>
                <th>${getT('длина срезов торца')}</th>
        <th></th>
      </tr>
      <tr>
        <th> ${type_name? type_name : ( getT(xnc?.type_name) ?? '')}</th>
         <td>${+Number(xnc?.part_srez_count ?? '').toFixed(2) }</td>
         <td>${+Number(xnc?.srez_count ?? '').toFixed(2) }</td>
         <td>${+Number(xnc?.srez_cut_count ?? '').toFixed(2) }</td>
        <td></td>
      </tr>
  `
  );
}