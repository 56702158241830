import * as React from "react";

function SurfaceTreatment(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.375 10h-9.75C2.728 10 2 10.727 2 11.625v9.75C2 22.273 2.728 23 3.625 23h9.75c.898 0 1.625-.727 1.625-1.625v-9.75c0-.898-.727-1.625-1.625-1.625zM18 19.5l2.5 2.5m0 0l2.5-2.5M20.5 22V9M12.5 6L15 3.5m0 0L12.5 1M15 3.5H2"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SurfaceTreatment;
