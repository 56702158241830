import { tools } from './tools.js';

export const measurementsLib = {
  getEdgeCoords(
    partX, partY, partZ, parentTranslate, sideTranslates,
    measurementOffset
  ) {
    let [xParent, yParent] = parentTranslate;
    let xLeft = sideTranslates.left[0] - measurementOffset;
    let xRight = sideTranslates.right[0] + partZ + measurementOffset;
    let yTop = sideTranslates.top[1] - measurementOffset;
    let yBottom = sideTranslates.bottom[1] + partZ + measurementOffset;
    let coords = [
      {
        main: [xParent, yParent],
        x: [xLeft, xParent],
        y: [xLeft, yParent],
        side: 'f',
        show: {
          x: 0, y: 0
        },
        show1: {
          x1: 0, y1: 0
        },
        cornerMes: true
      },
      {
        main: [xParent + partX, yParent],
        x: [xParent + partX, yTop],
        y: [xRight, yParent],
        side: 'f',
        show: {
          x: partX, y: 0
        },
        show1: {
          x1: partX, y1: 0
        },
        cornerMes: true
      },
      {
        main: [xParent + partX, yParent + partY],
        x: [xParent + partX, yBottom],
        y: [xRight, yParent + partY],
        side: 'f',
        show: {
          x: partX, y: partY
        },
        show1: {
          x1: partX, y1: partY
        },
        cornerMes: true
      },
      {
        main: [xParent, yParent + partY],
        x: [xParent, yBottom],
        y: [xLeft, yParent + partY],
        side: 'f',
        show: {
          x: 0, y: partY,
        },
        show1: {
          x1: 0, y1: partY
        },
        cornerMes: true
      }
    ];

    for (let c of coords) {
      c.starting = true;
    }

    return coords;
  },
  partMeasurements(
    partX, partX1, partY, partY1, parentTranslate, sideTranslates, maxMesOffset,
    fontSize, fontSizePostfix
  ) {
    let [parX, parY] = parentTranslate;

    let [topX, topY] = sideTranslates.top;
    let [leftX, leftY] = sideTranslates.left;

    let rndPartX = tools.rnd(partX);
    let rndPartX1 = tools.rnd(partX1);
    let rndPartY = tools.rnd(partY);
    let rndPartY1 = tools.rnd(partY1);

    let horText;

    if (rndPartX === rndPartX1) {
      horText = String(rndPartX);
    } else {
      horText = `${tools.rnd(partX)} / ${tools.rnd(partX1)}`;
    }

    let [horX, horY] = [topX, topY - maxMesOffset];
    let horTextX = horX + partX / 2;
    let horTextY = horY - fontSize;

    let verText;

    if (rndPartY === rndPartY1) {
      verText = String(rndPartY);
    } else {
      verText = `${tools.rnd(partY)} / ${tools.rnd(partY1)}`;
    }

    let [verX, verY] = [leftX - maxMesOffset, leftY];
    let verTextX = verX - fontSize;
    let verTextY = verY + partY / 2;

    let lineArgs = {
      stroke: 'blue',
      'stroke-width': '2px'
    }

    let partMeasurementsLines = [
      // width measurement
      tools.g([
        tools.line(parX, parY, parX, horY - 10, { ...lineArgs, lborder: '1' }),
        tools.line(parX + partX, parY, parX + partX, horY - 10, { ...lineArgs, lborder: '1' }),
        tools.line(horX, horY, horX + partX, horY, { ...lineArgs, lbridge: '1' }),
      ], { 'main-mes': 'w' }),
      // height measurement
      tools.g([
        tools.line(parX, parY, verX - 10, parY, { ...lineArgs, lborder: '1' }),
        tools.line(parX, parY + partY, verX - 10, parY + partY, { ...lineArgs, lborder: '1' }),
        tools.line(verX, verY, verX, parY + partY, { ...lineArgs, lbridge: '1' }),
      ], { 'main-mes': 'h' })
    ].join('');
    let textKwargs = {
      'text-anchor': 'middle',
    };
    let partMeasurementsTexts = [
      tools.text(
        horText,
        {
          x: horTextX,
          y: horTextY,
          'font-size': `${fontSize}${fontSizePostfix}`,
          fill: lineArgs.stroke,
          ...textKwargs,
          'main-mes': 'w'
        }
      ),
      tools.text(
        verText,
        {
          x: verTextX,
          y: verTextY,
          transform: 'rotate(-90)',
          'font-size': `${fontSize}${fontSizePostfix}`,
          fill: lineArgs.stroke,
          ...textKwargs,
          'main-mes': 'h'
        }
      )
    ].join('');

    return [partMeasurementsLines, partMeasurementsTexts];
  },
  formatMeasurements(
    partX, partX1, partY, partY1, partZ, parentTranslate, sideTranslates,
    measurementOffset, measurements
  ) {
    let fontSize = 100;
    let fontSizePostfix = 'px';
    let [parentX, parentY] = parentTranslate;
    let parentHalfWX = parentX + partX / 2;
    let parentHalfHY = parentY + partY / 2;
    let additionalOffsetX = 0;
    let additionalOffsetY = 0;

    let allShows = Object.fromEntries(
      ['t', 'b', 'r', 'l', 'f', 'bb'].map(e => [
        e, Object.fromEntries(['t', 'r', 'b', 'l'].map(ee => [
          ee, []
        ]))
      ])
    );
    let measurementsSvg = '';
    let textSvgs = '';
    let measurementsSvgNoMeasurements = '';
    let textSvgsNoMeasurements = '';

    let edgeCoords = this.getEdgeCoords(
      partX, partY, partZ, parentTranslate, sideTranslates,
      measurementOffset
    );
    measurements = [...measurements, ...edgeCoords];

    let xSorted = measurements
      .filter(m => m.x)
      .sort((a, b) => a.x[0] - b.x[0]);
    let ySorted = measurements
      .filter(m => m.y)
      .sort((a, b) => a.y[1] - b.y[1]);

    let lineKwargs = {
      stroke: 'teal',
      'stroke-width': '1px',
      'class': 'measurements'
    };
    let noMesLastId = 0;

    for (let [side, arr] of [['x', xSorted], ['y', ySorted]]) {
      for (let currIndex = 0; currIndex < arr.length; currIndex++) {
        let curr = arr[currIndex];
        let [xCurr, yCurr] = curr[side];

        let t, t1, rotT1, xText, yText, transform, textSide, mesGroup;

        // parallel to Y
        if (arr === xSorted) {
          t = curr.show.x;
          t1 = curr.show1.x1;
          rotT1 = curr.show1.rotX1;
          xText = xCurr;
          yText = yCurr;
          transform = 'rotate(90)';

          if (yCurr < parentHalfHY) {
            textSide = 't';
          } else {
            textSide = 'b';
          }

          mesGroup = `${textSide}-${currIndex}`;
        } else if (arr === ySorted) {
          t = curr.show.y;
          t1 = curr.show1.y1;
          rotT1 = curr.show1.rotY1;
          xText = xCurr;
          yText = yCurr;
          transform = '';

          if (xCurr < parentHalfWX) {
            textSide = 'l';
          } else {
            textSide = 'r'
          }

          mesGroup = `${textSide}-${currIndex}`
        }

        // if is corner measurement, then don't write text
        let isCornerMes = (
          (side === 'x' && [0, partX].includes(t))
          || (side === 'y' && [0, partY].includes(t))
        )
        if (isCornerMes) continue;

        // measurements which are shown on hover,
        // when all measurements are off
        let measureId = curr.measureId;

        measurementsSvgNoMeasurements += tools.line(
          ...curr.main, xCurr, yCurr,
          {
            ...lineKwargs,
            'measure-id': measureId,
            'measure-side': textSide,
            'no-mes-id': noMesLastId,
          }
        );
        let [mesOffTextX, mesOffTextY] = [xCurr, yCurr];
        let textAttrs = {
          x0: mesOffTextX,
          y0: mesOffTextY,
          'measure-id': measureId,
          'measure-side': textSide,
          'no-mes-id': noMesLastId,
          'class': 'hidden',
          'style': 'fill: black; stroke: white; stroke-width: 0.7px; font-weight: bold;',
          'text-anchor': 'lt'.includes(textSide) ? 'end' : 'start',
          'alignment-baseline': 'middle',
          'single-measurement': '',
          'text-measurement': '',
        };
        textSvgsNoMeasurements += tools.text(
          tools.rnd(t1),
          {
            x: textAttrs.x0,
            y: textAttrs.y0,
            transform: transform,
            'font-size': `${fontSize / 4.3}${fontSizePostfix}`,
            ...textAttrs
          }
        );
        // end

        // double measurements start
        let doubleMeasurementsTextAttrs = {
          x0: mesOffTextX,
          y0: mesOffTextY,
          'measure-side': textSide,
          style: 'fill: black; stroke: white; stroke-width: 0.7px; font-weight: bold;',
          'text-anchor': 'lt'.includes(textSide) ? 'end' : 'start',
          'alignment-baseline': 'middle',
        };
        // line continuation
        let dmLineOffset = 10;

        if ('tl'.includes(textSide)) {
          dmLineOffset = -dmLineOffset;
        }

        let dmLineKwargs = {
          x2: mesOffTextX + dmLineOffset,
          y2: mesOffTextY,
        };

        let doubleMeasurementsGroup = tools.g([
          tools.g([
            tools.line(
              xCurr, yCurr, dmLineKwargs.x2, dmLineKwargs.y2, {
              ...lineKwargs,
              'class': ''
            }),
            tools.text(
              tools.rnd(t1), {
              x: textAttrs.x0,
              y: textAttrs.y0 + 10,
              'font-size': `${fontSize / 4.3}${fontSizePostfix}`,
              double_coord_side: 'b',
              ...doubleMeasurementsTextAttrs
            }),
            tools.text(
              tools.rnd(rotT1), {
              x: textAttrs.x0,
              y: textAttrs.y0 - 10,
              'font-size': `${fontSize / 4.3}${fontSizePostfix}`,
              double_coord_side: 't',
              ...doubleMeasurementsTextAttrs,
            }),
            tools.arrow(
              textAttrs.x0 + dmLineOffset / 2,
              textAttrs.y0
            ),
            tools.arrow(
              textAttrs.x0 + dmLineOffset / 2,
              textAttrs.y0,
              {
                rotate: 180
              },
            ),
          ],
            {
              double_measurement: '',
              x0: textAttrs.x0,
              y0: textAttrs.y0,
              'transform-origin': `${xCurr} ${yCurr}`,
              'no-mes-id': noMesLastId,
              'measure-id': measureId,
              'measure-side': textSide,
              'text-measurement': '',
              'class': 'hidden',
              'double-measurement': '',
            }),
        ],
          {
            transform: transform,
            'transform-origin': `${xCurr} ${yCurr}`
          });

        measurementsSvgNoMeasurements += doubleMeasurementsGroup;
        // double measurements end

        noMesLastId += 1;

        // single measurements start
        let mesSide = curr.side;

        // preventing duplication
        if (![
          ...allShows[mesSide][textSide],
          ...allShows.f[textSide],
          ...allShows.bb[textSide]].includes(t)
        ) {
          allShows[mesSide][textSide].push(t);

          measurementsSvg += tools.line(
            ...curr.main, xCurr, yCurr,
            { ...lineKwargs, 'mes-group': mesGroup }
          );

          textAttrs = {
            'class': 'measurements',
            x0: xText,
            y0: yText,
            'mes-group': mesGroup
          };
          let tT1Text = (
            tools.tspan(tools.rnd(t))
            + (tools.rnd(t) !== tools.rnd(t1) ? ' / ' + tools.tspan(tools.rnd(t1), { fill: tools.measureColor_2 }) : '')
          );

          textSvgs += tools.text(
            tT1Text, {
            x: xText,
            y: yText,
            transform: transform,
            'font-size': `${fontSize / 9}${fontSizePostfix}`,
            ...textAttrs
          }
          );
          // single measurements end
        }
      }
    }

    let additionalOffset = Math.max(
      additionalOffsetX, additionalOffsetY
    );

    let maxMeasurementOffset = measurementOffset + additionalOffset;

    let [partMeasurementsLines, partMeasurementsTexts] = this.partMeasurements(
      partX, partX1, partY, partY1, parentTranslate, sideTranslates,
      maxMeasurementOffset, fontSize / 5, fontSizePostfix
    );

    measurementsSvg += partMeasurementsLines;
    textSvgs += partMeasurementsTexts;

    measurementsSvgNoMeasurements += partMeasurementsLines;
    textSvgsNoMeasurements += partMeasurementsTexts;

    return [
      measurementsSvg + textSvgs,
      measurementsSvgNoMeasurements + textSvgsNoMeasurements,
      partMeasurementsLines + partMeasurementsTexts,
      additionalOffset
    ]
  },

  measurementCoords(
    x, y, x1, y1, partX, partY, partX1, partY1, partZ, side, parentTranslate,
    sideTranslates, measurementOffset, params = {}
  ) {
    x = Number(x);
    y = Number(y);
    x1 = Number(x1);
    y1 = Number(y1);

    let defaultParams = {
      axis: null,
      measureId: null,
      onSide: null
    }
    params = { ...defaultParams, ...params };
    let axis = params.axis;

    // partX and partY differ for side measurements
    let thisPartX1 = !'rl'.includes(side) ? partX1 : partZ;
    let thisPartY1 = !'tb'.includes(side) ? partY1 : partZ;

    let [parentX, parentY] = parentTranslate;
    let show = { x, y: tools.rotY(y, partY) };
    let show1 = {
      x1: x1,
      y1: thisPartY1 - y1,
      rotX1: thisPartX1 - x1,
      rotY1: y1,
    };

    if (x < partX / 2 && side !== 'r') {
      x1 = sideTranslates.left[0] - measurementOffset;
    } else {
      x1 = sideTranslates.right[0] + partZ + measurementOffset;
    }

    if (y < partY / 2 & side !== 'b') {
      y1 = sideTranslates.top[1] - measurementOffset;
    } else {
      y1 = sideTranslates.bottom[1] + partZ + measurementOffset;
    }

    x += parentX;
    y += parentY;

    let ret = {
      main: [x, y],
      side: side,
      show: show,
      show1: show1
    };
    let axises = {
      x: [x, y1],
      y: [x1, y],
    }

    if (axis === null) {
      ret = { ...ret, ...axises };
    } else {
      ret[axis] = axises[axis]
    }

    if (params.measureId) {
      ret['measureId'] = params.measureId
    }

    return ret;
  }
}
