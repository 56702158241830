import React, {useEffect, useState} from 'react';
import {useLang} from "../../../context/langProvider";
import "./main.css"
import {useSelector} from "react-redux";
import {getOrder} from "../../../selectors/order-selector";
import MemoryDataOrderContainer from "../../MemoryDataOrder/MemoryDataOrderContainer";


const LockWindow = () => {
    const order = useSelector(getOrder);
    const [isLockWindow, setIsLockWindow] = useState(false);
    const {getT} = useLang();
    useEffect(() => {
        if (!order) return null
        const order_firm = order?.firm;
        const ls_firm = localStorage.getItem('ifurn_service_main_user_firm')
        if (Number(order_firm) !== Number(ls_firm)) {
            setIsLockWindow(true);
            let body = document.querySelector('body');
            body.classList.add('body-disabled')
        } else if (isLockWindow) {
            setIsLockWindow(false);
            let body = document.querySelector('body');
            body.classList.remove('body-disabled')
        }
    }, [order]);
    if (!isLockWindow) return <>
        <MemoryDataOrderContainer key={'MemoryDataOrderContainer'}/>
    </>
    return (
        <div className={"lock-window"}>
            <div className="lock-window-modal">
                <p>{getT('Данный заказ создан под другой фирмой!')}</p>
            </div>
        </div>
    );
};

export default LockWindow;