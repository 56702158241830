/**
 * Helper konfirmat.
 * Ф-ции для проверки условий сверления (текущей операции, списке операций в детали, список деталей с операциями) для условного рендеринга
 // * @param {number} keyNavigationIndex - Index current switch keyboard  up/down.
 * @param {array} ACCESS_SIDE - List access side for manipulate.
 * @param {function} konfirmantonConditionPartOperation - проверяет операцию сверления и возвращает boolean
 * @param {function} konfirmantonConditionPartOperations - проверяет в детали операции сверления и возвращает boolean.
 * @param {function} konfirmantonConditionParts - проверяет в деталях операции сверления и возвращает boolean.

 * @returns {jsx | null} - Component or null.
 */
const ACCESS_SIDE = ['f', 'bb']

/**
 * Helper  konfirmantonConditionPartOperation.
 * Проверяет операцию на условие отображение зенкования
 * @param {object} part - деталь.
 * @param {object} operation - операция сверления.
 * @param {number || string} production - свойство с прод.
 * @returns {boolean} - display/hide
 */
export const konfirmantonConditionPartOperation = ({part, operation, production}) => {
    let part_z = Number(part.z);
    let isDiameterEqual = Number(production) === Number(operation.d);
    let isDepthGreater = part_z < Number(operation.z);
    return ACCESS_SIDE.includes(operation.side) && isDepthGreater && isDiameterEqual
}

/**
 * Helper  konfirmantonConditionPartOperations.
 * Проверяет в детали список операций сверления на условие отображение зенкования
 *
 * @param {object} part - деталь.
 * @param {number || string} production - свойство с прод.
 * @returns {boolean} - display/hide
 */
export const konfirmantonConditionPartOperations = ({part, production}) => {
    let part_z = Number(part.z);
    let bore = part.operations.bore;

    return bore?.some(item => {
        let isDiameterEqual = Number(production) === Number(item.d);
        let isDepthGreater = part_z < Number(item?.z)
        return ACCESS_SIDE.includes(item.side) && isDepthGreater && isDiameterEqual
    })
}


/**
 * Helper  konfirmantonConditionParts. Проверяет список деталей  операций сверления на условие отображение зенькования
 *
 // * @param {number} keyNavigationIndex - Index current switch keyboard  up/down.
 * @param {object} part - деталь.
 * @param {number || string} production - свойство с прод.
 * @returns {boolean} - display/hide
 */
export const konfirmantonConditionParts = ({parts, production}) => {
    return parts?.some(part => {
        return konfirmantonConditionPartOperations({part, production})
    })
}


/**
 * Helper  konfirmantChangeValueOperationOfPart.
 * Задать значение текущей операции сверления
 *
 // * @param {number} keyNavigationIndex - Index current switch keyboard  up/down.
 * @param {object} part - деталь.
 * @param {object} operation - операция сверления.
 * @param {string} value - значение на которое зенкуем.
 * @returns {object} - возвращает актуальную деталь
 */
const ACCESS_VALUE = ['f', 'bb', 'no']
export const konfirmantChangeValueOperationOfPart = ({part, operation, value}) => {
    try {
        if (!ACCESS_VALUE.includes(value)) {
            console.error('not include value', value)
            return part;
        }

        let operation_update = {...operation, zenkovanie: value}
        if (value !== 'no') {
            operation_update.side = value;
        }
        let part_update = {
            ...part,
            operations: {
                ...part.operations,
                bore: part?.operations?.bore?.map(item => {
                    if (Number(item.id) === Number(operation_update.id)) {
                        return operation_update
                    }
                    return item
                })
            }
        }
        return part_update
    } catch (e) {
        console.error(e);
        return part
    }

}

/**
 * Helper  konfirmantChangeAllValueOperationsOfPart.
 * Задать значение в операции детали сверления которые входят в условие
 *
 // * @param {number} keyNavigationIndex - Index current switch keyboard  up/down.
 * @param {object} part- деталь.
 * @param {string} value - значение.
 * @param {string|| number} production - значение на которое зенкуем.
 * @returns {object} - возвращает актуальную деталь
 */
export const konfirmantChangeAllValueOperationsOfPart = ({part, value, production}) => {
    try {
        if (!ACCESS_VALUE.includes(value)) {
            console.error('not include value', value)
            return part;
        }

        let part_update = {
            ...part,
            operations: {
                ...part.operations,
                bore: part?.operations?.bore?.map(item => {
                    if (konfirmantonConditionPartOperation({part, production, operation: item})) {
                        return {
                            ...item,
                            zenkovanie: value,
                            side: value !== 'no' ? value : item.side
                        }
                    }
                    return item
                })
            }
        }
        return part_update
    } catch (e) {
        console.error(e);
        return part
    }
}


/**
 * Helper  konfirmantChangeAllValueOperationsOfPart.
 * Задать значение в операции деталей сверления которые входят в условие
 *
 * @param {object} parts- деталь.
 * @param {string} value - значение на которое зенкуем..
 * @param {string|| number} production - продакшин.
 * @returns {object} -  Возвращает актуальную деталь
 */
export const konfirmantChangeAllValueOperationsOfEntryParts = ({parts, value, production}) => {
    try {
        if (!ACCESS_VALUE.includes(value)) {
            console.error('not include value', value)
            return parts;
        }

        let parts_update = parts?.map(part => {
                return {
                    ...part,
                    operations: {
                        ...part.operations,
                        bore: part?.operations?.bore?.map(item => {
                            if (konfirmantonConditionPartOperation({part, production, operation: item})) {
                                return {
                                    ...item,
                                    zenkovanie: value,
                                    side: value !== 'no' ? value : item.side
                                }
                            }
                            return item
                        })
                    }
                }
            }
        );
        return parts_update
    } catch
        (e) {
        console.error(e);
        return parts
    }
}

export const konfirmantOffAllOperationsOfParts = ({parts}) => {
    try {
        let parts_update = parts?.map(part => {
            return {
                ...part,
                operations: {
                    ...part.operations,
                    bore: part?.operations?.bore?.map(item => {
                        if (ACCESS_SIDE.includes(item.side) && item.hasOwnProperty('zenkovanie')) {
                            return {
                                ...item,
                                zenkovanie: 'no',
                            }
                        }
                        return item
                    })
                }
            }
        });
        return parts_update
    } catch (e) {
        return parts;
    }
}

export const konfirmantOffAllOperationsBore = ({bore}) => {
    try {
        let bore_update = bore?.map(item => {
            if (ACCESS_SIDE.includes(item.side) && item.hasOwnProperty('zenkovanie')) {
                return {
                    ...item,
                    zenkovanie: 'no',
                }
            }
            return item
        })
        return bore_update
    } catch (e) {
        return bore;
    }
}