import React, {useCallback, useMemo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {
    changeClientMaterialPartValuesAC,
    changeClientMaterialValuesAC,
    cleanMaterialsPartsFormDataAC
} from "../../../store/reducers/MaterialsReducer";
import {random} from "../../../helpers/helpers";
import CloseModal from "../../../Icons/Actions/Close";
import {changeOrderMaterialValueDataAC} from "../../../store/reducers/OrderReducer";
import IconArrow from "../../../Icons/IconArrow";

const MaterialParts = ({isAdded, goods_id, withTitle = true, getT}) => {
    const [isOpen, setToggleOpen] = useState(false)
    const dispatch = useDispatch();
    const productionCONST = useSelector((state) => state.order.order.production_constants);
    const form = useSelector((state) => state.materials.clientMaterialPart);
    const changeValue = useCallback((input) => (event) => dispatch(changeClientMaterialPartValuesAC(input, event.target.value)), [dispatch])
    const material = useSelector((state) => state.order.order.material.filter(e => Number(e.goods_id) === Number(goods_id)))[0];
    let partsClient = useSelector((state) => state.materials.clientMaterial.parts);

    const parts = useMemo(() => {
        if (isAdded && goods_id && material.parts) {
            return [...material.parts]
        }
        return partsClient
    }, [isAdded, goods_id, material?.parts, partsClient])


    const deleteMaterialPart = useCallback((id) => (event) => {
        const partsWithout = [...parts].filter(e => Number(e.id) !== Number(id));
        if (isAdded && goods_id) {
            dispatch(changeOrderMaterialValueDataAC(goods_id, 'parts', partsWithout))
        } else {
            dispatch(changeClientMaterialValuesAC('parts', partsWithout));
        }


    }, [parts, dispatch, goods_id, isAdded])
    //::TODO добавила свойства
    const addPart = () => {
        const partsWith = [
            ...parts,
            {
                id: '1' + String(random(0, 12, 0)),
                x: form.x,
                y: form.y,
                count: form.count,
                forSale: form.count,
                // goods_stock_id: Number( goods_id) + 1
            }
        ];
        if (isAdded && goods_id) {
            dispatch(changeOrderMaterialValueDataAC(goods_id, 'parts', partsWith))
        } else {
            dispatch(changeClientMaterialValuesAC('parts', partsWith));
        }
        dispatch(cleanMaterialsPartsFormDataAC())
    }

    return <React.Fragment key={'material-parts'}>
        <Table parts={parts} getT={getT} deleteMaterialPart={deleteMaterialPart} key={'table'}/>
        <div className="material_band_modal_create_header">
            <h3>{getT("Добавить остаток материала")}</h3>
            <IconArrow top={isOpen} onCLickHandler={()=>setToggleOpen(!isOpen)}/>
        </div>
        {isOpen && <form id="add-materials-parts-pieces-form" onSubmit={(event) => {
            event.preventDefault();
            addPart();
        }}
              className={'add-materials-parts-pieces-form'}
        >
            {/*<div className={"form-field"}>*/}
            {/*    <h3>{getT("Добавить кусок материала")}</h3>*/}
            {/*</div>*/}
            <div className="form-group-rx-sm-02  form-group-rx-md-03">
                <div className="form-field">
                    <label className="label label-sm" htmlFor="material-type">
                        {getT("Длина")}:
                    </label>
                    <input
                        type="number"
                        className="form-input-sm"
                        min={productionCONST["production.min_size_material_client"]}
                        max={productionCONST["production.max_size_material_client"]}
                        step={1}
                        placeholder=""
                        value={form.x}
                        onChange={changeValue("x")}
                        required={true}
                    />
                </div>

                <div className="form-field">
                    <label className="label label-sm" htmlFor="material-type">
                        {getT("Ширина")}:
                    </label>
                    <input
                        type="number"
                        className="form-input-sm"
                        min={productionCONST["production.min_size_material_client"]}
                        max={productionCONST["production.max_size_material_client"]}
                        step={1}
                        placeholder=""
                        value={form.y}
                        onChange={changeValue("y")}
                        required={true}
                    />
                </div>

                <div className="form-field">
                    <label className="label label-sm" htmlFor="material-type">
                        {getT("Количество")}:
                    </label>
                    <input
                        type="number"
                        className="form-input-sm"
                        min={1}
                        step={1}
                        placeholder=""
                        value={form.count}
                        onChange={changeValue("count")}

                        required={true}
                    />
                </div>
            </div>
            <div className="form-field" style={{marginTop: '15px'}}>
                <input className="form-input submit" type="submit" value={getT('Добавить')}/>
            </div>
        </form>}
    </React.Fragment>;
};

const Table = ({parts, getT, deleteMaterialPart}) => {
    const [isOpen, setToggleOpen] = useState(false)
    if (!Array.isArray(parts) || !parts?.length) return null;
    return (
        <div className="mb-2 w-100">
            <div className="material_band_modal_create_header">
                <h3>{getT("Список материалов")}</h3>
                <IconArrow top={isOpen} onCLickHandler={()=>setToggleOpen(!isOpen)}/>
            </div>
            {isOpen &&  <div className="">
                <table className={"table-border"}>
                    <thead>
                    <tr>
                        {/*<th scope="col">№</th>*/}
                        <th scope="col">{getT("Длина")}</th>
                        <th scope="col">{getT("Ширина")}</th>
                        <th scope="col">{getT("Количество")}</th>
                        <th scope="col">{getT("Id остатка")}</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {parts.map(e => {
                        return <tr>
                            {/*<td>{e.id}</td>*/}
                            <td>{e.x}</td>
                            <td>{e.y}</td>
                            <td>{e.count}</td>
                            <td>{e.goods_stock_id ? e.goods_stock_id : "-"}</td>
                            <td><CloseModal width={15} height={15}  onClick={deleteMaterialPart(e.id)} isBlack={true}/></td>
                        </tr>;
                    })}
                    </tbody>
                </table>
            </div> }
        </div>
    )
}

export default React.memo(MaterialParts);