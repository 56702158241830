import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeMaterialsFiltersValuesAC,
    changeMaterialsSearchDataAC, cleanMaterialsFiltersFormAC,
    getMaterialsDataThunk,
    getMaterialsTreeDataThunk,
    setActiveMaterialsCategoryAC
} from "../../../store/reducers/MaterialsReducer";
import {
    getActiveCategoryIsMaterials,
    getFilterMaterials,
    getMaterialsDT,
    getSearchMaterials,
    getTreeMaterials
} from "../../../selectors/custom-materials-balances";
import MaterialContainerList from "./MaterialContainerList";
import {actionsCellsLeftovers} from "../../../store/reducers/StorageCellsLeftovers";

const MaterialChoiceContainer = ({getT, onComeBackBefore}) => {
    const dispatch = useDispatch();
    const treeMaterials = useSelector(getTreeMaterials);
    const filters = useSelector(getFilterMaterials);
    const searchInput = useSelector(getSearchMaterials);
    const materials = useSelector(getMaterialsDT);
    const activeCategory = useSelector(getActiveCategoryIsMaterials);

    useEffect(() => {
        dispatch(getMaterialsTreeDataThunk(1));
        dispatch(setActiveMaterialsCategoryAC(4, 'Плитный материал'));
        // onChangeSearchInput();
        dispatch(getMaterialsDataThunk(4, filters, searchInput, 1))
    }, []);
    useEffect(() => {
        dispatch(getMaterialsDataThunk(activeCategory.id, null, null, 1))
    }, [activeCategory])

    const onGetMaterials = (page = 1) => {
        dispatch(getMaterialsDataThunk(activeCategory, filters, searchInput, page))
    }
    const onAddMaterial = (goods) => {
        dispatch(actionsCellsLeftovers.onSetChoiceMaterial(goods))

        onComeBackBefore('create')
    }
    const onChangeSearchInput = (search = '')=>{
        dispatch(changeMaterialsSearchDataAC(search));
    }
    const onChangeFilterValue = (input, value)=>{
        dispatch(changeMaterialsFiltersValuesAC(input, value))
    }
    const onCleanFilters= ()=> dispatch(cleanMaterialsFiltersFormAC())

    return <>
    <MaterialContainerList
        onComeBackBefore={onComeBackBefore}
        getT={getT}
        tree={treeMaterials}
        // setActiveCategory={onSetActiveCategories}
        activeCategory={activeCategory}
        onGetMaterials={onGetMaterials}
        materials={materials}
        changeSearchInput={onChangeSearchInput}
        searchInput={searchInput}
        changeFilterValue={onChangeFilterValue}
        filters={filters}
        cleanFilters={onCleanFilters}
        onAddMaterial={onAddMaterial}
        // addMaterial={onAddMaterial}
        // orderData={{
        //     code: 1,
        //     version: 1,
        // }}
        // addedMaterials={onAddMaterial}
    />
    </>
}

export default MaterialChoiceContainer;