import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeOrdersFiltersDataValueAC, resetOrdersFiltersDataValueAC} from "../../store/reducers/CommonReducer";
import {getAccountOrdersThunk} from "../../store/reducers/AuthReducer";
import {useLang} from "../../context/langProvider";

const CabinetOrdersFilters = () => {
    const {getT} = useLang();

    const dispatch = useDispatch();
    const changeValue = (input, value) => dispatch(changeOrdersFiltersDataValueAC(input, value));
    const form = useSelector((state) => state.commons.ordersFilters);
    const statuses = useSelector((state) => state.commons.statuses);
    const getOrders = () => dispatch(getAccountOrdersThunk());
    useEffect(() => {

        return () => {
            dispatch(resetOrdersFiltersDataValueAC())

        };
    }, []);
    return <div className="cabinet-orders-filters-container">
        <div className="cabinet-orders-filters-item">
            <div className="form-field">
                <label className="label" htmlFor="material-type">
                    {getT('Название')}:
                </label>
                <input
                    type="text"
                    className="form-input"
                    value={form.name}
                    onChange={(event) => changeValue('name', event.target.value)}
                    onBlur={() => getOrders()}
                    required={'required'}
                />
            </div>
        </div>
        <div className="cabinet-orders-filters-item">
            <div className="form-field">
                <label className="label" htmlFor="material-type">
                    {getT('Код')}:
                </label>
                <input
                    type="text"
                    className="form-input"
                    value={form.order_code}
                    onChange={(event) => changeValue('order_code', event.target.value)}
                    onBlur={() => getOrders()}
                    required={'required'}
                />
            </div>
        </div>
        <div className="cabinet-orders-filters-item">
            <div className="form-field">
                <label className="label" htmlFor="material-type">
                    {getT('ID')}:
                </label>
                <input
                    type="text"
                    className="form-input"
                    value={form.id}
                    onChange={(event) => changeValue('id', event.target.value)}
                    onBlur={() => getOrders()}
                    required={'required'}
                />
            </div>
        </div>
        <div className="cabinet-orders-filters-item">
            <div className="form-field">
                <label className="label" htmlFor="producer">
                    {getT('Статус')}:
                </label>
                <select className="form-input"
                        placeholder=""
                        value={form.status}
                        defaultValue={form.material}
                        onChange={(event) => changeValue('status', event.target.value)}
                        onBlur={() => getOrders()}
                        required={'required'}
                >
                    <option value={''} disabled={'disabled'} selected={'selected'}>--- {getT('Выбрать')} ---</option>
                    {statuses.map(e => {
                        return <option value={e.id} key={e.id}>{getT(e.name)}</option>
                    })}
                </select>
            </div>
        </div>
        <div className="cabinet-orders-filters-item">
            <div className="form-field">
                <label className="label" htmlFor="material-type">
                    {getT('Дата (от)')}:
                </label>
                <input
                    type="date"
                    className="form-input"
                    value={form.date_from}
                    onChange={(event) => changeValue('date_from', event.target.value)}
                    onBlur={() => getOrders()}
                    required={'required'}
                />
            </div>
        </div>
        <div className="cabinet-orders-filters-item">
            <div className="form-field">
                <label className="label" htmlFor="material-type">
                    {getT('Дата (до)')}:
                </label>
                <input
                    type="date"
                    className="form-input"
                    value={form.date_to}
                    onChange={(event) => changeValue('date_to', event.target.value)}
                    onBlur={() => getOrders()}
                    required={'required'}
                />
            </div>
        </div>
    </div>;
};

export default CabinetOrdersFilters;