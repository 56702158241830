import React from 'react';
import ChpuClear from "../../../Icons/TableActions/ChpuClear";
import OperationConfirmDelete from "./OperationConfirmDelete";

const ItemSide = ({delete_operation, settings_production, disabled, checked, cnc_operations, handlerToggleOperation, handlerDelete, getT, part, side}) => {
    // let {delete_operation, settings_production, disabled, checked, cnc_operations} = side_settings;

    return (
        <td style={{
            background: `${delete_operation?.is_delete ? '#f2f2f2' : 'inherit'}`
        }}>
            {delete_operation?.is_delete &&
                <span className="current_operation_name" title={delete_operation?.operation?.name}>{delete_operation?.operation?.name}</span>
                    }
            <div className="plane-operation-action d-flex relative">
                {delete_operation?.is_delete ?
                    <>
                    <OperationConfirmDelete getT={getT} delete_operation={delete_operation}
                                            handlerDelete={handlerDelete}/>
                    </>
                    :
                    <label
                        className={`form-input-checkbox-label ${disabled ? 'disabled' : ''}`}

                        htmlFor={"plane_operations_side_" +side+ part.id}>
                        <input type="checkbox"
                               disabled={disabled}
                               checked={checked}
                               className={"form-input-checkbox"}
                               id={"plane_operations_side_" +side+ part.id}
                               onChange={handlerToggleOperation}
                        />
                        <span> </span>
                    </label>
                }
                {cnc_operations &&
                    <span className={"cnc-title"}
                          title={cnc_operations?.map(e => getT(e))?.join('\n')}>
                                <ChpuClear active={true}/>
                </span>}
            </div>
        </td>
    );
};

export default ItemSide;