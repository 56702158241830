import React, {useTransition} from 'react';
import Modal from "../BasicModal";
import CreateProjectForm from "./CreateProjectForm";
import {useDispatch, useSelector} from "react-redux";
import {createOrderProjectDataThunk} from "../../../store/reducers/OrderReducer";
import {getCreateProjectModalSelected} from "../../../selectors/modal-selector";
import {LS_FIRM} from "../../../constants";
import {modalToggleCreateProjectAC} from "../../../store/actions/modal-action";
import {getProductionProjectMakeStartName} from "../../../selectors/production-selectors";
import {getAuthUser} from "../../../selectors/auth-selector";

const CreateProjectModal = React.memo( ({getT}) => {
    const dispatch = useDispatch();
    const create_project_state = useSelector(getCreateProjectModalSelected);
    const project_make_start_name = useSelector(getProductionProjectMakeStartName);
    const user_state = useSelector(getAuthUser)
    const handlerSubmit = (values)=> {
        dispatch(createOrderProjectDataThunk(create_project_state.user_id, localStorage.getItem(LS_FIRM), values.title))
    }
    const initial_title_project = !!Number(project_make_start_name) ? user_state?.name?.slice(0, 50) : 'new order'
    const handlerClose = ()=>{
        dispatch(modalToggleCreateProjectAC({isOpen: false, firm:  null, user_id: null}))
    }
    if(!create_project_state?.isOpen) return null
    return (
        <Modal open={create_project_state?.isOpen} title={getT("Создать проект")} onClose={handlerClose}>
            <div className="modal-body">
                <CreateProjectForm getT={getT} handlerSubmit={handlerSubmit} handlerCancel={handlerClose} initial_title={initial_title_project}/>
            </div>
        </Modal>
    );
});

export default CreateProjectModal;