import {dispatcherErrorThunk} from "./common-thunk";
import {changeLoaderStatusAC} from "../reducers/CommonReducer";
import {EdgeEditAPI} from "../../api/External/EdgeEditAPI";
import {orderPartChangeProcessingEdgeSideInfPartAC} from "../actions/order-part-action";
import MaterialSelectedAPI from "../../api/MaterialSelectedAPI";
import {_getItemID, addOrderMaterialAC} from "../reducers/OrderReducer";
import getT from "../../printHtml/getT";
import {PROCESSING_EDGE_KEY_ASSOCIATION, SREZ_ANGEL_RANGE} from "../constats/processing-edge-const";
import {calculateProfilesHandlerPackHelpers} from "../../helpers/processing-edge-helpers";

const materialApi = new MaterialSelectedAPI();

export const getFetchingProcessingEdgeData = async () => {
    try {
        const response_round = await EdgeEditAPI.getRound()
        const response_handler = await EdgeEditAPI.getHandler()
        const response_hand_paint = await EdgeEditAPI.getHandlerPainting()
        const response_faska = await EdgeEditAPI.getFaska()
        const response_srez = await EdgeEditAPI.getSrez()

        let payload_result = {
            round: response_round?.data?.filter(e => !!Number(e?.enabled)) ?? null, //Number(e?.edge_edit_handle_id) !== 4
            hand: response_handler?.data?.filter(e => !!Number(e?.enabled)) ?? null,
            faska: response_faska?.data?.filter(e => !!Number(e?.enabled)) ?? null,
            hand_paint: response_hand_paint?.data?.filter(e => !!Number(e?.enabled)) ?? null,
            srez: response_srez?.data?.filter(e => !!Number(e?.enabled)) ?? null
        }

        return Promise.resolve(payload_result)
    } catch (e) {
        return Promise.resolve({
            round: null, hand: null, faska: null, hand_paint: null, srez: null
        })
    }
}
/**
 * Расчёт количества профилей в ручке.
 *
 * @param {}  -
 * @param {}  -
 * @returns  {}  -
 */

export const calculateNumberOfProfilesHandlerAndEdge = async (order, dispatch) => {
    try {
        let goods = calculateProfilesHandlerPackHelpers(order)
        return Promise.resolve({...order, goods: goods})
    } catch (e) {
        let error_msg = e?.message ? `${e?.message}` : '';
        console.error(e)
        dispatch(dispatcherErrorThunk({error: `${getT("Не удалось расчётать количество профилей в ручке")}. message=${error_msg}`}))
        return Promise.resolve(order)
    }
}

/**
 * Добавить, убрать по стороне детали обработку торца.
 *
 * @param {array} store_part -
 * @param {object}  payload -
 * @param {string}  payload.side -
 * @param {number}  payload.part_id -
 * @param {object} payload.change_data -
 * @param {object || null} payload.change_data.edge -
 * @param {string} payload.change_data.type_key_edge -
 * @param {string} payload.change_data.initial_type_key_edge-
 * @param {object || null} production_setting-
 * @param {number} production_setting.xnc_mill_width_production_min_w_for_paz- толщина пилы паза
 * @returns  {array}  updates part.edge_edit[type_key][side] -
 */
const NEAR_SIDE_PROCESSING_EDGE = {
    t: ['r', 'l'], b: ['r', 'l'], r: ['t', 'b'], l: ['t', 'b'],
}

function processingEdgeUpdateInPart(store_part, payload, production_setting = null) {
    let {side, change_data, part_id, additional_condition = {}} = payload;

    let current_key = change_data?.type_key_edge;
    let is_hand_add_paz = !!change_data?.edge?.hasOwnProperty(PROCESSING_EDGE_KEY_ASSOCIATION.hand.edge_id_key) && !!Number(change_data?.edge?.paz_w) && !!Number(change_data?.edge?.paz_z)
    let side_edge_near = change_data?.edge && change_data?.edge?.hasOwnProperty('two_sides_near_for_using') && !Number(change_data?.edge?.two_sides_near_for_using) ?
        [side, ...NEAR_SIDE_PROCESSING_EDGE[side]] : [side];

    return store_part?.map(e => {
        if (Number(e.id) !== Number(part_id)) return e;
        let edit_edge_copy = {
            ...e.edge_edit,
        }
        let part_srez = {...e.srez}
        let part_operations_paz = Array.isArray(e?.operations?.paz) ? [...e?.operations?.paz] : [];
        //** create actual data edge_edit.  Delete side which contain near side*/
        for (const [key_type, edge_side] of Object.entries(edit_edge_copy)) {
            let another_side_by_near = NEAR_SIDE_PROCESSING_EDGE[side]; // t || b
            let actual_side = edge_side ? Object.keys(edge_side)
                .filter(key => !side_edge_near.includes(key))
                .reduce((obj, key) => {
                    let is_has_near = another_side_by_near.includes(key) && edge_side?.[key]?.hasOwnProperty('two_sides_near_for_using') && !Number(edge_side?.[key]?.['two_sides_near_for_using']);
                    if (is_has_near) return obj
                    obj[key] = edge_side[key];
                    return obj;
                }, {}) : null;

            edit_edge_copy = {
                ...edit_edge_copy, [key_type]: actual_side
            }
        }
        //** filtered paz operation, which not contains actual data edge_edit.hand */
        let _edge_edit_hand_ = edit_edge_copy?.hand ?? {};

        part_operations_paz = part_operations_paz?.filter(paz => {
            let key_hand_id = PROCESSING_EDGE_KEY_ASSOCIATION.hand.edge_id_key;
            let paz_operation_has_hand = Object.entries(_edge_edit_hand_)?.find(([side, hand]) => {
                return side === paz?.side && Number(paz?.[key_hand_id]) === Number(hand?.[key_hand_id]);

            });
            if (!paz?.hasOwnProperty(key_hand_id) || !!paz_operation_has_hand) return paz;
        })
        //** create new operation in edge_edit */
        if (current_key && change_data.edge) {
            let payload = {...change_data?.edge}
            edit_edge_copy[current_key] = {
                ...edit_edge_copy[current_key], [side]: payload
            }
        }
        //добавить паз если в edge_edit есть обработка по сторонне
        if (is_hand_add_paz && edit_edge_copy?.[current_key]?.[side]) {
            let paz_id = _getItemID(e.operations.paz);
            let payload_for_paz = {
                id: paz_id,
                [PROCESSING_EDGE_KEY_ASSOCIATION.hand.edge_id_key]: change_data?.edge?.[PROCESSING_EDGE_KEY_ASSOCIATION.hand.edge_id_key],
                side: side,
                side_from: side, // side_code: 'top',
                w: production_setting?.xnc_mill_width_production_min_w_for_paz,
                f_w: change_data?.edge?.paz_w,
                w_paz: change_data?.edge?.paz_w,
                z: change_data?.edge?.paz_z,
                d: null,
                d1: change_data?.edge?.paz_d_from_back,
                band_db_id: change_data?.edge?.band_db_id,
                type_paz: 1,

            }
            part_operations_paz = [...part_operations_paz, payload_for_paz]

        }
        //** find hand which contains (srez_torca_grad >= MIN && <= MAX) for create srez by side*/
        let edit_edge_hand_find_srez_by_side = edit_edge_copy?.hand ?
            Object.entries(edit_edge_copy?.hand)
                ?.reduce((acc, [key_side, hand]) => {
                    let srez_grad = Math.abs(hand?.srez_torca_grad);
                    let is_has_srez_operation = hand?.hasOwnProperty('srez_torca_grad') && (srez_grad >= SREZ_ANGEL_RANGE?.min) && (srez_grad <= SREZ_ANGEL_RANGE?.max)
                    if (is_has_srez_operation) {
                        return {
                            ...acc, [key_side]: {...hand, otstup_from_base: 0}
                        }
                    }
                    return acc
                }, {}) : {};


        //** merge srez with hand and srez of edge_edit */
        let srez_operations = {...edit_edge_hand_find_srez_by_side, ...edit_edge_copy?.srez ?? {}};
        //** update part srez */
        for (const [key_side, value] of Object.entries(e.srez)) {
            let edge_edit_srez = srez_operations?.[key_side] ?? null;

            let key_custom_processing = edge_edit_srez?.hasOwnProperty(PROCESSING_EDGE_KEY_ASSOCIATION.srez?.edge_id_key)
                ? PROCESSING_EDGE_KEY_ASSOCIATION.srez?.edge_id_key
                : edge_edit_srez?.hasOwnProperty(PROCESSING_EDGE_KEY_ASSOCIATION.hand?.edge_id_key)
                    ? PROCESSING_EDGE_KEY_ASSOCIATION.hand?.edge_id_key : null;
            let payload = {
                db_id: edge_edit_srez?.band_db_id ?? null,
                otstup: edge_edit_srez?.otstup_from_base ?? null,
                ugol: edge_edit_srez?.srez_torca_grad ?? null
            }

            if (key_custom_processing) {
                payload = {
                    ...payload, [key_custom_processing]: edge_edit_srez?.[key_custom_processing],
                }
            }
            part_srez = {
                ...part_srez, [key_side]: payload
            }

        }


        return {
            ...e, edge_edit: edit_edge_copy, srez: part_srez, operations: {
                ...e.operations, paz: [...part_operations_paz]
            }
        }
    })
}

/**
 * Изменить обработку торцов для текущей детали.
 *
 * @param {number}  part_id -
 * @param {object} change_data -
 * @param {object || null} change_data.edge -
 * @param {string} change_data.type_key_edge -
 * @param {string} change_data.initial_type_key_edge-
 * @returns  {}  -
 */

export const processingEdgeChangeItemPartThunk = ({
                                                      side, part_id, change_data, additional_condition = {}
                                                  }) => async (dispatch, getState) => {
    const access_type_key_for_adding_material = ['hand', 'srez']
    try {
        let order = getState().order.order;
        let xnc_mill_width_production_min_w_for_paz = Number(order?.production_constants?.['production.xnc_mill_width'])
        const store_part = order.part;
        let is_has_adding_goods = change_data?.edge?.goods && !order?.goods?.some(e => Number(e.id) === Number(change_data?.edge?.goods))
        let is_has_adding_band = change_data?.edge?.band_db_id && !order?.band?.some(e => Number(e.id) === Number(change_data?.edge?.band_db_id));

        let update_part = processingEdgeUpdateInPart(store_part, {side, part_id, change_data, additional_condition}, {
            xnc_mill_width_production_min_w_for_paz: xnc_mill_width_production_min_w_for_paz
        })
        let type_processing_edge_key = change_data.type_key_edge
        dispatch(changeLoaderStatusAC(true));

        if (access_type_key_for_adding_material.includes(type_processing_edge_key) && is_has_adding_goods) {
            const response = await materialApi.getByIdMaterial(change_data.edge?.goods);
            if (!response || response?.hasOwnProperty('error')) {
                dispatch(dispatcherErrorThunk({error: `${getT("Товар не доступный")} ${change_data.edge?.goods}`}))
                return
            }
            dispatch(addOrderMaterialAC(response))
        }
        if (access_type_key_for_adding_material.includes(type_processing_edge_key) && is_has_adding_band) {
            const response = await materialApi.getByIdMaterial(change_data.edge?.band_db_id);

            if (!response || response?.hasOwnProperty('error')) {
                dispatch(dispatcherErrorThunk({error: `${getT("Кромка не доступна")} ${change_data.edge?.band_db_id}`}))
                return
            }
            dispatch(addOrderMaterialAC(response))
        }
        dispatch(orderPartChangeProcessingEdgeSideInfPartAC(update_part))
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        console.log(e)
        dispatch(dispatcherErrorThunk(e))
    }
}
