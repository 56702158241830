import * as React from "react";

function Detail(props) {
  return (
    <svg
      width={30}
      height={24}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 15a3 3 0 100-6 3 3 0 000 6z"
        stroke="#7CC576"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a1.998 1.998 0 010 2.83 1.998 1.998 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-4 0v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a1.998 1.998 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 110-4h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 112.83-2.83l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 114 0v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 013.417 1.415 2 2 0 01-.587 1.415l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 010 4h-.09a1.65 1.65 0 00-1.51 1v0z"
        stroke="#7CC576"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/*<path*/}
        {/*d="M49.917 20.79H48.73V18h-7.44v2.79h-1.188v-3.918h.828c.752-1.265 1.394-2.803 1.926-4.614.538-1.812.835-3.467.894-4.966h4.812v9.58h1.355v3.919zm-2.6-3.918v-8.46h-2.44c-.087 1.182-.382 2.603-.885 4.263-.503 1.656-1.09 3.054-1.758 4.197h5.083zm7.317 1.274c-1.186 0-2.124-.36-2.812-1.084-.684-.722-1.026-1.726-1.026-3.01 0-1.294.318-2.322.952-3.083.64-.762 1.497-1.143 2.571-1.143 1.006 0 1.802.332 2.388.996.586.66.879 1.531.879 2.615v.77h-5.53c.024.941.261 1.657.71 2.145.455.488 1.092.732 1.912.732.864 0 1.719-.18 2.564-.541v1.084c-.43.185-.838.317-1.224.395-.38.083-.842.125-1.384.125zm-.33-7.302c-.644 0-1.16.21-1.545.63-.38.42-.605 1.001-.674 1.743h4.197c0-.766-.17-1.352-.513-1.758-.341-.41-.83-.615-1.465-.615zm10.474.169h-2.607V18h-1.216v-6.987h-2.578v-1.04h6.401v1.04zM71.004 18l-.241-1.143h-.059c-.4.503-.8.845-1.201 1.026-.396.176-.891.264-1.487.264-.796 0-1.42-.206-1.875-.616-.45-.41-.674-.993-.674-1.75 0-1.621 1.297-2.47 3.89-2.55l1.362-.043v-.498c0-.63-.137-1.094-.41-1.392-.27-.302-.701-.454-1.297-.454-.669 0-1.426.205-2.27.616l-.374-.93a5.73 5.73 0 011.297-.506 5.674 5.674 0 011.42-.183c.957 0 1.665.212 2.124.637.464.425.696 1.106.696 2.043V18h-.9zm-2.746-.857c.757 0 1.35-.207 1.78-.623.434-.415.651-.996.651-1.743v-.725l-1.215.052c-.967.034-1.666.185-2.095.454-.425.263-.637.676-.637 1.237 0 .44.131.774.395 1.004.269.23.642.344 1.12.344zM80.1 18h-1.23v-6.987h-2.41c-.132 1.738-.315 3.117-.55 4.138-.234 1.02-.539 1.768-.915 2.241-.37.474-.844.71-1.42.71a1.84 1.84 0 01-.63-.087v-.901c.107.03.229.044.366.044.561 0 1.008-.594 1.34-1.78.332-1.187.584-2.988.754-5.405h4.695V18zm3.502-4.71h2.475c2.05 0 3.076.755 3.076 2.264 0 .79-.268 1.396-.805 1.816-.533.42-1.314.63-2.344.63h-3.618V9.973h1.216v3.317zm0 1.048v2.644h2.343c.645 0 1.138-.112 1.48-.337.342-.225.513-.564.513-1.018 0-.444-.16-.77-.477-.974-.312-.21-.83-.315-1.552-.315h-2.307z"*/}
        {/*fill="#050038"*/}
      {/*/>*/}
    </svg>
  );
}

export default Detail;
