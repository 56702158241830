import ApiService from "../../api/api";
import {
    changeAlertStatusAC,
    changeLoaderMessageAC,
    changeLoaderStatusAC,
    checkErrorsAPI,
    typeErrorApi
} from "./CommonReducer";
import {getTranslateMessageAPI} from "../../helpers/helpers";
import {onToggleCellModal, onTogglePlanModal, onToggleRemainModal} from "./ModalReducer";
import {
    removeMaterialPlaneStockDT,
    removeMaterialStockDT,
    setMaterialPlaneStockDT,
    setMaterialStockDT
} from "./MaterialsReducer";
import {materialSelectedFilterRemainStockAC} from "../actions/material-selected-filter-actions";

const api = new ApiService();
export const setEditCellForm = (data) => {
    return {
        stock_cell_id: data.stock_cell_id,
        x: data.x,
        y: data.y,
        z: data.z,
        name: data.name
    }
}
export const setEditRemainForm = (data) => {
    return {
        goods_stock_id: data.goods_stock_id,
        goods: data.goods_id ?? data.goods,
        cell: data.cell && Array.isArray(data.cell) && data.cell.length === 1 ? data.cell[0].stock_cell_id : data.cell.stock_cell_id,
        order_in: data.order_in,
        units: data.units,
        x: data.x,
        y: data.y
    }
}
export const setEditPlanForm = (data) => {
    return {
        orders_goods_stock_rest_plan_id: data.orders_goods_stock_rest_plan_id,
        order: data.id,
        goods: data.goods?.goods_id,
        x: data.x,
        y: data.y,
        reason_why_not: data.reason_why_not
    }
}
export const setCreatePlanForm = (data) => {
    return {
        orders_goods_stock_rest_plan_id: null,
        order_in: data.order
    }
}

let initialState =
    {
        cellsForm: {
            stock_cell_id: null,
            x: null,
            y: null,
            z: null,
            name: ''
        },
        cellsDT: [],
        cellFullInfo: [],
        isOpenCellFullInfo: {
            isOpen: null,
            dt: []
        },
        remainsDT: [],
        remainFrom: {
            goods: null,
            cell: null,
            order_in: null,
            units: null,
            x: null,
            y: null
        },
        remainInfoDT: {},
        planesDT: [],
        planEditForm: {
            orders_goods_stock_rest_plan_id: null,
            order: null,
            goods: null,
            x: null,
            y: null,
            reason_why_not: ''
        },
        planDt: [],
        stocksDT: [],
        stocksOrdersDT: [],
        choiceMaterial: null

    }

const actionType = {
    cellsForm: 'STORAGE_CELLS/FORM_CU',
    setCells: 'STORAGE_CELLS/SET_DT',
    updateCell: 'STORAGE_CELLS/UPDATE_DT',
    initialValueForm: 'SET_DT/FORM_INITIAL_VALUE',
    resetFormValue: 'FORM/RESET_VALUE',
    setFullInfoCell: 'STORAGE_CELLS/GET_FULL_INFO',
    setCellInINFO: 'STORAGE_CELLS/SET_IN_INFO',
    remainFrom: 'REMAINS/FORM_CU',
    setRemainsDT: 'REMAINS/SET_DT',
    updateRemainDT: 'REMAINS/UPDATE_DT',
    setRemainInfoDT: 'REMAINS/SET_INFO_DT',
    setPlansDT: 'PLANE/SET_DT',
    updatePlansDT: 'PLANE/UPDATE_DT',
    removePlansDT: 'PLANE/DELETE_DT',
    planForm: 'PLANE/FORM_U',
    infoPlane: 'PLANE/SET_INFO',
    setStockDT: 'STOCK/SET_DT',
    setStockOrdersDT: 'STOCK/ORDERS_SET_DT',
    setChoiceMaterial: 'CHOICE/SET_MATERIAL'
}

export const actionsCellsLeftovers = {
    onChangeCellsForms: (payload) => {
        return {
            type: actionType.cellsForm,
            payload
        }
    },
    onSetCellsDT: (payload) => {
        return {
            type: actionType.setCells,
            payload
        }
    },
    onSetIsCellFullInfo: ({isOpen, dt}) => {
        return {
            type: actionType.setCellInINFO,
            payload: {isOpen, dt}
        }
    },
    onUpdateCell: ({id, dt}) => {
        return {
            type: actionType.updateCell,
            payload: {id, dt}
        }
    }, setCellInfo: (payload) => {
        return {
            type: actionType.setFullInfoCell,
            payload
        }
    },
    setInitialValuesForm: ({form, initialValue}) => {
        return {
            type: actionType.initialValueForm,
            payload: {form, initialValue}
        }
    },
    onResetFormValue: ({form}) => {
        return {
            type: actionType.resetFormValue,
            payload: {form}
        }
    },
    onChangeRemainForms: (payload) => {
        return {
            type: actionType.remainFrom,
            payload
        }
    },
    onSetRemainDT: (payload) => {
        return {
            type: actionType.setRemainsDT,
            payload
        }
    },
    onSetRemainInfoDT: (payload) => {
        return {
            type: actionType.setRemainInfoDT,
            payload
        }
    },
    onUpdateRemain: ({id, dt}) => {
        return {
            type: actionType.updateRemainDT,
            payload: {id, dt}
        }
    },
    setPlansDT: (payload) => {
        return {
            type: actionType.setPlansDT,
            payload
        }
    },
    updatePlansDT: (payload) => {
        return {
            type: actionType.updatePlansDT,
            payload
        }
    },
    removePlansDT: (planId) => {
        return {
            type: actionType.removePlansDT,
            payload: {planId}
        }
    },
    onChangePlanEditForms: (payload) => {
        return {
            type: actionType.planForm,
            payload
        }
    },
    onSetInfoPlan: (payload) => {
        return {
            type: actionType.infoPlane,
            payload
        }
    },
    onSetStcoksDT: (payload) => {
        return {
            type: actionType.setStockDT,
            payload
        }
    },
    onSetStcokOrdersDT: (payload) => {
        return {
            type: actionType.setStockOrdersDT,
            payload
        }
    },
    onSetChoiceMaterial: (payload) => {
        return {
            type: actionType.setChoiceMaterial,
            payload
        }
    }
}
const StorageCellsLeftovers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.setCells: {
            return {
                ...state,
                cellsDT: action.payload
            }
        }
        case actionType.setCellInINFO: {
            return {
                ...state,
                isOpenCellFullInfo: {
                    ...state.isOpenCellFullInfo,
                    ...action.payload
                }
            }
        }
        case actionType.cellsForm: {
            return {
                ...state,
                cellsForm: {
                    ...state.cellsForm,
                    [action.payload.key]: action.payload.dt
                }
            }
        }
        case actionType.updateCell: {
            const updateCells = [...state.cellsDT].map(cell => {
                if (Number(cell.stock_cell_id) == Number(action.payload.id)) {
                    cell = {
                        ...cell,
                        ...action.payload.dt
                    }
                }
                return cell
            });
            return {
                ...state,
                cellsDT: updateCells
            }
        }
        case actionType.initialValueForm: {
            return {
                ...state,
                [action.payload.form]: action.payload.initialValue
            }
        }
        case actionType.updateRemainDT: {
            const updateRemain = [...state.remainsDT].map(remain => {
                if (Number(remain.goods_stock_id) === Number(action.payload.id)) {
                    remain = {
                        ...remain,
                        ...action.payload.dt
                    }
                }
                return remain;
            })
            return {
                ...state,
                remainsDT: updateRemain
            }
        }
        case actionType.resetFormValue: {
            return {
                ...state,
                [action.payload.form]: initialState[action.payload.form]
            }
        }
        case actionType.setFullInfoCell: {
            return {
                ...state,
                cellFullInfo: action.payload
            }

        }
        case actionType.remainFrom: {
            return {
                ...state,
                remainFrom: {
                    ...state.remainFrom,
                    [action.payload.key]: action.payload.dt
                }
            }
        }
        case actionType.setRemainsDT: {
            return {
                ...state,
                remainsDT: action.payload
            }
        }
        case actionType.setRemainInfoDT: {
            return {
                ...state,
                remainInfoDT: action.payload
            }
        }
        case actionType.setPlansDT: {
            return {
                ...state,
                planesDT: action.payload
            }
        }
        case actionType.updatePlansDT: {
            const updatePlane = [...state.planesDT].map(p => {
                if (Number(p.orders_goods_stock_rest_plan_id) === Number(action.payload.planId)) {
                    p = {
                        ...p,
                        ...action.payload.dt
                    }
                }
                return p;
            });
            return {
                ...state,
                planesDT: updatePlane
            }
        }
        case actionType.removePlansDT: {
            const updatePlane = [...state.planesDT].filter(p => Number(p.orders_goods_stock_rest_plan_id) != Number(action.payload.planId));
            return {
                ...state,
                planesDT: updatePlane
            }
        }
        case actionType.planForm: {
            return {
                ...state,
                planEditForm: {
                    ...state.planEditForm,
                    [action.payload.key]: action.payload.dt
                }
            }
        }
        case actionType.infoPlane: {
            return {
                ...state,
                planDt: action.payload
            }
        }
        case actionType.setStockDT: {
            return {
                ...state,
                stocksDT: action.payload
            }
        }
        case actionType.setStockOrdersDT: {
            return {
                ...state,
                stocksOrdersDT: action.payload
            }
        }
        case actionType.setChoiceMaterial: {
            return {
                ...state,
                choiceMaterial: action.payload
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
};

export const onSetCellStorageFullInfo = (id, name) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._getCellStorageFullInfo(id);
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response && Array.isArray(response)) {

            dispatch(actionsCellsLeftovers.setCellInfo(response));
            dispatch(onToggleCellModal({key: 'info', dt: {isOpen: true, title: name}}))
        }
    } catch (e) {
        checkErrorsAPI('', 'api:/goods_stock_cells/id, M:G')
        dispatch(changeLoaderStatusAC(false));
        console.log(e);
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onGetCellsStoragesDt = () => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Получаем данные...')));
    try {
        const response = await api._getCellsStorageCU();

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.hasOwnProperty('data')) {
            dispatch(actionsCellsLeftovers.onSetCellsDT(response.data));
        }
    } catch (e) {
        dispatch(checkErrorsAPI('', 'api:/goods_stock_cells, M:G'))
        dispatch(changeLoaderStatusAC(false));
        console.log(e);
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onCellsStorageCU = (payload) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));


    try {
        const cellsDT = getState().cellsLeftore.cellsDT;
        const response = await api._setCellsStorageCU(payload);
        let {stock_cell_id} = payload;
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            if (stock_cell_id != null) {
                dispatch(actionsCellsLeftovers.onUpdateCell({id: stock_cell_id, dt: response.result}))
            } else {
                cellsDT.push(response.result);
                dispatch(actionsCellsLeftovers.onSetCellsDT([...cellsDT]))
            }
            if (response.hasOwnProperty('res') && response.res == 'ok') {
                dispatch(onToggleCellModal({key: 'isOpen', dt: false}));
            }
            dispatch(actionsCellsLeftovers.onResetFormValue({form: 'cellsForm'}))
        }

    } catch (e) {
        dispatch(checkErrorsAPI('', 'api:/goods_stock_cells, M:P'))
        dispatch(changeLoaderStatusAC(false));

    }
    dispatch(changeLoaderStatusAC(false));
}

export const onDeleteCellStorage = (id) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._removeCellsStorage(id);
        const cellsDt = getState().cellsLeftore.cellsDT;

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.hasOwnProperty('result') && response.result === 'ok') {
            const updateCells = cellsDt.filter(cell => Number(cell.stock_cell_id) != Number(id))
            dispatch(actionsCellsLeftovers.onSetCellsDT(updateCells));
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock_cells, M:D')
        dispatch(changeLoaderStatusAC(false));
        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}


export const onSendRemainCU = (data, id = null, orderIn = null) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const userId = Number(getState().settingFirm.userId);
        const response = await api._setRemainsCU({
            ...data,
            user: userId,
            goods_stock_id: id,
            order_in: orderIn
        });
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            if (response.res === 'ok') {
                if (id == null) {
                    // dispatch(materialSelectedFilterRemainStockAC({id: response.db_res.goods, dt: response.db_res}));
                    dispatch(setMaterialStockDT({id: response.db_res.goods, dt: response.db_res}));
                } else {
                    dispatch(actionsCellsLeftovers.onUpdateRemain({
                        id: data.goods_stock_id,
                        dt: {...response.response, cell: {stock_cell_id: data.cell}}
                    }));
                }
            }

            dispatch(onToggleRemainModal({key: 'formAdd', dt: {isOpen: false, goods: null, order_in: null}}));
            dispatch(actionsCellsLeftovers.onResetFormValue({form: 'remainFrom'}))
        }
    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock/, M:P ')
        dispatch(changeLoaderStatusAC(false));
        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}


export const setRemainsDT = (call = null) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));

    try {
        const response = await api._getRemains();
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        }
        if (response.res === 'ok') {
            dispatch(actionsCellsLeftovers.onSetRemainDT(response?.stock ?? []))
        }
        if (call) call();
    } catch (e) {
        checkErrorsAPI('', 'api:/goods_stock/, M:G ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onRemoveRemain = ({goods_id, goods_stock_id}) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));

    try {
        const response = await api._removeRemains(goods_stock_id);
        const goodDT = getState().materials.materials.data.filter(m => Number(m.goods_id) === Number(goods_id))
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        }
        if (response.result === 'good') {
            dispatch(removeMaterialStockDT({goods_id, goods_stock_id}));
            let goods = goodDT && Array.isArray(goodDT) && goodDT.length >= 1 ? goodDT[0] : {};
            dispatch(onToggleRemainModal({key: 'listView', dt: {isOpen: true, goods}}));
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock/, M:D ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}
export const onRemoveRemainCabinet = (id) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));

    try {
        const response = await api._removeRemains(id);
        const remainsDR = getState().cellsLeftore.remainsDT;
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        }
        if (response.result === 'good') {

            const updateRemains = remainsDR.filter(remain => Number(remain.goods_stock_id) != Number(id))
            dispatch(actionsCellsLeftovers.onSetRemainDT(updateRemains))
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock/, M:D ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onSetRemainInfo = (id) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._getRemainsStorageFullInfo(id);

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {

            dispatch(actionsCellsLeftovers.onSetRemainInfoDT(Array.isArray(response) ? response : [response]))
        }
    } catch (e) {
        checkErrorsAPI('', 'api:/goods_stock/, M:G ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}


export const onSetPlansDT = () => async dispatch => {
    dispatch(changeLoaderStatusAC(true));


    try {
        const response = await api._getPlans();
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            dispatch(actionsCellsLeftovers.setPlansDT(response.data))
        }

    } catch (e) {
        checkErrorsAPI('', 'api:/goods_stock_rest_plan/, M:G ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}
export const onUpdatePlanDT = (dt) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._updatePlan(dt);


        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.res == 'ok') {
            dispatch(actionsCellsLeftovers.updatePlansDT({
                planId: dt.orders_goods_stock_rest_plan_id,
                dt: response.db_res
            }))
            dispatch(actionsCellsLeftovers.onResetFormValue({
                form: 'planEditForm', initialValue: {
                    orders_goods_stock_rest_plan_id: null,
                    order: null,
                    goods: null,
                    x: null,
                    y: null,
                    reason_why_not: ''
                }
            }))
            dispatch(onTogglePlanModal({
                key: 'edit', dt: {isOpen: false}
            }))
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock_rest_plan/, M:P ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}
export const onDeletePlanDT = (id) => async dispatch => {
    dispatch(changeLoaderStatusAC(true))

    try {
        const response = await api._removePlan(id);
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.res.hasOwnProperty('del_goods_stock_plan') && response.res.del_goods_stock_plan && (response.result === 'good')) {
            dispatch(actionsCellsLeftovers.removePlansDT(id))
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock_rest_plan/, M:D ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}
export const onSetInfoPlanDT = (id) => async dispatch => {
    dispatch(changeLoaderStatusAC(true))

    try {
        const response = await api._getCuurentPlan(id);
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            dispatch(actionsCellsLeftovers.onSetInfoPlan(response.data))
            dispatch(onTogglePlanModal({key: 'info', dt: {isOpen: true}}))
        }

    } catch (e) {
        checkErrorsAPI('', 'api:/goods_stock_rest_plan/id, M:G ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onDeletePlanStockMaterialDT = (goods_id, id) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true))

    try {
        const response = await api._removePlan(id);
        const planeStock = getState().materials.materials.data.filter(m => Number(m.goods_id) === Number(goods_id));
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            dispatch(removeMaterialPlaneStockDT({goods_id, id}));
            dispatch(onTogglePlanModal({
                key: 'materialList',
                dt: {
                    isOpen: true,
                    planeStock: planeStock.length >= 1 ? planeStock[0].goods_stock_plans : [],
                    goods_id: goods_id
                }
            }))

        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock_rest_plan/, M:D ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onUpdateMaterialPlanStockDT = (dt) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    try {

        const response = await api._updatePlan(dt);

        const plane = getState().materials.materials.data.filter(m => Number(m.goods_id) === Number(dt.goods));

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.res == 'ok') {
            dispatch(setMaterialPlaneStockDT({
                goods_id: dt.goods,
                id: dt.orders_goods_stock_rest_plan_id,
                dt: response.db_res
            }))
            dispatch(actionsCellsLeftovers.onResetFormValue({
                form: 'planEditForm', initialValue: {
                    orders_goods_stock_rest_plan_id: null,
                    order: null,
                    goods: null,
                    x: null,
                    y: null,
                    reason_why_not: ''
                }
            }))
            dispatch(onTogglePlanModal({
                key: 'edit', dt: {isOpen: false}
            }))
            dispatch(onTogglePlanModal({
                key: 'materialList',
                dt: {isOpen: true, goods_id: dt.goods, planeStock: plane[0].goods_stock_plans ?? []}
            }))
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock_rest_plan/, M:P ')

        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}


export const onSetStocksDT = ({client, firm}) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api.getStockMaterialClientData({
            client,
            firm,
            get_stock: 1
        });

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/clients/,  M:P ')

        dispatch(changeLoaderStatusAC(false));

        console.error(e)
    }
    dispatch(changeLoaderStatusAC(false));
}
export const onSetStockOrdersDT = () => async dispatch => {
    dispatch(changeLoaderStatusAC(true));

    try {
        const response = await api._getStockOrders();

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.hasOwnProperty('orders')) {
            dispatch(actionsCellsLeftovers.onSetStcokOrdersDT(response.orders.data ?? []))
        }

    } catch (e) {
        checkErrorsAPI('', 'api:/goods_stock/orders/, M:G ')

        dispatch(changeLoaderStatusAC(false));

        console.error(e)
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onCreatePlaneStock = (dt) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._updatePlan({...dt});
        const stocksOrdersDT = getState().cellsLeftore.stocksOrdersDT;
        const lang = getState().translation.localLang;
        let material = [];
        const materialChoice = stocksOrdersDT.filter(order => {
            if (Number(order.id) === Number(dt.order) && order.hasOwnProperty('materials')) {
                const find = order.materials.data.filter(m => Number(m.goods) == Number(dt.goods))
                material = material.concat(find);
                material.flat();
                return order.materials.data
            }
        });

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.res == 'ok') {
            const updateOrders = stocksOrdersDT.map(order => {
                if (Number(order.id) === Number(dt.order)) {
                    let stock = {
                        area_plan: response.model.area_plan,
                        goods: response.model.goods,
                        order: response.model.order,
                        orders_goods_stock_rest_plan_id: response.model.orders_goods_stock_rest_plan_id,
                        reason_why_not: response.model.reason_why_not,
                        x: response.model.x,
                        y: response.model.y,
                        name: Array.isArray(material) && material.length >= 1 ? material[0].goods_name[lang] : ''
                    }
                    order.goods_stock_plan.push(stock)
                }
                return order;
            });
            dispatch(actionsCellsLeftovers.onSetStcokOrdersDT(updateOrders));
            dispatch(actionsCellsLeftovers.onResetFormValue({
                form: 'planEditForm', initialValue: {
                    orders_goods_stock_rest_plan_id: null,
                    order: null,
                    goods: null,
                    x: null,
                    y: null,
                    reason_why_not: ''
                }
            }))
            dispatch(onTogglePlanModal({
                key: 'create', dt: {isOpen: false, materials: []}
            }));
        }
    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock_rest_plan/, M:P ')

        dispatch(changeLoaderStatusAC(false));

        console.error(e)
    }
    dispatch(changeLoaderStatusAC(false));
}
export const onDeleteCabinetPlanStockDT = (id, planId) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true))
    try {
        const response = await api._removePlan(planId);
        const stocksOrdersDT = getState().cellsLeftore.stocksOrdersDT.map(order => {
            if (Number(order.id) === Number(id)) {
                const updatePlane = order.goods_stock_plan.filter(s => Number(s.orders_goods_stock_rest_plan_id) !== Number(planId));
                order.goods_stock_plan = updatePlane;
            }
            return order;
        });

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {

            dispatch(actionsCellsLeftovers.onSetStcokOrdersDT(stocksOrdersDT))
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock_rest_plan/id, M:D ')
        dispatch(changeLoaderStatusAC(false));
        console.log(e)
    }

}
export const onDeleteCabinetRemainStockDT = (id, remainId) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true))

    try {
        const response = await api._removeRemains(remainId);
        const stocksOrdersDT = getState().cellsLeftore.stocksOrdersDT.map(order => {
            if (Number(order.id) === Number(id)) {
                const updateRemain = order.goods_stock.filter(s => Number(s.orders_goods_stock_rest_plan_id) !== Number(remainId));
                order.goods_stock = updateRemain;
            }
            return order;
        });
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {

            dispatch(actionsCellsLeftovers.onSetStcokOrdersDT(stocksOrdersDT))
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock/id, M:D ')
        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}
export const onSendRemainCreateNotOrder = (data, id = null, orderIn = null, call, name = '') => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));

    try {
        const userId = Number(getState().settingFirm.userId);
        const client = Number(getState().auth.user.auth_client);
        const cell = getState().cellsLeftore.cellsDT.filter(c => Number(c.stock_cell_id) === Number(data.cell))

        const remainDT = getState().cellsLeftore.remainsDT;
        const response = await api._setRemainsCU({
            ...data,
            user: userId,
            client: client,
            goods_stock_id: id,
            order_in: orderIn
        });
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            if (response.res === 'ok') {
                if (call) call();
                let stock = {
                    count: response.response.count,
                    units: response.response.units,
                    name: name,
                    goods_stock_id: response.response.goods_stock_id,
                    order: response.response.order,
                    order_in: response.response.order_in,
                    order_info: response.response.order_info,
                    x: response.response.x,
                    y: response.response.y,
                    code: response.response.code,
                    date_in: response.response.date_in,
                    goods: data.goods,
                    cell
                }
                remainDT.push(stock)
                dispatch(actionsCellsLeftovers.onSetRemainDT(remainDT))
                dispatch(actionsCellsLeftovers.onResetFormValue({form: 'remainFrom'}))
            }
        }

    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock/, M:P ')
        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onSendPlanCreateNotOrderStock = (dt, call) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._updatePlan(dt);

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.res == 'ok') {

            if (call) call();
            dispatch(actionsCellsLeftovers.onResetFormValue({form: 'create'}))
        }
    } catch (e) {
        checkErrorsAPI(typeErrorApi.save, 'api:/goods_stock_rest_plan/, M:P ')

        dispatch(changeLoaderStatusAC(false));
        console.error(e)
    }
    dispatch(changeLoaderStatusAC(false));
}


export const onSendRemainCreate = (data, id = null, orderIn = null) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const userId = Number(getState().settingFirm.userId);
        const stocksOrdersDT = getState().cellsLeftore.stocksOrdersDT;
        const lang = getState().translation.localLang;
        let material = [];
        const materialChoice = stocksOrdersDT.filter(order => {
            if (Number(order.id) === Number(orderIn) && order.hasOwnProperty('materials')) {
                const find = order.materials.data.filter(m => Number(m.goods) == Number(data.goods))
                material = material.concat(find);
                material.flat();
                return order.materials.data
            }
        });
        const response = await api._setRemainsCU({...data, user: userId, goods_stock_id: id, order_in: orderIn});

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else {
            if (response.res === 'ok' && response.hasOwnProperty('response')) {
                const updateOrders = stocksOrdersDT.map(order => {
                    if (Number(order.id) === Number(orderIn)) {
                        let stock = {
                            x: response.response.x,
                            y: response.response.y,
                            count: response.response.count,
                            goods_stock_id: response.response.goods_stock_id,
                            name: Array.isArray(material) && material.length >= 1 ? material[0].goods_name[lang] : ''
                        }
                        order.goods_stock.push(stock)
                    }
                    return order;
                })
                dispatch(actionsCellsLeftovers.onSetStcokOrdersDT(updateOrders));
                dispatch(onToggleRemainModal({key: 'create', dt: {isOpen: false, goods: null, order_in: null}}));
                dispatch(actionsCellsLeftovers.onResetFormValue({form: 'remainFrom'}))
            }


        }
    } catch (e) {
        dispatch(checkErrorsAPI('', 'api:/goods_stock/, M:P '))
        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}

export default StorageCellsLeftovers;