import React from 'react';
import RemainsAddForm from "./RemainsAddForm";
import RemainsList from "./RemainsList";
import {useLang} from "../../../context/langProvider";

const RemainsModal = () => {
    const {getT} = useLang()
    return <>
        <RemainsAddForm getT={getT}/>
        <RemainsList getT={getT}/>
    </>
}

export default RemainsModal;