import React from 'react';
import Card from "../../components/Card";
import IconLogin from "../../Icons/TableActions/IconLogin";
import {useDispatch} from "react-redux";
import {firmsThunkSwitchFirms} from "../../store/reducers/AuthReducer";
import {clearAllStorage} from "../../helpers/helpers";

const CabinetSwitchFirms = ({firms, firmId, getT}) => {
    const dispatch = useDispatch();
    const handlerSwitchFirm = async (firm_id, url) => {
        await dispatch(firmsThunkSwitchFirms(firm_id));
        clearAllStorage();
        window.location = url;
    }
    return (
        <div className={"settings-section w-100"}>
            <div className="client-firms-container">
                <Card label={getT("Подключенные фирмы")}>
                    <ul className={'list-none'}>
                        {firms?.map(item => {
                            return <li
                                className={`${Number(firmId) === Number(item.firm) ? "current clr-blue" : ""} d-flex items-center`}>
                                {item?.firm_name}
                                {(Number(firmId) !== Number(item.firm)) &&
                                    <IconLogin onClick={() => handlerSwitchFirm(item?.firm, item.url?.newgo)}
                                               width={'25px'}
                                               height={'25px'}/>
                                }
                            </li>
                        })}
                    </ul>
                </Card>
            </div>
        </div>
    );
};

export default CabinetSwitchFirms;