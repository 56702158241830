import React from 'react';
import {connect} from "react-redux";
import GiblabSendForm from "./GiblabSendForm";
import ParametersContext from '../../context/parametrs.js';

class GiblabSendFormContainer extends React.Component {

    static contextType = ParametersContext;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        return <GiblabSendForm action_link={''}
                               code={this.context.code}
                               version={this.context.version}

        />
    }
}

let mapStateToProps = (state) => {
    return {
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiblabSendFormContainer);
