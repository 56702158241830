import React from 'react';
import MasterPassword from "./MasterPassword";
import {useLang} from "../../../../context/langProvider";
import InputForm from "../../../../components/InputFields/InputForm/InputForm";
import SubmitPrivacy from "./sign_in/SubmitPrivacy";

/**
 * AuthAccountPassword. радио тип пароля
 *
 * @param {boolean} isShortPassword - радио тип  отображения поля короткий/мастре (пароль)
 * @param {object} values -
 * @param {function} handleChange -
 * @param {function} setFieldValue -
 * @returns  {}  -
 */
const AuthAccountPassword = ({resendChildrenEmail = null, values, handleChange, setFieldValue, submitForm, isVisibleButtonSubmit=true}) => {
    const {getT} = useLang()
    return (
        <>
            <div className="choice-type-login d-flex flex-row">
                <div className="item d-flex align-items-center">
                    <input type="radio" value="1" id={'password-short'}
                           checked={values.isRadioShortPassword} className={''}
                           onChange={() => setFieldValue('isRadioShortPassword', true)}/>
                    <label htmlFor="password-short">
                        {getT('Пароль')}
                    </label>
                </div>
                <div className="item d-flex align-items-center">
                    <input type="radio" value="0" id={'master-password'}
                           checked={!values.isRadioShortPassword} className={''}
                           onChange={() => setFieldValue('isRadioShortPassword', false)}/>
                    <label htmlFor="master-password">
                        {getT('Мастер пароль')}
                    </label>
                </div>
            </div>

            {values.isRadioShortPassword ?
                <div className={'mb-2'}>
                    <InputForm cName={'filed-border'} id="password"
                               autoComplete={'new-password'}
                               name="password"
                               type="text" value={values.password} onChange={handleChange}/>
                    {resendChildrenEmail && resendChildrenEmail}
                </div>
                : <div className={'mb-2'}>
                    <MasterPassword isDisplayLabel={false} handleChange={handleChange} values={values}/>
                </div>}
            {isVisibleButtonSubmit && <SubmitPrivacy submitForm={submitForm}/>}

        </>
    );
};

export default AuthAccountPassword;