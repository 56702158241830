import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import store from './store/store';
import AppContainer from "./App";
import ReactGA from "react-ga4";

ReactGA.initialize('G-XZNQM0260N', {
    // gaOptions: {
    //     debug_mode: true,
    // },
    // gtagOptions: {
    //     debug_mode: true,
    // },
})
ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <AppContainer/>
            </Provider>
        </Router>
    </React.StrictMode>
    ,
    document.getElementById('root')
);



