import React from 'react';
import InputForm from "../../../../../../../components/InputFields/InputForm/InputForm";
import {useLang} from "../../../../../../../context/langProvider";
import {useDispatch} from "react-redux";
import {useFormikContextProviderRegister} from "../../../../../SignUp";
import FiledPhoneMask from "../../../../../../../components/InputFields/FiledPhoneMask";
import {changeAlertStatusAC} from "../../../../../../../store/reducers/CommonReducer";
import {authThunkAccountAccess} from "../../../../../../../store/reducers/AuthReducer";
import {
    parsePhoneNumber,
    validateEmail,
    validatePhoneNumber
} from "../../../../../../../helpers/helpersListCountryCode";

const StepLookup = ({onSwitchTab, handleNext, setting_phone_code_country = null}) => {
    const {getT} = useLang()
    const dispatch = useDispatch();
    const {values, handleChange, setFieldValue, validateForm} = useFormikContextProviderRegister();
    const handleValidate = async () => {
        // validateForm().then(async (errors) => {
        let error = {};
        if (values.email && validateEmail(values.email)) {
            error['email'] = getT("Email-адрес некорректен.");
        }

        const {countryCode, phoneNumberWithoutCode, mask} = parsePhoneNumber(values['phone']);
        const isValidPhone = validatePhoneNumber(countryCode, phoneNumberWithoutCode);
        if (values.phone && !isValidPhone) {
            error['phone'] = getT("Некоректний номер телефону")

        }
        if (values.email && !values.phone) {
            error['phone'] = getT("Поле для ввода телефона обязательно для заполнения.")
        }
        if (!values.phone && !values.email) {
            error['email'] = getT("Email-адрес некорректен.");
            error['phone'] = getT("Некоректний номер телефону")
        }


        if (!Object.keys(error).includes('email') && !Object.keys(error).includes('phone')) {
            await onReqAccountAccess({send_password: 0})
            handleNext();

        } else if (Object.keys(error).includes('email') || Object.keys(error).includes('phone')) {
            let error_msg = `${error['phone'] || ''} ${error['email'] || ''}`
            dispatch(changeAlertStatusAC(true, error_msg));
        }
        console.log('error', error);
        // });
    };
    const onSwitchTabLogin = (login) => {
        onSwitchTab(true, login);
    }
    const onReqAccountAccess = async ({send_password = 0}) => {
        if (values.email) {
            await dispatch(authThunkAccountAccess(
                {login: values.email, send_password: send_password}, null, () => onSwitchTabLogin(values.email)));
            setFieldValue('password', '');
            setFieldValue('master_password', '');
            return;
        }
        if (values.phone) {
            await dispatch(authThunkAccountAccess(
                {login: values.phone, send_password: send_password}, null, () => onSwitchTabLogin(values.phone)));
            setFieldValue('password', '');
            setFieldValue('master_password', '');
        }
    }
    const onChangeTelHandler = (result) => {
        handleChange({
            target: {name: 'phone', value: result},
        });
    }
    return (
        <div>
            <div className="mb-2">

                <InputForm label={getT('Введите email')}
                           id="email"
                           name="email"
                           type="email" value={values.email} onChange={handleChange}/>
            </div>
            <div className="mb-2">
                <FiledPhoneMask onChange={onChangeTelHandler}
                                label={<>{getT('Введите номер телефона')} <sub style={{color:'red', cursor:"pointer", verticalAlign: "super"}} title={getT("Обязательно для заполнения.")}>*</sub></>}
                                value={values.phone} setting_code_country={setting_phone_code_country}/>

            </div>

            <button className={"btn m-0"} type={'button'} style={{margin: '0 auto'}}
                    // disabled={!values.email && !values.phone}
                    onClick={handleValidate}>{getT("Отправить")}</button>


        </div>
    );
};

export default StepLookup;