import React from 'react';
import ItemSide from "./ItemSide";
import {useDispatch} from "react-redux";
import {togglePlaneOperationsThunk} from "../../../store/PartThunks";

const operationType = {
    "bore": "Сверления",
    "paz": "Пазование",
    // "frez": "Фрезеровка",
    "trough": "Выемка",
    // "cut_to": "Урезка"
}


const OperationsPartTable = ({parts, getT, current_operation}) => {
        const dispatch = useDispatch();
        const handlerToggleOperation = ({part_id, plane_id, side}) => (e) => {
            dispatch(togglePlaneOperationsThunk({part_id, side, plane_id}))
        }
        //только одна обработка на сторону
        // let current_exclusive_on_same_side = !!Number(current_operation?.exclusive_on_same_side);
//только по одной сторонне обработка
//         let current_exclusive_on_detail = !!Number(current_operation?.exclusive_on_detail)
//единственная обработка в детали
//         let current_one_only_on_detail = !!Number(current_operation?.one_only_on_detail)
        const getCncOperationsSideByPart = (part) => {
            return Object.keys(operationType)?.reduce((acc, key_type) => {
                let operation_face = part?.operations?.[key_type]?.filter(e => ['f'].includes(e?.side));
                let operation_back = part?.operations?.[key_type]?.filter(e => ['bb'].includes(e?.side));
                if (operation_face?.length) {
                    let uniq = Array.isArray(acc?.f) ? [...acc?.f, operationType[key_type]] : [operationType[key_type]]
                    acc = {
                        ...acc, f: [...new Set([...uniq])]
                    }
                }
                if (operation_back?.length) {
                    let uniq = Array.isArray(acc?.bb) ? [...acc?.bb, operationType[key_type]] : [operationType[key_type]]
                    acc = {
                        ...acc, bb: [...new Set([...uniq])]
                    }
                }
                return acc
            }, {'f': null, 'bb': null})
        }
        const settingByPartForSide = (part) => {

            let operation_side_f_entry = part?.plane_operations?.filter(e => e?.side === 'f') ?? []
            let operation_side_bb_entry = part?.plane_operations?.filter(e => e?.side === 'bb') ?? []
            let one_only_on_detail_arr = [...operation_side_f_entry, ...operation_side_bb_entry]

            let side_f = {
                exclusive_operation: operation_side_f_entry?.some(e => !!Number(e?.exclusive_on_same_side) && Number(e?.id) !== Number(current_operation?.id)),
                exclusive_on_detail: operation_side_bb_entry?.some(e => !!Number(e?.exclusive_on_detail)),
                one_only_on_detail: one_only_on_detail_arr?.some(e => !!Number(e?.one_only_on_detail) && Number(e?.id) !== Number(current_operation?.id)),

            }

            let side_bb = {
                exclusive_operation: operation_side_bb_entry?.some(e => !!Number(e?.exclusive_on_same_side) && Number(e?.id) !== Number(current_operation?.id)),
                exclusive_on_detail: operation_side_f_entry?.some(e => !!Number(e?.exclusive_on_detail)),
                one_only_on_detail: one_only_on_detail_arr?.some(e => !!Number(e?.one_only_on_detail) && Number(e?.id) !== Number(current_operation?.id)),

            }
            let delete_by_prev_operation = {
                f: {
                    is_delete: !!part?.plane_operations?.find(e => e?.side === 'f' && Number(current_operation.id) !== Number(e.id) && (side_f?.exclusive_operation || side_f?.one_only_on_detail)),
                    operation: part?.plane_operations?.find(e => e?.side === 'f' && Number(current_operation.id) !== Number(e.id))
                },
                bb: {
                    is_delete: !!part?.plane_operations?.find(e => e?.side === 'bb' && Number(current_operation.id) !== Number(e.id) && (side_bb?.exclusive_operation || side_bb?.one_only_on_detail)),
                    operation: part?.plane_operations?.find(e => e?.side === 'bb' && Number(current_operation.id) !== Number(e.id))
                },
            }
            let checked = {
                f: !!part?.plane_operations?.find(e => e?.side === 'f' && Number(current_operation.id) === Number(e.id)),
                bb: !!part?.plane_operations?.find(e => e?.side === 'bb' && Number(current_operation.id) === Number(e.id)),
            }
            return {
                settings_production: {
                    f: {...side_f},
                    bb: {...side_bb},
                },
                checked: checked,
                delete_operation: delete_by_prev_operation
            }
        }
        const is_has_product = parts?.some(p => !!p?.product_name)
        return (
            <div className="overflow-scrolled" style={{minHeight: '500px'}}>
                <table>
                    <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">{getT("Название")}</th>
                        <th scope="col">{getT("Размеры")}</th>
                        <th scope="col">{getT("Количество")}</th>
                        <th scope="col">{getT("Лицевая")}</th>
                        <th scope="col">{getT("Обратная")}</th>
                        {is_has_product && <th scope="col">{getT("Группа")}</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {parts.map(e => {
                        let cnc_operations_side = getCncOperationsSideByPart(e);
                        let side_settings = settingByPartForSide(e);
                        return <tr>
                            <td>{e.id}</td>
                            <td>{e.name}</td>
                            <td>{e.x ?? e.x1 ?? e.x2} x {e.y ?? e.y1 ?? e.y2} x {e.z}</td>
                            <td>{e.count}</td>
                            {['f', 'bb']?.map(key => {
                                return <ItemSide
                                    side={key}
                                    delete_operation={side_settings?.delete_operation?.[key]}
                                    settings_production={side_settings?.settings_production?.[key]}
                                    disabled={Object.values(side_settings?.settings_production?.[key] ?? {})?.some(e => !!e)}
                                    checked={side_settings?.checked?.[key]}
                                    cnc_operations={cnc_operations_side?.[key]}
                                    handlerToggleOperation={handlerToggleOperation({
                                        part_id: e?.id,
                                        side: key,
                                        plane_id: current_operation?.id
                                    })}
                                    handlerDelete={
                                        handlerToggleOperation({
                                            part_id: e?.id,
                                            side: key,
                                            plane_id: side_settings?.delete_operation?.[key]?.operation?.id
                                        })
                                    }
                                    part={e}
                                    getT={getT}
                                />
                            })
                            }
                            {is_has_product && <td>{e?.product_name ?? "----"}</td>}
                        </tr>;
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
;

export default OperationsPartTable;
