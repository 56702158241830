import React, {useEffect, useState} from 'react';
import {resetBatchMachiningFormAC} from "../../../store/reducers/OrderReducer";
import {truncateToOneDecimalPlace} from "../../../helpers/helpers";
import {useDispatch} from "react-redux";

const GroupOperations = ({
                             form, lang,
                             getT, materials = [],
                             part = [],
                             onChangeValue,
                             addOperations,
                             order,
                             is_processing_current_material
                         }) => {
    const dispatch = useDispatch();
    const optionsPlane = [{name: "Все", value: "all"},
        {name: getT("торцы"), value: "ends"},
        {name: getT("лицевая обратная"), value: "front_reverse"}, {name: "лево", value: "left"},
        {name: getT("право"), value: "right"}, {name: "верх", value: "top"},
        {name: getT("низ"), value: "bottom"}, {name: "лицевая", value: "facial"},
        {name: getT("обратная"), value: "reverse"}
    ];
    const [optionsReplacement, setOptionsReplacement] = useState([
        {name: getT("Диаметр"), value: "diameter"},
        {name: getT("Глубина"), value: "depth"},
        {name: getT("Плоскость"), value: "plane"},
    ]);
    let defaultSteps = {
        second: false,
        third: false,
        fourth: false
    }
    const [partSelectOptions, setPartSelectOptions] = useState(part);
    const [steps, setStep] = useState(defaultSteps);
    const [isReplacement, setIsReplacement] = useState({
        diameter: true,
        depth: false,
        plane: false
    });
    const onUnlockedReplacement = (prop) => {
        setIsReplacement((prev) => {
            let clonePrev = {}
            for (let property in isReplacement) {
                if (property == prop) {
                    clonePrev[property] = true;
                } else {
                    clonePrev[property] = false;
                }
            }
            return {
                ...clonePrev
            }
        })
    }

    const onUnlockStep = (step) => {
        setStep((prev) => {
            return {...prev, [step]: true}
        })
    };

    useEffect(() => {
        onUnlockedReplacement(form.replacement);
        materialOptionsFilter();
        if (is_processing_current_material && materials?.length === 1) {
            let value = [{goods_id: materials[0]?.goods}]
            onChangeValue('material', value);
            setPartChoiseMaterial(value);
            if (materialOptionsFilter()?.length >= 1) onUnlockStep('second');
        }

        return () => setStep(defaultSteps);


    }, [is_processing_current_material, materials]);

    const getNameMaterial = (id) => {
        let mt = materials.filter(m => Number(m.goods_id) == Number(id));
        return mt[0].hasOwnProperty('translate') && mt[0]['translate'][lang] ? mt[0]['translate'][lang] : mt[0].name
    }
    const filterPartOperations = (p) => {
        return (p.operations["bore"].length >= 1 || p.operations["paz"].length >= 1 || p.operations["frez"].length >= 1 || p.operations["trough"].length >= 1)
    }
    const materialOptionsFilter = () => {
        let partOperation = part.filter(p => filterPartOperations(p));
        let removeDuplicates = Object.values(partOperation.reduce((acc, cur) => Object.assign(acc, {[cur.material]: cur}), {}))

        let materialOption = removeDuplicates.map(p => {
            return [...materials].filter(m => {
                if (Number(m.goods_id) == Number(p.material)) {
                    return m;
                }
            })
        }).flat();
        return materialOption;

    };

    const setPartChoiseMaterial = (dt_id) => {
        let partOptions = dt_id.map(({goods_id}) => {
            return part.filter(p => Number(goods_id) === Number(p.material) && filterPartOperations(p))
        }).flat();
        // let removeDuplicates = Object.values(partOptions.reduce((acc,cur)=>Object.assign(acc,{[cur.material]:cur}),{}))
        setPartSelectOptions(partOptions);
    }
    return (
        <form className={"batch-machining-modal"}
              onSubmit={(event) => {
                  event.preventDefault();
                  addOperations({form, order}, () => {
                      dispatch(resetBatchMachiningFormAC());
                      setStep(defaultSteps);
                  });
              }}>
            {/*<div className="form-field ">*/}
            {/*    <label className="label" htmlFor="material">*/}
            {/*        {getT('Операция')}:*/}
            {/*    </label>*/}
            {/*    <select className="form-input" name={"operations"}*/}

            {/*            onChange={(event) => {*/}

            {/*                // let target = event.target;*/}
            {/*                // let valueArray = Array.from(target.selectedOptions, option => option.value);*/}
            {/*                // let value = valueArray.map(v => {*/}
            {/*                //     return {goods_id: v}*/}
            {/*                // });*/}
            {/*                // onChangeValue('material', value);*/}
            {/*                // if (event.target.value != '') {*/}
            {/*                //     onUnlockStep('second');*/}
            {/*                // }*/}
            {/*            }}*/}
            {/*            onBlur={(event) => {*/}

            {/*            }}*/}
            {/*    >*/}

            {/*        <option key={1} value={"bore"} selected={"selected"}>{getT("отверстия")}</option>*/}

            {/*    </select>*/}
            {/*</div>*/}
            <div className="form-field ">
                <label className="label" htmlFor="material">
                    {getT('Выбор материалов')}:
                </label>
                {materialOptionsFilter().length >= 1 ? (
                    <select className="form-input" name={"material"}
                            multiple={"multiple"}
                            required={'required'}
                            onChange={(event) => {
                                // let value = event.target.value;
                                let target = event.target;
                                let value;
                                if (target.value == 'all') {
                                    value = materialOptionsFilter().map(p => ({goods_id: p.goods_id}));
                                } else {

                                    let valueArray = Array.from(target.selectedOptions, option => option.value);
                                    value = valueArray.map(v => {
                                        return {goods_id: v}
                                    });
                                }
                                onChangeValue('material', value);
                                setPartChoiseMaterial(value);
                                if (event.target.value != '') {
                                    onUnlockStep('second');
                                }
                            }}
                            onBlur={(event) => {

                            }}
                    >
                        {!is_processing_current_material &&
                            <option key={0} value={"all"}>{getT("Все")}</option>}
                        {/*<option key={0} value={"all"}>{getT("Все")}</option>*/}
                        {materialOptionsFilter().map((el) => (
                            <option key={el.goods_id} value={el.goods_id}
                                    selected={Number(is_processing_current_material) === Number(el.goods_id)}>
                                {el.hasOwnProperty('translate') && el['translate'][lang] ? el['translate'][lang] : el.name}
                            </option>
                        ))}
                    </select>
                ) : getT("Отсутствует детали с операциями ЧПУ")}
                {/*) : getT("Нет материалов которые есть в деталях с операциями ЧПУ")}*/}

            </div>
            {steps.second && <div className="form-field ">
                <label className="label" htmlFor="details">
                    {getT('Выбор деталей ')}:
                </label>
                <select className="form-input" name={"parts"} multiple={"multiple"}
                        required={'required'}
                        onChange={(event) => {
                            let target = event.target;
                            let value;
                            if (target.value == 'all') {
                                value = partSelectOptions.map(p => ({id: p.id}));
                            } else {
                                let valueArray = Array.from(target.selectedOptions, option => option.value);
                                value = valueArray.map(v => {
                                    return {id: v}
                                });
                            }
                            onChangeValue('parts', value);
                            if (event.target.value != '') {
                                onUnlockStep('third');
                            }
                        }}
                >
                    <option key={0} value={"all"}>{getT("Все")}</option>
                    {/*{el.name}*/}
                    {partSelectOptions.map((el) => (
                        <option key={el.id}
                                value={el.id}>id:{el.id} -
                            ({el.x1}*{el.y1}*{el.z}) {getNameMaterial(el.material)} </option>
                    ))}
                </select>
            </div>
            }
            {steps.third && <div className="form-field ">
                <label className="label" htmlFor="plane">
                    {getT('Выбор плоскости')}:
                </label>
                <select className="form-input" name={"side"}
                        required={'required'}
                        onChange={(event) => {
                            // let target = event.target;
                            // let value = Array.from(target.selectedOptions, option => option.value);
                            onChangeValue('side', event.target.value);
                            if (event.target.value != '') {
                                onUnlockStep('fourth');
                            }
                        }}
                >
                    <option value={""} disabled={"disabled"} selected={"selected"}>---{getT("Выбрать")}---
                    </option>
                    {optionsPlane.map((el, index) => (
                        <option key={index} value={el.value}>{getT(el.name)}</option>
                    ))}
                </select>
            </div>
            }
            {steps.fourth && <>
                <div className="form-field ">
                    <label className="label" htmlFor="replacement">
                        {getT('Выбор замена')}:
                    </label>
                    <select className="form-input" name={"replacement"}
                            value={form.replacement}
                            onChange={(event) => {
                                let value = event.target.value;
                                onChangeValue('replacement', value);
                                onUnlockedReplacement(event.target.value);
                            }}
                    >
                        <option value={""} disabled={true}>---{getT("Выбрать")}---</option>
                        {optionsReplacement.map((el, index) => (
                            <option key={index} value={el.value}>{el.name}</option>
                        ))}
                    </select>
                </div>
                <ChoiceReplacementComponent onChangeValue={onChangeValue} isReplacement={isReplacement}
                                            form={form} getT={getT}/>

            </>}

            <button type={"submit"} className={"btn"}>{getT('Отправить')}</button>
        </form>
    );
};
const ChoiceReplacementComponent = ({isReplacement, onChangeValue, form, getT}) => {
    const optionsPlane = [
        {name: getT("лево"), value: "left"},
        {name: getT("право"), value: "right"},
        {name: getT("низ"), value: "bottom"},
        {name: getT("лицевая"), value: "facial"},
        {name: getT("обратная"), value: "reverse"}
    ];

    return (
        <div>
            {isReplacement.diameter && <div className="field-group">
                <div className="form-field">
                    <label className="label" htmlFor="">
                        {getT('Найти')}:
                    </label>
                    <input
                        type="number"
                        className="form-input"
                        step={0.1}
                        placeholder=""
                        value={form.diameter_from}
                        onChange={(event) => {
                            let result = '';
                            let value = event.target.value
                            if(!!value){
                                result = truncateToOneDecimalPlace(+event.target.value)
                            }
                            onChangeValue('diameter_from', result)
                        }}

                        required={true}
                    />
                </div>
                <div className="form-field">
                    <label className="label" htmlFor="">
                        {getT('Заменить')}:
                    </label>
                    <input
                        type="number"
                        className="form-input"
                        step={0.1}
                        placeholder=""
                        value={form.diameter_to}
                        onChange={(event) => {
                            let result = '';
                            let value = event.target.value
                            if(!!value){
                                result = truncateToOneDecimalPlace(+event.target.value)
                            }
                            onChangeValue('diameter_to', result)
                        }}

                        required={'required'}
                    />
                </div>
            </div>}
            {isReplacement.depth && <div className="field-group">
                <div className="form-field">
                    <label className="label" htmlFor="">
                        {getT('Найти')}:
                    </label>
                    <input
                        type="number"
                        className="form-input"
                        placeholder=""
                        step={'0.1'}
                        value={form.depth_from}
                        onChange={(event) => {
                            let result = '';
                            let value = event.target.value
                            if(!!value){
                                result = truncateToOneDecimalPlace(+event.target.value)
                            }
                            onChangeValue('depth_from',result)
                        }}
                        required={true}
                    />
                </div>
                <div className="form-field">
                    <label className="label" htmlFor="">
                        {getT('Заменить')}:
                    </label>
                    <input
                        type="number"
                        className="form-input"
                        placeholder=""
                        step={'0.1'}
                        value={form.depth_to}
                        onChange={(event) => {
                            let result = '';
                            let value = event.target.value
                            if(!!value){
                                result = truncateToOneDecimalPlace(+event.target.value)
                            }
                            onChangeValue('depth_to', result)
                        }}
                        required={true}
                    />
                </div>
            </div>}
            {isReplacement.plane && <div className="field-group">
                <div className="form-field">
                    <label className="label" htmlFor="">
                        {getT('Найти')}:
                    </label>
                    <select className="form-input" name={"plane_from"}
                            required={'required'}
                            value={form.plane_from}
                            onChange={(event) => onChangeValue('plane_from', event.target.value)}
                    >
                        <option value={""} disabled={"disabled"}>---{getT("Выбрать")}---</option>
                        {optionsPlane.map((el) => (
                            <option key={el.value} value={el.value}
                                    disabled={el.value == form.plane_to ? "disabled" : ""}
                            >{el.name}</option>
                        ))}
                    </select>
                </div>

                <div className="form-field">
                    <label className="label" htmlFor="">
                        {getT('Заменить')}:
                    </label>
                    <select className="form-input" name={"plane_to"}
                            required={'required'}
                            value={form.plane_to}
                            onChange={(event) => onChangeValue('plane_to', event.target.value)}
                    >
                        <option value={""} disabled={"disabled"}>---{getT("Выбрать")}---</option>
                        {optionsPlane.map((el) => (
                            <option key={el.value} value={el.value}
                                    disabled={el.value == form.plane_from ? "disabled" : ""}
                            >{el.name}</option>
                        ))}
                    </select>
                </div>
            </div>}
        </div>
    )
}

export default GroupOperations;