import React, {useState} from "react";
import {changeOrderTitleAC} from "../../../store/reducers/OrderReducer";

import {useDispatch, useSelector} from "react-redux";
import Edit from "../../../Icons/TableActions/Edit";
import {useLang} from "../../../context/langProvider";

const EditOrderTitle = () => {
  const {getT} = useLang();

  const dispatch = useDispatch();
  const [editTitle, toggleEditTitle] = useState(false);
  const orderTitle = useSelector((state) => state.order.order.title);

  return <div className="order">
    <h3 className="order-title">{getT("Заказ")}</h3>
    {editTitle ?
      <>
        <div className="form-field">
          <input
            type="text"
            className="form-input"
            value={orderTitle}
            onChange={(event) => dispatch(changeOrderTitleAC(event.target.value))}
            onBlur={() => toggleEditTitle(false)}
          />
        </div>
      </>
      :
      <>
        <span className="order-name"> <span className="name">{orderTitle}  </span> <Edit onClick={() => toggleEditTitle(true)}/></span>

      </>
    }
  </div>
}

export default  EditOrderTitle;