import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import Modal from "../BasicModal";
import {deleteItemFileManagerDT, onChangeFileManagerModalDT} from "../../../store/reducers/ModalReducer";
import Container from "../../Container";
import AddFiles from "./AddFiles";
import CloseModal from "../../../Icons/Actions/Close";
import "./file-manager.css"
import {useLang} from "../../../context/langProvider";

const FileManager = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();

    const {isOpen, listFiles, addedFiles, isAddedFiles} = useSelector(({
                                                                           modal: {
                                                                               fileManager: {
                                                                                   isOpen,
                                                                                   listFiles,
                                                                                   addedFiles,
                                                                                   isAddedFiles
                                                                               }
                                                                           }
                                                                       }) => ({
        isOpen,
        listFiles,
        addedFiles,
        isAddedFiles
    }));
    const close = () => dispatch(onChangeFileManagerModalDT({isOpen: false, isAddedFiles: false, addedFiles: {}}));
    const [isImport, setIsImport] = useState(false);
    const onDeleteFile = (url) => dispatch(deleteItemFileManagerDT(url))
    useEffect(() => {
        if (isAddedFiles) {
            setIsImport(false);
            setTimeout(() => {
                dispatch(onChangeFileManagerModalDT({isAddedFiles: false}));
            }, 5000)

        }
    }, [isAddedFiles]);
    if (!isOpen) return null
    return <>
        <Modal title={getT("Файловый менеджер")} open={isOpen} onClose={close}>
            <Container cName={"file-manager__container"}>
                <p>{getT('К проекту можно прикрепить один или несколько файлов. Выберите нужные файлы.')}</p>

                {isImport && <div className={"file-manager-add-file"}>
                    <div className="file-manager-header"><span className=""
                                                               onClick={() => setIsImport(false)}><CloseModal/></span>
                    </div>
                    <AddFiles getT={getT} description={' '}/></div>}
                {isAddedFiles && <div className={"added-files-name"}><p>{getT('Файл(ы) успешно отправлен(ы)')}</p>
                    <div className="wrap">
                    </div>
                </div>}
                {!isImport &&<>

                    <button className={"file-manager-btn__add btn add-to-card"}
                                      onClick={() => setIsImport(true)}>{getT("Добавить файл")}</button>
                </>
                }

                <div className={"list-files"}>
                    {Array.isArray(listFiles) && listFiles.length != 0 && listFiles.map((list, index) =>
                        <ListItem {...list} index={index + 1} getT={getT}
                                  onDeleteFile={onDeleteFile}/>)}
                </div>
            </Container>
        </Modal>
    </>
}


const ListItem = ({file_real, link, link_delete, index, onDeleteFile, getT}) => {
    return <div>
        <span className="count">{index}.</span>
        <a href={link} className="" download title={getT("Скачать")}> {file_real}</a>
        <span onClick={() => onDeleteFile(link_delete)} className=""><CloseModal isBlack={true}/></span>

    </div>
}
export default FileManager;