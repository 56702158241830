import "./ChpuGroup.css"

const ChpuGroup = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             id="Слой_1" data-name="Слой 1" viewBox="0 0 100 100">
            <defs>
            </defs>
            <line className="cls-1" x1="73.37" y1="63.98" x2="73.37" y2="86.16"/>
            <line className="cls-1" x1="84.45" y1="75.07" x2="62.28" y2="75.07"/>
            <circle className="cls-1" cx="73.37" cy="74.82" r="22.01"/>
            <polyline className="cls-2" points="15.88 35.1 24.5 35.1 34.84 35.1 40.16 35.1 43.45 35.1"/>
            <line className="cls-2" x1="11.51" y1="71.39" x2="47.82" y2="71.39"/>
            <rect className="cls-2" x="24.5" y="35.1" width="10.34" height="4.43"/>
            <rect className="cls-2" x="25.71" y="39.53" width="7.92" height="6.42"/>
            <polygon className="cls-2"
                     points="35.56 45.95 35.56 50.1 33.32 52.77 26.01 52.77 23.77 50.1 23.77 45.95 35.56 45.95"/>
            <polygon className="cls-2"
                     points="31.67 52.77 31.67 62.42 29.66 64.06 27.66 62.42 27.66 52.77 31.67 52.77"/>
            <line className="cls-2" x1="31.67" y1="58.33" x2="27.66" y2="61.44"/>
            <line className="cls-2" x1="31.67" y1="55.22" x2="27.66" y2="58.33"/>
            <path className="cls-2"
                  d="M47.82,62.64H37.07a3.41,3.41,0,0,1-3.41,3.41H25.47a3.41,3.41,0,0,1-3.41-3.41H11.51"/>
            <line className="cls-2" x1="23.78" y1="49.36" x2="33.08" y2="49.36"/>
            <path className="cls-1"
                  d="M40.16,28.2H8.46A3.85,3.85,0,0,0,4.62,32V74.45a3.85,3.85,0,0,0,3.84,3.83H50.88a3.74,3.74,0,0,0,.75-.08"/>
            <line className="cls-1" x1="54.71" y1="63.16" x2="54.71" y2="53.25"/>
            <line className="cls-2" x1="51.42" y1="10.06" x2="78.99" y2="10.06"/>
            <line className="cls-2" x1="83.36" y1="46.35" x2="54.71" y2="46.35"/>
            <rect className="cls-2" x="60.04" y="10.06" width="10.33" height="4.44"/>
            <rect className="cls-2" x="61.25" y="14.5" width="7.92" height="6.42"/>
            <polygon className="cls-2"
                     points="71.1 20.91 71.1 25.06 68.86 27.74 61.55 27.74 59.31 25.06 59.31 20.91 71.1 20.91"/>
            <polygon className="cls-2" points="67.2 27.74 67.2 37.38 65.2 39.02 63.2 37.38 63.2 27.74 67.2 27.74"/>
            <line className="cls-2" x1="67.2" y1="33.29" x2="63.2" y2="36.4"/>
            <line className="cls-2" x1="67.2" y1="30.18" x2="63.2" y2="33.29"/>
            <path className="cls-2" d="M54.71,37.6h2.88A3.42,3.42,0,0,0,61,41h8.18a3.41,3.41,0,0,0,3.42-3.41H83.36"/>
            <line className="cls-2" x1="59.31" y1="24.32" x2="68.62" y2="24.32"/>
            <path className="cls-1"
                  d="M77.74,53.25h8.67a3.83,3.83,0,0,0,3.83-3.83V7a3.82,3.82,0,0,0-3.83-3.83H44A3.82,3.82,0,0,0,40.16,7V28.2H50.88A3.84,3.84,0,0,1,54.71,32V53.25H69"/>
        </svg>
    )
}

export default ChpuGroup;