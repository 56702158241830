import React from 'react';
import PackItem from "./PackItem";
import {formatNumbersThousands} from "../../../../helpers/helpers";
import {getCalculateAmountAndVat, getCalculateVat} from "../../../ShopingCard/shoping_card_helper";

const TableOfPack = ({getT, packing, currency = '', production_vat, production_vat_display_short}) => {
    if (!Array.isArray(packing) || !packing?.length) return null

    return (<>
            {packing?.map((packing, root_index) => {
                const total_vat = getCalculateVat(packing?.fullSumm, production_vat)
                const amount_total_vat = getCalculateAmountAndVat(packing?.fullSumm, total_vat);

                return (
                    <div className={'section-table-pack section-gap'}>
                        <table>
                            <tr>
                                <th>{getT('Коробки')}</th>
                                <th colSpan={3}>{getT('Размер')}</th>
                                <th colSpan={2}>{getT('Количество')}</th>
                                <th colSpan={2}>{getT('Вес')}</th>
                                <th rowSpan={2}>{getT('Стоимость')}</th>
                            </tr>
                            <tr>
                                <th>{getT('Номер')}</th>
                                <th>{getT('Высота')}</th>
                                <th>{getT('Ширина')}</th>
                                <th>{getT('Глубина')}</th>
                                <th>{getT('Слои')}</th>
                                <th>{getT('Детали')}</th>
                                <th>{getT('Брутто')}</th>
                                <th>{getT('Нетто')}</th>
                            </tr>
                            {packing?.packs?.map((pack, index) => {
                                return <PackItem pack={pack} getT={getT} index={index}
                                                 key={`root_${root_index}_pack_item_${index}`}/>

                            })}
                            <tr className={'tr-bg-green'}>
                                <td colSpan={9} style={{textAlign: 'right'}} className={'amount'}>
                                    {getT('Стоимость')} :
                                    {formatNumbersThousands(packing?.fullSumm)}

                                    {!!production_vat ?
                                        production_vat_display_short ?
                                            <span className={"amount-total-vat"} style={{fontSize: `${production_vat_display_short ? '14px' : 'inherit'}`}}>
                                                {currency} / {amount_total_vat} {currency} {getT("с НДС")} </span>
                                            :
                                            <> + {total_vat} {getT("НДС")} = {amount_total_vat}  {currency}</>:currency}
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            })}
        </>
    );
};

export default TableOfPack;