import React from "react";
import "./InputForm.css";

const InputForm = ({
                       type = "text",
                       name,
                       htmlFor,
                       onChange,
                       onBlur,
                       placeholder,
                       cName = "",
                       label,
                       required,
                       value,
                        cNameField="",
                       ...rest
                   }) => {
    let fullClassName = `form-input ${cName}`;
    return (
        <div className={cNameField + " form-field"}>
            {label &&<label className="label" htmlFor={name}>
                {label}
            </label>}
            <input
                name={name}
                type={type}
                className={fullClassName}
                placeholder={placeholder}
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                value={value ? value : ''}
                {...rest}
            />
        </div>
    );
};

export default InputForm;
