import React from 'react';
import {useLang} from "../../../context/langProvider";

const Contacts = () => {
    const {getT} = useLang();

    return <div className="info-page-inner-content">
        <h2 className="privacy-title"> {getT('Контакты')}:</h2><p className="privacy-text">
        {getT('Коток ИМ (СПД)')}
        {getT('Киев,' +
        ' ул. Голосеевская, 13а')} </p>
        <p> +38&nbsp;093 0&nbsp;888&nbsp;709 (telegram)</p>
        <p className="privacy-text">{getT('Email')}: <a className="privacy-link"
                                                        href="mailto: i@ifurn.pro"> i@ifurn.pro</a>
        </p>
    </div>
};

export default Contacts;