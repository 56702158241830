import React, {useEffect} from 'react';

export const useCloseCommandEsc = (handlerClose) => {
    useEffect(() => {
        const closeCommand = (evt) => {

            evt = evt || window.event;
            let isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }
            if (isEscape) {
                handlerClose()
            }
        }
        document.addEventListener('keydown', closeCommand);
        return () => document.removeEventListener('keydown', closeCommand);

    }, [handlerClose]);
};

