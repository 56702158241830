import Modal from "../BasicModal";
import Container from "../../Container";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {onChangeFileManagerPhotosModalDT} from "../../../store/reducers/ModalReducer";
import AddPhotos from "./AddPhotos";

const FileManagerPhotos = ({getT})=>{
    const dispatch = useDispatch();
    const {isOpen, article_id} = useSelector(state => state.modal.fileManagerPhotos);
    const close = () => dispatch(onChangeFileManagerPhotosModalDT({isOpen: false, article_id:null}));

    return <>
        <Modal title={getT("Файловый менеджер")} open={isOpen} onClose={close}>
            <Container cName={"file-manager__container"}>
             <div className={" w-100"}>
                    <AddPhotos getT={getT} article_id={article_id}/></div>

            </Container>
        </Modal>

    </>
}

export default FileManagerPhotos;