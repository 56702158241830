import React from "react";
import {useLang} from "../../../context/langProvider";

const SelectForm = ({
                        el,
                        type = "text",
                        name,
                        htmlFor,
                        onChange,
                        cName = "",
                        label,
                        ...rest
                    }) => {
    let fullClassName = `form-input ${cName}`
    const {getT} = useLang();
    return (
        <div className="form-field">
            <label className="label" htmlFor={name}>
                {label}
            </label>
            <select
                className={fullClassName}
                name={name}
                onChange={onChange}
                {...rest}
            >
                <option value={''}>{getT('Выбрать')}...</option>
                {el.map((elem) => {
                    let key_id = elem?.hasOwnProperty('id') ? 'id' : 'value';
                    let key_title = elem?.hasOwnProperty('name') ? 'name' : 'label';
                    return (
                    <option key={elem[key_id]} value={elem[key_id]}>{elem[key_title]}</option>
                )})}
            </select>
        </div>
    );
};

export default SelectForm;