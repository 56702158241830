import getT from "./getT";
import table_of_pack from "./packaging/table_of_pack";
import table_of_part from "./packaging/table_of_part";
import data_of_project from "./packaging/data_of_project";

export class PackagingHtml {
    constructor() {
    }

    generateContentHtml({order, setting, currency, manager_name, unit}) {
        let production_vat_display_short = order?.production_constants?.['production.vat_added_tax_show_needed'] || 1;
        let production_vat = order?.production_constants?.['production.VAT_add_price_if_more_than_0'] || 0;

            if
        (!Array.isArray(order?.packing) || !order?.packing?.length)
        {
            return `<h3>${getT('Отсутствуют упаковки в проекте')}</h3>`
        }
        return `
       <div class="container">
        ${data_of_project({order, currency, manager_name, unit})}
       ${!!setting.packs ? table_of_pack({
            packing: order?.packing,
            isVisibleBins: setting.packs_bins,
            isVisiblePart: setting.packs_part,
            currency: currency,
            production_vat: Number(production_vat),
            production_vat_display_short: !Number(production_vat_display_short) && !!Number(production_vat)
        }) : ''}
       ${!!setting?.part ? table_of_part({packing: order?.packing, isVisiblePosition: setting?.part_position}) : ''}
   </div>
    `
    }

}