import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getLocalLang} from "../../selectors/settings-selector";
import {changeLangApp} from "../../store/reducers/TranslationReducer";
import LanguageSelect from "./LanguageSelect";
import LanguageProduction from "./LanguageProduction";
import {languagesList} from "../../constants";

const Language = ({production, isSaveProject = true}) => {
    const dispatch = useDispatch();
    const lang = useSelector(getLocalLang);
    const onChange = lang => {
            dispatch(changeLangApp(lang, isSaveProject))
    };
    // const {productionLang, changeLang, lang} = useTranslate()
    // const onChange = lang => changeLang(lang, true);
    return (
        <div>
            {languagesList.includes(production?.toLowerCase()) ?
                <LanguageProduction lang={lang}/>
                :
                <LanguageSelect lang={lang} onChange={onChange}/>
            }
        </div>
    );
};

export default Language;