import React from 'react';
import LoaderIcon from "../../Icons/LoaderIcon";
import './Loader.css';

const Loader = (props) => {
    return <div className={'loader_container_main'}>
        <div className="loader_image">
            <LoaderIcon/>
        </div>
        <div className="loader_message_container">
            {props.message}
        </div>
    </div>
}

export default Loader;