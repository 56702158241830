import React from 'react';
import QRCode from "react-qr-code";
const QrCode = ({qrProps={}, cName=''}) => {
    return (
        <div className={cName}>
            <QRCode {...qrProps}/>
        </div>
    );
};

export default QrCode;