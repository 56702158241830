import { tools } from './tools.js';

const frezTypeCornerRounding = 'cornerRounding';
const frezTypeCornerCutRectangle = 'cornerCutRectangle';
const frezTypeCornerCut = 'cornerCut';

const cornerRounding = 'cornerRounding';
const cornerCutRectangle = 'cornerCutRectangle';
const cornerCut = 'cornerCut';
const multipleCornerRounding = 'multipleCornerRounding';

const cornerNames = ['ru', 'rd', 'ld', 'lu'];

const frezFields = {
  cornerRounding: ['d', 'corner_only', 'side_face', 'id'],
  cornerCutRectangle: [
    'd', 'd2', 'r1', 'r2', 'r3', 'corner_only', 'side_face', 'id'
  ],
  cornerCut: ['d', 'd2', 'corner_only', 'side_face', 'id'],
  multipleCornerRounding: ['d_ld', 'd_lu', 'd_rd', 'd_ru'],
}

export const inputFrezes = {
  frezTypeCornerRounding,
  frezTypeCornerCutRectangle,
  frezTypeCornerCut,
  cornerRounding,
  cornerCutRectangle,
  cornerCut,
  multipleCornerRounding,
  cornerNames,
  frezFields,

  getFrezType(frez) {
    let inputs = frez.inputs;
    if ('r1' in inputs) {
      return cornerCutRectangle;
    } else if ('d2' in inputs) {
      return cornerCut;
    } else if ('d_ld' in inputs) {
      return multipleCornerRounding;
    } else if ('d' in inputs) {
      return cornerRounding;
    }
  },
  getCornerCoordsByText(cornerOnly, partX, partY) {
    let sideCornerCoord = {
      r: partX,
      l: 0,
      d: partY,
      u: 0
    };
    return Array.from(cornerOnly).map(a => sideCornerCoord[a])
  },
  getStartingPoint(cornerOnly, d, partX, partY) {
    if (cornerOnly === 'ru') {
      return [partX - d, 0];
    } else if (cornerOnly === 'rd') {
      return [partX, partY - d];
    } else if (cornerOnly === 'ld') {
      return [d, partY];
    } else if (cornerOnly === 'lu') {
      return [0, d];
    }
  },
  getEndingPoint(cornerOnly, d, partX, partY) {
    if (cornerOnly === 'ru') {
      return [partX, d];
    } else if (cornerOnly === 'rd') {
      return [partX - d, partY];
    } else if (cornerOnly === 'ld') {
      return [0, partY - d];
    } else if (cornerOnly === 'lu') {
      return [d, 0];
    }
  },
  getCutStartingPoint(cornerOnly, d, d2, partX, partY) {
    if (cornerOnly === 'ru') {
      return [d, 0];
    } else if (cornerOnly === 'rd') {
      return [partX, d2];
    } else if (cornerOnly === 'ld') {
      return [partX - d2, partY];
    } else if (cornerOnly === 'lu') {
      return [0, partY - d]
    }
  },
  getCutEndingPoint(cornerOnly, d, d2, partX, partY) {
    if (cornerOnly === 'ru') {
      return [partX, partY - d2];
    } else if (cornerOnly === 'rd') {
      return [d, partY];
    } else if (cornerOnly === 'ld') {
      return [0, d];
    } else if (cornerOnly === 'lu') {
      return [partX - d2, 0];
    }
  },
  getCutRectangleStartingPoint(cornerOnly, d, d2, partX, partY) {
    if (cornerOnly === 'ru') {
      return [partX - d, 0];
    } else if (cornerOnly === 'rd') {
      return [partX, partY - d2];
    } else if (cornerOnly === 'ld') {
      return [d2, partY];
    } else if (cornerOnly === 'lu') {
      return [0, d];
    }
  },
  getCutRectangleEndingPoint(cornerOnly, d, d2, partX, partY) {
    if (cornerOnly === 'ru') {
      return [partX, d2];
    } else if (cornerOnly === 'rd') {
      return [partX - d, partY];
    } else if (cornerOnly === 'ld') {
      return [0, partY - d];
    } else if (cornerOnly === 'lu') {
      return [d2, 0];
    }
  },
  prepareFrezes(frezes) {
    let cornerFrezes = {
      ru: null,
      rd: null,
      ld: null,
      lu: null,
    };

    for (let frez of frezes) {
      if (!tools.isInputFrez(frez)) {
        continue;
      }

      let inputs = frez.inputs;
      let corner = inputs.corner_only;

      // if is multiple rounding frez
      if (corner === undefined) {
        for (let [corner, cornerRadius] of Object.entries(inputs)) {
          if (!corner.startsWith('d_')) {
            continue;
          }

          let newFrez = {
            ...frez,
            frez_move: null,
            inputs: {
              corner_only: corner.slice(2),
              d: cornerRadius,
            }
          };
          frezes.push(newFrez);
        }
        frezes.splice(frezes.indexOf(frez), 1);
        continue;
      }

      let corFrez = cornerFrezes[corner];

      if (corFrez === null) {
        cornerFrezes[corner] = frez;
      } else {
        let corFrezInputs = corFrez.inputs;

        for (let attr of ['d', 'd2', 'r1', 'r2', 'r3']) {
          let corFrezAttr = parseFloat(corFrezInputs[attr] || 0);
          let frezAttr = parseFloat(inputs[attr] || 0);

          if (frezAttr > corFrezAttr) {
            cornerFrezes[corner] = frez;
            break
          }
        }
      }
    }

    return cornerFrezes;
  },
  inputFrezesOnSide(frezes, partX, partY, partZ, bands, part, side) {
    // paint edges
    frezes = this.prepareFrezes(frezes);
    let partEdgeColor = tools.getPartEdgeColor(bands, part, side);
    let a = '';

    for (let [corner, frez] of Object.entries(frezes)) {
      let cornerWithSides = corner.replace('u', 't').replace('d', 'b');

      let fill = partEdgeColor;
      let [x, y] = [0, 0];
      let [x1, y1] = [partX, partY];

      if (cornerWithSides.includes(side) && Object.keys(frez || {}).length) {
        let frezType = this.getFrezType(frez);
        let inputs = frez.inputs;

        let d, d2, r1, r2, r3;

        if (frezType === cornerRounding) {
          d = parseFloat(inputs.d);
          [x, y] = this.getStartingPoint(
            inputs.corner_only, d, partX, partY
          );
          [x1, y1] = this.getEndingPoint(
            inputs.corner_only, d, partX, partY
          );
        } else if (frezType === cornerCut) {
          [d, d2] = [inputs.d, inputs.d2].map(parseFloat);
          [x, y] = this.getCutStartingPoint(
            inputs.corner_only, d, d2, partX, partY
          );
          [x1, y1] = this.getCutEndingPoint(
            inputs.corner_only, d, d2, partX, partY
          );
        } else if (frezType === cornerCutRectangle) {
          [d, d2, r1, r2, r3] = [
            inputs.d, inputs.d2, inputs.r1 || 0, inputs.r2 || 0,
            inputs.r3 || 0
          ].map(parseFloat);
          [x, y] = this.getCutRectangleStartingPoint(
            inputs.corner_only, d, d2, partX, partY
          );
          [x1, y1] = this.getCutRectangleEndingPoint(
            inputs.corner_only, d, d2, partX, partY
          );
        }

        fill = tools.getEdgeColor(bands, frez.db_id) || fill;
      }

      if ('lr'.includes(side)) {
        a += tools.rect(
          0, Math.min(y, y1), partZ, Math.abs(y - y1), {
          stroke: 'none',
          fill: fill
        }
        );
      } else if ('tb'.includes(side)) {
        a += tools.rect(
          Math.min(x, x1), 0, Math.abs(x - x1), partZ, {
          stroke: 'none',
          fill: fill
        }
        );
      }
    }
    return a;
  },
  inputFrezes(frezes, partX, partY, partZ, bands, part, fill, typeSvg) {
    let typeSmallCart = ['small', 'car'].includes(typeSvg);
    let hoverShow = 'front';
    let strokeWidth = (
      !['lbl', 'cart'].includes(typeSvg) ? '1px' : '5px'
    );

    if (!tools.objectIsTrue(frezes) || typeSvg === 'lbl') {
      let border = typeSmallCart ? 'none' : 'black';
      return tools.rect(
        0, 0, partX, partY, {
        fill: fill, stroke: border, hover_show: hoverShow,
        'stroke-width': strokeWidth
      }
      );
    }

    let a = '';
    let coloredFrezes = {};
    let result = '';
    frezes = this.prepareFrezes(frezes);

    // for edge painting
    let prevCornerData = null; // (color, index in colouredFrez value)
    // for last edge painting
    let firstFrezCoords = null;

    let x, y, x1, y1;

    for (let [corner, frez] of Object.entries(frezes)) {
      let svgMove = '';

      if (frez === null) {
        // if no frez for corner, then put a point,
        // which will be later connected with other points
        [x, y] = [x1, y1] = this.getCornerCoordsByText(corner, partX, partY);
        svgMove = ` M ${x} ${y}`;
      } else {
        let frezType = this.getFrezType(frez);
        let inputs = frez.inputs;

        if (frezType === cornerRounding) {
          let d =  parseFloat(inputs.d);
          let r = d;
          [x, y] = this.getStartingPoint(inputs.corner_only, d, partX, partY);
          [x1, y1] = this.getEndingPoint(inputs.corner_only, d, partX, partY);
          svgMove = ` M ${x} ${y} A ${r} ${r} 0 0 1 ${x1} ${y1}`;
        } else if (frezType === cornerCut) {
          let [d, d2] = [inputs.d, inputs.d2].map(parseFloat);
          [x, y] = this.getCutStartingPoint(inputs.corner_only, d, d2, partX, partY);
          [x1, y1] = this.getCutEndingPoint(inputs.corner_only, d, d2, partX, partY);
          svgMove = ` M ${x} ${y} L ${x1} ${y1}`
        } else if (frezType === cornerCutRectangle) {
          let [d, d2, r1, r2, r3] = [
            inputs.d, inputs.d2, inputs.r1 || 0, inputs.r2 || 0,
            inputs.r3 || 0
          ];
          [x, y] = this.getCutRectangleStartingPoint(
            inputs.corner_only, d, d2, partX, partY
          );
          [x1, y1] = this.getCutRectangleEndingPoint(
            inputs.corner_only, d, d2, partX, partY
          );

          if (['ld', 'ru'].includes(inputs.corner_only)) {
            svgMove = ` M ${x} ${y} L ${x} ${y1} L ${x1} ${y1}`;
          } else {
            svgMove = ` M ${x} ${y} L ${x1} ${y} L ${x1} ${y1}`;
          }
        }
      }

      if (firstFrezCoords === null) {
        firstFrezCoords = [x, y];
      }

      a += svgMove;

      // paint corner frezes
      if (tools.objectIsTrue(frez)) {
        let frezColor = tools.getEdgeColor(bands, frez.db_id);

        if (frezColor !== null) {
          if (!(frezColor in coloredFrezes)) {
            coloredFrezes[frezColor] = [];
          }

          coloredFrezes[frezColor].push(svgMove.toString())
        }

        // doesnt work for lu corner, execute this block outside the cycle
        if (prevCornerData !== null) {
          let [color, index] = prevCornerData;
          coloredFrezes[color][index] += ` L ${x} ${y}`;
          prevCornerData = null;
        }

        // paint edges
        let edgeSideByBeginCorner = {
          ru: 'r',
          rd: 'b',
          ld: 'l',
          lu: 't'
        };
        let edgeSide = edgeSideByBeginCorner[corner];
        let edge = part.edge[edgeSide];
        let edgeColor = tools.getEdgeColor(bands, edge.db_id);

        if (edgeColor !== null) {
          if (!(edgeColor in coloredFrezes)) {
            coloredFrezes[edgeColor] = [];
          }
          coloredFrezes[edgeColor].unshift(` M ${x1} ${y1}`);
          prevCornerData = [edgeColor, 0];
        }
      }

      if (prevCornerData !== null) {
        let [color, index] = prevCornerData;
        let [x, y] = firstFrezCoords;
        coloredFrezes[color][index] += ` L ${x} ${y}`;
        prevCornerData = null;
      }

      let firstACommand = a.indexOf(' ', a.indexOf(' ', a.indexOf(' ') + 1) + 1);
      a = a.slice(0, firstACommand) + a.slice(firstACommand).replace('M', 'L');
      a += 'Z';

      result = `<path d="${a}" fill="${fill}" stroke="black" stroke-width=${strokeWidth} hover_show="front"/>`;

      for (let [color, moves] of Object.entries(coloredFrezes)) {
        for (let move of moves) {
          result += `<path d="${move}" fill="none" stroke="${color}" stroke-width="${strokeWidth}"/>`;
        }
      }
    }

    return result;
  }
}
