export const extraSvgs = `
<svg 
    style="cursor: pointer"
	id="blueprint-zoom-out"
    baseProfile="tiny"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:ev="http://www.w3.org/2001/xml-events"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    overflow="auto"
>
    <ellipse ry="10" rx="10" cy="12" cx="12" stroke-width="2" stroke="#000" fill="#fff"></ellipse>
    <line y2="30" x2="30" y1="20" x1="20" stroke-width="3" stroke="#000" fill="none"></line>
    <line y2="12" x2="19" y1="12" x1="5" stroke-width="3" stroke="#000" fill="none"></line>
    <g text-hint="" display="none">
        <rect 
            x="0"
            y="0"
            width="0"
            height="0"
            fill="white" 
            stroke="black"
        > </rect>
        <text 
            x="0"
            y="0"
            alignment-baseline="middle"
            text-anchor="start"
        ></text>
    </g>
</svg>
<svg 
    style="cursor: pointer"
	id="blueprint-zoom-in"
    baseProfile="tiny"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:ev="http://www.w3.org/2001/xml-events"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    overflow="auto"
>
    <ellipse ry="10" rx="10" cy="12" cx="12" stroke-width="2" stroke="#000" fill="#fff"></ellipse>
    <line y2="30" x2="30" y1="20" x1="20" stroke-width="3" stroke="#000" fill="none"></line>
    <line y2="19" x2="12" y1="5" x1="12" stroke-width="3" stroke="#000" fill="none"></line>
    <line y2="12" x2="19" y1="12" x1="5" stroke-width="3" stroke="#000" fill="none"></line>
    <g text-hint="" display="none">
        <rect 
            x="0"
            y="0"
            width="0"
            height="0"
            fill="white" 
            stroke="black"
        > </rect>
        <text 
            x="0"
            y="0"
            alignment-baseline="middle"
            text-anchor="start"
        ></text>
    </g>
</svg>
<svg
    style="cursor: pointer"
	id="blueprint-toggle-measurements"
    width="30"
    height="30"
    xmlns="http://www.w3.org/2000/svg"
    >
    <rect x="0" y="0" width="28" height="28" stroke-width="1" stroke="none" fill="#fff" opacity="0"></rect>
    <line y2="2" x2="20" y1="28" x1="20" stroke-width="1" stroke="#000" fill="none"></line>
    <line y2="2" x2="20" y1="8" x1="16" stroke-width="1" stroke="#000" fill="none"></line>
    <line y2="2" x2="20" y1="8" x1="24" stroke-width="1" stroke="#000" fill="none"></line>
    <line x2="2" y2="20" x1="28" y1="20" stroke-width="1" stroke="#000" fill="none"></line>
    <line x2="2" y2="20" x1="8" y1="16" stroke-width="1" stroke="#000" fill="none"></line>
    <line x2="2" y2="20" x1="8" y1="24" stroke-width="1" stroke="#000" fill="none"></line>
</svg>
<text
	id="blueprint-show-id"
	x="0"
	y="0"
	font-size="20px"
	fill="black"
	font-family="sans-serif"
	style="cursor: pointer"
>
	ID
</text>
<svg 
    id="blueprint-resize-scroll-to-center"
    style="cursor: pointer"
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" 
    x="0" 
    y="0" 
    viewBox="0 0 256 256" 
    enable-background="new 0 0 256 256" 
    xml:space="preserve"
    width="23"
    height="23"
>
    <rect
        x="10"
        y="10"
        width="230"
        height="230"
        stroke-width="1"
        stroke="none"
        fill="#fff"
    />
    <path 
        fill="#000000" 
        d="M36.2,167.3H10v52.5c0,14.5,11.7,26.2,26.2,26.2h52.4v-26.2H36.2V167.3z M36.2,36.2h52.4V10H36.2C21.7,10,10,21.7,10,36.2v52.4h26.2V36.2z M219.8,10h-52.5v26.2h52.5v52.4H246V36.2C246,21.7,234.3,10,219.8,10z M219.8,219.8h-52.5V246h52.5c14.5,0,26.2-11.7,26.2-26.2v-52.5h-26.2V219.8z M128,88.7c-21.7,0-39.3,17.6-39.3,39.3s17.6,39.3,39.3,39.3s39.3-17.6,39.3-39.3S149.7,88.7,128,88.7z"
    />
</svg>
<svg
    id="blueprint-toggle-double-measurements"
    width="30"
    height="30"
    xmlns="http://www.w3.org/2000/svg"
>
    <g show-double="">
        <text
            x="-7"
            y="30"
            font-size="20px"
	        fill="black"
	        font-family="sans-serif"
        >
            →|←
        </text>
    </g>
    <g show-single="" class="">
        <text
            x="-7"
            y="30"
            font-size="20px"
	        fill="black"
	        font-family="sans-serif"
        >
            →|
        </text>
    </g>

</svg>
<g part-sizes-type="" transform="translate(50 50)" style="display: none;">
    <rect 
        font-family="sans-serif"
        font-size="15px"
        x="0"
        y="0"
        width="0"
        height="0"
        stroke="black"
        fill="white"
        bg=""
    ></rect>
    <text></text>
</g>
<g main-operation-table="" transform="translate(50 60)"></g>
`
