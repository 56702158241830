import React from 'react';

const IconDownload = (props) => {
    let {className = '', ...reset} = props;
    const cName = `icon-download cursor-pointer ${className}`;
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={cName} width={"24px"} height={"24px"} {...reset}>
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g id="Interface / Download">
                    <path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" stroke="#4262ff" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </g>
        </svg>
    );
};

export default IconDownload;