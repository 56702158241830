import React, {useState} from 'react';
import {useLang} from "../../../../../context/langProvider";

const SubmitPrivacy = ({submitForm}) => {
    const [isPrivacy, setIsPrivacy] = useState(true);
    const {getT} = useLang();

    return (
        <>
            <div className="d-flex justify-center form-group mb-2">
                <button type={'button'} className={!isPrivacy ? 'disabled btn m-0' : 'btn m-0'} onClick={submitForm}>
                    <> {getT('Авторизоваться')}</>
                </button>

            </div>
            <div className="privacy-checkbox filed-checkbox">
                <input type="checkbox" id={'privacy'}
                       checked={isPrivacy}
                       onChange={(event) => setIsPrivacy(event.target.checked)}/>
                <label htmlFor="privacy">
                    {getT('Я согласен с обработкой моих персональных данных, правилами использования сайта и использованием cookie на сайте')}
                </label>
            </div>
        </>
    );
};

export default SubmitPrivacy;