import React from 'react';
import {LS_CONNECTED_BOX3D} from "../constants";

const IconPacked = (props) => {
    const cName = `${props?.className ? props.className : ' '} sc-egTteO eoYkrk`
    const available_box_color = !!Number(localStorage.getItem(LS_CONNECTED_BOX3D)) ? "black" : "#ccc"
    return (
        <svg stroke={available_box_color} fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round"
             strokeLinejoin="round" className={cName} style={{width: '24px', height: '24px'}}
        >
            <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
            <path
                d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
            <line x1="12" y1="22.08" x2="12" y2="12"></line>
        </svg>
    );
};

export default IconPacked;