import React, {useEffect, useState} from "react";

export default function () {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const handlerStartSeconds = (isStart = true) => {

        if (isStart) {
            setIsButtonDisabled(true);
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 60000);
            return
        } else if (isButtonDisabled) {
            setIsButtonDisabled(false);

        }
    }
    useEffect(() => {
        let timerInterval = 0;
        if (isButtonDisabled) {
            timerInterval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else {
            clearInterval(timerInterval);
            setSeconds(60)
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [isButtonDisabled]);
    return [seconds, isButtonDisabled, handlerStartSeconds]
}