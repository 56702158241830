import {changeAlertStatusAC, changeLoaderStatusAC} from "../reducers/CommonReducer";
import ApiService from "../../api/api";
import {accountMessageChannelsAC} from "../actions/auth-actions";
import {createFormData, getTranslateMessageAPI} from "../../helpers/helpers";

const API = new ApiService();

export const checkConnectMessageChannelsThunk = (message_channels) => async (dispatch, getState) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        await dispatch(messageChannelsThunk());
        const message_channel = getState().auth.message_channel;
        dispatch(changeLoaderStatusAC(false));
        if (message_channel && message_channel.hasOwnProperty('connected_message_channel_list')) {
            let isAddedBot = Object.keys(message_channel.connected_message_channel_list).find(key => key === message_channels);
            if (!isAddedBot) {
                let msg = `${getTranslateMessageAPI('Вы не подключены к боту')} ${message_channels}`
                dispatch(changeAlertStatusAC(true, msg));
            }
        }
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
    }
}
export const messageChannelsThunk = () => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response = await API._getMessageChannels();
        if (API._errors || response.hasOwnProperty('error')) {
            dispatch(changeLoaderStatusAC(false));
            let msg = API._getErrors() || response?._errors;
            dispatch(changeAlertStatusAC(true, msg));
            return;
        }
        dispatch(accountMessageChannelsAC(response));
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
    }
}

export const setDefaultMessageChanelThunk = (api_data) => async dispatch => {
    try {
        const formData = createFormData(api_data)
        dispatch(changeLoaderStatusAC(true));
        const response = await API._setDefaultMessageChannel(formData);
        if (API._errors || response.hasOwnProperty('error')) {
            dispatch(changeLoaderStatusAC(false));
            let msg = API._getErrors() || response?._errors;
            dispatch(changeAlertStatusAC(true, msg));
            return;
        }
        dispatch(messageChannelsThunk());
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
    }
}
export const toggleEnabledMessageChannelThunk = (api_data) => async dispatch => {
    try {
        const formData = createFormData(api_data)
        dispatch(changeLoaderStatusAC(true));
        const response = await API._toggleEnabledMessageChannel(formData);
        if (API._errors || response.hasOwnProperty('error')) {
            dispatch(changeLoaderStatusAC(false));
            let msg = API._getErrors() || response?._errors;
            dispatch(changeAlertStatusAC(true, msg));
            return;
        }
        dispatch(messageChannelsThunk());
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
    }
}