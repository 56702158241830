function Edit(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.916 1.75a1.65 1.65 0 112.334 2.333l-7.875 7.875-3.208.875.875-3.208L9.915 1.75z"
        stroke="#4262FF"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Edit;
