import React, {useMemo,  useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeBatchMachiningModalValue} from "../../../store/reducers/ModalReducer";
import {
    onChangeBatchMachiningHolesValues,
    resetBatchMachiningFormAC,
    updateBatchMachiningPartThunck
} from "../../../store/reducers/OrderReducer";
import BatchMachiningHolesModal from "./BatchMachiningHolesModal";
import {getBatchMachiningHolesForm, getOrder, getOrderMaterial, getOrderPart} from "../../../selectors/order-selector";
import {getModalBatchMachiningModal} from "../../../selectors/modal-selector";

const BatchMachiningHolesModalContainer = () => {
    const dispatch = useDispatch();
    const modal = useSelector(getModalBatchMachiningModal);
    const form = useSelector(getBatchMachiningHolesForm);
    const order = useSelector(getOrder);
    const materials_state = useSelector(getOrderMaterial)
    const part_state = useSelector(getOrderPart);
    const closeModal = useCallback(() => {
        dispatch(changeBatchMachiningModalValue({
            isOpen: false,
            processing_current_material: null
        }));
        dispatch(resetBatchMachiningFormAC());
    }, [dispatch]);
    const onChangeValue = useCallback((input, value) => dispatch(onChangeBatchMachiningHolesValues(input, value)), [dispatch]);
    const addOperations = ({form, order}, call) => dispatch(updateBatchMachiningPartThunck({form, order}, call));
    const part = useMemo(() => {
        if (modal?.processing_current_material) {
            return part_state?.filter(part => Number(part.material) === Number(modal.processing_current_material))
            // return part_state?.filter(part => Number(part.material) === Number(21864  ))
        }
        return part_state
    }, [part_state, modal]);
    const materials = useMemo(() => {
        if (modal?.processing_current_material) {
            // return materials_state?.filter(material => Number(material.goods_id) === Number(21864  ))
            return materials_state?.filter(material => Number(material.goods_id) === Number(modal.processing_current_material))
        }
        return materials_state
    }, [materials_state, modal]);

    return <BatchMachiningHolesModal open={modal.isOpen}
                                     is_processing_current_material={modal?.processing_current_material}
                                     onClose={closeModal}
                                     form={form}
                                     onChangeValue={onChangeValue}
                                     addOperations={addOperations}
                                     materials={materials}
                                     part={part}
                                     order={order}
    />;
}


export default BatchMachiningHolesModalContainer;
