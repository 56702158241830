export const getTreeMaterials = (state)=>{
    return state.materials.tree;
}
export const getFilterMaterials = (state)=>{
    return state.materials.filters;
}
export const getSearchMaterials = (state)=>{
    return state.materials.search;
}
export const getMaterialsDT = (state)=>{
    return state.materials.materials;
}
export const getActiveCategoryIsMaterials= (state)=>{
    return state.materials.activeCategory;
}

