import React from 'react';
import LogoLogin from "../../../Icons/Logo";
import BasicStaticMenu from "../../../components/BasicStaticMenu/BasicStaticMenu";
import Language from "../../../components/Language";
import {useLang} from "../../../context/langProvider";

const Header = ({children=null, project_rules, firm_code}) => {
    const {getT} = useLang()
    return (
        <div className="header">
            <div className="logo">
                <a className="link main-logo"
                   href={project_rules?.www ? project_rules.www : process.env.REACT_APP_IFURN_PRO_URL}
                   style={{maxWidth: '115px'}}>
                    {project_rules?.logoUrl ? <img src={project_rules?.logoUrl} alt=""/> : <LogoLogin/>}
                </a>
            </div>
            {project_rules && !project_rules.infoText &&
                <BasicStaticMenu getT={getT} firm_code={firm_code}/>
            }
            {project_rules?.need_reg && project_rules?.registration ?
                <div className="register-btn" style={{marginLeft: 'auto'}}>
                    <a className="link register" href={project_rules?.registration}>
                        {getT('Регистрация')}
                    </a>
                </div>
                : null}

            {children}
           <Language production={project_rules.lang} isSaveProject={false}/>

        </div>
    );
};

export default Header;