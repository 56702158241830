import NoImage from "../../Icons/NoImage";
import React from "react";
import Edit from "../../Icons/TableActions/Edit";
import Delete from "../../Icons/Actions/Delete";
import {useDispatch} from "react-redux";
import {onDeleteFurniture, onDeletePhotoFurniture} from "../../store/reducers/FurnitureReducer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import Plus from "../../Icons/Plus";
import {onChangeFileManagerPhotosModalDT} from "../../store/reducers/ModalReducer";

export const Card = ({box, getT}) => {
    const dispatch = useDispatch();
    const onDelete = (url) => dispatch(onDeleteFurniture(url));
    const onDeletePhoto =(furniture)=> dispatch(onDeletePhotoFurniture(furniture))
    const openFileManager = (article_id)=> dispatch(onChangeFileManagerPhotosModalDT({isOpen: true, article_id}));
    const {article_id, name, image, code, description, x_max, y_max, z_max, x_min, y_min, z_min, picture_all} = box;
    return <>
        <div className={'card-wrapper card-materials card-furniture-wrap'} key={box.article_id+'_nt'}>
            {Number(article_id) !== 231 }
            <div className="card-furniture__actions">
                {Number(article_id) !== 231 && <span onClick={()=> openFileManager(article_id)} title={getT('Добавить изображение')} className={'cursor-pointer'}><Plus/></span>}
                {box.hasOwnProperty('edit_link') && <a className={'cursor-pointer'} href={box.edit_link} title={getT('Редактировать')} target={'_blank'}><Edit/></a>}
                {Number(article_id) !== 231 &&  box.hasOwnProperty('del_link') && <span title={getT('Удалить мебельный модуль')} className={'cursor-pointer'} onClick={() => onDelete(box.del_link)}><Delete/></span>}
            </div>
            <div className="card-body">
                <div className="card-img">

                    {picture_all&& picture_all.hasOwnProperty('data') && Array.isArray(picture_all.data)  && picture_all.data.length >=1 ?
                        <FurnitureOwl isDeletePicture={Number(article_id) !== 231 ? true: false} onDelete={onDeletePhoto} picture={picture_all.data} title={getT('Удалить изображение')}/> : <NoImage/>}
                </div>
                <h3 className="card-title">{name}</h3>
                <p className="card-sizes">
                    <small>{description}</small>
                </p>
                <p className="card-sizes box-card-sizes-container">
                    <span>{getT('Размеры')}:</span>
                    <span>X: {getT('от')} {x_min} {getT('до')} {x_max}</span>
                    <span>Y: {getT('от')} {y_min} {getT('до')} {y_max}</span>
                    <span>Z: {getT('от')} {z_min} {getT('до')} {z_max}</span>
                </p>
                {/*<p className="card-sizes">*/}
                    {/*{getT('Код')}: {box.article_id}_{box.on}_{box.type}*/}
                {/*</p>*/}
            </div>
        </div>
    </>
}
export const FurnitureOwl = ({picture, isDeletePicture = false, onDelete= (pic_article_id)=>{}}, title='') => {
    return <Carousel thumbWidth={0} swipeable={true} showThumbs={false} verticalSwipe={'standard'}>
        {picture.map(p => <div className={'furniture-owl__item'}>
            {isDeletePicture && <span title={title} className={'furniture-delete__photo'} onClick={()=> onDelete(p)}><Delete/></span>}
            <img src={p.link} alt=""/>
        </div>)}
    </Carousel>
}