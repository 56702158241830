import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {importProjectsThunk} from "../../../store/reducers/CommonReducer";
import ApiService from "../../../api/api";
import Upload from "../../../Icons/Dotts/UploadProject";
import {useLang} from "../../../context/langProvider";
import {getOrder} from "../../../selectors/order-selector";
import InfoUploadExel from "./InfoUploadExel";

const api = new ApiService();

const ImportForm = ({files, getFiles}) => {
    const {getT} = useLang();

    const dispatch = useDispatch();
    const order = useSelector(getOrder)
    const renderFilesName = () => {
        return getT('Загружено') + ' ' + files.length + ' ' + getT('файлов');
    };

    const {error, viyar} = useSelector(state => state.commons.files.uploadClarification);
    const [optionViyar, setOptionViyar] = useState(null);


    const sendFiles = () => dispatch(importProjectsThunk(files, optionViyar, order?.id))

    return <>
        <InfoUploadExel getT={getT}/>

        <form className="add-material upload_file_project_form"
              encType={'multipart/form-data'}
              method={'POST'}
              action={api._baseUrl + '/import/'}
              onSubmit={(event) => {
                  event.preventDefault();
                  sendFiles();
              }}
        >
            <div className="input__wrapper">
                <input type="file"
                       name={'project'}
                       className="input input__file"
                       onChange={(event) => getFiles(event.target.files)}
                       id={'input__file'}
                       multiple
                />
                <label htmlFor={'input__file'} className="input__file-button">
                        <span className="input__file-icon-wrapper">
                            <Upload/>
                        </span>
                    {files.length > 0 ?
                        <span className="input__file-button-text">{renderFilesName()}</span>
                        :
                        <span className="input__file-button-text">{getT('Выберите файл(ы)')}</span>
                    }
                </label>
            </div>
            {error && viyar && Object.keys(viyar).length != 0 && <div className={'input__wrapper'}>
                <label className={'label'} htmlFor="" style={{textAlign: 'left'}}>{error}</label>
                <select className="form-input" id=""
                        value={optionViyar}
                        onChange={(e) => setOptionViyar(e.target.value)}
                >
                    <option value={null} disabled={'disabled'} selected={'selected'}>{getT('Выбрать')}</option>
                    {Object.entries(viyar).map(([key, value]) => {
                        return <option value={key}>{value}</option>
                    })}
                </select>
            </div>}
            <p>
                {getT('Для загрузки подходят файлы проектов .project. Можно импортировать .csv, .xls, .zip заданного формата.')}
                {/*{getT('Для загрузки подходят файлы проектов .ifp или архивы, содержащие такие файлы. Можно загружать одновременно один или несколько проектов. Все загруженные проекты будут сведены в один')}*/}
            </p>
            <p>
                {getT("Если необходимо соединить, импортировать проекты в формате iFurn.pro (скачать проект - zip файл), это надо делать отдельной операцией. Можно одновременно импортировать несколько файлов нашего формата.")}

            </p>
            <input className="create-project" type="submit" value={getT('Загрузить')}/>
        </form>
    </>

}

export default ImportForm;