import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getNewsThunk} from "../../store/reducers/CommonReducer";
import Pagination from "../../components/Pagination/Pagination";
import NewItem from "./component/News/NewItem";

const NewsContainer = ({getT, news_state}) => {
    const url_img = process.env.REACT_APP_API_URL_IMG;
    const dispatch = useDispatch();
    const handlerChangePage = (page) => {

        dispatch(getNewsThunk(page));
    };
    return (
        <div className={"section-news settings-section w-100 "}>
            <h2 className="mb-2">{getT("Новости")}</h2>
            <div className="news-wrap">
                {news_state.news.map(new_item => <NewItem item={new_item} url_img={url_img} getT={getT}/>)}
            </div>
            <Pagination count={news_state.pages} changeHandler={handlerChangePage}/>
        </div>
    );
};

export default NewsContainer;