export const SEARCH_GOODS_TOGGLE_OPEN_PAGE = 'SEARCH_GOODS/TOGGLE_OPEN_PAGE';
export const SEARCH_GOODS_RESULT_OF_FILTER_DATA = 'SEARCH_GOODS/RESULT_OF_FILTER_DATA';

export const SEARCH_GOODS_FILTER_FORM_UPDATE= 'SEARCH_GOODS/FILTER_FORM_UPDATE';


/**
 * searchGoodsToggleOpenPageAC.
 *
 * @param {boolean}  isOpen-
 * @returns  {object}  -
 */
export const searchGoodsToggleOpenPageAC = (isOpen) => {
    return {
        type: SEARCH_GOODS_TOGGLE_OPEN_PAGE, payload: isOpen
    }
}

/**
 * searchGoodsToggleOpenPageAC.
 *
 * @param {object}  payload-
 * @returns  {object}  -
 */
export const searchGoodsResultOfFilterDataAC = (payload) => {
    return {
        type: SEARCH_GOODS_RESULT_OF_FILTER_DATA, payload: payload
    }
}
/**
 * searchGoodsToggleOpenPageAC.
 *
 * @param {object}  payload-
 * @returns  {object}  -
 */
export const searchGoodsFilterFormUpdateAC = (payload) => {
    return {
        type: SEARCH_GOODS_FILTER_FORM_UPDATE, payload: payload
    }
}
