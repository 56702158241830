import React from 'react';

const ChoiceGroup = ({selectGroup, group, onChange, getT}) => {
    if (!Array.isArray(group) || !onChange || !getT) return false
    return (
        <div>
            <div className="form-field">
                <label htmlFor="" className={'label'}>{getT('Выберите размер')}</label>
                <select className={'form-input'}
                        value={selectGroup} onChange={(e) => {
                    onChange({key: 'selectGroup', value: e.target?.value})
                }}>
                    <option value={null} disabled={true} selected={true}> {getT('Выберите')}</option>
                    {group?.filter(part => part?.length >= 2)?.map((item, index) => {
                        let z = item.reduce((current, next) => Math.round((Number(current?.z ?? current) + Number(next?.z))*100)/100, 0)
                        return <option
                            value={index}>{`${item?.[0]?.x1 || item?.[0]?.x || item?.[0]?.x2} X ${item?.[0]?.y1 || item?.[0]?.y || item?.[0]?.y2} [z: ${z}]`} {item?.length}{getT('шт')}</option>
                    })}
                </select>
            </div>
        </div>
    );
};

export default ChoiceGroup;