import React from "react";
import Modal from "../BasicModal";
import Container from "../../Container/Container";
import './UploadProjectModal.css';
import Upload from "../../../Icons/Dotts/UploadProject";
import {useLang} from "../../../context/langProvider";



const UploadProjectModal = (props) => {
    const {getT} = useLang();
    const renderFilesName = () => {
        return getT('Загружено') + ' ' + props.file.length + ' ' + getT('файлов');
    }

    return (
        <Modal title={getT('Загрузить проект')} open={props.isOpen} onClose={props.toggleOpen}>
            <Container>
                <form className="add-material upload_file_project_form" onSubmit={(event) => {
                    event.preventDefault();
                    props.sendFileData();
                }}>
                    <div className="input__wrapper">
                        <input type="file"
                               name={'file'}
                               className="input input__file"
                               onChange={(event) => props.getFiles(event.target.files)}
                               id={'input__file'}
                               multiple
                        />
                        <label htmlFor={'input__file'} className="input__file-button">
                        <span className="input__file-icon-wrapper">
                            <Upload/>
                        </span>
                        {props.file ?
                            <span className="input__file-button-text">{renderFilesName()}</span>
                            :
                            <span className="input__file-button-text">{getT('Выберите файл(ы)')}</span>
                        }
                        </label>
                    </div>
                    <p>
                        {getT('Для загрузки подходят файлы проектов .ifp, архив .ZIP, содержащий файлы .ifp')}
                        {/*{getT('Для загрузки подходят файлы проектов .ifp или архивы, содержащие такие файлы. Можно загружать одновременно один или несколько проектов. Все загруженные проекты будут сведены в один')}*/}
                    </p>
                    <input className="create-project" type="submit" value={getT('Загрузить')} />
                </form>
            </Container>

        </Modal>
    );
};

export default UploadProjectModal;

