import React from "react";
import IconXlsx from "../../../Icons/Actions/IconXlsx";

const InfoUploadExel = ({getT}) => {
    let lang = localStorage.getItem('lang');
    let versionLan = lang !== 'ru' && lang !== 'ua' ? 'en' : lang;

    return (
        <div className={'info-exel'} style={{width: '100%', textAlign: 'right'}}>
            <a style={{margin: '0px 6px', display: 'inline-block', color: 'green'}}
               href={`${window.location.origin}/exel/import_${versionLan}.xlsx`}><IconXlsx/></a>

            <div className="info-exel-content"
                 style={{maxWidth: '100%', width: 'calc(100% - 45px)', textAlign: 'left'}}>
                <p>  {getT('Если нужно импортировать из MS Excel, используйте шаблон файла для импорта.')}</p>

            </div>

        </div>
    );
};

export default InfoUploadExel;