import React from 'react';
import {connect} from "react-redux";
import Cabinet from "./Cabinet";
import {getAccountOrdersThunk, logoutThunk} from "../../store/reducers/AuthReducer";
import {getGlobalGodeUser} from "../../store/reducers/SettingsReducer";


class CabinetContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        // this.createOrder = this.createOrder.bind(this)

    }

    componentDidMount() {
        this.props.setGlobalUserId();
        this.props.getOrders();
    }

    // createOrder() {
    //     this.props.createOrder(this.props.user.id);
    // }


    render() {
        return <Cabinet logout={this.props.logout}
                        user={this.props.user}
                        orders={this.props.orders}
                        getOrders={this.props.getOrders}
                        // createOrder={this.createOrder}
                        userId={this.props.userId}
                        manager={this.props.manager}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        manager: state.auth.manager,
        orders: state.auth.orders,
        userId: state.settingFirm.userId
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logoutThunk()),
        getOrders: (page) => dispatch(getAccountOrdersThunk(page)),
        // createOrder: (client, firm) => dispatch(createOrderProjectDataThunk(client, firm)),
        setGlobalUserId: () => dispatch(getGlobalGodeUser()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CabinetContainer);
