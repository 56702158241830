import React, {useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import MemoryDTOrderModal from "./MemoryDTOrderModal";
import {changeMemoryDTOrderProjectModalValue} from "../../store/reducers/ModalReducer";
import {compareCurrentOrdersThunk, saveOrderProjectDataThunk} from "../../store/reducers/OrderReducer";
import {compareOrders, onSetDTMemory} from "../../helpers/helpers";
import {useLang} from "../../context/langProvider";
import {getOrder} from "../../selectors/order-selector";
// Helper functions for localStorage
const getOrderFromLocalStorage = (id) => JSON.parse(localStorage.getItem(id));
// const setOrderToLocalStorage = (id, order) => localStorage.setItem(id, JSON.stringify(order));

const MemoryDataOrderContainer = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.modal.memoryDateOrderModal.isOpen);
    const isOpenModal = useRef(false)
    // const isChange

    const order = useSelector(getOrder);
    const hasCheckedStorage = useRef(false);
    const checkDtStorage = useCallback((store_order) => {
        if (!store_order || Number(store_order?.status) !== 1) {
            localStorage.removeItem(store_order?.id)
            return
        }
        const dtStorage = getOrderFromLocalStorage(store_order.id);
        if (!dtStorage) {
            onSetDTMemory(store_order);
            return;
        }
        let date_order = new Date(store_order?.date);
        let date_storage = new Date(dtStorage?.date);
        // const diffMilliseconds = date_storage.getTime() - date_order.getTime();


        let order_storage = dtStorage.order
        try {
            if (!compareOrders(order_storage, store_order) && !isOpenModal.current) {
                isOpenModal.current= true;
                dispatch(changeMemoryDTOrderProjectModalValue("isOpen", true));
            }
        } catch (e) {
            console.log('check dt', e.message);
        }
    }, [dispatch]);


    const onSaveProject = useCallback((withSet, memoryDT) => {
        localStorage.removeItem('calculate');
        dispatch(saveOrderProjectDataThunk(withSet, memoryDT))
    }, [dispatch, order]);

    useEffect(() => {
        let intervalSetOrderLs = setInterval(() => {
            if (order && !isOpen) {
                onSetDTMemory(order, !isOpen)
            }
        }, 5000);

        return () => clearInterval(intervalSetOrderLs)
    }, [order, isOpen]);
    useEffect(() => {
        if (!order) return
        if (Object.keys(order)?.length && !hasCheckedStorage.current) {
            checkDtStorage(order);
            hasCheckedStorage.current = true; // Mark as checked

        }
    }, [order]);


    useEffect(() => {
        const interval = setInterval(() => dispatch(compareCurrentOrdersThunk()), 15000);
        return () => clearInterval(interval);
    }, [dispatch]);

    return (
        isOpen && <MemoryDTOrderModal
            isOpen={isOpen}
            getT={getT}
            key={'MemoryDTOrderModal'}
            orderDate={order?.date}
            stageLSDate={getOrderFromLocalStorage(order?.id)?.date}
            onSaveProject={onSaveProject}
        />
    );
}

export default MemoryDataOrderContainer;
