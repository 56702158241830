import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getLocalLang} from "../../../selectors/settings-selector";
import {onToggleCellModal} from "../../../store/reducers/ModalReducer";
import CellForm from "./CellForm";
import {
    actionsCellsLeftovers,
    onDeleteCellStorage,
    onGetCellsStoragesDt,
    onSetCellStorageFullInfo,
    setEditCellForm
} from "../../../store/reducers/StorageCellsLeftovers";
import {getCellInFullInfo, getCellsStorage} from "../../../selectors/cells-leftovers-selector";
import Delete from "../../../Icons/Actions/Delete";
import Edit from "../../../Icons/TableActions/Edit";
import ShowOrder from "../../../Icons/ShowOrder";
import CellInfo from "./CellInfo";

const Cells = ({getT, firmId}) => {

    const dispath = useDispatch();


    const cellsDT = useSelector(getCellsStorage)
    // const idFirm = firmId;
    // const idFirm = useSelector(getIdFirm)

    const lang = useSelector(getLocalLang)
    const onUpdateCells = (cell) => {
        const initialValue = setEditCellForm(cell);
        dispath(actionsCellsLeftovers.setInitialValuesForm({form: 'cellsForm', initialValue: initialValue}))
        dispath(onToggleCellModal({key: 'isOpen', dt: true}))
    }
    const onDeleteCell = (id) => {
        dispath(onDeleteCellStorage(id));
    }
    const onToggleModal = (dt) => {
        dispath(onToggleCellModal({key: 'isOpen', dt: dt}));
        dispath(actionsCellsLeftovers.onResetFormValue({form: 'cellsForm'}))
    }
    const onToggleInfoModal = (dt) => {
        dispath(onToggleCellModal({key: 'info', dt: {isOpen: dt, title: null}}))
    }
    const onFullCellInfo = (id, name) => {
        dispath(onSetCellStorageFullInfo(id, name));
    }

    const cellIn = useSelector(getCellInFullInfo);

    const onSetCellInDT = ({isOpen, dt}) => {
        dispath(actionsCellsLeftovers.onSetIsCellFullInfo({isOpen: isOpen, dt}));
    }
    const [isOpen, isSetOpen] = useState(false);
    useEffect(() => {
        dispath(onGetCellsStoragesDt())
    }, [])
    return <div className={"settings-section"}>
        <div className="section-header">
            <h2 className="title">{getT('Ячейки хранения')}</h2>
            <button className="btn"
                    onClick={() => onToggleModal(true)}>{getT('Создать')}</button>

        </div>
        {Array.isArray(cellsDT) && cellsDT.length >= 1 ? <>


                <div className="table">
                    <div className="overflow-scrolled">
                        <table className={'table-wrapper main-parts-table'}>
                            <thead>
                            <th>{getT('Название ячейки')}</th>
                            <th>{getT('Размеры')}</th>
                            <th>{getT("Перечень остатков на складе")}</th>

                            <th>{getT('Действие')}</th>

                            </thead>
                            {cellsDT.map((cell, idx) => {
                                return <>
                                    <tr key={idx}>
                                        <td>{cell.name}</td>
                                        <td>x:{cell.x} y:{cell.y} z:{cell.z}</td>
                                        <td>
                                            {cell.hasOwnProperty('in') && Array.isArray(cell.in) && cell.in.length >= 1 &&
                                            <div className="" onClick={() => {
                                                let data = Number(cellIn?.isOpen) == Number(cell.stock_cell_id) ? [] : cell.in;
                                                let id = Number(cellIn?.isOpen) == Number(cell.stock_cell_id) ? null : cell.stock_cell_id;

                                                onSetCellInDT({isOpen: id, dt: data})
                                            }
                                            }>
                                                {Number(cellIn?.isOpen) === Number(cell.stock_cell_id) ? <Delete/>
                                                    : <ShowOrder/>
                                                }
                                            </div>
                                            }
                                        </td>
                                        <td>
                                            <div className="settings-actions">
                                                {/*//TODO:: сделать просмотр полной информации по ячейкам*/}
                                                <span className={'info '}
                                                      onClick={() => onFullCellInfo(cell.stock_cell_id, cell.name)}> <ShowOrder/></span>
                                                <span className="edit" onClick={() => onUpdateCells(cell)}>
                                        <Edit/>
                                    </span>
                                                <span className={'delete'} onClick={() => onDeleteCell(cell.stock_cell_id)}>
                                        <Delete/>
                                    </span>
                                            </div>
                                        </td>

                                    </tr>
                                    {Number(cellIn?.isOpen) === Number(cell.stock_cell_id) && Array.isArray(cellIn.dt) &&
                                    <tr>
                                        <td colspan="12"><LeftoversInStock getT={getT} dt={cellIn.dt} lang={lang}/></td>
                                    </tr>
                                    }
                                </>
                            })}
                        </table>
                    </div>
                </div>
            </>
            : <h3 style={{textAlign: 'center'}}>{getT('Ячейки хранения отсутствуют')}</h3>}

        <CellForm onToggleModal={onToggleModal} getT={getT}/>
        <CellInfo onToggleInfoModal={onToggleInfoModal} getT={getT}/>
    </div>
}

const LeftoversInStock = ({getT, dt, lang}) => {
    const mainStyle = {
        background: 'rgba(66, 98, 255, 0.1)',
        width: 'max-content',
        maxWidth: '100%',
        margin: '20px auto'
    }
    return <table className={'table-wrapper main-parts-table'}
                  style={mainStyle}
    >
        <thead>
        <th>{getT('Материал')}</th>
        <th>{getT('Размеры')} (мм)</th>
        <th>{getT('Количество')}</th>
        <th>{getT('Остаток')}</th>


        </thead>

        {dt.map(d => {
            return <tr>
                <td>{d.goods.name[lang]}</td>
                <td>{d.x} x {d.y}</td>
                <td>{d.count_goods_stock}</td>
                <td>{d.area_goods_stock} / {d.goods['unit'][lang]}</td>
            </tr>
        })}


    </table>
}
export default Cells;