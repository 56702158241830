
export const ORDER_PART_CHANGE_PROCESSING_EDGE = "ORDER_PART_CHANGE_PROCESSING_EDGE"
export const ORDER_PART_CHANGE_PROCESSING_EDGE_SIDE_IN_PART = "ORDER_PART_CHANGE_PROCESSING_EDGE_SIDE_IN_PART"
export const ORDER_PART_CHANGE_PROCESSING_EDGE_HANDLER = "ORDER_PART_CHANGE_PROCESSING_EDGE_HANDLER"

export const orderPartChangeProcessingEdgeAC = ({side, part_id, change_data})=>{
    return {
        type: ORDER_PART_CHANGE_PROCESSING_EDGE,
        payload: {side, change_data, part_id}
    }
}
export const orderPartChangeProcessingEdgeSideInfPartAC = (part)=>{
    return {
        type: ORDER_PART_CHANGE_PROCESSING_EDGE_SIDE_IN_PART,
        payload: {part: part}
    }
}
export const orderPartChangeProcessingEdgeHandlerAC = ({side, part_id, change_data})=>{
    return {
        type: ORDER_PART_CHANGE_PROCESSING_EDGE_HANDLER,
        payload: {side, change_data, part_id}
    }
}