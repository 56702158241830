function Danger(props) {

  const hasErrors = props.hasErrors;

  return (
    <svg
      width={52}
      height={48}
      viewBox="0 0 52 48"
      fill={hasErrors ? '#F72121' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4a4 4 0 014-4h44a4 4 0 014 4v40a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
        fill={hasErrors ? '#F72121' : '#FFFFFF'}
      />
      <path
        d="M24.289 15.86L15.819 30a2 2 0 001.71 3h16.94a2 2 0 001.71-3l-8.47-14.14a2 2 0 00-3.42 0v0zM26 21v4M26 29h.01"
        stroke={hasErrors ? '#FFFFFF' : '#F72121'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Danger;
