import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getRemainsModal} from "../../../selectors/cells-leftovers-selector";
import {onToggleRemainModal} from "../../../store/reducers/ModalReducer";
import {onRemoveRemain} from "../../../store/reducers/StorageCellsLeftovers";
import Modal from "../BasicModal";
import Container from "../../Container";
import {getIdFirm, getLocalLang} from "../../../selectors/settings-selector";
import Delete from "../../../Icons/Actions/Delete";
import {isAccessEdit} from "../../../pages/SettingsFirm/help";

const RemainsList = ({getT}) => {
    const {listView} = useSelector(getRemainsModal);
    const dispatch = useDispatch();
    const onToggleModal = () => {
        dispatch(onToggleRemainModal({key: 'listView', dt: {isOpen: false, goods: null}}));
    }
    const onDeleteRemains = (goods_stock_id) => {
        dispatch(onRemoveRemain({goods_id: listView.goods.goods_id, goods_stock_id}));
    }

    const lang = useSelector(getLocalLang)
    const title = listView.goods && listView.goods.hasOwnProperty('translate') ? listView.goods['translate'][lang] : '';
    const idFirm = useSelector(getIdFirm);
    return <>
        <div className="container-stock">
            <Modal title={getT('Остатки') + "  " + title} open={listView.isOpen} onClose={() => onToggleModal()}>
                <Container>
                    {listView.goods?.hasOwnProperty('goods_stock') && Array.isArray(listView.goods.goods_stock) && listView.goods.goods_stock.length >= 1 ? (
                        <table className={"stock-table"}>
                            <thead>
                            <tr>
                                {/*<td>{getT("Название")}</td>*/}
                                <td>{getT("Размер (мм)")}</td>
                                <td>{getT("Кол-во")}</td>
                                <td>{getT("Дата")}</td>
                                <td>{getT("Действие")}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {listView.goods.goods_stock.map(l => {
                                return (<tr>
                                    {/*<td>[{l.goods_id}]{l.name}</td>*/}
                                    <td>
                                        <span title={'x'}> {l.x}</span>
                                        <strong> x </strong>
                                        <span title={'y'}> {l.y}</span>
                                        {l.z && <strong> x </strong>}
                                        <span title={'z'}> {l.z}</span>

                                    </td>
                                    <td>{l.count}</td>
                                    <td>{l.date_in}</td>
                                    <td>
                                        {isAccessEdit(idFirm) && (l.order == null || l.order == '') && <span className={'cursor-pointer'}
                                                                                     onClick={() => onDeleteRemains(l.goods_stock_id)}>
                                            <Delete/>
                                        </span>}
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    ) : (
                        <h3 style={{margin: "0 auto"}}>{getT("Остатков на складе нет")}</h3>
                    )}

                </Container>
            </Modal>
        </div>
    </>
}

export default RemainsList;