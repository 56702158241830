import material_table from "./material_table";
import {LS_LANG} from "../../constants";
import band_table from "./band_table";
import parts_table from "./parts_table";
import parts_rest_table from "./parts_rest_table";
import getT from "../getT";

export default function ({order, full, goods_service_for_part_by_firm}) {
    let material_filtered = order?.material?.filter(material => order?.part?.filter(part => Number(part.material) === Number(material.id))?.length)
    let render = material_filtered?.map((material, index) => {
        let parts_include = order?.part?.filter(part => Number(part.material) === Number(material.id))?.map(part=>{
            let find_product = order?.product?.find(prod=> Number(prod?.id) === Number(part?.product));
            if(find_product && part?.product) {
                return {
                    ...part,
                    goods_service_added_firm: part?.goods_service_added_firm?.map(item=>{
                        let find_goods = goods_service_for_part_by_firm?.find(e=> Number(e?.id) === Number(item?.id)) || item;
                        return{
                            ...item,
                            price: find_goods?.price,
                            currency: find_goods?.currency,
                            currency_name: find_goods?.currency_name,
                        }
                    }),
                    count: Number(part.count) * Number(find_product.count)
                }
            }
            return {...part, goods_service_added_firm: part?.goods_service_added_firm?.map(item=>{
                    let find_goods = goods_service_for_part_by_firm?.find(e=> Number(e?.id) === Number(item?.id)) || item;
                    return{
                        ...item,
                        price: find_goods?.price,
                        currency: find_goods?.currency,
                        currency_name: find_goods?.currency_name,
                    }
                })}
        })
        let bands_include = order?.band?.filter(band => parts_include.some(part => Object.entries(part.edge).some(([_, values]) => Number(band.id) === Number(values.db_id))))
            ?.sort((a, b)=> Number(a.id) - Number(b.id))
            ?.map(band=>{
            let find_calc_band = order?.band_calculate?.find(item=> Number(item.id) === Number(band.id))
            if(find_calc_band && full){
                return {
                    ...band, band_count: find_calc_band?.count
                }
            }
            return {
                ...band, band_count: 0
            }
        })
        let cutting_cards_include = order?.cutting_card?.cards?.filter(card => Number(material.id) === Number(card.goods_id))
        let material_group = cutting_cards_include?.reduce((acc, item) => {
            let key = `item_${item.goods_id}_${item.x}_${item.y}`
            let lang = localStorage.getItem(LS_LANG)
            if (!acc.hasOwnProperty(key)) {
                acc[key] = {
                    goods_id: item.goods_id,
                    x: item.x, y: item.y, count: Number(item.count), name: material?.translate?.[lang] ?? material?.name
                }
            } else {
                acc[key] = {
                    ...acc[key], count: Number(acc[key]?.count) + Number(item?.count)
                }
            }

            return acc
        }, {});

        let material_list = full ? [...Object.values(material_group)] : [material]
        let parts_include_reset = cutting_cards_include?.reduce((acc, card) => {
            let reset_parts = card?.cut_card?.filter(item => item.part_id === 'rest')
            if (reset_parts?.length) acc.push(reset_parts);
            return acc
        }, [])?.flat();
        if(!material_list) return `
        <p>${getT("Не удалось создать спецификацию")}</p>
        `
        return `
        <div style="font-size: 0;  page-break-inside: avoid;">
            ${material_table({material, index, material_list, full: full})}
            ${bands_include?.length ? band_table({bands: bands_include, full}) : ''}
            ${parts_include?.length ? parts_table({bands: bands_include, parts: parts_include}) : ''}
            ${full && parts_include_reset?.length ? parts_rest_table({parts: parts_include_reset}) : ''}
         </div>   
        `
    })?.join('');

    return render
}