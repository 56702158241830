import React, {useEffect, useMemo} from 'react';
import {useFormik} from "formik";
import InputForm from "../../../components/InputFields/InputForm/InputForm";
import {findKeyByCodePhone} from "../../../helpers/helpersListCountryCode";
import FieldPhone from "./ui/FieldPhone";
import {SELECT_OPTIONS_PHONE} from "../../../constants";
import SelectForm from "../../../components/InputFields/SelectForm";
import {getCalculateAmountAndVat, getCalculateVat} from "../../ShopingCard/shoping_card_helper";

const Form = ({
                  handlerSubmit,
                  initialForm,
                  getT,
                  setting_phone_code_country,
                  setting_hide_rus,
                  stores,
                  packSamo,
                  pack, getTotal, currency_code,
                  setting_production_calc_confirm_only_one_phone,
                  setting_production_hide_point_sales_confirmation, production_vat,
                  production_vat_display_short
              }) => {
    useEffect(() => {
        // Set initial values only once when actualData changes
        formik.setValues(initialForm);
    }, [initialForm]); // Trigger effect whenever actualData changes
    const formik = useFormik({
        initialValues:
            {
                name: initialForm?.name ?? '',
                email: initialForm?.email ?? '',
                phone: initialForm?.phone ?? '',
                phone1: initialForm?.phone1 ?? '',
                phone2: initialForm?.phone2 ?? '',
                productionPoint: initialForm?.productionPoint,
                salePoint: initialForm?.salePoint,
                deliveryMethod: initialForm?.deliveryMethod,
                deliveryPoint_select: initialForm?.deliveryPoint_select,
                objectAdress: initialForm?.objectAdress,
                pack: initialForm?.pack,
                comment: '',
                region: initialForm?.region,
                phone_code: initialForm?.phone_code,
            },
        onSubmit: (values) => {
            handlerSubmit(values)
        }
    });
    const un_connect_points_confirm = useMemo(() => {
        if (!setting_production_hide_point_sales_confirmation) return false
        return !(formik.values.productionPoint && formik.values.salePoint)
    }, [setting_production_hide_point_sales_confirmation, formik])
    const onChangeTelHandler = (key_phone) => (result) => {
        formik.handleChange({
            target: {name: key_phone, value: result},
        });

    }
    const handlerChangePhoneCode = (reuslt) => {
        const get_regions = findKeyByCodePhone(reuslt, SELECT_OPTIONS_PHONE)
        formik.handleChange({
            target: {name: 'phone_code', value: reuslt},
        });
        formik.handleChange({
            target: {name: 'region', value: get_regions},
        });
    }
    const packing_type = useMemo(() => {
        return formik.values.deliveryMethod === "Самовывоз" || formik.values.deliveryMethod === getT('Самовывоз') ? packSamo : (pack?.['delivery'] ?? [])
    }, [formik.values.deliveryMethod]);

    const total_vat = useMemo(() => {
        let total = getTotal(formik.values.pack)
        return getCalculateVat(total, production_vat)
    }, [formik, production_vat, getTotal]);
    const amount_total_vat = useMemo(() => {
        let total = getTotal(formik.values.pack)
        return getCalculateAmountAndVat(total, total_vat);
    }, [total_vat, formik, getTotal]);
    return (
        <form className="ordering-form" id={"order-confirm-form-main"} onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-sm-4">
                    <InputForm label={getT("Ф.И.О")}
                               type={"text"}
                               value={formik.values.name}
                               onChange={formik.handleChange}
                               name={'name'}
                               required={true}
                    />
                </div>
                <div className="col-sm-4">
                    <InputForm label={getT("Email")}
                               type={"email"}
                               value={formik.values.email}
                               onChange={formik.handleChange}
                               name={'email'}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <FieldPhone onChange={onChangeTelHandler('phone')} label={getT('Телефон')}
                                onChangePhoneCode={handlerChangePhoneCode}
                                value_phone_code={formik.values.phone_code}
                                setting_hide_code_country={!!(Number(setting_hide_rus)) ? 'ru' : null}
                                setting_code_country={setting_phone_code_country}
                                value={formik.values.phone} autoComplete={"off"} required={true}
                    />
                </div>
                {!setting_production_calc_confirm_only_one_phone && <>
                    <div className="col-sm-4">
                        <FieldPhone onChange={onChangeTelHandler('phone1')} label={getT('Телефон для смс')}
                                    onChangePhoneCode={handlerChangePhoneCode}
                                    value_phone_code={formik.values.phone_code}
                                    setting_hide_code_country={!!(Number(setting_hide_rus)) ? 'ru' : null}
                                    setting_code_country={setting_phone_code_country}
                                    value={formik.values.phone1} autoComplete={"off"}
                        />
                    </div>
                    <div className="col-sm-4">
                        <FieldPhone onChange={onChangeTelHandler('phone2')}
                                    label={getT('Телефон для вопросов по заказу')}
                                    onChangePhoneCode={handlerChangePhoneCode}
                                    value_phone_code={formik.values.phone_code}
                                    setting_hide_code_country={!!(Number(setting_hide_rus)) ? 'ru' : null}
                                    setting_code_country={setting_phone_code_country}
                                    value={formik.values.phone2} autoComplete={"off"}
                        />
                    </div>
                </>}
                {!setting_production_hide_point_sales_confirmation && <>
                    <div className="col-sm-4">
                        <SelectForm label={getT("Точка производства")}
                                    name={'productionPoint'}
                                    value={formik.values.productionPoint}
                                    onChange={formik.handleChange}
                                    el={stores.filter(e => Number(e.production) === 1).map(e => {
                                        return {
                                            id: e.firm_adress_id,
                                            name: e.name
                                        };
                                    })}
                                    required={true}
                        />
                    </div>
                    <div className="col-sm-4">
                        <SelectForm label={getT("Точка продажи")}
                                    name={'salePoint'}
                                    value={formik.values.salePoint}
                                    onChange={formik.handleChange}
                                    el={stores.filter(e => Number(e.sale) === 1).map(e => {
                                        let name = e.name ?? e.adress;
                                        return {
                                            id: e.firm_adress_id,
                                            name: name,
                                        };
                                    })}
                                    required={true}
                        />
                    </div>
                </>}
                <div className="col-sm-4 order-form-method-delivery-container">
                    <SelectForm label={getT("Способ доставки")}
                                name={'deliveryMethod'}
                                value={formik.values.deliveryMethod}
                                onChange={(event) => {

                                    formik.handleChange({
                                        target: {name: "pack", value: []},
                                    });
                                    formik.handleChange({
                                        target: {name: "deliveryMethod", value: event.target.value},
                                    });
                                }
                                }
                                el={[
                                    {id: getT("Самовывоз"), name: getT("Самовывоз")},
                                    {id: getT("Доставка на объект"), name: getT("Доставка на объект")}
                                ]}
                                required={true}
                    />
                    {formik.values.deliveryMethod === getT('Самовывоз') &&
                        <SelectForm label={getT("Точка выдачи")}
                                    name={'deliveryPoint_select'}
                                    value={formik.values.deliveryPoint_select}
                            // defaultValue={for.deliveryPoint_select}
                                    onChange={formik.handleChange}
                                    el={stores.filter(e => Number(e.stock) === 1).map(e => {
                                        return {
                                            id: e.firm_adress_id,
                                            name: e.name ?? e.adress
                                        };
                                    })}
                                    required={true}
                        />
                    }
                    {formik.values.deliveryMethod !== getT('Самовывоз') &&
                        <InputForm
                            label={getT("Адрес для доставки на объект")}
                            name={'objectAdress'}
                            type={"text"}
                            value={formik.values.objectAdress}
                            onChange={formik.handleChange}
                            required={true}
                        />
                    }

                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 ">
                    <SelectForm
                        style={{borderColor: "#4262ff7d"}}
                        label={getT('Тип упаковки')}
                        el={packing_type?.map(s => ({id: s.service_id, name: s.name})) ?? []}
                        value={!!formik.values.pack?.length ? formik.values?.pack?.[0]?.service_id : ''}
                        onChange={(event) => {
                            let id = event.target.value;
                            let selected = packing_type?.filter(s => {
                                if ((s.service_id).toString() === id) {
                                    return s;
                                }
                            });
                            formik.handleChange({
                                target: {name: "pack", value: selected},
                            });
                        }
                        }
                        required={true}
                    />
                    <p style={{fontSize: '15px', margin: '8px 0px', fontWeight: '500'}}>
                        {!!formik.values.pack?.length && formik.values?.pack[0].hasOwnProperty('description') ? formik.values?.pack[0].description : ''}
                    </p>

                </div>
            </div>
            <div className="form-field">
                <label className="label">{getT('Коментарий')}</label>
                <textarea rows="4" className="form-input" name={'comment'}
                          onChange={formik.handleChange}>{formik.values.comment}</textarea>
            </div>
            <div className="ordering-footer">
                {un_connect_points_confirm && <div className="alert-confirm">
                    <p className={"text-danger text-center"}>{getT("Торговые точки отсутствуют, обратитесь к администраторам!")}</p>
                </div>}
                <div className="total-content">
                    <h3 className="content-text">{getT('Итого за все')}:</h3>
                    {/*//::TODO добавить текущую валюту*/}
                    <span className="content-amount">
                        {getTotal(formik.values.pack)}
                        {!!production_vat ?
                            production_vat_display_short ?
                                <span
                                    className={"amount-total-vat"}> {currency_code} / {amount_total_vat} {currency_code} {getT("с НДС")} </span>
                                :
                                <> + {total_vat} {getT("НДС")} = {amount_total_vat} {currency_code} </> : currency_code}
 </span>
                    <button className={`content-btn ${un_connect_points_confirm ? 'disabled' : ''}`}
                            type={"submit"}>{getT('Оформить заказ')}</button>
                </div>
            </div>
        </form>
    );
};

export default Form;