import ApiNewService from "../../api/apiNew";
import {
    changeAlertStatusAC,
    changeLoaderMessageAC,
    changeLoaderStatusAC,
    checkErrorsAPI,
    onSetRuleSettingsProject,
} from "./CommonReducer";
import ApiService from "../../api/api";
import {setOrderProjectDataThunk} from "./OrderReducer";
import {clearAllStorage, createFormData, getTranslateMessageAPI, utf8ToB64} from "../../helpers/helpers";
import {
    IFURN_PRO_URL,
    languagesList,
    LS_CLIENT_TOKEN, LS_CUTTING_CARD_TYPE_VERSION_DEFAULT,
    LS_FIRM,
    LS_GIBLAB_DOMEN,
    LS_IMAGES_INTERNAL,
    LS_IMG_API_URL,
    LS_IS_AUTH,
    LS_USER_TOKEN,
    PATHNAME_FOR_REDIRECT,
    URL_REDIRECT_HOSTNAME
} from "../../constants";
import {actions} from "./TranslationReducer";
import {gaEvents} from "../../gaEvents";
import {
    accountMessageChannelsConfirmAC,
    AUTH_ACCOUNT_MESSAGE_CHANNEL,
    AUTH_ACCOUNT_MESSAGE_CHANNEL_CONFIRM,
    AUTH_ACCOUNT_NETWORK_SOCIAL,
    AUTH_LOOKUP,
    AUTH_NETWORK_SOCIAL_LOGIN,
    AUTH_REGISTER_USER,
    authLookupAC,
    registerUserAC
} from "../actions/auth-actions";
import {messageChannelsThunk} from "../thunks/message-channels-thunk";


const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SET_USERS_FIRM = 'SET_USERS_FIRM';
const CHANGE_AUTH_FORM_VALUE = 'CHANGE_AUTH_FORM_VALUE';
const SET_USERS_ACCOUNT_ORDERS = 'SET_USERS_ACCOUNT_ORDERS';
const SET_MANAGER_AUTH_DATA = 'SET_MANAGER_AUTH_DATA';
const DELETE_ORDER_VERSION = 'DELETE_ORDER_VERSION';
const DELETE_ORDER = 'CABINET/DELETE_ORDER';
const AUTH_CLIENT_FIRMS = 'AUTH/CLIENT_FIRMS';


const cabinetType = {
    changeStatus: 'CABINET/CHANGE_PROJECT_STATUS'
}


const newApi = new ApiNewService();
const api = new ApiService();

let initialState = {
    isLogin: false,
    user: {
        id: null, name: null, email: null, phone: null, firm: null, isManager: true
    }, manager: null, userFirms: [], authForm: {
        email: null, password: null, phone: null, isFailed: false, message: null
    }, orders: {
        count: 0, data: []
    },
    client_firms: null,
    message_channel: null,
    confirmAccountChannelsModal: {
        isOpen: false,
        form_data: null
    },
    network_login: null,
    auth_lookup: null,
    registration: {
        isSuccessful: false
    },
    auth_network_social_login: {
        form_data: null,
        response_data: null,
        modal: {
            isOpen: false,
            isShortPassword: false,
            isSendEmail: false
        }
    }
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state, user: {
                    ...action.user
                },
                isLogin: action.isLogin,
                authForm: {
                    ...state, isFailed: false, message: null
                }
            };
        case LOGOUT:
            clearAllStorage();
            return {
                ...state, user: initialState.user, isLogin: false
            };
        case SET_USERS_FIRM:
            return {
                ...state, userFirms: action.firms,
            };
        case SET_USERS_ACCOUNT_ORDERS:
            return {
                ...state, orders: action.orders,
            };
        case SET_MANAGER_AUTH_DATA:
            return {
                ...state, manager: action.manager,
            };
        case CHANGE_AUTH_FORM_VALUE:
            let auth_form = {...state.authForm};
            auth_form[action.input] = action.value;
            return {
                ...state, authForm: auth_form,
            };
        case cabinetType.changeStatus: {
            const updateStatus = [...state.orders.data].map(order => {
                if (Number(order.id) === Number(action.payload.orderId)) {
                    order.status = {
                        id: action.payload.statusId, name: action.payload.title

                    }
                }
                return order
            })
            return {
                ...state, orders: {
                    ...state.orders, data: updateStatus
                }
            }
        }
        case DELETE_ORDER_VERSION: {
            const deleteVersion = action.payload?.version?.map(v => Number(v.version)) ?? []
            const actual = [...state.orders?.data].map(item => {
                if (Number(action.payload.id) === Number(item.id)) {
                    return {
                        ...item, version: item.version.filter(item => !deleteVersion.includes(Number(item.version)))
                    }
                }
                return item;
            });
            return {
                ...state, orders: {
                    ...state.orders, data: actual
                }
            }
        }
        case DELETE_ORDER: {
            const data = state?.orders?.data ?? [];
            const actual = data.filter(item => Number(item.id) !== Number(action.payload.id));
            return {
                ...state, orders: {
                    ...state.orders, data: actual
                }
            }
        }
        case AUTH_CLIENT_FIRMS: {
            return {
                ...state,
                client_firms: action.payload

            }
        }
        case AUTH_ACCOUNT_MESSAGE_CHANNEL: {
            return {
                ...state,
                message_channel: action.payload
            }
        }
        case AUTH_ACCOUNT_MESSAGE_CHANNEL_CONFIRM: {
            return {
                ...state,
                confirmAccountChannelsModal: {
                    ...state.confirmAccountChannelsModal,
                    ...action.payload
                }
            }
        }
        case AUTH_ACCOUNT_NETWORK_SOCIAL: {
            return {
                ...state,
                network_login: action.payload
            }
        }
        case AUTH_LOOKUP: {
            return {
                ...state,
                auth_lookup: action.payload
            }
        }
        case AUTH_REGISTER_USER: {
            return {
                ...state,
                registration: {
                    ...state.registration,
                    ...action.payload
                }
            }
        }
        case AUTH_NETWORK_SOCIAL_LOGIN: {
            return {
                ...state,
                auth_network_social_login: {
                    ...state.auth_network_social_login,
                    ...action.payload
                }
            }
        }
        default:
            return state
    }
}

export const loginAC = (user, isLogin) => {
    return {
        type: LOGIN, user: user, isLogin: isLogin
    }
}
export const logoutAC = () => {
    return {
        type: LOGOUT,
    }
}
export const setUserFirsmAC = (firms) => {
    return {
        type: SET_USERS_FIRM, firms: firms
    }
}
export const setManagerAuthDataAC = (manager) => {
    return {
        type: SET_MANAGER_AUTH_DATA, manager: manager
    }
}
export const changeAuthFormValueAC = (input, value) => {
    return {
        type: CHANGE_AUTH_FORM_VALUE, input: input, value: value
    }
}
export const setAccountsOrdersAC = (orders) => {
    return {
        type: SET_USERS_ACCOUNT_ORDERS, orders: orders
    }
}
export const logoutThunk = () => (dispatch) => {
    newApi.logout().then(res => {
        if (res.result) {
            clearAllStorage();

            dispatch(logoutAC());
            window.location.pathname = '/login'
        }
    })
}

async function loginSystemDispatcher(api_data, dispatch) {
    try {
        const response = await api.getAuth(api_data.email, api_data.password, utf8ToB64(window.location.href));
        if (!response || response?.status === 404) {
            dispatch(changeAlertStatusAC(true, getTranslateMessageAPI('Логин или пароль неверные')));
            return
        }
        if (response?.status === 403) {
            dispatch(changeAlertStatusAC(true, getTranslateMessageAPI('Менеджер не может войти в портал')));
            await api._logout();
            return
        }
        return new Promise((resolve) => resolve(response))
    } catch (e) {
        return new Promise((_, reject) => reject(e))
    }
}

export const authThunkLogin = (api_data) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Проверяем авторизационные данные...')));
        let response = await loginSystemDispatcher(api_data, dispatch);
        if (response && response.status === 'success' && response.token) {


            dispatch(getAuthDataThunk(response?.firm));
            dispatch(changeLoaderStatusAC(false));


            gaEvents.loginIn();
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        console.log('error', e);
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, getTranslateMessageAPI('Логин или пароль неверные')));
    }
}
//remove
export const getAuthThunk = (email, password) => (dispatch) => {
    try {
        clearAllStorage();
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Проверяем авторизационные данные...')));

        api.getAuth(email, password)
            .then(res => {
                if (res && res.status === 'success' && res.token) {
                    //::TODO проверка на наличия  фирмы для аунтификации
                    //::TODO но почему-то если приходят фирмы и токены не записаны после обновления страници мы попадаем в ЛК
                    if (res.firms) {
                        dispatch(setUserFirsmAC(res.firms))
                    } else {
                        localStorage.setItem('ifurn_service_main_user_token', res.token);
                        localStorage.setItem('ifurn_service_main_client_token', res.token);
                        localStorage.setItem(LS_IS_AUTH, true);
                    }
                    gaEvents.loginIn();
                }
                if (res?.status === 403) {
                    dispatch(changeAuthFormValueAC('message', getTranslateMessageAPI('Менеджер не может войти в портал')));
                } else {
                    dispatch(changeAuthFormValueAC('isFailed', true));
                    dispatch(changeAuthFormValueAC('message', getTranslateMessageAPI('Логин или пароль неверные')));
                }
                dispatch(changeLoaderStatusAC(false));
            }).catch(e => {
            console.log(e?.message)
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeAuthFormValueAC('isFailed', true));
            dispatch(changeAuthFormValueAC('message', getTranslateMessageAPI('Логин или пароль неверные')));
        })
    } catch (e) {
        console.log(e, e?.message)
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAuthFormValueAC('isFailed', true));
        dispatch(changeAuthFormValueAC('message', getTranslateMessageAPI('Логин или пароль неверные')));
    }


}


export const setStartRuleSettingProject = (data, dispatch) => {
    let {
        logo: logoUrl = '',
        no_info: infoText = false,
        phone_code: phoneCode = '',
        visa = '',
        lang = '',
        name = '',
        viber: viberQr = '', telegram: telegramQr = '', copyright = '', need_reg = false,
        registration = false, www = '', img: imgApi = 'normal', images_internal = 0,
        firm = '', giblab_domen = null, cutting = 0,
    } = data;
    let lang_code = languagesList.includes(lang?.toLowerCase()) ? lang?.toLowerCase() : '';
    const lang_current = localStorage.getItem('lang')?.toLowerCase()
    localStorage.setItem(LS_IMG_API_URL, imgApi);

    localStorage.setItem(LS_IMAGES_INTERNAL, images_internal);
    localStorage.setItem(LS_GIBLAB_DOMEN, giblab_domen);
    localStorage.setItem(LS_CUTTING_CARD_TYPE_VERSION_DEFAULT, Number(cutting));
    if (!!name) document.title = name;

    if (!!lang_code && lang_code !== lang_current) {
        dispatch(actions.changeLang(lang_code))
        localStorage.setItem('lang', lang_code);
    }

    dispatch(onSetRuleSettingsProject({
        logoUrl: logoUrl,
        infoText: infoText,
        phoneCode: phoneCode,
        visa: visa,
        lang: lang_code,
        name: name,
        viberQr: viberQr,
        telegramQr: telegramQr,
        copyright: copyright,
        need_reg: need_reg,
        registration: registration,
        www: www,
        imgApi: imgApi, firm,
        images_internal: Number(images_internal), giblab_domen: giblab_domen,
        cutting: !!Number(cutting)
    }));

}
export const getAuthDataThunk = (client_firm = '') => async (dispatch, getState) => {
    let params = new URL(document.location).searchParams;
    let firm_params = params.get("firm");

    let is_login_page_firm_code = (window.location.pathname === '/login') && window.history.state?.state?.firm_code;
    const firm_company = client_firm || firm_params || '';
    let isCheckFirm = client_firm || firm_params || is_login_page_firm_code || '';

    localStorage.removeItem(LS_CLIENT_TOKEN);
    dispatch(changeLoaderStatusAC(true));

    try {
        let response_auth = await newApi.userVerification(firm_company);
        if (!response_auth) {
            clearAllStorage();
            if ((URL_REDIRECT_HOSTNAME.includes(window.location.hostname) || window.location.hostname.includes('newgo')) && PATHNAME_FOR_REDIRECT.includes(window.location.pathname)) {
                window.location.href = IFURN_PRO_URL
            }

            dispatch(changeLoaderStatusAC(false));
            return
        }
        let {token, firm, isAuthed, hasClients} = response_auth;
        let isAuthorized = isAuthed && token;
        //** редирект на лендинг
        //  если: не авторизован, перейшол по newgo
        // */

        if ((URL_REDIRECT_HOSTNAME.includes(window.location.hostname) || window.location.hostname.includes('newgo')) && PATHNAME_FOR_REDIRECT.includes(window.location.pathname) && (!isCheckFirm) && !isAuthorized) {
            window.location.href = IFURN_PRO_URL
        }
        setStartRuleSettingProject(response_auth, dispatch);
        if (!isAuthorized) {
            clearAllStorage();
            dispatch(changeLoaderStatusAC(false));
            return
        }
        localStorage.setItem(LS_FIRM, firm);
        if (hasClients) localStorage.setItem(LS_IS_AUTH, true);
        const response_user = await newApi.getAuthData(token);
        if (!response_user) throw new Error('not authorized');
        if (newApi._getErrors()) throw new Error(newApi._getErrors())
        let {id: user_id, auth_client, client_token = ''} = response_user;
        localStorage.setItem(LS_USER_TOKEN, token);
        if (client_token) localStorage.setItem(LS_CLIENT_TOKEN, client_token);

        dispatch(loginAC({
            ...response_user, client_id: response_auth?.client_id
        }, !!`${user_id}`));
        dispatch(changeLoaderStatusAC(false));

        return new Promise(res => res({
            isAuthorized: true,
        }));

    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        clearAllStorage();
        if (e?.message?.trim()?.length) dispatch(changeAlertStatusAC(true, e?.message));
        return new Promise((_res) => _res())

    }

}
export const setManagerAuthDataThunk = (client_id, firm_id, manager_id) => (dispatch, getState) => {

    if (!client_id || !firm_id || !manager_id) return false;
    const authUserFirm = getState().auth?.userFirms;

    localStorage.setItem('ifurn_service_main_user_firm', firm_id);
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Проводим авторизацию менеджера...')));

    newApi.getManagerAuthData(client_id, firm_id, manager_id)
        .then(res => {

            if (res && res.client.id) {
                if (!!authUserFirm?.length || !Array.isArray(authUserFirm)) dispatch(setUserFirsmAC([]));
                dispatch(loginAC(res.client, true));

                /// TODO: Переделать на нормальный map;
                if (res.manager) {
                    res.manager.id = res.manager.user_id;
                }
                dispatch(setManagerAuthDataAC(res.manager));
                if (res.manager) {
                    localStorage.setItem(LS_IS_AUTH, false);
                } else {
                    localStorage.setItem(LS_IS_AUTH, true)
                }
                localStorage.setItem('ifurn_service_main_user_token', res.user_token);
                localStorage.setItem('ifurn_service_main_client_token', res.client_token);
                dispatch(setOrderProjectDataThunk(false, false, null, true));

            } else {
                dispatch(changeAlertStatusAC(true, getTranslateMessageAPI('Вы не прошли проверку авторизации!')));
                clearAllStorage();
            }


            dispatch(changeLoaderStatusAC(false));
        }).catch(e => {
        console.log(e.message)
        dispatch(checkErrorsAPI('', 'api: /authn, method: post, pa: manager_auth'))
    })
};


export const getAccountOrdersThunk = (page, token, firm) => (dispatch, getState) => {
    const filters = getState().commons.ordersFilters;

    if (localStorage.getItem('ifurn_service_main_user_firm') && (localStorage.getItem('ifurn_service_main_client_token') || localStorage.getItem('ifurn_service_main_user_token'))) {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Загружаем заказы клиента...')));
        newApi.getAccountOrders(page, filters)
            .then(orders => {
                if (orders) {
                    dispatch(setAccountsOrdersAC(orders));
                } else {
                    dispatch(changeAlertStatusAC(true, newApi._getErrors()));
                }
                dispatch(changeLoaderStatusAC(false));
            })
            .catch((error) => {
                dispatch(checkErrorsAPI('', 'api:ordersn, method: post, pa:filter '))
            });
    }
}

export const onRedirectAuntifiation = () => dispatch => {
    const query = new URLSearchParams(window.location.search);
    const value = query.get('auth_token');
    if (!!value) {
        newApi.isRedirectAuntification(value).then(res => {
            if (res === "pong") {
                let pathname = !window.location.pathname.includes('/registration') ? window.location.pathname : '';
                window.location.href = window.location.origin + pathname;
            }
        }).catch(e => {
            dispatch(checkErrorsAPI('', 'api:/ping?auth_token=&no_redirect=1, method: get '))
        })
    }
}

//      CABINET FUNCTIONAL

export const actionsCabinet = {
    changeStatusOrder: (payload) => {
        return {
            type: cabinetType.changeStatus, payload
        }
    }
}

export const onCabinetChangeStatusOrder = ({orderId, statusId, userId}) => async (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    const lang = localStorage.getItem('lang');
    try {
        const response = await api._updateCabinetStatusOrder({orderId, userId, statusId});
        if (response.hasOwnProperty('status') && response.status == 'Data updated.') {
            dispatch(actionsCabinet.changeStatusOrder({orderId, statusId, title: response['status_name'][lang] ?? ''}))
        } else if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        }
    } catch (e) {
        dispatch(checkErrorsAPI('', 'api: /orders/{orderId}, method: put'))
    }
    dispatch(changeLoaderStatusAC(false));

}

const deleteActionCabinetOrderVersion = ({id, version}) => {
    return {
        type: DELETE_ORDER_VERSION, payload: {id, version}
    }
}
const deleteActionCabinetOrder = ({id}) => {
    return {
        type: DELETE_ORDER, payload: {id}
    }
}
/**
 * authClintFirmsAC. получить группу фирм где пользователь может дорегистрироваться
 */
const authClintFirmsAC = (payload) => {
    return {
        type: AUTH_CLIENT_FIRMS, payload: payload
    }
}
export const deleteCabinetOrder = ({id, type, code}) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._deleteOrderVersion({type, code});
        if (response.res === 'ok' || response?.[id]) {
            dispatch(deleteActionCabinetOrder({id}));
            if (response?.data?.[id] === 'no_details' || response?.[id] === 'no_details') {
                // dispatch(changeAlertStatusAC(true, getTranslateMessageAPI('В это заказе не было деталей!')))
            }
            // dispatch(changeAlertStatusAC(true, getTranslateMessageAPI('Заказ успешно удален!')))
            dispatch(changeLoaderStatusAC(false));
        } else if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
            dispatch(changeLoaderStatusAC(false));
        }
    } catch (e) {
        console.log(e.message)
        dispatch(checkErrorsAPI('', 'api: /deleteorders/${type}/${code}/${v}, method: get'))
    }
    dispatch(changeLoaderStatusAC(false));
}
export const deleteCabinetOrderVersion = ({id, type, code, v}) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._deleteOrderVersion({type, code, v});

        const transformResponse = response?.data?.filter(item => {
            if (item.db && item.ok === 1) {
                return item
            }
        }).map(item => item?.db_arr) ?? [response?.db_arr];
        if (Number(response?.ok) === 1) {
            dispatch(deleteActionCabinetOrderVersion({id, version: transformResponse}));

        } else if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        }

    } catch (e) {
        console.log(e.message)
        dispatch(checkErrorsAPI('', 'api: /deleteorders/${type}/${code}/${v}, method: get'))
    }
    dispatch(changeLoaderStatusAC(false));
}


export const accountUpdateUserDataThunk = (api_data) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const update_response = await api._authUpdateUserData({...api_data, type_input: "part", active: 1});
        dispatch(changeLoaderStatusAC(false));
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
            return
        }
        let firm_id = localStorage.getItem('ifurn_service_main_user_firm')
        dispatch(getAuthDataThunk(firm_id));
        dispatch(messageChannelsThunk());

    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}

export const authGetGroupFirmsThunk = (from_static_uri = null) => async dispatch => {
    try {
        let from_uri = utf8ToB64(window.location.origin)
        dispatch(changeLoaderStatusAC(true));
        const response = await api._authGetForUriClientsFirms(from_static_uri || from_uri);
        dispatch(changeLoaderStatusAC(false));
        if (response.hasOwnProperty('error')) {
            dispatch(changeAlertStatusAC(true, response.error));
            return
        }
        dispatch(authClintFirmsAC(response))
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}

export const firmsThunkConnected = (form_data, from_static_url) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response = await api._authConnectedFirms(form_data);
        dispatch(changeLoaderStatusAC(false));
        if (response.hasOwnProperty('error')) {
            dispatch(changeAlertStatusAC(true, response.error));
            return
        }
        dispatch(authGetGroupFirmsThunk(from_static_url));
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}

/**
 * confirmMessageChannelEmailOrPhone. подтвердить email or phone и обновить данные пользователя
 *
 * @param {object} api_data -
 * @param {string} api_data.value -  email or phone
 * @returns  {number} api_data.send_password - 0/1 отпарвить пароль
 * @returns  {string} api_data.message_channel - key чат-бот
 * @returns  {string} api_data.password - короткий пароль
 */
export const confirmMessageChannelEmailOrPhone = (api_data) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const form_data = createFormData(api_data)
        const response = await api._confirmationMessageChannel(form_data);
        if (api._errors || response.hasOwnProperty('error')) {
            dispatch(changeLoaderStatusAC(false));
            let msg = api._getErrors() || response?._errors;
            dispatch(changeAlertStatusAC(true, msg));
            return;
        }
        if (response.hasOwnProperty('passwordValid')) {
            if (response.passwordValid) {
                dispatch(accountMessageChannelsConfirmAC({
                    isOpen: false,
                    form_data: null
                }))
                dispatch(getAuthDataThunk(localStorage.getItem("ifurn_service_main_user_firm")));
            } else if (!response.passwordValid) {
                dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Пароль неверный")));
            }
            dispatch(changeLoaderStatusAC(false));
            return
        }
        let send_channel = response.lookup === 'email' ? 'email' : response.lookup === 'phone' ?
            response.message_channel : ''
        let isSendPassword = response && response.lookup && response.passwordSent;
        if (api_data.send_password && isSendPassword) {
            dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Пароль был отправлен"), true));
            dispatch(accountMessageChannelsConfirmAC({
                isOpen: true,
                form_data: {
                    ...api_data,
                    ...response
                }
            }))
        }
        if (api_data.send_password && !isSendPassword) {
            dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Не удалось отправить пароль"), false));
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}

async function accountAccessDispatcher(api_data, dispatch) {
    try {
        const formData = createFormData(api_data)
        const response = await api._authLookup(formData);

        if (api._errors || response.hasOwnProperty('error')) {
            let msg = api._getErrors() || response?.error;

            dispatch(changeAlertStatusAC(true, msg));
            dispatch(changeLoaderStatusAC(false));
            return   new Promise((_, reject) => reject(response))
        }
        return new Promise((resolve, reject) => resolve(response))
    } catch (e) {
        return new Promise((_, reject) => reject(e))
    }
}

/**
 * authThunkAccountAccess Проверить есть ли пользователь .
 * @param {object}  api_data - key, values
 * @returns  {function | null} onSwitchTabRegister- переключить форму на регисрацию
 * @returns  {function | null} onSwitchTabAuth- переключить форму на авторизацию
 */
export const authThunkAccountAccess = (api_data, onSwitchTabRegister = null, onSwitchTabAuth = null) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));

        const response = await accountAccessDispatcher(api_data, dispatch);
        const isFindUser = response && response.lookup && response.user;
        const isShowMessageSendPassword = (response && response.lookup && response.passwordSent);
        if (isShowMessageSendPassword) {
            dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Пароль был отправлен"), true));
        }
        if (isFindUser && !isShowMessageSendPassword && api_data.send_password) {
            dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Не удалось отправить пароль")));
        }
        if (isFindUser && onSwitchTabAuth) {
            dispatch(authLookupAC(null))
            dispatch(changeLoaderStatusAC(false));
            setTimeout(() => {
                onSwitchTabAuth()
            }, 1000)
            return
        }
        if (!isFindUser && onSwitchTabRegister) {
            // dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Учетные данные не соответствуют ни одной учетной записи. Пожалуйста, проверьте правильность ввода и повторите попытку.")));
            dispatch(authLookupAC(null))
            dispatch(changeLoaderStatusAC(false));
            setTimeout(() => {
                onSwitchTabRegister()
            }, 1000)
            return
        }
        if (!isFindUser && (!onSwitchTabRegister && !onSwitchTabAuth)) {
            dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Учетные данные не соответствуют ни одной учетной записи. Пожалуйста, проверьте правильность ввода и повторите попытку.")));

        }
        dispatch(changeLoaderStatusAC(false));
        dispatch(authLookupAC(response))
        return new Promise(res=> res(true))
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
        dispatch(authLookupAC(null))
        return new Promise((_res, req)=> req(e))
    }
}
/**
 * authThunkRegisterCheckUser.
 *
 * @returns  {}  -
 */
export const checkConnectBot = (api_data, bot_key) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        await accountAccessDispatcher(api_data, dispatch);
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
    }
}
export const authThunkRegisterCheckUser = (api_data) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response = await api._authRegistration(api_data);

        if (response.hasOwnProperty('error')) {
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeAlertStatusAC(true, response?.error));
            return
        }
        if (response.result) {
            dispatch(changeLoaderStatusAC(false));
            dispatch(authLookupAC(null));
            dispatch(registerUserAC({isSuccessful: true}));
            return
        }
        dispatch(changeLoaderStatusAC(false));
        dispatch(registerUserAC({isSuccessful: false}));
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
        dispatch(authLookupAC(null))
    }
}
export const authThunkLoginAndRegisterUserFirm = (api_data) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response = await loginSystemDispatcher(api_data, dispatch);

        if (response) {
            let find_register_firm = response.firms?.find(firm => Number(firm.id) === Number(api_data.firm))

            if (!find_register_firm) {
                let form_data = createFormData({[`firms[]`]: api_data.firm})
                const response_connect_firm = await api._authConnectedFirms(form_data);
                if (response_connect_firm.hasOwnProperty('error')) {
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(changeAlertStatusAC(true, response_connect_firm?.error));
                    await api._logout();
                    clearAllStorage();
                    return
                }
            }
            dispatch(getAuthDataThunk(api_data.firm))
            dispatch(changeLoaderStatusAC(false));
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
        dispatch(authLookupAC(null))
    }
}
export const firmsThunkSwitchFirms = (firm) => async dispatch => {
    try {
        await dispatch(getAuthDataThunk(firm));
        localStorage.setItem('updateForTabs', true);
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}
export default AuthReducer;
