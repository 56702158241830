import ApiService from "../api/api";

const api = new ApiService();

const {v4: uuidv4} = require('uuid');

function createClones(part, count, material_id) {
    let clones = [];
    let clonedArray = Array.from({length: count}, (_, index) => ({
        uuid: uuidv4(),
        id: part.id,
        name: part.name,
        x: part.x1,
        y: part.y1,
        z: part.z,
        product_id: material_id,
        id_ifurn: part.id
    }));


    return clonedArray;
}
export const generateDataForPackedWithoutSizes = async ({
                                                order,
                                                settingsProduction = {}
                                            }) => {
    try {
        let parts = order?.part;
        let material = order?.material;
        //
        let {product, part} =  await generateMaterialAndPart({material, detail: parts});

        const settings =  generateSettings({settings: settingsProduction});
        const calculate = generateCalculate({settings: settingsProduction});

        return {
            product, part, data: settings, calculate
        }
    } catch (e) {

        return new Promise((res, reject) => reject(e))
    }
}


export const generateDataForPacked = async ({
                                                order,
                                                settingsProduction = {}
                                            }) => {
    try {
        let parts = order?.part;
        let band_type = order?.bands_type;
        let bands = order?.band;
        let client = order?.client;
        let material = order?.material;

        let detail = await api.getAllPartSizes(parts, band_type, material, bands, client);
        if(!detail) throw  new Error('fetch getSize error')
        let {product, part} =  await generateMaterialAndPart({material, detail});

        const settings =  generateSettings({settings: settingsProduction});
        const calculate = generateCalculate({settings: settingsProduction});

        return {
            product, part, data: settings, calculate
        }
    } catch (e) {

        return new Promise((res, reject) => reject(e))
    }
}


const generateMaterialAndPart = ({material, detail}) => {
    try {
        let associative_part_of_material = material.filter(item => detail.find(d => Number(d?.material) === Number(item?.goods_id)))
            ?.map(item => {
                let title = item?.translate?.[localStorage.getItem('lang')] ?? item?.name
                return {
                    id: item.goods_id,
                    name: title
                }
            })
        let partList = [];
        detail.forEach(part => {
            let material = associative_part_of_material.find(item => Number(item.id) === Number(part.material))
            let count_clone = Number(part.count);

            let parts = createClones(part, count_clone, material?.id);

            partList = [...partList, ...parts]
        });
        return new Promise((res)=>{
            res ({
                product: associative_part_of_material,
                part: partList
            })
        })
    }catch (e) {
        return new Promise((_, rej)=> rej(e))
    }



}
const returnValueSettingOfBoolean = (value) => {
    let num = Number(value);
    return (!Number.isNaN(num) && Number.isFinite(num) && (typeof Number(num) === 'number')) ? num.toString() : false
}
const generateSettings = ({settings = {}}) => {

    let divideByProduct = settings?.[`pack.devideByProduct`] ?? 1;
    let sideCartoonLineDividedBySide = settings?.[`pack.SideCartoonLineDevidedBySide`] ?? 0;

    return {
        minboxX: settings?.[`pack.minboxX`] ?? 500,
        minboxY: settings?.[`pack.minboxY`] ?? 400,
        maxWeight: settings?.[`pack.maxWeight`] ?? 26,
        minWeightNeGabarit: settings?.[`pack.minWeightNeGabarit`] ?? 20,
        maxWeightNeGabarit: settings?.[`pack.maxWeightNeGabarit`] ?? 120,
        minWeightSmallZlayerForCombine: settings?.[`pac.minWeightSmallZlayerForCombine`] ?? settings?.[`pack.minWeightSmallZlayerForCombine`] ?? 5,
        minNeGabarit: settings?.[`pack.minNeGabarit`] ?? 1200,
        devideByProduct: Number(divideByProduct),
        outsideSideZ: settings?.[`pack.outsideSideZ`] ?? 4,
        cartoonCornerZ: settings?.[`pack.cartoonCornerZ`] ?? 5,
        cartoonCornerW: settings?.[`pack.cartoonCornerW`] ?? 25,
        areaOutsideZ: settings?.[`pack.areaOutsideZ`] ?? 5,
        PercentForPPbetweenDetails: settings?.[`pack.PercentForPPbetweenDetails`] ?? 34,
        SideCartoonLineDevidedBySide: Number(sideCartoonLineDividedBySide)
    }
}

const generateCalculate = ({settings = {}}) => {


    return {
        cartoonPrice: {
            insideArea: settings?.[`pack.insideArea`] ?? 4,
            outsideSide: settings?.[`pack.outsideSide`] ?? 6,
            areaOutside: settings?.[`pack.areaOutside`] ?? 3,
            MainCartoonPrice: settings?.[`pack.MainCartoonPrice`] ?? 2
        },
        cartoonCorner: {
            PercentLoss: settings?.[`pack.PercentLosscartoonCorner`] ?? 10,
            CartoonCornerM: settings?.[`pack.CartoonCornerM`] ?? 4,
        },
        glueLine: {
            PercentLoss: settings?.[`pack.PercentLossglueLine`] ?? 10,
            glueLineM: settings?.[`pack.glueLineM`] ?? 3
        },
        A4printOneSheet: settings?.[`pack.A4printOneSheet`] ?? 1,
        workCostPerM2detail: settings?.[`pack.workCostPerM2detail`] ?? 3,
        NonGabaritCostPerM2: settings?.[`pack.NonGabaritCostPerM2`] ?? 3,
        AdditionalExpensesPercent: settings?.[`pack.AdditionalExpensesPercent`] ?? 10,
        PPpriceM2: settings?.[`pack.PPpriceM2`] ?? 3

    }
}
