import ApiService from "../../api/api";

import {changeAlertStatusAC, changeLoaderStatusAC} from "../reducers/CommonReducer";
import {
    accountMessageChannelsConfirmAC,
    accountNetworkLoginAC,
    authLookupAC,
    authNetworkSocialLoginAC
} from "../actions/auth-actions";
import {createFormData, getTranslateMessageAPI, utf8ToB64} from "../../helpers/helpers";
import {OauthGoogleApi} from "../../api/ApiSocial";
import md5 from "md5";
import {LS_FIRM} from "../../constants";
import {getAuthDataThunk} from "../reducers/AuthReducer";

const API = new ApiService();

async function hashDataSocial({id, network, email = null, name = null, from=null}) {
    try {
        const getHash = await API._getNetworkLoginHash();
        let hash = getHash.hash;
        let api_data = {
            network: network,
            // data: {
            hash: md5(id + hash),
            id: id
            // }
        }
        if (email) {
            api_data = {
                ...api_data,
                // data: {
                //     ...api_data.data,
                email: email
                // }
            }
        }
        if (name) {
            api_data = {
                ...api_data,
                // data: {
                //     ...api_data.data,
                name: name
                // }
            }
        }
        if(from){
            api_data={
                ...api_data,
                from: from
            }
        }
        return Promise.resolve(api_data)
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        return Promise.reject(msg)
    }

}

export const networkLoginUserThunk = () => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response_network = await API._getNetworkLogin();
        if (API._errors || response_network.hasOwnProperty('error')) {
            dispatch(changeLoaderStatusAC(false));
            let msg = API._getErrors() || response_network?._errors;
            dispatch(changeAlertStatusAC(true, msg));
            return;
        }
        dispatch(accountNetworkLoginAC(response_network.list));
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(accountNetworkLoginAC(null))
        dispatch(changeAlertStatusAC(true, msg));
    }
}

/**
 * connectedNetworkSocial привязать соцсеть авторизованного пользователя и получить обновленый список.
 */
async function connectedNetworkSocial(api_data, dispatch) {
    const form_data = createFormData(api_data);
    const response_connect_network = await API._networkLogin(form_data);
    if (response_connect_network.hasOwnProperty('error')) {
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, response_connect_network.error));
        return
    }
    dispatch(networkLoginUserThunk())
}

/**
 * deleteNetworkLoginSocial удалить привязку соцсети авторизованного пользователя.
 *
 * @param {object}  api_data
 * @param {string} api_data.id -
 * @param {string} api_data.network - google or fb
 */
export const deleteNetworkLoginSocial = (api_data) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response_delete = await API._deleteNetworkLogin(api_data);
        if (response_delete.hasOwnProperty('error')) {
            dispatch(changeLoaderStatusAC(false));
            let msg = API._getErrors() || response_delete?._errors;
            dispatch(changeAlertStatusAC(true, msg));
            return
        }
        dispatch(networkLoginUserThunk());
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}

/**
 * accountConnectedGoogle  привязать соцсеть google авторизованного пользователя.
 * @param {string} access_token - return provider google
 */
export const accountConnectedGoogle = (access_token) => async (dispatch, getState) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        let userInfo = await OauthGoogleApi.userInfo(access_token)
        let data_api = await hashDataSocial({
            id: userInfo.sub,
            network: "google"
        });
        await connectedNetworkSocial(data_api, dispatch)
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
    }
}
/**
 * accountConnectedGoogle  привязать соцсеть google авторизованного пользователя.
 * @param {object} data_api -
 * @param {string} data_api.id - return provider fb id
 * @param {string} data_api.network - fb
 */
export const accountConnectedFacebook = (data_api) => async (dispatch) => {
    try {
        dispatch(changeLoaderStatusAC(false));
        const hash_data = await hashDataSocial(data_api)
        await connectedNetworkSocial(hash_data, dispatch)
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
    }
}

/**
 * networkLoginSystem общий метод для логина/реги в систему через соц.сети.
 *
 * @param {object}  api_data-
 * @param {function} dispatch  -
 */
async function networkLoginSystem(api_data, dispatch) {

    try {
        const form_data = createFormData(api_data)
        const response_network_login = await API._networkLogin(form_data);
        if (response_network_login.hasOwnProperty('error')) {
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeAlertStatusAC(true, response_network_login.error))
            return
        }
        if (response_network_login.state.state === "error") {
            dispatch(changeLoaderStatusAC(false));
            dispatch(authNetworkSocialLoginAC({
                response_data: null,
                form_data: null, modal: {
                    isOpen: false, isShortPassword: false, isSendEmail: false
                }
            }))
            dispatch(changeAlertStatusAC(true, response_network_login.state.text))
            return
        }
        if (response_network_login.state.state === "need_data") {
            let isPassword = response_network_login.state.fields.includes('once_password')
            let isEmail = response_network_login.state.fields.includes('email')
            dispatch(changeLoaderStatusAC(false));
            dispatch(authNetworkSocialLoginAC({
                response_data: response_network_login,
                form_data: api_data, modal: {
                    isOpen: true, isShortPassword: isPassword, isSendEmail: isEmail
                }
            }))
            if (isPassword) {
                let send_channel = response_network_login?.sent_to_message_channel ? response_network_login.sent_to_message_channel : response_network_login.lookup;
                dispatch(changeAlertStatusAC(true, `${getTranslateMessageAPI("Пароль был отправлен")} ${send_channel}`, true));
            }
            return
        }
        if (response_network_login.hasOwnProperty('state') && response_network_login.state.state === 'ok') {
            let firm_id = response_network_login.state.firm_id;
            if (firm_id) {
                localStorage.setItem(LS_FIRM, firm_id)
            }
            dispatch(authNetworkSocialLoginAC({
                response_data: null,
                form_data: null, modal: {
                    isOpen: false, isShortPassword: false, isSendEmail: false
                }
            }))
            dispatch(authLookupAC(null))
            // window.location.hash = TAB_HASH_IDS.cabinet;
            dispatch(getAuthDataThunk(firm_id));
            // window.location.reload();
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        return Promise.reject(e)
    }

}

export const googleLoginThunk = (access_token) => async dispatch => {
    try {

        dispatch(changeLoaderStatusAC(true));
        const user_info = await OauthGoogleApi.userInfo(access_token);
        const hash_data = await hashDataSocial({
            id: user_info.sub,
            email: user_info.email,
            name: user_info.name,
            network: "google",
            from: utf8ToB64(window.location.href)

        })
        await networkLoginSystem({
            ...hash_data,
            id: user_info.sub
        }, dispatch)
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}
// access_token
export const facebookLoginThunk = (user_info) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        // const user_info = await OauthFacebookApi.userInfo(access_token);
        const hash_data = await hashDataSocial({
            id: user_info.id,
            email: user_info.hasOwnProperty('email') ? user_info.email : null,
            name: user_info.name,
            network: "fb",
            from: utf8ToB64(window.location.href)

        })
        await networkLoginSystem({
            ...hash_data,
            id: user_info.id
        }, dispatch)
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}
export const confirmNetworkSocialLoginSystem = (api_data) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        await networkLoginSystem(api_data, dispatch)
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}
export const facebookGetEmailUserForLoginThunk = (api_data) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        await networkLoginSystem(api_data, dispatch)
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}