import getT from "../../getT"

export default function ({xnc, type_name=false}){
  return (
    ` <tr>
        <th></th>
        <th>${getT('количество деталей с пазами')}</th>
        <th>${getT('количество пазов')}</th>
        <th>${getT('длина пазов')}</th>
        <th>${getT('количество проходов пилы в пазах')}</th>
      </tr>
      <tr>
        <th> ${type_name? type_name : ( getT(xnc?.type_name)?? '')}</th>
          <td>${+Number(xnc?.part_paz_count ?? '').toFixed(2)}</td>
          <td>${+Number(xnc?.paz_count ?? '').toFixed(2)}</td>
          <td>${+Number(xnc?.paz_length_count ?? '').toFixed(2)}</td>
          <td>${+Number(xnc?.paz_count_real ?? '' ).toFixed(2)}</td>
      </tr>
  `
  );
}