function Search(props) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => props.handlerClick()}>
            <path d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z" stroke="#3F3C67" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.2502 19.2502L15.2627 15.2627" stroke="#3F3C67" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default Search;
