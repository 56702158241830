import React, {useReducer} from 'react';
import {PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES} from "../../../store/reducers/PackagingPrinting";

let initialState = {
    packs: PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES.packs,
    packs_bins: PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES.packs_bins,
    packs_part: PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES.packs_part,
    part: PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES.part,
    part_position: PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES.part_position
}


function reducer(state, action) {
    switch (action.type) {
        case 'change': {
            return {
                ...state,
                ...action.payload
            }
        }
        default: {
            return state
        }
    }
}

function disabledButton(state) {
    return !Object.keys(state)?.some((key) => state[key])
}

const Form = ({getT, onSendPrint}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    function handleChange(payload) {
        dispatch({type: 'change', payload: payload});
    }

    return (
        <div className={'packaging-print-form w-100'}>
            <div className="form-group">
                <div className="item checkbox-label__input">
                    <input
                        value={state.packs}
                        checked={Boolean(state.packs)}
                        type="checkbox"
                        id={"packs_all"}
                        onChange={(e) => {
                            let value = Number(e.target.checked);
                            handleChange({
                                packs: value,
                                packs_bins: value,
                                packs_part: value
                            })
                        }}/>
                    <label htmlFor={"packs_all"} className={""}>
                        {getT("Коробки")}
                    </label>
                </div>
                <div className="item level_2">
                    <div className="checkbox-label__input">
                        <input
                            id={'packs_bins'}
                            checked={Boolean(state.packs_bins)}
                            type="checkbox"
                            value={Boolean(state.packs_bins)}
                            onChange={(e) => {
                                let value = Number(e.target.checked);
                                let result = value ? {
                                    packs_bins: value,
                                    packs: value,
                                } : {
                                    packs_bins: value,
                                    packs_part: value
                                }
                                handleChange({
                                    ...result
                                })
                            }
                            }/>
                        <label htmlFor={'packs_bins'}
                               className={' '}>
                            {getT('Слои')}
                        </label>
                    </div>
                </div>
                <div className="item level_3">
                    <div className="checkbox-label__input">

                        <div className="checkbox-label__input">
                            <input
                                id={'pack_part'}
                                checked={Boolean(state.packs_part)}
                                type="checkbox"
                                value={Boolean(state.packs_part)}
                                onChange={(e) => {
                                    let value = Number(e.target.checked);
                                    let result = value ? {
                                        packs_part: value,
                                        packs_bins: value,
                                        packs: value
                                    } : {
                                        packs_part: value
                                    }
                                    handleChange({
                                        ...result
                                    })
                                }
                                }/>
                            <label htmlFor={'pack_part'}
                                   className={' '}>
                                {getT('Детали')}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="item checkbox-label__input">
                    <input
                        value={state.part}
                        checked={Boolean(state.part)}
                        type="checkbox"
                        id={"part"}
                        onChange={(e) => {
                            let value = Number(e.target.checked);
                            handleChange({
                                part: value,
                                part_position: value
                            })
                        }}/>
                    <label htmlFor={"part"} className={""}>
                        {getT("Детали")}
                    </label>
                </div>
                <div className="item level_2">
                    <div className="checkbox-label__input">
                        <input
                            id={'part_position'}
                            checked={Boolean(state.part_position)}
                            type="checkbox"
                            value={state.part_position}
                            onChange={(e) => {
                                let value = Number(e.target.checked);
                                let result = value ? {
                                    part: value,
                                    part_position: value
                                } : {
                                    part_position: value
                                }
                                handleChange({
                                    ...result
                                })
                            }
                            }/>
                        <label htmlFor={'part_position'}
                               className={' '}>
                            {getT('Расположение')}
                        </label>
                    </div>
                </div>
            </div>
            <button disabled={!Object.keys(state)?.some((key) => state[key])} className={'btn btn-maxCCenter w-100'}
                    onClick={() => onSendPrint(state)}>{getT('Отправить')}</button>
        </div>
    );
};

export default Form;