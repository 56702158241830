import React, {useEffect, useState} from 'react';
import IconArrow from "../../../../Icons/IconArrow";
import {SIDE_DETAIL} from "../CombineMaterialModal";
import Top from "../../../../Icons/Cutting/Top";
import Bottom from "../../../../Icons/Cutting/Bottom";

const updateSideDetail = (array, partAdded, direction) => {
    return array?.map(part => {
        if (partAdded?.[0] && (Number(part?.id) === Number(partAdded?.[0]?.id))) {
            part.side = direction;
        } else if (partAdded?.[partAdded?.length - 1] && (Number(part?.id) === Number(partAdded?.[partAdded?.length - 1]?.id))) {
            part.side = direction === SIDE_DETAIL.up ? SIDE_DETAIL?.down : SIDE_DETAIL?.up;
        } else {
            part.side = null;
        }
        return part
    });
}

const ChoicePart = ({group, selectGroup, onChange, material, getT, onNextStep}) => {
    const [parts, setParts] = useState([]);

    const onChangeAddedPart = (value, id) => {
        const direction = parts?.find(part => part?.added)?.side || SIDE_DETAIL?.up;
        const updatePart = parts?.map(part => {
            if (Number(part?.id) === Number(id)) {
                part.added = value
            }
            return part
        })
        let partAdded = updatePart.filter(part => part?.added)
        if (partAdded?.length >= 2) {
            let mergeParts = updateSideDetail(updatePart, partAdded, direction);
            setParts(mergeParts);
        } else {
            setParts(updatePart);
        }
        if (parts?.filter(part => part?.added)?.length < 2) {
            onNextStep(false)
        }
    };
    const onMovePositionPart = (currentIndex, move = true) => {
        const copyArray = group[selectGroup];
        const direction = parts?.find(part => part?.added)?.side || SIDE_DETAIL?.up;
        let removeElem = [];
        let nextIndex = 0;
        if (move) {
            nextIndex = currentIndex - 1;
            if (nextIndex < 0) {
                nextIndex = group[selectGroup].length - 1;
            }
        } else {
            nextIndex = currentIndex + 1;
            if (nextIndex > group[selectGroup].length - 1) {
                nextIndex = 0;
            }
        }
        removeElem = copyArray.splice(currentIndex, 1);
        copyArray.splice(nextIndex, 0, ...removeElem);

        let partAdded = copyArray.filter(part => part?.added);
        if (partAdded?.length >= 2) {
            let mergeParts = updateSideDetail(copyArray, partAdded, direction);
            setParts(mergeParts);
        } else {
            setParts([...copyArray])
        }

    }
    const onUpdateGroupSelectParts = () => {
        let updateGroup = group?.map((item, index) => {
            if (index === Number(selectGroup)) {
                item = [...parts]
            }
            return item
        })
        onChange({key: 'group', value: updateGroup})
    }
    const onCreateCombine = () => {
        onUpdateGroupSelectParts();
        onNextStep(true);
    }
    const changeDirectionSide = () => {
        let partAdded = parts?.filter(part => part?.added)
        let side =  parts.find(part => part?.added)?.side;
        let direction = SIDE_DETAIL?.[side] === SIDE_DETAIL?.up ? SIDE_DETAIL?.down : SIDE_DETAIL?.up;

        let update = group[selectGroup]?.map(part=> {
            if(partAdded?.[0] && (Number(partAdded?.[0]?.id) === Number(part?.id))){
                part = {
                    ...part,
                    side: direction
                }
            }else if(partAdded?.[partAdded?.length -1] && (Number(partAdded?.[partAdded?.length -1]?.id) === Number(part?.id))){
                part = {
                    ...part,
                    side: direction === SIDE_DETAIL?.up ? SIDE_DETAIL?.down : SIDE_DETAIL?.up
                }
            }else{
                part={
                    ...part,
                    side: null
                }
            }
            return part
        })

        setParts(update)
    }
    useEffect(() => {
        setParts(group[selectGroup]);
        onNextStep(false)
    }, [selectGroup]);


    return (
        <div>
            {parts ? <div className="combine-part-list" key={'combineMaterialPart'}>
                {parts?.map((part, index) => {
                    const findMaterial = material?.find(item => Number(item?.goods_id) === Number(part?.material));
                    return <div className="item"
                                key={'combineMaterialPart' + part?.id}>
                        <div className="change-position">
                            <IconArrow onCLickHandler={() => onMovePositionPart(index, true)}/>
                            <IconArrow top={false} onCLickHandler={() => onMovePositionPart(index, false)}/>
                        </div>
                        <div className="checkbox-label__input">
                            <input type="checkbox" checked={part?.added} id={`combineMaterialPartChecked${part?.id}`}
                                   onChange={(e) => onChangeAddedPart(e.target.checked, part?.id)}/>
                            <label htmlFor={`combineMaterialPartChecked${part?.id}`}></label>
                        </div>
                        <div className="title">
                            [{part?.id}] {getT('Деталь')} {part?.name} <br/>
                            {findMaterial?.translate?.[localStorage.getItem('lang')] || findMaterial?.name}
                        </div>
                        {part?.added && SIDE_DETAIL[part?.side] ?
                            <div className="side">
                                {SIDE_DETAIL[part?.side] === SIDE_DETAIL.up ?
                                    <span onClick={changeDirectionSide} title={getT('Положение лицевой стороны детали при склейке')}>
                                        <Top/>
                                    </span> :
                                    <span onClick={changeDirectionSide} title={getT('Положение лицевой стороны детали при склейке')}>
                                        <Bottom/>
                                    </span>}
                            </div>
                            : null}
                    </div>
                })}
            </div> : null}
            {parts && parts?.length && parts?.filter(part => part?.added)?.length >= 2 ? <button className={'btn'}
                                                                                                 onClick={onCreateCombine}>{getT('Создать')}</button> : null}
        </div>
    );
};

export default ChoicePart;