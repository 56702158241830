import {useSelector} from "react-redux";
import {getLocalLang} from "../../../selectors/settings-selector";

export const PriceList = ({getT}) => {
    const lang = useSelector(getLocalLang);
    return <div className="info-page-inner-content price-list">

        <h4>{getT('Прайс-лист на услуги')}:</h4>
        {false ? <>
            <ul>
                <li>
                    <span>{getT('Импорт офлайн – 1 грн за 1 деталь, но не менее 100 грн')}</span>
                    <ul>
                        <li>{getT('Импорт из различных программ можно сделать автоматически. Подробно всё описано на')}
                            <a href="https://help.ifurn.pro" target={'_blank'}> https://help.ifurn.pro.</a>
                            <br/>
                            {getT('Если по каким-либо причинам у Вас не получается сделать импорт самостоятельно, заполните анкету, приложите файлы и Компания - производитель либо IFurn.pro поможет Вам с импортом.')}
                        </li>
                    </ul>
                </li>
                <li><a href={window.location.origin + `/pdf/OffertaMeb_${lang}-converted.pdf`}
                       download={true}>{getT('Подключение производителя мебели')}</a>
                    <ul>
                        {(getT('3 000 грн/год для Украины').trim() !== '') &&
                            <li>{getT('3 000 грн/год для Украины')}</li>}
                        {(getT('300 грн/месяц для Украины').trim() !== '') &&
                            <li>{getT('300 грн/месяц для Украины')}</li>}
                        {(getT('330 евро / год другие страны').trim() !== '') &&
                            <li>{getT('330 евро / год другие страны')}</li>}
                        {(getT('30 евро/месяц другие страны').trim() !== '') &&
                            <li>{getT('30 евро/месяц другие страны')}</li>}
                    </ul>
                </li>
                <li>
                    <a href={window.location.origin + `/pdf/Offerta_firm_${lang}-converted.pdf`}
                       download={true}>{getT('Подключение Компании – поставщика мебельных услу')}</a>
                    <ul>
                        {(getT('70 000 грн/год для Украины').trim() !== '') &&
                            <li>{getT(' 70 000 грн/год для Украины')}</li>}
                        {(getT('3 000 евро/год для других стран').trim() !== '') &&
                            <li>{getT('3 000 евро/год для других стран')}</li>}
                    </ul>
                </li>
            </ul>
            <p>{getT('Подписка гарантирует доступ к системе на указанный период. Если в процессе действия подписки цена меняется, это не влияет на действующую подписку.')}</p>
        </> :
            <>
                <p>{getT('Цена формируется под потребности Клиента.')}</p>
                <p>{getT('Учитывается количество рабочих мест, количество заказов в месяц, количество используемого оборудования.')}</p>
                <p>{getT('Сервис построен как универсальный конструктор, легко адаптируемый под Ваши нужды.')}</p>
                <p>{getT('Свяжитесь с нами удобным Вам способом и мы сформируем коммерческое предложение.')}</p>
            </>}
    </div>
}