import React, {useEffect} from 'react';
import Modal from "../../components/Modals/BasicModal";
import Container from "../../components/Container";
import {useDispatch, useSelector} from "react-redux";
import {changeOrderDetailModalValue, setFileManagerDT} from "../../store/reducers/ModalReducer";
import {getGiblabDataThunk, getGiblablDataAPIThunk} from "../../store/reducers/CommonReducer";
import {getOrderProjectDataThunk} from "../../store/reducers/OrderReducer";
import FileManager from "../../components/Modals/FileManager";
import {isAccessEdit} from '../SettingsFirm/help'
import {getAuthUserDT} from "../../selectors/cabinet-sector";
import {useLang} from "../../context/langProvider";

const OrderDetail = () => {
    const {getT} = useLang();

    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.modal.orderDetail.isOpen);
    const main = useSelector((state) => state.modal.orderDetail.main);
    const detail = useSelector((state) => state.modal.orderDetail.detail);
    const authDt = useSelector(getAuthUserDT)

    const onClose = () => {
        dispatch(changeOrderDetailModalValue('isOpen', false));
        dispatch(changeOrderDetailModalValue('main', null));
        dispatch(changeOrderDetailModalValue('detail', null));
    };

    useEffect(() => {
        /// TODO: поправить на номральный вариант version
        main && dispatch(getOrderProjectDataThunk(1, main.code, detail.client))
    }, [main]);

    return isOpen && main && detail ?
        <Modal title={getT('Информация о заказе')} open={isOpen} onClose={() => onClose()}
               widthProp={window.innerWidth <= 768 ? "100%" : "745px"}>
            <Container>
                <div className="order-details-main-container">
                    <div className="order-detail-infos">
                        <div className="order-detail-infos-column">
                            <h4 className={'order-detail-container-titles'}>
                                {getT('Основная информация')}
                            </h4>
                            <div className="order-detail-container-information-row">
                                <span
                                    className={'order-detail-container-information-row-label'}>{getT('Клиент')}:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{detail.client_surname} {detail.client_name} {detail.client_middlename} </span>
                            </div>
                            <div className="order-detail-container-information-row">
                                <span
                                    className={'order-detail-container-information-row-label'}>{getT('Номер заказа')}:</span>
                                <span className={'order-detail-container-information-row-value'}><a
                                    href={'https://q.ifurn.pro/v/p/' + main.code}
                                    target={'_blank'}><b>{main.id}</b></a> {main.code} (<small>{main.name}</small>)</span>
                            </div>
                            <div className="order-detail-container-information-row">
                                <span
                                    className={'order-detail-container-information-row-label'}>{getT('Валюта заказа')}:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{detail.currency_name}</span>
                            </div>
                            <div className="order-detail-container-information-row">
                                <span
                                    className={'order-detail-container-information-row-label'}>{getT('Дата заказа')}:</span>
                                <span className={'order-detail-container-information-row-value'}>{main.date}</span>
                            </div>
                        </div>
                        <div className="order-detail-infos-column">
                            <h4 className={'order-detail-container-titles'}>
                                {getT('Контактная информация')}
                            </h4>
                            <div className="order-detail-container-information-row">
                                <span className={'order-detail-container-information-row-label'}>{getT('Email')}:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{detail.client_email}</span>
                            </div>
                            <div className="order-detail-container-information-row">
                                <span
                                    className={'order-detail-container-information-row-label'}>{getT('Телефон')}: <small>({getT('основной')})</small>:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{main.phones.main}</span>
                            </div>
                            <div className="order-detail-container-information-row">
                                <span className={'order-detail-container-information-row-label'}>{getT('Телефон')}
                                    <small>({getT('для SMS')})</small>:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{main.phones.sms}</span>
                            </div>
                            <div className="order-detail-container-information-row">
                                <span className={'order-detail-container-information-row-label'}>{getT('Телефон')}
                                    <small>({getT('для вопросов')})</small>:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{main.phones.question}</span>
                            </div>
                        </div>
                    </div>
                    <div className="order-detail-infos">
                        <div className="order-detail-infos-column">
                            <h4 className={'order-detail-container-titles'}>
                                {getT('Адреса')}
                            </h4>
                            <div className="order-detail-container-information-row">
                                <span
                                    className={'order-detail-container-information-row-label'}>{getT('Точка продажи')}:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{detail.adress_sale}</span>
                            </div>
                            <div className="order-detail-container-information-row">
                                <span
                                    className={'order-detail-container-information-row-label'}>{getT('Точка производства')}:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{detail.adress_production}</span>
                            </div>
                            <div className="order-detail-container-information-row">
                                <span
                                    className={'order-detail-container-information-row-label'}>{getT('Способ доставки')}:</span>
                                <span
                                    className={'order-detail-container-information-row-value'}>{getT(detail.delivery_method)}</span>
                            </div>
                        </div>
                        <div className="order-detail-infos-column">
                            {detail.comment && <>
                                <h4 className={'order-detail-container-titles'}>
                                    {getT('Комментарий')}
                                </h4>
                                <div>
                                    <span
                                        className={'order-detail-container-information-row-value'}>{detail.comment}</span>
                                </div>
                            </>}
                            <div className="order-detail-container-buttons">
                                <button className={'create-project'} onClick={() => {
                                    dispatch(getGiblablDataAPIThunk('order_blank_short_html', null, false));
                                    // dispatch(getGiblablDataAPIThunk('order_blank_short', null, false));
                                }}>{getT('Бланк заказа')}</button>

                                {Number(authDt?.firm) === 1 && authDt?.manager ?
                                <button className={'create-project'} onClick={() => {
                                    dispatch(getGiblablDataAPIThunk('order_blank_short_without_html', null, false));
                                    // dispatch(getGiblablDataAPIThunk('order_blank_short_without', null, false));
                                }}>{getT('Бланк заказа')} <br/>
                                    {getT("(производство)")}</button>
                                    : null}

                                <button className={'create-project'} onClick={() => {
                                    dispatch(getGiblablDataAPIThunk('cards', null, false));
                                }}>{getT('Карты кроя')}</button>
                                {detail && detail.hasOwnProperty('firm') && (Number(detail.firm) == 1) &&
                                    <button className={'create-project'}
                                            onClick={() => {
                                                dispatch(getGiblablDataAPIThunk('gibsaw_ftp', null, false));
                                            }}
                                    >{getT('Программы и .project')}</button>
                                }
                                <button className={'create-project'} onClick={() => {
                                    dispatch(setFileManagerDT({isOpen: true, idProject: main.code}));
                                }}>{getT('Файлы для заказа')}</button>

                                <FileManager/>
                                {detail && detail.hasOwnProperty('firm') && isAccessEdit(Number(detail.firm))  &&
                                    <button className={'create-project'}
                                            onClick={() => {
                                                dispatch(getGiblablDataAPIThunk('material_list', null, false));
                                            }}
                                    >{getT('Накладная на сырье')}</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="order-detail-container-calculate">
                        <h4 className={'order-detail-container-titles'}>
                            {getT('В заказе')}:
                        </h4>
                        <br/>
                        <div className="products_sale_main_table overflow-scrolled">
                            <table>
                                <thead>
                                <tr>
                                    <th>{getT('Код')}</th>
                                    <th>{getT('Наименование')}</th>
                                    <th>{getT('Количество')}</th>
                                    <th>{getT('Цена')}</th>
                                    <th>{getT('Сумма')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {detail.calculate && detail.calculate.materials.length > 0 && <>
                                    <tr>
                                        <td colSpan={5}><b>{getT('Материалы и товары')}</b></td>
                                    </tr>
                                    {detail.calculate.materials.map((m) => {
                                        return (
                                            <tr>
                                                <td>{m.goods}</td>
                                                <td>{m.name}</td>
                                                <td>{Number(m.count).toFixed(2)}</td>
                                                <td>{Number(m.price).toFixed(2)}
                                                    <br/><small>{detail.currency_name}</small></td>
                                                <td>{Number(m.count * m.price).toFixed(2)}
                                                    <br/><small>{detail.currency_name}</small></td>
                                            </tr>
                                        )
                                    })}
                                </>}
                                {detail.calculate && detail.calculate.services.length > 0 && <>
                                    <tr>
                                        <td colSpan={5}><b>{getT('Услуги')}</b></td>
                                    </tr>
                                    {detail.calculate.services.map((m) => {
                                        return (
                                            <tr>
                                                <td>{m.services}</td>
                                                <td>{m.name}</td>
                                                <td>{Number(m.count).toFixed(2)}</td>
                                                <td>{Number(m.price).toFixed(2)}
                                                    <br/><small>{detail.currency_name}</small></td>
                                                <td>{Number(m.count * m.price).toFixed(2)}
                                                    <br/><small>{detail.currency_name}</small></td>
                                            </tr>
                                        )
                                    })}
                                </>}
                                {detail.calculate && detail.calculate.extra.length > 0 && <>
                                    <tr>
                                        <td colSpan={5}><b>{getT('Дополнительные услуги')}</b></td>
                                    </tr>
                                    {detail.calculate.extra.map((m) => {
                                        return (
                                            <tr>
                                                <td></td>
                                                <td>{m.extra}</td>
                                                <td></td>
                                                <td></td>
                                                <td>{Number(m.price).toFixed(2)}
                                                    <br/><small>{detail.currency_name}</small></td>
                                            </tr>
                                        )
                                    })}
                                </>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Container>
        </Modal>
        :
        ''
}

export default  OrderDetail;
