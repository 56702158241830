import React from "react";
import Modal from "../BasicModal";
import "./WarningModal.css"
import {useLang} from "../../../context/langProvider";

const WarningModal = ({title="", open,  message, onSuccessful, onClose}) => {
    let {getT} = useLang();
    return (<Modal title={title} open={open} widthProp={window.innerWidth < 769 ? "100%" :850}
                   onClose={onClose}>
            <div className="warning-modal">
                <p className="message">{message}</p>
                <div className="warning-modal__btns">
                    <button className="btn"  onClick={() => {
                       if(onSuccessful) onSuccessful();
                        onClose(false);
                    }
                    }>{getT("Да")}</button>
                    <button className="btn btn-danger"  onClick={onClose}>{getT("Нет")}</button>
                </div>
            </div>
    </Modal>)
}

export default React.memo(WarningModal);