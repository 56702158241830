import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {onServicePriceCUCabinet, onSetInitialServiceDT} from "../../../store/reducers/ServiceReducer";
import {getCurrency, getDrop, getService} from "../../../selectors/service-selector";
import {getLocalLang} from "../../../selectors/settings-selector";
import {isAccessEdit} from "../help";
import FieldChangePriceOption from "./FieldChangePriceOption";

const stateSelectType = {
    noSelected: 'noSelected',
    every: 'every'
}

const Services = ({getT, firmId}) => {
    const {type, service} = useSelector(getService);
    const currency = useSelector(getCurrency);
    const drop = useSelector(getDrop);
    const lang = useSelector(getLocalLang);
    const [selectedFolder, setSelectedFolder] = useState(stateSelectType.noSelected);
    const [baseField, setBaseField] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(onSetInitialServiceDT())
    }, []);

    const onChangeFolder = (event) => {
        const selected = event.target.value;
        let resultBase = [];

        switch (selected) {
            case stateSelectType.noSelected: {
                resultBase = [];
                break;
            }
            case stateSelectType.every: {
                resultBase = [...service];
                break;
            }
            default: {
                if (Array.isArray(service) && service.length >= 1) {
                    resultBase = service.filter(b => Number(b.type) === Number(selected));
                }
            }
        }
        resultBase.sort(function (a, b) {
            let nameA = a.name[lang].toLowerCase(), nameB = b.name[lang].toLowerCase()
            if (nameA < nameB) //сортируем строки по возрастанию
                return -1
            if (nameA > nameB)
                return 1
            return 0 // Никакой сортировки
        })
        setBaseField(resultBase);
        setSelectedFolder(selected);


    }

    const onChangePriceOptions = (payload) => {
        dispatch(onServicePriceCUCabinet({...payload, drop: 1}));
    }
    return <div className={"settings-section w-100"}>
        <div className="head">
            <div className="filed">
                <h2>{getT('Типы услуг')}</h2>
                <select className={"material-select"} name="folder" id="" value={selectedFolder}
                        onChange={onChangeFolder}
                >
                    <option value={stateSelectType.noSelected} disabled={true}>---{getT("Выбрать")}---</option>
                    <option value={stateSelectType.every}> {getT("Все")}</option>
                    {Array.isArray(type) && type?.map(f => {
                        return <option value={f.goods_service_type_id}>{f.name[lang]} </option>
                    })}
                </select>
            </div>

            {(Array.isArray(baseField) && !!baseField.length) &&
                <div className="table">
                    <table className={"table-wrapper main-parts-table"}>
                        <thead>
                        <th>{getT("#")}</th>
                        <th>{getT("Услуга")}</th>
                        <th>{getT("Стоимость")}</th>

                        </thead>
                        <tbody>
                        {baseField?.filter(item => !!item?.price?.price)?.map((b, idx) => {

                            return <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{b.name[lang]}</td>
                                <td>
                                    {isAccessEdit(firmId) ? b.hasOwnProperty('price') ?
                                            <FieldChangePriceOption getT={getT}
                                                                    onChange={(payload) => onChangePriceOptions(payload)}
                                                                    service={b} currency={currency}
                                                                    drop={drop}/> : '-'
                                        :
                                        b.hasOwnProperty('price') ? <div className="">
                                            <span>{b.price.price}
                                                <strong>{b.price && b.price.hasOwnProperty('currency') &&
                                                    currency.find(c => Number(c.currency_id) === Number(b.price.currency))?.name?.[lang]}</strong></span>
                                            <br/>
                                            <span>{b.price && b.price.hasOwnProperty('drop') && b.price.drop && drop.find(c => Number(c.material_drop_id) === Number(b.price.drop))?.name?.[lang]}</span>
                                        </div> : '-'
                                    }
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    </div>
}

export default Services;