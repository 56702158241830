export const HELP_ARTICLE_GET_BY_ID_TOGGLE_MODAL='HELP_ARTICLE/GET_BY_ID_TOGGLE_MODAL'
export const HELP_ARTICLE_SEND_COMMENT_MAIL='HELP_ARTICLE/SEND_COMMENT_MAIL';


export const helpArticleGetByIdToggleModalAC = (payload) => {
    return {
        type: HELP_ARTICLE_GET_BY_ID_TOGGLE_MODAL,
        payload: payload
    }
}
export const helpArticleSendCommentMailAC = (payload) => {
    return {
        type: HELP_ARTICLE_SEND_COMMENT_MAIL,
        payload: payload
    }
}
