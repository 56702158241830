import axios from "axios";

export const OauthGoogleApi = {
    userInfo(access_token) {
        return axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: {Authorization: `Bearer ${access_token}`},
        }).then(res => {
            return res.data
        }).catch(e => Promise.reject(e))
    }
}
export const OauthFacebookApi = {
    userInfo(access_token) {
        return axios.get('https://graph.facebook.com/v18.0/me?fields=id,email,name', {
            headers: {Authorization: `Bearer ${access_token}`},
        }).then(res => {
            return res.data
        }).catch(e => Promise.reject(e))
    }
}