import React from 'react';
import {useLang} from "../../../context/langProvider";

const Offerta = () => {
    const {getT} = useLang();

    return <div className="info-page-inner-content">
        <h2 className="privacy-title"> {getT("Договор публичной оферты")}</h2><p
        className="privacy-text">{getT("Общие положения")}</p>
        <ul>
            <li>{getT("1.1. Настоящая оферта, является официальным предложением “ФОП Коток И.М.”, далее по тексту —" +
                " «Продавец»," +
                " заключить Договор купли-продажи товаров дистанционным способом, то есть через Интернет-магазин, далее по" +
                " тексту — «Договор», и размещает Публичную оферту (предложение) на официальном интернет-сайте Продавца")}

                <a
                    href="https://ifurn.pro" target="_blank" title={'ifurn.pro'}
                    className="privacy-link">«https://ifurn.pro», </a>
                <a
                    href="https://newgo.ifurn.pro" target="_blank" title={'newgo.ifurn.pro'}
                    className="privacy-link">«https://newgo.ifurn.pro», </a>
                 <a
                    href="https://crm.ifurn.pro" target="_blank" title={'crm.ifurn.pro'}
                    className="privacy-link">«https://crm.ifurn.pro»</a>

                {getT("(далее - «Интернет-сайт»)")}.
            </li>
            <li> {getT("1.2. Моментом полного и безусловного принятия Покупателем предложения Продавца (акцептом)" +
                " заключить" +
                " электронный договор купли-продажи товаров, считается факт оплаты Покупателем заказа на условиях" +
                " настоящего Договора, в сроки и по ценам, указанным на Интернет-сайте Продавца.")}
            </li>
            <li> {getT("2.1. В настоящей оферте, если контекст не требует иного, нижеприведенные термины имеют" +
                " следующие значения:")}
            </li>
            <ul>
                <li>{getT("* «товар» - составляющие мебели, полуфабрикаты, аксессуары, комплектующие и" +
                    " сопроводительные предметы;")}
                </li>
                <li>{getT("* «Интернет-магазин» - в соответствии с Законом Украины «об электронной коммерции»," +
                    " средство для представления или реализации товара, работы или услуги путем совершения электронной сделки.")}
                </li>
                <li>{getT("* «Продавец» - компания, реализующая товары, представленные на Интернет-сайте.")}</li>
                <li>{getT("* «Покупатель» - физическое лицо, заключившее с Продавцом Договор на условиях, изложенных ниже.")}</li>
                <li>{getT("* «Заказ» - выбор отдельных позиций из перечня товаров, указанных Покупателем при" +
                    " размещении заказа и проведении оплаты.")}
                </li>
            </ul>
            <p className="privacy-text">{getT("Предмет Договора")}</p>
            <li>{getT("3.1. Продавец обязуется передать в собственность Покупателя Товар, а Покупатель обязуется оплатить и принять Товар на условиях данного Договора.")}
            </li>
            <ul>{getT("Настоящий Договор регулирует куплю-продажу товаров в Интернет-магазине, в том числе:")}
                <li> {getT("- добровольный выбор Покупателем товаров в Интернет-магазине;")}</li>
                <li> {getT("- самостоятельное оформление Покупателем заказа в Интернет-магазине;")}</li>
                <li> {getT("- оплата Покупателем заказа, оформленного в Интернет-магазине;")}</li>
                <li> {getT("- обработка и доставка заказа Покупателю в собственность на условиях настоящего" +
                    " Договора.")}</li>
            </ul>
            <p className="privacy-text">{getT('Порядок оформления заказа')}</p>
            <li>{getT("4.1. Покупатель имеет право оформить заказ на любой товар, представленный на Сайте Интернет-магазина и имеющийся в наличии.")}
            </li>
            <li>{getT("4.2. Каждая позиция может быть представлена в заказе в любом количестве.")}</li>
            <li>{getT("4.3. При отсутствии товара на складе, Менеджер компании обязан поставить Покупателя в известность (по телефону или через электронную почту).")}
            </li>
            <li>{getT("4.4. При отсутствии товара Покупатель вправе заменить его товаром аналогичной модели, отказаться от данного товара, аннулировать заказ.")}
            </li>
            <p className="privacy-text">{getT("Порядок оплаты")}&nbsp;</p><p
            className="privacy-text">{getT("Банковский платеж.")}&nbsp;</p>
            <li>{getT("5.1. Оплата осуществляется на расчетный счёт Продавца. Поставка товара Продавцом Покупателю осуществляется после оплаты счета способом оговоренным в условиях доставки п. 6.1, 6.2.")}
            </li>
            <li>{getT("5.2. При не поступлении денежных средств Интернет-магазин оставляет за собой право аннулировать заказ.")}
            </li>
            <p className="privacy-text">{getT("Условия доставки заказа")}&nbsp;</p>
            <li>{getT("6.1. Доставка товаров, приобретенных в Интернет-магазине, осуществляется самовывозом с указанного в" +
                " счёте адреса, транспортной компанией или другим способом оговоренным Покупателем с Продавцом.")}
            </li>
            <li>{getT("6.2. Вместе с заказом Покупателю предоставляются документы согласно законодательства Украины.")}</li>
            <p className="privacy-text">{getT("Права и обязанности сторон:")}&nbsp;</p>
            <li>{getT("7.1. Продавец имеет право:")}</li>
            <ul>
                <li>{getT("- в одностороннем порядке приостановить оказание услуг по настоящему договору в случае нарушения" +
                    " Покупателем условий настоящего договора.")}
                </li>
            </ul>
            <li>{getT("7.2. Покупатель обязан:")}</li>
            <ul>
                <li>{getT("- своевременно оплатить и получить заказ на условиях настоящего договора.")}</li>
            </ul>
            <li>{getT("7.3. Покупатель имеет право:")}</li>
            <ul>
                <li>{getT("- оформить заказ в Интернет-магазине;")}</li>
                <li>{getT("- оформить электронный договор;")}</li>
                <li>{getT("- требовать от Продавца исполнения условий настоящего Договора.")}</li>
            </ul>
            <p className="privacy-text">{getT("Ответственность сторон")}&nbsp;</p>
            <li>{getT("8.1. Стороны несут ответственность за невыполнение или ненадлежащее выполнение условий настоящего" +
                " договора в порядке, предусмотренном этим договором и действующим законодательством Украины.")}
            </li>
            <li>{getT("8.2. Продавец не несет ответственности за:")}</li>
            <ul>
                <li>{getT("- измененный производителем внешний вид Товара;")}</li>
                <li>{getT("- за незначительное несоответствие цветовой гаммы товара, что может отличаться от оригинала товара" +
                    " исключительно из-за разной цветовой передачи мониторов персональных компьютеров отдельных моделей;")}
                </li>
                <li>{getT("- за содержание и правдивость информации, предоставляемой Покупателем при оформлении заказа;")}</li>
                <li>{getT("- за задержку и перебои в предоставлении Услуг (обработки заказа и доставке товара), которые" +
                    " происходят по причинам, находящимся вне сферы его контроля;")}
                </li>
                <li>{getT("- за противоправные незаконные действия, осуществленные Покупателем с помощью данного доступа к сети" +
                    " Интернет;")}
                </li>
                <li>{getT("- за передачу Покупателем своих сетевых идентификаторов - IP, MAC-адреса, логина и пароля третьим" +
                    " лицам;")}
                </li>
            </ul>
            <li>{getT("8.3. Покупатель, используя предоставленный ему доступ к сети Интернет, самостоятельно несет" +
                " ответственность за вред, причиненный его действиями (лично, даже если под его логином находилось другое" +
                " лицо) лицам или их имуществу, юридическим лицам, государству или моральным принципам моральности.")}
            </li>
            <li>{getT("8.4. В случае наступления обстоятельств непреодолимой силы, стороны освобождаются от выполнения условий" +
                " этого договору. Под обстоятельствами непреодолимой силы для целей настоящего договора понимаются" +
                " события, имеющие чрезвычайный, непредвиденный характер, которые исключают или объективно мешают" +
                " исполнению настоящего договора, наступление которых Стороны не могли предвидеть и предотвратить" +
                " разумными способами.")}
            </li>
            <li>{getT("8.5. Стороны прикладывают максимум усилий для решения любых разногласий исключительно путем" +
                " переговоров.")}
            </li>
            <p className="privacy-text">{getT("Другие условия")}&nbsp;</p>
            <li>{getT("9.1. Интернет-магазин оставляет за собой право в одностороннем порядке вносить изменения в настоящий договор при условии предварительной публикации его на сайте")}
                <a
                    href="https://newgo.ifurn.pro" target="_blank" title={'newgo.ifurn.pro'}
                    className="privacy-link">«https://newgo.ifurn.pro» </a>
            </li>
            <li>{getT("9.2. Интернет-магазин создан для организации дистанционного способа продажи товаров через Интернет.")}</li>
            <li>{getT("9.3. Покупатель несет ответственность за достоверность указанной при оформлении заказа информации. При" +
                " этом, при осуществлении акцепта (оформлении заказа и последующей оплаты товара) Покупатель предоставляет" +
                " Продавцу свое безоговорочное согласие на сбор, обработку, хранение, использование своих персональных" +
                " данных, в понимании ЗУ «О защите персональных данных».")}
            </li>
            <li>{getT("9.4. Оплата Покупателем оформленного в Интернет-магазине заказа означает полное согласие Покупателя с" +
                " условиями договора купли-продажи (публичной оферты)")}
            </li>
            <li>{getT("9.5. Фактической датой электронного соглашения между сторонами есть дата принятия условий, в" +
                " соответствии со ст. 11 Закона Украины «Об электронной коммерции»")}
            </li>
            <li>{getT("9.6. Использование ресурса Интернет-магазина для предварительного просмотра товара, а также для" +
                " оформления заказа для Покупателя есть бесплатным.")}
            </li>
            <li>{getT("9.7. Информация, предоставляемая Покупателем, является конфиденциальной. Интернет-магазин использует" +
                " информацию о Покупателе исключительно в целях обработки заказа, отправления уведомлений Покупателю," +
                " доставки товара, осуществления взаиморасчетов и др.")}
            </li>
            <p className="privacy-text">{getT("Порядок возврата товара надлежащего качества")}</p>
            <li>{getT("10.1. Возврат товара в Интернет-магазин производится согласно действующего законодательства Украины.")}
            </li>
            <p className="privacy-text">{getT("Срок действия договора")}</p>
            <li>{getT("11.1. Электронный договор считается заключенным с момента получения лицом направившим предложение заключить такой договор, ответа о принятии этого предложения в порядке, определенном частью шестой статьи 11 Закона Украины Об электронной коммерции.")}</li>
            <li>{getT("11.2. До окончания срока действия этот Договор может быть расторгнут по взаимному согласию сторон до" +
                " момента фактической доставки товара, путем возврата денежных средств")}
            </li>
            <p className="privacy-text">
                {getT("Обращаем ваше внимание, что интернет-магазин «СПД Коток И.М.» на официальном" +
                    " интернет-сайте")}
                <a
                    href="https://newgo.ifurn.pro" target="_blank" title={'newgo.ifurn.pro'}
                    className="privacy-link">«https://newgo.ifurn.pro», </a>
                {getT(" имеет право, в соответствии с" +
                    " законодательством Украины, предоставлять право пользования интернет платформой ФЛП и юридическим лицам" +
                    " для реализации товара.")}
            </p>
        </ul>
    </div>
};

export default Offerta;