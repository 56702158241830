export const short_order_style = `
   <style type="text/css">
   table {
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid;
  font-size: 14px;
  padding: 2px 5px;
  text-align: center;
}
table tfoot th{
  text-align: left;
}


table caption {
    caption-side: top;
    font-size: 15px;
    font-weight: 600;
    color: black;
}
table{
width: 100%;
    margin: 10px 0px;
}
.b-left-hide{
border-left: 1px solid white !important;
}
.b-bottom-hide{
border-bottom: 1px solid white !important;
}
.b-right-hide{
border-right: 1px solid white !important;
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>

`