import React, {useState} from 'react';
import GoBackArrow from "../../../../Icons/Actions/GoBackArrow";
import {formatNumbersThousands} from "../../../../helpers/helpers";

const Bind = ({bind, index, getT, component, sqBins}) => {
    const [isShowComponent, setIsShowComponent] = useState(false);
    let {binSq = 0, binBlankSq = 0} = bind;
    let sqDetails = bind?.parts?.reduce((acc, item)=>{
        return ((Number(item.w) * Number(item.h)) / 1000000) + acc
    }, 0);
    let binSqResult = (Number(sqDetails) / Number(sqBins))
    return (
        <>
            <tr className={'tr-collapse'}>
                <th colSpan={9} style={{textAlign: 'left'}}>{getT('Слой')} {index + 1}
                    <span onClick={() => (setIsShowComponent(!isShowComponent))}>
                        <GoBackArrow cName={`icon-down ${isShowComponent ? 'show' : ' '}`}/>
                    </span>
                </th>

            </tr>

            {isShowComponent && <>
                <tr>
                    <th rowSpan={2}>{getT('Номер')}</th>
                    <th rowSpan={2}>{getT('Деталей')}</th>
                    <th rowSpan={2}>{getT('Вес')}</th>
                    <th colSpan={2}>{getT('Толщина')}</th>
                    <th rowSpan={2}>{getT('% Заполнения')}</th>
                </tr>
                <tr>
                    <th>{getT('мин')}</th>
                    <th>{getT('макс')}</th>
                </tr>
                <tr>
                    <td>{index + 1}</td>
                    <td>{bind?.binParts ?? '-'}</td>
                    <td>{formatNumbersThousands(bind?.binWeight) ?? '-'}</td>
                    <td>{bind?.binMinZ ?? '-'}</td>
                    <td>{bind?.binMaxZ ?? '-'}</td>
                    <td>{binSqResult !== 0 ? formatNumbersThousands(binSqResult) : binSqResult}</td>
                </tr>
                {component}
            </>}
        </>
    );
};

export default Bind;