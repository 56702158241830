import Modal from "../../../components/Modals/BasicModal";
import React from 'react';
import {useSelector} from "react-redux";
import {getInfoPlanDT, getPlanModal} from "../../../selectors/cells-leftovers-selector";
import {getLocalLang} from "../../../selectors/settings-selector";
import Container from "../../../components/Container";

const statusBg = {
    'middle': 'orange',
    'ok': 'green',
    'no': 'red'
}
const InfoPlane = ({onToggleInfoModal, getT}) => {

    const {info} = useSelector(getPlanModal);
    const planeDt = useSelector(getInfoPlanDT);
    const lang = useSelector(getLocalLang);

    return <Modal title={getT('Остатки')} open={info.isOpen} onClose={() => onToggleInfoModal(false)}>
        <Container>
            <div className="overflow-scrolled w-100">
                {Array.isArray(planeDt) && planeDt.length >= 1 ? <>
                        {planeDt.map(p => {
                            return <div className="container-info-plane">
                                <div className={'item'}>
                                    <div className={'label'}>№ {getT("Заказа")}</div>
                                    <div>{p.id}</div>
                                </div>
                                <div className={'item'}>
                                    <div className={'label'}>{getT("Код")}</div>
                                    <div>{p.code}</div>
                                </div>
                                <div className={'item'}>
                                    <div className={'label'}>{getT("Имя заказ")}</div>
                                    <div>{p.name_order}</div>

                                </div>
                                <div className={'item'}>
                                    <div className={'label'}>{getT("Материал")}</div>
                                    <div style={{minWidth: '140px'}}><span className={'cursor-pointer'}
                                                                           title={'id заказа'}>[{p.id}]</span> {p.goods.name[lang]}
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <div className={'label'}>{getT("Планируемая дата производства")}</div>
                                    <div>{p.plan_of_production}</div>

                                </div>
                                <div className={'item'}>
                                    <div className={'label'}>{getT("Клиент")}</div>
                                    <div>{p.name}
                                        <p style={{whiteSpace: 'nowrap'}}>{p.client_email}</p>
                                        <p style={{whiteSpace: 'nowrap'}}>{p.client_phone_main}</p>
                                    </div>

                                </div>


                                <div className={'item'}>
                                    <div className={'label'}>{getT("Размер (мм)")}</div>
                                    <div style={{whiteSpace: 'nowrap'}}><span className={'cursor-pointer'}
                                                                              title={'x'}>{p.x}</span> x <span
                                        className={'cursor-pointer'} title={'y'}>{p.y}</span></div>
                                </div>
                                <div className={'item'}>
                                    <div className={'label'}>{getT("Площадь поступления материала ")}</div>
                                    <div>{p.area_plan}</div>

                                </div>
                                <div className={'item'}>
                                    <div className={'label'}>{getT("Сведенные данные по заказу в этом материале")}</div>
                                    <div>{p.plan_fact.plan} / {p.plan_fact.hasOwnProperty('fact') && (p.plan_fact.fact != 0 || p.plan_fact.fact != '') ? p.plan_fact.fact : getT('Нет приходов')}</div>

                                </div>
                                <div className={'item'}>
                                    <div className={'label'}>{getT("Комментарий ")}</div>
                                    <div>{p.reason_why_not}</div>

                                </div>
                                <div className={'item'}>
                                    <div className={'label'}>{getT("Статус состояния прихода по планам ")}</div>
                                    <div><span
                                        style={{color: `${statusBg[p?.plan_fact_text_status.trim()]}`}}>{p.plan_fact_text}</span>
                                    </div>
                                </div>
                            </div>
                        })}
                    </> :
                    getT('')
                }
            </div>
        </Container>
    </Modal>
}
export default InfoPlane;