import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPlanEditForm} from "../../../../selectors/cells-leftovers-selector";
import {
    actionsCellsLeftovers,
    onSendPlanCreateNotOrderStock
} from "../../../../store/reducers/StorageCellsLeftovers";


const PlanCreateForm = ({goods, getT, onSuccessStep})=>{
    const dispath = useDispatch();

    const valueForm = useSelector(getPlanEditForm);

    const onChangeValues = ({key, dt}) => {
        dispath(actionsCellsLeftovers.onChangePlanEditForms({key, dt}))
    }

    const onSubmitCell = (e) => {
        e.preventDefault();
        dispath(onSendPlanCreateNotOrderStock({...valueForm, goods:goods }, onSuccessStep));
    }
    return <form action="" className={"inst__bore-form"} onSubmit={onSubmitCell}>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>x</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.x}
                               required={'required'}
                               onChange={(e) => {
                                   // let value = Number(e.target.value) > 100 ? 100 : e.target.value
                                   onChangeValues({key: 'x', dt: e.target.value})
                               }}/>
                    </div>
                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>y</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.y}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'y', dt: e.target.value})
                               }}/>
                    </div>
                </div>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>{getT('Комментарий к плану')}</label>
                        <input className={"input-val w-100"} type="text"
                               value={valueForm.reason_why_not}
                               onChange={(e) => {
                                   onChangeValues({key: 'reason_why_not', dt: e.target.value})
                               }}/>
                    </div>
                </div>
                <button className={"btn btn-maxCCenter"} type={"submit"}>{getT('Отправить')}</button>
            </form>

}
export default PlanCreateForm;