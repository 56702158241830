import React, {useEffect, useMemo, useState} from 'react';
import IconTelegram from "../../../Icons/Social/IconTelegram";
import IconViber from "../../../Icons/Social/IconViber";
import IconWA from "../../../Icons/Social/IconWA";
import {useLang} from "../../../context/langProvider";
import {useDispatch, useSelector} from "react-redux";
import {getAuthNetworkSocialLoginSelected} from "../../../selectors/auth-selector";
import {authNetworkSocialLoginAC} from "../../../store/actions/auth-actions";
import {useFormik} from "formik";
import Modal from "../BasicModal";
import InputForm from "../../InputFields/InputForm/InputForm";
import {
    confirmNetworkSocialLoginSystem,
    facebookGetEmailUserForLoginThunk
} from "../../../store/thunks/social-network-thunk";
import {filterObjectByKeys} from "../../../helpers/utils";
import Container from "../../Container";

const ICONS_MESSAGE = {
    telegram: IconTelegram,
    viber: IconViber,
    whatsapp: IconWA,
}
const LoginSocialForm = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const auth_network_social_login = useSelector(getAuthNetworkSocialLoginSelected);
    const onClose = () => {
        dispatch(authNetworkSocialLoginAC({
            response_data: null,
            form_data: null, modal: {
                isOpen: false, isShortPassword: false, isSendEmail: false
            }
        }))
    };
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const formik = useFormik({
        initialValues: {
            password: '',
            email: ''
        },
        onSubmit(values) {
            if (auth_network_social_login.modal.isShortPassword) {
                dispatch(confirmNetworkSocialLoginSystem({
                    ...auth_network_social_login.form_data,
                    password: values.password
                }))
            }
            if (auth_network_social_login.modal.isSendEmail) {
                dispatch(facebookGetEmailUserForLoginThunk({
                    ...auth_network_social_login.form_data,
                    email: values.email
                }))
            }
        }
    })
    const connected_channel = useMemo(() => {
        let data = auth_network_social_login.response_data;
        if (!data) return {}
        if (!data.hasOwnProperty('available_message_channel_list') || !data.hasOwnProperty('full_message_channel_list')) {
            return {}
        }

        if (Object.keys(data.full_message_channel_list).length && Object.keys(data.available_message_channel_list)) {
            let keys = Object.keys(data.available_message_channel_list);
            return filterObjectByKeys(data.full_message_channel_list, keys)
        }
        return {}
    }, [auth_network_social_login]);

    const resendPasswordMessage = (message_channel = null) => {
        setIsButtonDisabled(true);
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000);


        let api_data = {
            ...auth_network_social_login.form_data,
        }
        if (message_channel) {
            api_data['message_channel'] = message_channel
        }
        dispatch(confirmNetworkSocialLoginSystem(api_data));

    }
    useEffect(() => {
        let response_data = auth_network_social_login.response_data;
        if (!response_data) {
            setIsButtonDisabled(false);
            return
        }
        if (response_data && !response_data.hasOwnProperty('oncePasswordSent') ||
            (response_data.hasOwnProperty('oncePasswordSent') && !response_data.oncePasswordSent)) {
            setIsButtonDisabled(false);

        }
        if (response_data && (response_data.hasOwnProperty('oncePasswordSent') && response_data.oncePasswordSent)) {
            setIsButtonDisabled(true);
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 60000);


        }

    }, [auth_network_social_login])
    useEffect(() => {
        let timerInterval = 0;
        if (isButtonDisabled) {
            timerInterval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);

        } else {
            clearInterval(timerInterval);
            setSeconds(60)
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [isButtonDisabled]);
    useEffect(() => {
        if (!auth_network_social_login.modal.isOpen) {
            formik.resetForm();
        }
    }, [auth_network_social_login.modal.isOpen])

    if (!auth_network_social_login.modal.isOpen) return null
    return (
        <Modal title={getT('Подтвердить')} onClose={onClose} open={auth_network_social_login.modal?.isOpen}>
            <Container>
                <div className="w-100">
                    <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
                        {auth_network_social_login.modal.isShortPassword && <>
                            <InputForm label={getT('Пароль')} type={'text'} cName={""}
                                       required={true}
                                       value={formik.values.password} onChange={formik.handleChange} name={'password'}/>
                            {Object.keys(connected_channel).length ?
                                <div className="form-group mb-2">
                                    <label htmlFor="" className={"label"}>{getT('Отправить пароль через')}: {isButtonDisabled &&
                                        <span className={'seconds'}>{seconds}</span>}</label>
                                    <div
                                        className={`connected-channel d-flex message-channel-entry ${isButtonDisabled ? ' disabled' : ''}`}>
                                        {connected_channel && Object.keys(connected_channel).map(key => {
                                            const isDefault = connected_channel[key].default;
                                            const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                                            const name = connected_channel[key].name;
                                            const isEnabled = connected_channel[key].enabled;
                                            return (
                                                <div
                                                    className={`item   ${isDefault ? 'default' : ' '} ${!isEnabled ? 'disabled' : ' '}`}
                                                    onClick={() => resendPasswordMessage(key)}
                                                >
                                                    {Icon ? <Icon/> : name ? name : ''}
                                                </div>)

                                        })}
                                    </div>
                                </div> :
                                auth_network_social_login.form_data && auth_network_social_login.form_data.email ?
                                    <div className="form-group mb-2">
                    <span onClick={() => resendPasswordMessage()}
                          className={`resend-password ${isButtonDisabled ? 'disabled' : ''}`}>
                {getT("Отправить повторно пароль")} {isButtonDisabled &&
                        <span className="seconds">{seconds}</span>}</span>
                                    </div>
                                    : null
                            }
                        </>
                        }

                        {auth_network_social_login.modal.isSendEmail &&
                            <InputForm label={getT('Почта')} type={'email'} required={true} cName={"mb-2"}
                                       value={formik.values.email} onChange={formik.handleChange} name={'email'}/>
                        }
                        <div className="d-flex justify-center">
                            <button className={"btn m-0"} type={"submit"}>{getT("Отправить")}</button>
                        </div>
                    </form>
                </div>
            </Container>


        </Modal>
    );
};

export default LoginSocialForm;