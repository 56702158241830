import React, {useCallback, useState} from 'react';
import {useLang} from "../../../context/langProvider";
import {useDispatch, useSelector} from "react-redux";
import {getConfirmAccountChannelsModalSelected} from "../../../selectors/cabinet-sector";
import {accountMessageChannelsConfirmAC} from "../../../store/actions/auth-actions";
import Modal from "../BasicModal";
import InputForm from "../../InputFields/InputForm/InputForm";
import {confirmMessageChannelEmailOrPhone} from "../../../store/reducers/AuthReducer";
import Container from "../../Container";

const ConfirmAuthModal = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const confirm_modal_state = useSelector(getConfirmAccountChannelsModalSelected);
    const [fieldPassword, setFieldPassword] = useState('');
    const onClose = useCallback(() => {
        dispatch(accountMessageChannelsConfirmAC({
            isOpen: false
        }))
        setFieldPassword('')
    }, [dispatch]);
    const handlerSubmit = (e) => {
        e.preventDefault();
        let data_api = {
            password: fieldPassword,
            value: confirm_modal_state.form_data.value,
            send_password: 0,
        }
        dispatch(confirmMessageChannelEmailOrPhone(data_api))
    }
    if (!confirm_modal_state.isOpen) return null;
    return (
        <Modal open={confirm_modal_state.isOpen} onClose={onClose} key={'confirm-auth-modal'} cName={'xs-modal'}
               title={getT("Подтвердить")}>
            <Container>
                <form onSubmit={handlerSubmit} className={'w-100'}>
                    <InputForm label={getT("Пароль")} required={true}
                               value={fieldPassword} onChange={(e) => setFieldPassword(e.target.value)}/>
                    <div className="d-flex justify-center" style={{marginTop: "25px"}}>
                        <button className={"btn btn-maxCCenter"} type={"submit"}>{getT("Отправить")}</button>
                    </div>
                </form>
            </Container>
        </Modal>
    );
};

export default ConfirmAuthModal;