export const isOpenCuttingPage = state => state.cuttingChart.isOpenPage;

export const getCuttingCardDT = state => state.order.order.cutting_card;

export const getMaterialsDT = state => state.order.order.material;

export const getCuttingEdgingTotalDT = state => state.order.order.cutting_card.edging_total;

export const getPartsDT = state => state?.order?.order?.part || [];

export const getCuttingLblDT = state => state.order.order.cutting_card?.lbl ?? [];

export const isCuttingPreviewModal = state => state.cuttingChart.cuttingPreviewModal;
export const isCuttingPrintModal = state => state.cuttingChart.cuttingPrintModal;

export const getCuttingDataForHtml = state => state.cuttingChart.cuttingDataForHtml;