import React, {useCallback, useMemo, useState} from 'react';
import TableOfPack from "./ui/Pack/TableOfPack";
import "./main.css"
import DataOfProject from "./ui/DataOfProject";
import {useLang} from "../../context/langProvider";
import CloseModal from "../../Icons/Actions/Close";
import Print from "../../Icons/Actions/Print";
import {useDispatch, useSelector} from "react-redux";
import {getOrder} from "../../selectors/order-selector";
import TableOfPart from "./ui/Part/TableOfPart";
import {getAuthManagerDT} from "../../selectors/cabinet-sector";
import {getCurrencies} from "../../selectors/common-selector";
import {actionsPackagingPrinting} from "../../store/reducers/PackagingPrinting";
import {useCloseCommandEsc} from "../../HOOK/useCloseCommandEsc";
import PackagingPrintingModal from "../../components/Modals/PackagingPrintingModal";
import {getVatAddedTaxShow, getVatAddPriceIfMoreThan} from "../../selectors/production-selectors";
// 'main-parts-table
const PackagingPrintingPage = () => {
    const dispatch = useDispatch();
    const {getT, lang} = useLang();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const order = useSelector(getOrder);
    const manager = useSelector(getAuthManagerDT);
    const production_vat = useSelector(getVatAddPriceIfMoreThan)
    const production_vat_display_short = useSelector(getVatAddedTaxShow) && !!Number(production_vat);

    const currencies = useSelector(getCurrencies);
    const currency = useMemo(() => {
        if (order?.currency && Array.isArray(currencies)) {
            let currency = currencies?.find(item => Number(item.id) === Number(order?.currency));
            return currency ? currency?.name : null


        }
        return null

    }, [currencies, order])
    const onClose = useCallback(() => {
        dispatch(actionsPackagingPrinting.onToggleOpenPage(false))
    }, [dispatch]);

    useCloseCommandEsc(onClose)

    const onCloseModal = useCallback(() => {
        setIsOpenModal(false)
    }, [setIsOpenModal])

    if (!Array.isArray(order?.packing) || !order?.packing?.length) {
        return <div className={"page-container packaging-printing-page"} style={{zIndex: "22"}}>
            <div className="container-max">
                <div className="header">
                    <div className="close" onClick={onClose} title={`ESC - ${getT('вернуться в деталировку')}`}>
                        <CloseModal/>
                    </div>
                </div>
                <h3 className={'text-center'}
                    style={{textAlign: 'center'}}>{getT('Отсутствуют упаковки в проекте')}</h3>
            </div>
        </div>
    }
    return (
        <div className={"page-container packaging-printing-page"} style={{zIndex: "22"}}>
            <div className="container-max">
                <div className="header">
                    <Print onClick={() => setIsOpenModal(true)}/>
                    <div className="close" onClick={onClose} title={`ESC - ${getT('вернуться в деталировку')}`}>
                        <CloseModal/>
                    </div>
                </div>
                <DataOfProject getT={getT} order_id={order?.id} date={order?.date} firm={order?.firm_name[lang]}
                               parts={order?.part}
                               manager_name={manager?.name ?? '-'}
                               client_name={order?.client_name} packing={order?.packing} unit={getT('кг')}
                               currency={currency}
                               production_vat={production_vat} production_vat_display_short={production_vat_display_short}
                />
                <TableOfPack key={'table-pack'} packing={order?.packing} getT={getT} currency={currency}
                             production_vat={production_vat} production_vat_display_short={production_vat_display_short}/>
                <TableOfPart key={'table-parts'} packing={order?.packing} getT={getT} currency={currency}/>
            </div>
            <PackagingPrintingModal isOpen={isOpenModal} onClose={onCloseModal}/>
        </div>
    );
};

export default PackagingPrintingPage;