import apiImages from "../../api/apiImages";
import {setCuttingPreviewAC, setPartsPreviewAC} from "../reducers/OrderReducer";
import {dispatcherErrorThunk} from "./common-thunk";
import {changeLoaderStatusAC, checkErrorsAPI} from "../reducers/CommonReducer";
import {LS_IMAGES_INTERNAL} from "../../constants";
import {blueprints} from "../../blueprints/blueprints";

const imagesApi = new apiImages();

/**
 * Получить все preview для карты крой.
 *
 * @param {object} order  - project data
 */
export const getCuttingCardPreviewsThunk = (order) => async (dispatch) => {
    try {
        dispatch(changeLoaderStatusAC(true));

        const response = await imagesApi.getCuttingPreviews(order);
        if (response?.hasOwnProperty('error') || response?.error) {
            dispatch(dispatcherErrorThunk(response))
            return
        }
        dispatch(setCuttingPreviewAC(response));
        // window.open(response, "_self")
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}

/**
 * * Получить чертежи для деталей из сервера.
 *  *
 *  * @param {object} order - Текущий проект, содержащий информацию о деталях.
 *  * @param {function} dispatch - Функция для отправки действий в Redux.
 */
const dispatchedPartsOfMaterialPreviewAPI = async (order, dispatch) => {
    try {
        let response_picture = await imagesApi.getPartsSmallPreviews(order);
        if (!response_picture.hasOwnProperty('error')) {
            dispatch(setPartsPreviewAC(response_picture ? response_picture : []));
        }
    } catch (e) {
        checkErrorsAPI("", "api:/images/, M:P");
    }
}
/**
 * getDetailsBlueprintsThunk. получить чертежи для деталей
 *   @var {boolean} ls_images_internal - Если 1, генерирует чертежи с использованием JavaScript.
 *   @var {boolean} ls_images_internal - Если 0, получает чертежи из сервера.
 *
 * @param {array} parts - Массив деталей, для которых нужно получить чертежи.
 */
export const getDetailsBlueprintsThunk = (parts = []) => async (dispatch, getState) => {
    if (!Array.isArray(parts) || !parts.length) return;
    const order_store = getState().order.order;
    let order = {
        ...order_store, part: parts
    }
    try {
        // Проверка наличия настройки о том где получать изображении в localStorage
        let ls_images_internal = !!Number(localStorage.getItem(LS_IMAGES_INTERNAL))
        if (ls_images_internal) {
            let order_clone = {...order_store, part: parts, screen_size: {x: 123, y: 123}, type_svg: 'small'};
            let previews = blueprints.blueprintByPart(JSON.parse(JSON.stringify(order_clone)));
            let transform_response = Object.entries(previews)?.map(([key, value]) => {
                return {id: key, svg: btoa(unescape(encodeURIComponent(value)))}
            })
            dispatch(setPartsPreviewAC(transform_response ? transform_response : []));
            return;
        }
        // Если внутренние изображения недоступны, отправляем запрос на сервер
        await dispatchedPartsOfMaterialPreviewAPI(order, dispatch)

    } catch (e) {
        console.error(e)
        // Если произошла ошибка, повторяем запрос на сервер
        await dispatchedPartsOfMaterialPreviewAPI(order, dispatch)
    }

};