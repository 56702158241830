function Goback(props) {
  return (
    <svg
        className={"go-back"}
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 9H1.75M7 14.25L1.75 9 7 3.75"
        stroke="#050038"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Goback;
