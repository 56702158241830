import React from 'react';
import Modal from "../../../components/Modals/BasicModal";
import {useDispatch, useSelector} from "react-redux";
import {getCustomerBalanceModal} from "../../../selectors/cabinet-sector";
import Container from "../../../components/Container";
import {onToggleCustomerBalanceModal} from "../../../store/reducers/ModalReducer";

const CustomerStockList = ({getT})=>{
    const dispatch = useDispatch();
    const {listView} = useSelector(getCustomerBalanceModal);
    const onClose = ()=> dispatch(onToggleCustomerBalanceModal({key:'listView', dt:{isOpen: false, stock: []}}))

    return   <div className="container-stock">
        <Modal title={getT("Остатки")} open={listView.isOpen} onClose={onClose}>
            <Container>
                {listView && listView.hasOwnProperty('stock') && Array.isArray(listView.stock) && listView.stock.length >= 1? (
                    <div className="overflow-scrolled">
                    <table className={"stock-table"}>
                        <thead>
                        <tr>
                            <td>{getT("№")}</td>
                            <td>{getT("Название")}</td>
                            <td>{getT("Размер (мм)")}</td>
                            <td>{getT("Кол-во")}</td>
                            <td>{getT("Имя ячейки" )}</td>
                            <td>{getT("Дата")}</td>
                        </tr>
                        </thead>
                        <tbody>
                        {listView.stock.map(l => {
                            return (<tr>
                                <td>{l.order}</td>
                                <td style={{maxWidth: '190px'}}>[{l.goods_id}] {l.name}</td>
                                <td> {l.x} <strong> x </strong> {l.y}</td>
                                <td>{l.count}</td>
                                <td>{l.hasOwnProperty('cell') && l.cell.name }</td>
                                <td>{l.date_in}</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                    </div>
                ):(
                    <h3 style={{margin: "0 auto"}}>{getT("Остатков  на складе нет")}</h3>
                )}

            </Container>
        </Modal>
    </div>
}

export default CustomerStockList;