import React from 'react';
import AuthAccountPassword from "../AuthAccountPassword";
import MasterPassword from "../MasterPassword";
import SubmitPrivacy from "./SubmitPrivacy";
import {useFormikContextProviderLogin} from "../../../SignIn";
import useAuthLookup from "../../../../../HOOK/useAuthLookup";
import {useLang} from "../../../../../context/langProvider";
import Edit from "../../../../../Icons/TableActions/Edit";
import FiledPhoneMask from "../../../../../components/InputFields/FiledPhoneMask";
import MessageChannels from "../MessageChannels";
import {changeAlertStatusAC} from "../../../../../store/reducers/CommonReducer";
import {useDispatch} from "react-redux";

const LoginPhone = ({
                        onHandlerEdit,
                        onReqAccountAccess,
                        isButtonDisabled,
                        seconds,
                        setting_phone_code_country = null
                    }) => {
    const dispatch = useDispatch()
    const {getT} = useLang();
    const {
        isAuthFind,
        isSendShortPassword, full_message_channel_list, connected_message_channel_list,
    } = useAuthLookup();

    const {values, handleChange, validateForm, setFieldValue, submitForm} = useFormikContextProviderLogin();
    if (values.isRadioLoginEmail) return null
    const onChangeTelHandler = (result) => {
        handleChange({
            target: {name: 'phone', value: result},
        });
    }
    const handleValidate = () => {
        validateForm().then((errors) => {
            console.log(errors)
            // Handle the validation errors
            if (!Object.keys(errors).includes('phone')) {
                onReqAccountAccess({send_password: 0})

            } else if (Object.keys(errors).includes('phone')) {
                let error_msg = `${errors['phone'] || ''}`
                dispatch(changeAlertStatusAC(true, error_msg));
            }
        });
    };


    return (
        <div>
            <div className="d-flex items-end mb-2 w-100">
                <div className={`${!values.isEditEmailOrPhone ? 'disabled' : ''} flex-auto`}>
                    <FiledPhoneMask onChange={onChangeTelHandler} label={getT('Телефон')}
                                    setting_code_country={setting_phone_code_country}
                                    value={values.phone} autoComplete={"off"}
                                    disabled={!values.isEditEmailOrPhone}
                    />
                </div>
                {!values.isEditEmailOrPhone &&
                    <Edit style={{margin: '0 0 25px 8px', cursor: "pointer"}} onClick={onHandlerEdit} width={'22px'}
                          height={'18px'}/>}
            </div>
            {values.isEditEmailOrPhone &&
                <button type={'button'} className={"btn"} style={{margin: '0 auto'}}
                        onClick={handleValidate}>{getT("Отправить")}
                </button>}

            {!values.isEditEmailOrPhone && isAuthFind && <React.Fragment>
                <MessageChannels onReqAccountAccess={onReqAccountAccess}
                                 seconds={seconds}
                                 isButtonDisabled={isButtonDisabled}
                                 available_message_channel_list={connected_message_channel_list}
                                 full_message_channel_list={full_message_channel_list}/>
            </React.Fragment>}
            {!values.isEditEmailOrPhone && isAuthFind && isSendShortPassword &&
                <AuthAccountPassword handleChange={handleChange} setFieldValue={setFieldValue} values={values}
                                     submitForm={submitForm}/>
            }
            {
                (!values.isEditEmailOrPhone && isAuthFind && !isSendShortPassword) &&
                <>
                    <MasterPassword values={values} handleChange={handleChange}/>
                    <SubmitPrivacy submitForm={submitForm}/>
                </>
            }
        </div>
    );
};

export default LoginPhone;