import React from "react";

const Globe = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.9974 18.3346C14.5998 18.3346 18.3307 14.6037 18.3307 10.0013C18.3307 5.39893 14.5998 1.66797 9.9974 1.66797C5.39502 1.66797 1.66406 5.39893 1.66406 10.0013C1.66406 14.6037 5.39502 18.3346 9.9974 18.3346Z" stroke="#050038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.66406 10H18.3307" stroke="#050038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.9974 1.66797C12.0818 3.94993 13.2664 6.91133 13.3307 10.0013C13.2664 13.0913 12.0818 16.0527 9.9974 18.3346C7.913 16.0527 6.72844 13.0913 6.66406 10.0013C6.72844 6.91133 7.913 3.94993 9.9974 1.66797V1.66797Z" stroke="#050038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default  Globe;