import React, {useState} from 'react';
import HistoryDots from "../../../Icons/HistoryDots";
import {useDispatch, useSelector} from "react-redux";
import {onCabinetChangeStatusOrder} from "../../../store/reducers/AuthReducer";
import {StatusedOrder} from "../../../selectors/cabinet-sector";
import {useDetectClickOutside} from "react-detect-click-outside";

const differentiationPossibilities = {
    '8': [1],
    '2': [1, 3, 4, 5, 6],
    '3': [1, 2, 4, 5, 6],
    '4': [1, 2, 3, 5, 6],
    '5': [1, 2, 3, 4, 6],
    '6': [1, 2, 3, 4, 5]
}
const classNames = {
    '1': 'bg-primary',
    '2': 'bg-success',
    '3': 'bg-info',
    '4': 'bg-primary',
    '5': 'bg-info',
    '6': 'bg-success',
    '7': 'bg-success',
    '8': 'bg-danger'
}



const ChangeStatusProject = ({getT, userId, orderId, currentStatusId}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const onChangeStatus = (status) => {
        let dt = {statusId: status, orderId: orderId, userId: userId}
        dispatch(onCabinetChangeStatusOrder(dt));
        setIsOpen(false)
    };
    const statusedList = useSelector(StatusedOrder);
    const refSelect = useDetectClickOutside({ onTriggered: () => setIsOpen(false)});
    return <div className={'container-change-status'} ref={refSelect}>
        <div className="btn-dots"  onClick={() => setIsOpen(!isOpen)}><HistoryDots/></div>
        {isOpen && <div className="container-status">
            <p className={'title label'}>{getT('Установить новый статус')}:</p>
            <ul className={'list-status-project'}>
                {statusedList.map(s=> {
                        if (differentiationPossibilities[currentStatusId].includes(Number(s.id))) {
                        return   <li className={`${classNames[s.id]}`}
                                onClick={() => onChangeStatus(s.id)}>{getT(s.name)}</li>
                        }
                    }
                 )}
                {/*<li className={'bg-primary'} onClick={() => onChangeStatus(1)}>{getT('в редактировании')}</li>*/}
                {/*<li className={'bg-info'} onClick={() => onChangeStatus(3)}>{getT('подтверждён в производство')}</li>*/}
            </ul>
        </div>}
    </div>
}
export default ChangeStatusProject;