import React, {useEffect} from "react";
import InputForm from "../../InputFields/InputForm/InputForm";
import SelectForm from "../../InputFields/SelectForm/SelectForm";
import "./Filter.css";
import {getMaterialsTreeDataThunk, getProducersThunk} from "../../../store/reducers/MaterialsReducer";
import {useDispatch, useSelector} from "react-redux";
import {useLang} from "../../../context/langProvider";
import {gaEvents} from "../../../gaEvents";

const Filter = (props) => {
    const {getT} = useLang();
    const producers = useSelector((state) => state.materials.producers);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMaterialsTreeDataThunk());
        dispatch(getProducersThunk());
    }, []);

    return (
        <form className="nightstand-filter" onSubmit={(event) => {
            event.preventDefault();
            props.getMaterials();
            gaEvents.customEvent('form_submit', {
               form_name: 'material page filter'

            })
        }}>
            <InputForm label={getT("Название")}
                       type="text"
                       onChange={(event) => props.changeFilterValue('name', event.target.value)}
                       value={props.filters.name}
            />
            <InputForm label={getT("Код")}
                       type="text"
                       onChange={(event) => props.changeFilterValue('code', event.target.value)}
                       value={props.filters.code}
            />
            <InputForm label={getT("ID")}
                       type="text"
                       onChange={(event) => props.changeFilterValue('goods_id', event.target.value)}
                       value={props.filters.goods_id}
            />
            <InputForm label={getT("Длина")}
                       type="number"
                       onChange={(event) => props.changeFilterValue('x', event.target.value)}
                       value={props.filters.x}
            />
            <InputForm label={getT("Ширина")}
                       type="number"
                       onChange={(event) => props.changeFilterValue('y', event.target.value)}
                       value={props.filters.y}
            />
            <InputForm label={getT("Толщина")}
                       type="number"
                       step={0.01}
                       onChange={(event) => props.changeFilterValue('z', event.target.value)}
                       value={props.filters.z}
            />
            <div className="filter-select">
                <SelectForm el={producers?.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                })}
                            label={getT("Производитель")}
                            onChange={(event) => props.changeFilterValue('producer', event.target.value)}
                            value={props.filters.producer}
                />
            </div>
            <div className="filters-footer">
                <a className="clear-all" onClick={() => props.cleanFilters()}>{getT("Очистить все")}</a>
                <button className="aplly-filter" type="submit">
                    {getT("Применить")}
                </button>
            </div>
        </form>
    );
};

export default Filter;
