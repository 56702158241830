import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../../components/Modals/BasicModal";
import {getPlanEditForm, getPlanModal} from "../../../selectors/cells-leftovers-selector";
import {
    actionsCellsLeftovers, onCreatePlaneStock,
} from "../../../store/reducers/StorageCellsLeftovers";
import {onTogglePlanModal} from "../../../store/reducers/ModalReducer";
import {getLocalLang} from "../../../selectors/settings-selector";
import {useLang} from "../../../context/langProvider";

const Create = () => {
    const {getT} = useLang();

    const dispath = useDispatch();

    const {create} = useSelector(getPlanModal);
    const valueForm = useSelector(getPlanEditForm);

    const onChangeValues = ({key, dt}) => {
        dispath(actionsCellsLeftovers.onChangePlanEditForms({key, dt}))
    }
    const lang = useSelector(getLocalLang)
    const onToggleModal = () => {
        dispath(onTogglePlanModal({key: 'create', dt: {isOpen: false, materials: []}}))
    }
    const onSubmitCell = (e) => {
        e.preventDefault();
        dispath(onCreatePlaneStock(valueForm));
    }
    return <div className={"settings-section"}>
        <Modal open={create.isOpen} title={getT('Создать планируемый остаток')}
               onClose={() => onToggleModal(false)}>
            <form action="" className={"inst__bore-form"} onSubmit={onSubmitCell}>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>x</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.x}
                               required={'required'}
                               onChange={(e) => {
                                   // let value = Number(e.target.value) > 100 ? 100 : e.target.value
                                   onChangeValues({key: 'x', dt: e.target.value})
                               }}/>
                    </div>
                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>y</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.y}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'y', dt: e.target.value})
                               }}/>
                    </div>
                </div>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>{getT('Комментарий к плану')}</label>
                        <input className={"input-val w-100"} type="text"
                               value={valueForm.reason_why_not}
                               onChange={(e) => {
                                   onChangeValues({key: 'reason_why_not', dt: e.target.value})
                               }}/>
                    </div>
                    {Array.isArray(create.materials) && create.materials.length >= 1 &&
                        <div className="field">
                            <label htmlFor="" className={"field-title label"}>{getT('Материал')}</label>
                            <select style={{padding: '9px', height: 'auto'}} className="form-input input-val w-100"
                                    name=""
                                    required={'required'}
                                    id=""
                                    onChange={(e) => onChangeValues({key: 'goods', dt: e.target.value})}>
                                <option value="" disabled={'disabled'} selected={'selected'}>{getT('Выбрать')}</option>
                                {create.materials.map(m => <option value={m.goods}>{m.goods_name[lang]}</option>)}
                            </select>
                        </div>
                    }
                </div>
                {Array.isArray(create.materials) && create.materials.length >= 1 &&
                    <button className={"btn"} type={"submit"}>{getT('Отправить')}</button>
                }
            </form>

        </Modal>
    </div>
}

export default Create;