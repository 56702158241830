import {changeLoaderStatusAC, checkErrorsAPI} from "./CommonReducer";
import {packagingWindowHtml} from "../../printHtml";
import {PackagingHtml} from "../../printHtml/packagingHtml";

const initialState = {
    isOpen: false,
    formPrint: {}
}

export const PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES = {
    packs: 1,
    packs_bins: 1,
    packs_part: 1,
    part: 1,
    part_position: 1
}

const ACTION_TYPE = {
    toggleOpenPage: "PACKAGING_PRINTING/TOGGLE_PAGE",
}

export const actionsPackagingPrinting = {
    onToggleOpenPage: (value) => {
        return {type: ACTION_TYPE.toggleOpenPage, payload: value}
    }
}

const PackagingPrinting = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE.toggleOpenPage: {
            return {
                ...state,
                isOpen: action.payload
            }
        }
        default: {
            return state

        }
    }
}

export const packagingPrintingHtml = (settings_for_print=PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES) => async (dispatch, getState)=>{
    const state = getState();
    const order = state.order.order;
    const manager = state.auth.manager?.name ?? '-';
    const currencies = state.commons?.currencies || [];
    const currency = currencies?.find(item => Number(item.id) === Number(order?.currency))?.name ?? '';
    const client_name = order?.client_name || '-';
    const packagingHtml = new PackagingHtml();
    const unit = 'кг'
    dispatch(changeLoaderStatusAC(true));
    try{
        const content = packagingHtml.generateContentHtml({
            order:order, setting: {...settings_for_print}, unit, currency, manager_name:manager
        });
        packagingWindowHtml.openWindowPrint({content, order})
    }catch (e) {
        console.log(e);
        dispatch(checkErrorsAPI("", `Packaging Print -> create html [message error ${e.message}]`))
    }
    dispatch(changeLoaderStatusAC(false));
}
export default PackagingPrinting;