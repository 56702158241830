export const PROCESSING_EDGE_SET_POINT = "PROCESSING_EDGE/SET_POINT";
export const PROCESSING_EDGE_GROUP_OPERATION_PAGE = "PROCESSING_EDGE/GROUP_OPERATION_PAGE";

export const processingEdgeSetPointAC = (payload)=>{
    return{
        type: PROCESSING_EDGE_SET_POINT,
        payload: payload
    }
}
export const processingEdgeGroupOperationPageAC = ({isOpen})=>{
    return{
        type: PROCESSING_EDGE_GROUP_OPERATION_PAGE,
        payload: {isOpen: isOpen}
    }
}