import {useSelector} from "react-redux";
import {getAuthLookupSelected} from "../selectors/auth-selector";

/**
 * hook useAuthLookup. Проверка на существование пользователя
 * auth_lookup - данные сервера о пользователе
 * @param {boolean} result -
 * @param {string|null} lookup -  найдет по email or phone
 * @param {boolean} passwordSent? -  короткий пароль был отпарвлен на указынй lookup - приходит только в том случаии если отправляем send_password = 1
 * @param {string| null} message_channel - месенджер на который был отправлен короткий пароль  = full_message_channel_list[key]
 * @param {array} available_message_channel_list - список доступных месенджеров
 *
 * @param {object} full_message_channel_list - obj-s всех месенджеров
 * @param {string} full_message_channel_list[key] - ключ который нужно добавить в отправку выбраного message_channel
 * @param {string} full_message_channel_list[key].name - название
 * @param {string} full_message_channel_list[key].default - мессенджер по умолчанию который подключен, куда уйдет пароль сли отправляем send_password = 1
 * @param {string} full_message_channel_list[key].link - ссылка на мессенджер
 *
 *
 * @returns  {object}
 * auth_lookup-
 * @param {boolean} isAuthFind - пользователь был найден
 * @param {boolean} isSendShortPassword - короткий пароль отправлен
 * @param {boolean} isAuthPhoneIsNotSendBot - пльзователя телефон найден но его нету в ботах и пароль не оптавлен, можно доать доступ к мастер паролю
 * @param {boolean} isNewUser -  новый юзер
 *
 * @param {array|| null} available_message_channel_list - array || null
 * @param {string|| null} message_channel - full_message_channel_list[key]  || null
 * @param {object|| null} full_message_channel_list - full_message_channel_list  || null
 */
export default function () {
    const auth_lookup = useSelector(getAuthLookupSelected);

    return {
        auth_lookup,
        isAuthFind: auth_lookup && auth_lookup.lookup && auth_lookup.user ? true : false,
        isSendShortPassword: auth_lookup && auth_lookup.hasOwnProperty('passwordSent') ? auth_lookup.passwordSent : false,
        isAuthPhoneIsNotSendBot: auth_lookup && auth_lookup?.lookup === 'phone' && !auth_lookup.passwordSent,
        isNewUser: auth_lookup && !auth_lookup.lookup && !auth_lookup.user,

        available_message_channel_list: auth_lookup && auth_lookup.hasOwnProperty('available_message_channel_list') ? auth_lookup.available_message_channel_list : null,
        connected_message_channel_list: auth_lookup && auth_lookup.hasOwnProperty('connected_message_channel_list') ? auth_lookup.connected_message_channel_list : null,
        message_channel: auth_lookup && auth_lookup.hasOwnProperty('message_channel') ? auth_lookup.message_channel : null,
        full_message_channel_list: auth_lookup && auth_lookup.hasOwnProperty('full_message_channel_list') ? auth_lookup.full_message_channel_list : null
    }
}