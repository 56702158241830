import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {getAuthUserDT} from "../../../selectors/cabinet-sector";
import IconUserConfirmed from "../../../Icons/IconUserConfirmed";
import IconUserNotConfirmed from "../../../Icons/IconUserNotConfirmed";
import {useLang} from "../../../context/langProvider";
import {useHistory} from "react-router-dom";

const styleIcon = {
    marginLeft: "8px"
}
const ConfirmationOfUserData = () => {
    const {getT} = useLang();
    const history = useHistory();
    const user_state = useSelector(getAuthUserDT);
    const isConfirmed = useMemo(() => {
        if (!user_state) return false;
        return !!(Number(user_state?.email_confirmed) && Number(user_state?.phone_confirmed))
    }, [user_state]);
    const handlerOpenCabinet = () => {
        history.push({
            pathname: '/account',
            state: {
                isOpenUserData: true
            }
        })
    }
    return (
        <>
            {isConfirmed ? <span title={getT("Почта и телефон подтвержден")} onClick={handlerOpenCabinet}>
               <IconUserConfirmed style={styleIcon}/>
               </span>
                : <span title={getT("Не подтвержден телефон или почта")} onClick={handlerOpenCabinet}>
                  <IconUserNotConfirmed style={styleIcon}/>
               </span>
            }
        </>
    );
};

export default ConfirmationOfUserData;