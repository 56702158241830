import getT from "../getT";

export default function ({order, logo}) {
    let cutting_card = order.cutting_card ? order.cutting_card : {}
    const {qr, link_order = ''} = cutting_card ;
    const {id, title, client_name, date} = order;
    return `
        <div class="form-qr-container">
         
            <div class="qr-info">
               <img src="${qr}" alt="" >
                <div style="">
                    <strong>${getT("Заказ")} № : </strong> ${id} "${title}" <br/>
                    <strong>${getT("Дата заказа")} : </strong>${date} <br/>
                    <strong>${getT("Клиент")}:  </strong>${client_name}
                </div>
                
            </div>
            <div class="right" style="text-align:right;">
            ${logo ? `<img src="${logo}" alt="" >` : ''}
            ${!!link_order ? `<div>
        <strong>${getT("Деталировка")}:</strong>
        <br/>
        <a href="${link_order}" target={"_blank"}>${link_order}</a>
    </div> ` : ''}
</div>
        </div>
        <style>
.form-qr-container {
    display: grid;
  grid-template-columns: 1fr auto;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 25px auto;
}

.qr-info {
    display: flex;
    align-items: center;
    gap: 10px;
}
.form-qr-container div {
    line-height: 20px;
}

.form-qr-container strong {
    color: #605d81;
   ont-weight: 400;
}

</style>
  `
}