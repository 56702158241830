import {
    _constructorPartSizes,
    _getItemID,
    _getPartSizes,
    _partGlassConstructor,
    addNewPartAC,
    changeAllPartAC,
    changePartDataAC,
    onToggleSelectedMaterialGoodsIdOfDetailing,
    resetBatchMachiningFormAC
} from "./reducers/OrderReducer";
import {
    changeMirroringTypeInPart,
    getDetailOptionSizesOfType,
    getTranslateMessageAPI,
    random
} from "../helpers/helpers";
import {GLASS_ID_TYPE_MATERIAL} from "../constants";
import {changeBatchMachiningModalValue} from "./reducers/ModalReducer";
import {changeAlertStatusAC, changeLoaderStatusAC} from "./reducers/CommonReducer";
import {getCalculateAdditionalGoodsServiceOfPart} from "../helpers/goods_service_order_part";
import {cleanCncPartMapping} from "../helpers/cleanCncPartMapping";


export const changePartNameThunk = (id, name) => (dispatch, getState) => {
    if (name.length > 50) return false;
    const part = getState().order.order.part.find(e => Number(e.id) === Number(id));
    if (!part) return false
    part.name = name;
    dispatch(changePartDataAC(part));
};
export const changeAllPartsMaterialThunk = (currentMaterialId, changeMaterialId) => (dispatch, getState) => {
    const material = getState().order.order.material.find((m) => Number(m.goods_id) === Number(changeMaterialId));
    const partNoIncludeMaterial = getState().order.order.part.filter(e => Number(e.material) !== Number(currentMaterialId));
    const partsChangeMaterial = getState().order.order.part.filter(e => Number(e.material) === Number(currentMaterialId)).map((part) => {
        const typeHem = part.operations.hem.length > 0 && part.operations.hem[0]?.typeHem ? Number(part.operations.hem[0]?.typeHem) : 0;
        const hemMaterialId = part.operations.hem.length > 0 ? part.operations.hem[0].material : 0;
        const findMaterialHem = hemMaterialId ? getState().order.order.material.find((m) => Number(m.goods_id) === Number(hemMaterialId)) : false;
        let hem_z = (typeHem < 1) ? 0 : (typeHem > 1) ? (findMaterialHem) ? findMaterialHem.z : material.z : material.z;
        return {
            ...part,
            material: material.goods_id,
            z: Number(material.z) + Number(hem_z),
            material_z: Number(material.z),
            operations: {
                ...part.operations,
                hem: Array.isArray(part.operations.hem) && part.operations.hem?.length ? part.operations.hem.map(item => {
                    let updateItem = {
                        ...item
                    };
                    if (Number(item.typeHem) === 1) {
                        updateItem["material"] = material.goods_id;
                        updateItem["material_z"] = Number(material.z);
                    }
                    return updateItem;
                }) : part.operations.hem
            }
        };

    });

    dispatch(changeAllPartAC([...partNoIncludeMaterial, ...partsChangeMaterial]));

};
export const changePartMaterialThunk = (part_id, material_id) => (dispatch, getState) => {
    const state = getState().order.order;
    const part = state.part.find(e => Number(e.id) === Number(part_id));
    const material = state.material.find(m => Number(m.goods_id) === Number(material_id));

    const hasHemOperations = Array.isArray(part.operations.hem) && !!part.operations.hem.length;
    const hemOperation = hasHemOperations ? part.operations.hem[0] : {};
    const typeHem = hemOperation?.typeHem ? Number(hemOperation.typeHem) : 0;
    const hemMaterial = state.material.find(m => Number(m.goods_id) === Number(hemOperation.material));
    let hem_z = 0;
    if (typeHem === 1) {
        hem_z = Number(material.z);
    } else if (typeHem > 1 && hemMaterial) {
        hem_z = Number(hemMaterial.z)
    }
    const updatedHemOperations = hasHemOperations ? part.operations.hem.map(item => {
        if (Number(item.typeHem) !== 1) return item;

        return {
            ...item,
            material: material_id,
            material_z: material.z,
        };
    }) : [];
    const updatedPart = {
        ...part,
        material: material_id,
        z: Number(material.z) + hem_z,
        material_z: material.z,
        operations: {
            ...part.operations,
            hem: updatedHemOperations
        }
    };
    dispatch(changePartDataAC(updatedPart));
    // const part = getState().order.order.part.filter(e => Number(e.id) === Number(id))[0];
    // const material = getState().order.order.material.filter((m) => Number(m.goods_id) === Number(material_id))[0];
    // // const hem_z = part.operations.hem.length > 0 ? part.operations.hem[0].material_z : 0;
    // const typeHem = part.operations.hem.length > 0 && part.operations.hem[0]?.typeHem ?
    //     Number(part.operations.hem[0]?.typeHem) : 0;
    // const hemMaterialId = part.operations.hem.length > 0 ? part.operations.hem[0].material : 0;
    // const findMaterialHem = hemMaterialId ? getState().order.order.material.find((m) => Number(m.goods_id) === Number(hemMaterialId)) : false;
    // let hem_z = (typeHem < 1) ? 0 : (typeHem > 1) ? (findMaterialHem) ? findMaterialHem.z : material.z : material.z;
    // dispatch(changePartDataAC(Object.assign(part, {
    //     material: material_id,
    //     z: Number(material.z) + Number(hem_z),
    //     material_z: Number(material.z),
    //     operations: {
    //         ...part.operations,
    //         hem: Array.isArray(part.operations.hem) && part.operations.hem?.length ? part.operations.hem.map(item => {
    //             let updateItem = {
    //                 ...item
    //             };
    //             if (Number(item.typeHem) === 1) {
    //                 updateItem["material"] = material_id;
    //                 updateItem["material_z"] = Number(material.z);
    //             }
    //             return updateItem;
    //         }) : part.operations.hem
    //     }
    // })))
    // ;

};

// export const change
export const changePartProductThunk = (id, product_id) => (dispatch, getState) => {
    const state = getState().order.order;
    const partToUpdate = state?.part?.find(e => Number(e.id) === Number(id));
    const products = state.product;
    let sizeType = state?.partSizesType;
    let product_of_part_count = products.find(prod => Number(prod?.id) === Number(product_id))?.count || 1;
    let goods_service_added_of_part = getCalculateAdditionalGoodsServiceOfPart(partToUpdate, sizeType, product_of_part_count)
    if (!partToUpdate) return;
    const updatedToPart = {
        ...partToUpdate,
        product: product_id,
        goods_service_added_firm: goods_service_added_of_part
    }
    dispatch(changePartDataAC(updatedToPart));
};


export const changeAllPartTextureThunk = (currentMaterial, texture) => (dispatch, getState) => {
    const partsNoIncludeMaterial = getState().order.order.part.filter(e => Number(e.material) !== Number(currentMaterial));
    const partsIncludeMaterial = getState().order.order.part.filter(e => Number(e.material) === Number(currentMaterial)).map(part => ({
        ...part,
        texture: texture ? 1 : 0
    }));

    const partsUpdate = [...partsNoIncludeMaterial, ...partsIncludeMaterial].map((part, index) => ({
        ...part, id: index + 1
    }));
    dispatch(changeAllPartAC(partsUpdate));


};

export const changePartSizeTextureThunk = ({part_id, texture, x, y, partSize}) => (dispatch, getState) => {
    const part = getState().order.order.part.find(e => Number(e.id) === Number(part_id));
    const products = getState().order.order.product;
    let product_of_part_count = products.find(prod => Number(prod?.id) === Number(part?.product))?.count || 1;
    x = String(x).replace(",", ".");
    y = String(y).replace(",", ".");
    let update = {
        ...part, texture: texture,
        ..._constructorPartSizes(partSize, x, y)
    }
    let goods_service_added_of_part = getCalculateAdditionalGoodsServiceOfPart(update, partSize, product_of_part_count)
    update = {...update, goods_service_added_firm: goods_service_added_of_part}
    dispatch(changePartDataAC(update));

};


export const changePartCountThunk = (id, count, partSize) => (dispatch, getState) => {
    const part = getState().order.order.part.find(e => Number(e.id) === Number(id));
    const products = getState().order.order.product;
    let product_of_part_count = products.find(prod => Number(prod?.id) === Number(part?.product))?.count || 1;

    part.count = Number(count);
    part.goods_service_added_firm = getCalculateAdditionalGoodsServiceOfPart(part, partSize, product_of_part_count);
    dispatch(changePartDataAC(part));

};


export const changePartInfoThunk = (id, text) => (dispatch, getState) => {
    const part = {...getState().order.order.part.filter(e => Number(e.id) === Number(id))[0]};
    part.info_user = text;
    dispatch(changePartDataAC(part));

};

export const setDefaultPartGlassDataThunk = (id, clean = false) => (dispatch, getState) => {
    const part = {...getState().order.order.part.filter(e => Number(e.id) === Number(id))[0]};
    const {steklo} = _partGlassConstructor(GLASS_ID_TYPE_MATERIAL);
    if (clean) {
        delete part.steklo;
    } else {
        part.steklo = steklo;
        part.edge.t.db_id = null;
        part.edge.b.db_id = null;
        part.edge.l.db_id = null;
        part.edge.r.db_id = null;
    }
    dispatch(changePartDataAC(part));

};

export const changePartGlassDataThunk = (id, input, value, side) => (dispatch, getState) => {
    const part = {...getState().order.order.part.filter(e => Number(e.id) === Number(id))[0]};

    if (input === "w") {
        if (value < 10) {
            value = 10;
        } else if (value > 50) {
            value = 50;
        }

    }

    part.steklo[side] = {
        ...part.steklo[side],
        [input]: value
    };
    dispatch(changePartDataAC(part));
};

export const removeAllPartEdgeSideThunk = (currentMaterial) => (dispatch, getState) => {
    const payloadEdge = {
        db_id: null,
        w: null,
        t: null,
        cut: null,
        pre_joint: null,
        radius: null,
        color: ""
    };
    const partSizesType = getState().order.order.partSizesType;
    const partsNoIncludeMaterial = getState().order.order.part.filter(e => Number(e.material) !== Number(currentMaterial));
    const partsIncludeMaterial = getState().order.order.part.filter(e => Number(e.material) === Number(currentMaterial)).map(part => {
        const partSizes = _getPartSizes(partSizesType, part);
        let updatePart = {
            ...part,
            edge: {
                t: {
                    ...part.edge.t,
                    ...payloadEdge
                }, b: {
                    ...part.edge.b,
                    ...payloadEdge
                }, l: {
                    ...part.edge.l,
                    ...payloadEdge
                }, r: {
                    ...part.edge.r,
                    ...payloadEdge
                }
            }
        };
        return Object.assign(updatePart, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y));
    });


    dispatch(changeAllPartAC([...partsNoIncludeMaterial, ...partsIncludeMaterial]));

    // dispatch(getArrayPartsPreviewThunk(partsIncludeMaterial))
};
export const changePartEdgeThunk = (id, band_id, band_side, material_id, isFetchingImage = true) => async (dispatch, getState) => {
    const part = getState().order.order.part.filter(e => Number(e.id) === Number(id))[0];
    const band = getState().order.order.band.filter(e => Number(e.goods_id) === Number(band_id))[0];
    const band_type = getState().order.order.bands_type.filter(e => Number(e.band) === Number(material_id))[0];
    const partSizesType = getState().order.order.partSizesType;

    if (Number(band_id) !== -1 && Number(band_id) !== 0) {
        part.edge[band_side] = {
            ...part.edge[band_side],
            db_id: band.goods_id,
            w: band.y,
            t: band.z,
            cut: 0,
            curve: 0,
            pre_joint: band_type ? band_type.pre_joint : 0,  /// TODO: this.props.productionConstants['production.el_width_pre_joint_line'],
            radius: null,
            color: band?.color || ""
        };
    } else if (Number(band_id) === -1) {
        part.edge[band_side] = {
            ...part.edge[band_side],
            db_id: band_id,
            cut: 1,
            pre_joint: null,
            radius: 1,
            color: band?.color || ""
        };
    } else {
        part.edge[band_side] = {
            ...part.edge[band_side],
            db_id: null,
            w: null,
            t: null,
            cut: null,
            pre_joint: null,
            radius: null,
            color: band?.color || ""
        };
    }
    const partSizes = _getPartSizes(partSizesType, part);
    dispatch(changePartDataAC(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));
    // if (isFetchingImage) {
    //     dispatch(getCurrentPartPreviewThunk(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));
    // }
};
export const changePartAllSidesEdgeThunk = (part, band_values = {}, isFetchingImage = true) => async (dispatch, getState) => {
    const band_type = getState().order.order.bands_type.find(e => Number(e.band) === Number(part?.material));
    const partSizesType = getState().order.order.partSizesType;


    for (const side in part.edge) {
        let band = Number(band_values[side]) !== 0 ? getState().order.order.band.find(e => Number(e.goods_id) === Number(band_values[side])) : false;

        part.edge[side] = {
            ...part.edge[side],
            db_id: band ? band.goods_id : null,
            w: band ? band.y : null,
            t: band ? band.z : null,
            cut: 0,
            curve: 0,
            pre_joint: (band && band_type) ? band_type.pre_joint : 0,  /// TODO: this.props.productionConstants['production.el_width_pre_joint_line'],
            radius: null,
            color: band?.color || ""
        };
    }
    const partSizes = _getPartSizes(partSizesType, part);
    dispatch(changePartDataAC(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));
    // if (isFetchingImage) {
    //     dispatch(getCurrentPartPreviewThunk(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));
    // }
};
export const changeAllPartEdgeThunk = (band_id, current_band_id, call) => (dispatch, getState) => {
    const order = getState().order.order;
    const min_delta_edge_material = order?.production_constants['production.min_delta_edge_material'] || 0
    const materials = order.material;
    const part = order.part;
    const band = order.band.find(e => Number(e.goods_id) === Number(band_id));
    const bandType = order.bands_type;
    const partSizesType = order.partSizesType;
    const keysPartSize = getDetailOptionSizesOfType(partSizesType)


    part?.forEach((_part) => {
        let material = materials?.find(e => Number(e?.goods_id) === Number(_part?.material))
        let material_band_only_connected = material?.band_only_connected;
        let check_band_for_conditional = Number(_part[keysPartSize.y]) >= (Number(band.z) + Number(min_delta_edge_material));
        let material_has_band = material?.connected_band?.some(e => Number(e?.goods_id) === Number(band_id));
        let is_update_part = material_band_only_connected ? material_has_band : true;
        if (is_update_part && check_band_for_conditional) {
            updatePart(_part)
        }
    })

    function updatePart(item) {
        let operations = {...item.operations};
        let operations_inputs = {...item.operations_inputs};

        for (const property in item.edge) {
            if (item.edge[property].db_id !== null &&
                Number(item.edge[property].db_id) === Number(current_band_id)) {
                let band_type = bandType?.find(e => Number(e.band) === Number(item.material));
                if (Number(item.edge[property].id) !== -1 && Number(item.edge[property].id) !== 0) {
                    item.edge[property] = {
                        ...item.edge[property],
                        db_id: band.goods_id,
                        w: band.y,
                        t: band.z,
                        cut: 0,
                        curve: 0,
                        pre_joint: band_type ? band_type.pre_joint : 0,  /// TODO: this.props.productionConstants['production.el_width_pre_joint_line'],
                        radius: null,
                        color: band?.color || ""
                    };

                } else if (Number(item.edge[property].id) === -1) {
                    item.edge[property] = {
                        ...item.edge[property],
                        db_id: band_id,
                        cut: 1,
                        pre_joint: null,
                        radius: 1,
                        color: band?.color || ""
                    };

                } else {
                    item.edge[property] = {
                        ...item.edge[property],
                        db_id: null,
                        w: null,
                        t: null,
                        cut: null,
                        pre_joint: null,
                        radius: null,
                        color: band?.color || ""
                    };
                }

            }
        }

        // for (const property in item.srez) {
        //     if (item.srez[property].db_id != null && Number(item.srez[property].db_id) === Number(current_band_id)) {
        //         item.srez[property] = {
        //             ...item.srez[property],
        //             db_id: band.goods_id
        //         };
        //     }
        // }

        for (let key in operations) {
            if (key === "frez" || key === "hem") {
                let updateOperation = operations[key].map(item => {
                    if (Number(item.db_id) === Number(current_band_id)) {
                        return {
                            ...item,
                            db_id: band.goods_id
                        };
                    }
                    return item;
                });
                operations = {
                    ...operations,
                    [key]: updateOperation
                };
            }
        }
        for (let key in operations_inputs) {
            if (key === "frez" || key === "hem") {
                let updateOperation = operations_inputs[key].map(item => {
                    if (Number(item.db_id) === Number(current_band_id)) {
                        return {
                            ...item,
                            db_id: band.goods_id
                        };
                    }
                    return item;
                });
                operations_inputs = {
                    ...operations_inputs,
                    [key]: updateOperation
                };
            }
        }

        dispatch(changePartDataAC({
            ...item,
            operations: {
                ...operations
            },
            operations_inputs: {
                ...operations_inputs
            }
        }));
    }


    if (call) call();


};


export const changePartSrezDataValueThunk = (id, side, input, value) => (dispatch, getState) => {
    const part = getState().order.order.part.filter(e => Number(e.id) === Number(id))[0];
    const partSizesType = getState().order.order.partSizesType;

    if (input === "otstup") {
        if (value < 0) {
            value = 0;
        }
        if (value > Number(part.z) / 2) {
            value = Number(part.z) / 2;
        }
    }
    if (input === "ugol") {
        if (Number(value) < -45) value = -45;
        if (Number(value) > 45) value = 45;
    }

    part.srez[side][input] = value;

    const partSizes = _getPartSizes(partSizesType, part);
    dispatch(changePartDataAC(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));

};

export const changePartEdgePreJointThunk = (id, pre_joint, band_side) => (dispatch, getState) => {
    const part = getState().order.order.part.filter(e => Number(e.id) === Number(id))[0];
    const partSizesType = getState().order.order.partSizesType;

    part.edge[band_side] = {
        ...part.edge[band_side],
        pre_joint: part.edge[band_side].ifp_curve ? part.edge[band_side].pre_joint : pre_joint
    };

    const partSizes = _getPartSizes(partSizesType, part);
    dispatch(changePartDataAC(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));

};

export const changePartEdgeCurveThunk = (id, curve, band_side) => (dispatch, getState) => {
    const part = getState().order.order.part.filter(e => Number(e.id) === Number(id))[0];
    const partSizesType = getState().order.order.partSizesType;

    part.edge[band_side] = {
        ...part.edge[band_side],
        curve: part.edge[band_side].ifp_curve ? part.edge[band_side].curve : curve
    };

    const partSizes = _getPartSizes(partSizesType, part);
    dispatch(changePartDataAC(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));

};

export const copyOrderPartThunk = (id) => (dispatch, getState) => {
    const part = getState().order.order.part.find(e => Number(e.id) === Number(id));
    const parts = getState().order.order.part;
    const CONST = getState().order.order.production_constants;

    const copyPart = JSON.parse(JSON.stringify(part));
    copyPart.id = _getItemID(parts);
    if (CONST.hasOwnProperty("production.paz_side_torec_off") && Number(CONST["production.paz_side_torec_off"]) === 1) {
        copyPart.name = copyPart.name;
    } else if (Number(CONST["production.new_detail_name_no_create"]) === 1) {
        copyPart.name = "";
    } else {
        copyPart.name = random(1, 1, 1);
    }
    let {uuid, ...reset} = copyPart;
    // let ui_id =
    dispatch(addNewPartAC(reset, id, true));

};
export const copyOrderPartVerOrHorThunk = (id, mirroringType, operationType) => (dispatch, getState) => {
    const part = getState().order.order.part.filter(e => Number(e.id) === Number(id))[0];
    const parts = getState().order.order.part;
    const copyPart = JSON.parse(JSON.stringify(part));
    const CONST = getState().order.order.production_constants;

    copyPart.id = _getItemID(parts);
    if (CONST.hasOwnProperty("production.paz_side_torec_off") && Number(CONST["production.paz_side_torec_off"]) === 1) {
        copyPart.name = copyPart.name;
    } else {
        copyPart.name = random(1, 1, 1);
    }
    let changeParts = changeMirroringTypeInPart({part: copyPart, types: operationType, mirroringType});
    let {uuid, ...reset} = changeParts;
    dispatch(addNewPartAC(reset, id, true, mirroringType, operationType));
    // dispatch(mirroringActionOperationItemAC(copyPart, mirroringType, operationType));
};
export const togglePlaneOperationsThunk = ({part_id, plane_id, side}) => (dispatch, getState) => {
    const part = {...getState().order.order.part.find(e => Number(e.id) === Number(part_id))};
    const operation = [...getState().order.order.plane_operations].find(e => Number(e.id) === Number(plane_id));
    let is_contains_plane_in_part = part?.plane_operations?.find(e => Number(e.id) === Number(plane_id) && e?.side === side)
    //только одна обработка на сторону
    let exclusive_on_same_side = !!Number(operation?.exclusive_on_same_side);
    //только по одной сторонне обработка
    let exclusive_on_detail = !!Number(operation?.exclusive_on_detail)
//единственная обработка в детали
    let one_only_on_detail = !!Number(operation?.one_only_on_detail)
    const partSizesType = getState().order.order.partSizesType;
    if (!part.hasOwnProperty("plane_operations")) {
        part.plane_operations = [];
    }

    if (one_only_on_detail && !is_contains_plane_in_part) {
        part.plane_operations = [{
            ...operation,
            side: side,
            inner_id: _getItemID(part.plane_operations)
        }];
    } else if (is_contains_plane_in_part) {
        let actual_plane = part?.plane_operations?.filter(e => Number(e.id) !== Number(plane_id) && e?.side !== side)
        part.plane_operations = [...actual_plane];
    } else {
        part.plane_operations = [
            ...part.plane_operations,
            {
                ...operation,
                side: side,
                inner_id: _getItemID(part.plane_operations)
            }
        ];
    }


    const partSizes = _getPartSizes(partSizesType, part);
    dispatch(changePartDataAC(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));
};
export const addPlaneOperationsThunk = (part_id, plane_id, side) => (dispatch, getState) => {
    const part = {...getState().order.order.part.find(e => Number(e.id) === Number(part_id))};
    const operation = [...getState().order.order.plane_operations].filter(e => Number(e.id) === Number(plane_id))[0];

    const partSizesType = getState().order.order.partSizesType;
    if (!part.hasOwnProperty("plane_operations")) {
        part.plane_operations = [];
    }
    part.plane_operations = [
        ...part.plane_operations,
        {
            ...operation,
            side: side,
            inner_id: _getItemID(part.plane_operations)
        }
    ];


    const partSizes = _getPartSizes(partSizesType, part);
    dispatch(changePartDataAC(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));
};

export const deletePlaneOperationsThunk = ({part_id, plane_id, side}) => (dispatch, getState) => {

    const part = {...getState().order.order.part.find(e => Number(e.id) === Number(part_id))};
    const operation = [...part.plane_operations].find(e => Number(e.id) === Number(plane_id) && e.side === side);
    const operations = [...part.plane_operations].filter(e => Number(e.id) !== Number(operation?.id));
    const partSizesType = getState().order.order.partSizesType;

    part.plane_operations = [...operations];

    const partSizes = _getPartSizes(partSizesType, part);
    dispatch(changePartDataAC(Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y))));
};


export const batchProcessingOfParts = ({parts_ids, operation}) => (dispatch, getState) => {
    const order = getState().order.order;
    const part_store = order.part;
    let update_parts = part_store;
    if (!part_store.length) return false;
    dispatch(changeLoaderStatusAC(true));
    try {
        if (operation.type === 'delete') {
            update_parts = part_store.filter(part => !parts_ids.includes(part.uuid));

        }
        if (operation.type === 'texture') {
            let checked = operation.payload;
            update_parts = part_store?.map(part => {
                if (parts_ids.includes(part.uuid)) {
                    return {
                        ...part,
                        texture: checked
                    }
                }
                return part
            })
        }
        if (operation.type === 'edge') {
            let update_edge = {
                db_id: null,
                w: null,
                t: null,
                cut: null,
                pre_joint: null,
                radius: null,
                color: ""
            }
            update_parts = part_store?.map(part => {
                if (parts_ids.includes(part.uuid)) {
                    return {
                        ...part,
                        edge: {t: update_edge, b: update_edge, l: update_edge, r: update_edge}

                    }
                }
                return part
            })
        }
        if (operation.type === 'clear-cnc') {
            update_parts = part_store?.map(part => {
                if (parts_ids.includes(part.uuid)) {
                    return cleanCncPartMapping(part)
                }
                return part
            })
        }

        if (JSON.stringify(part_store) !== JSON.stringify(update_parts)) dispatch(changeAllPartAC(update_parts));

        dispatch(resetBatchMachiningFormAC());
        dispatch(onToggleSelectedMaterialGoodsIdOfDetailing({
            checked_ids_part: []
        }));
        dispatch(changeBatchMachiningModalValue({
            isOpen: false,
            processing_current_material: null
        }));

    } catch (e) {
        dispatch(changeAlertStatusAC(true, `${e.message}  ${getTranslateMessageAPI("Произошел сбой, попробуйте позже!")}`));
        dispatch(changeLoaderStatusAC(false));
    }
    dispatch(changeLoaderStatusAC(false));

}