import getT from "../getT";
import {getCalculateAmountAndVat, getCalculateVat} from "../../pages/ShopingCard/shoping_card_helper";
import {formatNumbersThousands} from "../../helpers/helpers";

export default function ({order}) {
    let currency = order?.currency;
    let calculate = order?.calculate?.paints_edge;
    if (!calculate || !calculate?.entry?.length) return ''
    let production_vat = order?.production_constants?.['production.VAT_add_price_if_more_than_0'] || 0;

    let production_vat_display = order?.production_constants?.['production.vat_added_tax_show_needed'] || 1;
    let production_vat_display_short = !Number(production_vat_display) && !!Number(production_vat);

    const getTotal = () => {
        return parseFloat(`${calculate?.summ}`.replace(',', '.')).toFixed(2)
    };
    return `
     <div class='section-table-pack section-gap' style="font-size: 0px">
      <table style="  page-break-inside: auto;">
        <caption>${getT('Покраска ручек')}</caption>
                <thead>
                <tr>
                    <th>№</th>
                    <th>${getT("Материал")}</th>
                    <th>${getT("Название обработки")}</th>
                    <th>${getT("Покраска")}</th>
                    <th>${getT("Минимальный заказ")}</th>
                    <th>${getT("Кол-во")}</th>
                    <th>${getT("Цена(ед)")}</th>
                    <th>${getT("Сумма")}</th>
                </tr>
                </thead>
                <tbody>
                ${renderPaint(calculate?.entry)}
            ${Total(getTotal(), production_vat, production_vat_display_short, currency, "Итого покраска ручек")} 
       
        </tbody>
 <tfoot></tfoot>
</table>
</div>
   
    
    `

}

function Total(total, production_vat_display_short, production_vat, currency, title = "Стоимость") {
    const total_vat = !!Number(production_vat) ? getCalculateVat(total, Number(production_vat)) : 0;
    const amount_total_vat = !!Number(production_vat) ? getCalculateAmountAndVat(total, total_vat) : 0;

    return `
    
     <tr class='tr-bg-green'>
            <td colSpan="9" style="text-align:right;" class='amount'>
               <strong> ${getT(title)} : ${formatNumbersThousands(total)}</strong>
                ${!!Number(production_vat) ? production_vat_display_short ? `<span style="font-size: 14px">
                ${currency} / ${amount_total_vat} ${currency} ${getT("с НДС")} 
            </span>` : `+ ${total_vat} ${getT("НДС")} = ${amount_total_vat} ${currency}` : currency}
            </td>
        </tr>
    `
}

function renderPaint(calculate) {

    let is_has_calculate = !!(Array.isArray(calculate) && calculate?.length);
    if (!is_has_calculate) return <></>
    return is_has_calculate ? calculate?.map((item, index) => {

        return item?.paint?.map((e, index) => {
            return (`<tr>
                <td>${index + 1}</td>
                <td>[${item?.material.id}] ${item?.material.name}</td>
                <td>${e.edge_name}</td>
                <td>${e.name}</td>
               <td>${e?.multiplicity} ${getT("м.п.")}</td>
               
                <td>${e?.count} ${getT("м.п.")}</td>
                <td>${e?.price}</td>
                <td>${Number(e.cost).toFixed(2)}</td>
            </tr>`)
        })?.join('')

    })?.join('') : <></>;

}
