export const getCellsFormInitialValue = state => {
    return state.cellsLeftore.cellsForm
}

export const getCellsModal = state => {
    return state.modal.cellModal
}

export const getCellsStorage = (state) => {
    return state.cellsLeftore.cellsDT
}

export const getCellFullInfo = (state)=>{
    return state.cellsLeftore.cellFullInfo
}
export const getCellInFullInfo = (state)=>{
    return state.cellsLeftore.isOpenCellFullInfo
}



export const getRemainsModal = state => {
    return state.modal.remainsModal
}

export const getRemainFormInitialValue = state => {
    return state.cellsLeftore.remainFrom
}
export const getRemainsDT = (state) => {
    return state.cellsLeftore.remainsDT
}
export const getRemainInfoDT = (state) => {
    return state.cellsLeftore.remainInfoDT
}



export const getPlansDT = (state) => {
    return state.cellsLeftore.planesDT
}

export const getPlanModal = state => {
    return state.modal.planModal
}
export const getPlanEditForm = state => {
    return state.cellsLeftore.planEditForm
}
export const getInfoPlanDT = state => {
    return state.cellsLeftore.planDt
}

export const getChoiceMaterial = (state)=>{
    return state.cellsLeftore.choiceMaterial
}

export const getStocksDT = (state)=> state.cellsLeftore.stocksDT;
export const getStockOrdersDT = (state)=> state.cellsLeftore.stocksOrdersDT;