import {useSelector} from "react-redux";
import {getOrder} from "../selectors/order-selector";
import ApiService from "../api/api";
import MainApi from "../api/MainApi";

const apiService = new ApiService()
const main_api = new MainApi()
export default function () {
    const order = useSelector(getOrder);
    // const {firm, code} = useParams();
    return async () => {
        let responseParts = await apiService.getAllPartSizes(order.part, order.bands_type, order.material, order.band, order?.client);

        return new Promise(resolve => {
            const getNameEdge = (side, part) => {
                let findEdge = order.band.find(band => Number(band.goods_id) === Number(part?.edge?.[side]?.db_id));
                if (findEdge && findEdge?.translate?.[localStorage.getItem("lang")]) {
                    return {
                        name: findEdge?.translate?.[localStorage.getItem("lang")]
                    };
                }
                return {
                    name: findEdge ? findEdge?.name : "-"
                };
            };
            let dataApi = responseParts.map((part, index) => {

                let findMaterial = order.material.find(material => Number(material.goods_id) === Number(part.material));
                return {
                    id: part.id,
                    Material: findMaterial ?
                        findMaterial?.translate?.[localStorage.getItem("lang")] ?
                            findMaterial?.translate?.[localStorage.getItem("lang")] : findMaterial?.name : "-",
                    x: part.x,
                    x1: part.x1,
                    x2: part.x2,
                    y: part.y,
                    y1: part.y1,
                    y2: part.y2,
                    z: part.z,
                    count: part.count,
                    txt: part.texture,
                    edgeU: getNameEdge("t", part).name,
                    edgeD: getNameEdge("b", part).name,
                    edgeL: getNameEdge("l", part).name,
                    edgeR: getNameEdge("r", part).name,
                    hem: part.operations.cut_to?.length ? "1" : 0,
                    cut_to: part.operations.cut_to?.length ? "1" : "0",
                    cut_edge: Object.values(part.srez).filter(srez => Boolean(srez.db_id))?.length ? "1" : "0",
                    "xnc.bore": part.operations.bore?.length ? "1" : "0",
                    "xnc.paz": part.operations.paz?.length ? "1" : "0",
                    "xnc.frez": part.operations.frez?.length ? "1" : "0",
                    "xnc.trough": part.operations.trough?.length ? "1" : "0",
                    link: `${apiService._glLink}/${order.code}/${part.id}` || "-"
                };

            });

            resolve([...dataApi]);
        });
    }
}