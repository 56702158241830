import header from "./ShortOrderForm/header"
import short_order_view from "./ShortOrderForm/short_order_view";
import footer from "./ShortOrderForm/footer";
import processing_edge_calculate from "./ProcessingEdge/processing_edge_calculate";
import processing_paint_calculate from "./ProcessingEdge/processing_paint_calculate";

export class ShortOrderHtml {
    constructor() {
    }

    generateContentHtml({order, full = false, logo, goods_service_for_part_by_firm}) {
        return `
       <div class="container">
      ${header({order, logo})}
${short_order_view({order, full: full, goods_service_for_part_by_firm})}
      
${full ? processing_edge_calculate({order}) : ''}     
${full ? processing_paint_calculate({order}) : ''}     
${footer({order})}
   </div>
    `
    }
}


