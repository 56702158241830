import React from 'react';
import {Link} from "react-router-dom";

const BasicMenu = ({getT}) => {
    return (
        <div className="login-header-links-container">
            <a  href={'https://ifurn.pro/'} target={'_blank'}>{getT('О проекте')}</a>
            {/*<Link to={'/pages/about'}>{getT('О проекте')}</Link>*/}
            <Link to={'/pages/privacy'}>{getT('Политика конфиденциальности ')}</Link>
            <Link to={'/pages/offerta'}>{getT('Договор публичной оферты')}</Link>
            <Link to={'/pages/payment_card_security_policy'}>{getT('Политика безопасности ')}</Link>
            <Link to={'/pages/refund_terms'}>{getT('Условия возврата денег')}</Link>
            <Link to={'/pages/price_list'}>{getT('Прайс-лист')}</Link>
            <Link to={'/pages/contacts'}>{getT('Контакты')}</Link>
        </div>
    );
};

export default BasicMenu;