import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {useDispatch} from "react-redux";
import {changeLoaderStatusAC} from "../../../store/reducers/CommonReducer";
import useExelApi from "../../../HOOK/useExelApi";

export const ExportToExcel = ({  fileName='order', title }) => {
  const generateDataExelApi = useExelApi();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
const dispatch = useDispatch();
  const exportToCSV = async (fileName) => {
    dispatch(changeLoaderStatusAC(true));
    try {
      const apiData = await generateDataExelApi();
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      dispatch(changeLoaderStatusAC(false));
    }catch (e) {
      dispatch(changeLoaderStatusAC(false));
      console.log(e.message);
    }
  };

  return (
    <div onClick={(e) => exportToCSV(fileName)}>
       <span className={"singl-action-item-name"}>
                  {title}
                  </span>
    </div>
  );
};