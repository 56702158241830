import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getStockOrdersDT} from "../../../selectors/cells-leftovers-selector";
import {
    actionsCellsLeftovers,
    onGetCellsStoragesDt,
    onSetStockOrdersDT,
} from "../../../store/reducers/StorageCellsLeftovers";
import Plus from "../../../Icons/Plus";
import {onTogglePlanModal, onToggleRemainModal} from "../../../store/reducers/ModalReducer";
import PlaneModal from "../../../components/Modals/PlaneModal";
import ShowOrder from "../../../Icons/ShowOrder";
import RemainsCreateModal from "./RemainCreateModal";
import RemainsListModal from "../Remains/RemainsListModal";
import PlaneListModal from "../Plan/PlanListModal";


const CustomerBalancesAdd = ({getT, onComeBack}) => {
    const dispatch = useDispatch();
    const stockOrdersDT = useSelector(getStockOrdersDT);
    const onToggleRemainAddModal = ({isOpen, orderIn, materials}) => {
        dispatch(onToggleRemainModal({
            key: 'create',
            dt: {isOpen: isOpen, order_in: orderIn, materials}
        }))
    }

    const onTogglePlaneAddModal = (orderId, material) => {
        dispatch(actionsCellsLeftovers.setInitialValuesForm({
            form: 'planEditForm',
            initialValue: {order: orderId, orders_goods_stock_rest_plan_id: null}
        }))
        dispatch(onTogglePlanModal({key: 'create', dt: {isOpen: true, materials: material}}))
    }

    const onShowRemainList = (orderIn, materials) => {
        dispatch(onToggleRemainModal({
            key: 'listViewOrder',
            dt: {isOpen: true, materials: materials, order_in: orderIn}
        }))
    }
    const onShowPlanList = (orderIn, materials) => {
        dispatch(onTogglePlanModal({
            key: 'planeListOrder',
            dt: {isOpen: true, materials: materials, order: orderIn}
        }))
    }
    useEffect(() => {
        dispatch(onGetCellsStoragesDt())
        dispatch(onSetStockOrdersDT())
    }, [])


    return <div className={""}>
        <div className="section-header">
            <h2 className="title">{getT('Остаток приходуется по заказу')}</h2>
        </div>

        <div className="section-stocks">

            {Array.isArray(stockOrdersDT) && stockOrdersDT.length >= 1 &&
            <div className="table">
                <table className={'table-wrapper main-parts-table'}>
                    <thead>
                    <tr>
                        <th>{getT('Заказ')}</th>
                        <th>{getT('Планируемые поступления')}</th>
                        <th>{getT('Зарезервированные остатки')}</th>
                    </tr>
                    </thead>
                    {stockOrdersDT.map(stock => {
                        if (stock.hasOwnProperty('materials') && Array.isArray(stock.materials.data) && stock.materials.data.length >= 1) {
                            return <tr>
                                <td>{stock.mame}</td>
                                <td>{stock.hasOwnProperty('goods_stock_plan') && <>
                                    {Array.isArray(stock.goods_stock_plan) && stock.goods_stock_plan.length >= 1 &&
                                        <span className=""
                                              onClick={() => onShowPlanList(stock.id, stock.materials.data)}>
                                       <ShowOrder/>
                                   </span>
                                    }
                                    { (Number(stock.status) > 3) &&
                                    <span className=""
                                          onClick={() => onTogglePlaneAddModal(stock.id, stock.materials.data)}><Plus/></span>
                                    }
                                </>}</td>
                                <td>

                                    {stock.hasOwnProperty('goods_stock') && <>
                                        {Array.isArray(stock.goods_stock) && stock.goods_stock.length >= 1 &&
                                            <span
                                                onClick={() => onShowRemainList(stock.id, stock.materials.data)}> <ShowOrder/></span>
                                        }
                                        {  (Number(stock.status) > 3) &&
                                        <span onClick={() => onToggleRemainAddModal({
                                            isOpen: true,
                                            orderIn: stock.id,
                                            materials: stock.materials.data
                                        })}><Plus/></span>
                                        }
                                </>}</td>
                            </tr>
                        }
                    })}
                </table>

            </div>
            }
        </div>

        <PlaneModal/>
        <RemainsCreateModal getT={getT}/>
        <RemainsListModal getT={getT}/>
        <PlaneListModal getT={getT}/>
    </div>
}

export default CustomerBalancesAdd;
