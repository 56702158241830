export  const getBase = (state)=>{
    return state.settingFirm.base;
}
// export  const getProduction = (state)=>{
//     return state.settingFirm.production;
// }
export  const getFolder = (state)=>{
    return state?.settingFirm?.folder;
}
export const getOrdersProduction = (state)=>{
    return state?.order?.order?.production_constants;
}
export const getIdFirm = (state)=>{
    return state?.order?.order?.firm;
}
export  const getLocalLang = state=>{
    return state?.translation?.localLang;
}


export const getCodeUserId = (state)=>{
    return state?.settingFirm?.userId;
}