import * as React from "react";

function FolderIcon(props) {
    return (
        <svg className={"folder-icon"} width="26" height="24" viewBox="0 0 26 24" fill="none"
             xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M24.6654 20.1667C24.6654 20.7855 24.4195 21.379 23.9819 21.8166C23.5444 22.2542 22.9509 22.5 22.332 22.5H3.66536C3.04653 22.5 2.45303 22.2542 2.01545 21.8166C1.57786 21.379 1.33203 20.7855 1.33203 20.1667V3.83333C1.33203 3.21449 1.57786 2.621 2.01545 2.18342C2.45303 1.74583 3.04653 1.5 3.66536 1.5H9.4987L11.832 5H22.332C22.9509 5 23.5444 5.24583 23.9819 5.68342C24.4195 6.121 24.6654 6.71449 24.6654 7.33333V20.1667Z"
                stroke="#050038" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
}

export default FolderIcon;
