import React from 'react';
import {FieldArray, Form, Formik} from "formik";
import Select from 'react-select';
import IconTrash from "../../../Icons/IconTrash";
import {getGoodsServicePartCountCalculateWithType} from "../../../helpers/goods_service_order_part";


const INITIAL_ITEM_GOODS_SERVICE = {id: null, count: null, item: null, is_edit: false, value_detail: null}
const CreateForm = ({goods_service_part_order, part, sizeType, getT, handlerSubmit, product_count = 1}) => {
    const getInitialFormValues = () => {
        let goods_service_added_of_part = part?.goods_service_added_firm || [];
        if (goods_service_added_of_part?.length) {
            return goods_service_added_of_part?.reduce((acc, e) => {
                let result_count = getGoodsServicePartCountCalculateWithType(e, part, sizeType, product_count);
                let data = {
                    id: Number(e?.id),
                    value_detail: result_count?.value_detail,
                    count: result_count?.value,
                    is_edit: result_count?.is_edit,
                    item: e
                }
                acc.push(data)
                return acc
            }, []);
        }
        return [INITIAL_ITEM_GOODS_SERVICE]

    }
    const onSubmit = (values) => {
        handlerSubmit(values)
    }
    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: '38px', // Устанавливаем высоту самому Select
            minHeight: '38px', // Можно также установить минимальную высоту
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '110px', // Ограничиваем высоту списка
            overflowY: 'auto',   // Добавляем прокрутку при необходимости
            fontSize: '13px'
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Убедимся, что меню находится поверх других элементов
        }),
    };

    return (<div className="goods-service-part-wrap">
            <Formik
                initialValues={{goods_service: getInitialFormValues()}}
                onSubmit={onSubmit}
                render={({values, setFieldValue, setValues, handleChange}) => {
                    const goods_service_selected = goods_service_part_order?.filter(item => {
                        let is_includes_form = Array.isArray(values?.goods_service) && values?.goods_service?.some(field => Number(field?.id) === Number(item?.id))
                        return !is_includes_form
                    })
                    return <Form>
                        <FieldArray name="goods_service">
                            {({insert, remove, push}) => (
                                <div>
                                    <div className="d-flex items-center justify-between " style={{margin: "0 0 15px 0"}}>
                                        <strong
                                            style={{whiteSpace: "nowrap"}}>{getT("Всего")}: {values?.goods_service?.reduce((acc, item) => {
                                            return acc + Number(item?.count)
                                        }, 0)}</strong>
                                        {!!goods_service_selected?.length && <button
                                            type="button"
                                            className="btn btn-add"
                                            onClick={() => push({...INITIAL_ITEM_GOODS_SERVICE})}
                                        >
                                            +
                                        </button>}
                                    </div>
                                    {values.goods_service.length > 0 &&
                                        values.goods_service.map((goods, index) => {
                                            let value_select = goods?.id ? {
                                                value: goods.item.id,
                                                label: goods.item.name
                                            } : null
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col">
                                                        <label htmlFor={`goods_service.${index}.id`}
                                                               className={"label"}>{getT("Услуги")}</label>
                                                        <Select
                                                            styles={customStyles}
                                                            options={goods_service_selected?.map(item => ({
                                                                label: item.name, value: item?.id
                                                            }))}
                                                            value={value_select}
                                                            name={`goods_service.${index}.id`}
                                                            onChange={(selectedValues) => {
                                                                let find_select = goods_service_part_order?.find(item => Number(item?.id) === Number(selectedValues.value));
                                                                const updatedGoods = [...values.goods_service];
                                                                let result = getGoodsServicePartCountCalculateWithType(find_select, part, sizeType, product_count)
                                                                updatedGoods[index] = {
                                                                    ...updatedGoods[index],
                                                                    id: selectedValues.value,
                                                                    item: find_select,
                                                                    value_detail: result.value_detail,
                                                                    count: result.value,
                                                                    is_edit: result.is_edit
                                                                }
                                                                setValues({...values, goods_service: updatedGoods})
                                                            }}
                                                        />
                                                    </div>
                                                    {goods?.id && <>
                                                        <div className="col">
                                                            <label htmlFor={`goods_service.${index}.value_detail`}
                                                                   className={"label"}>{getT("На детали")}</label>
                                                            <input type="number"
                                                                   className={'form-input'}
                                                                   value={goods?.value_detail}
                                                                   name={`goods_service.${index}.value_detail`}
                                                                   onChange={(e)=>{
                                                                       let count_detail = e.target.value;
                                                                       const updatedGoods = [...values.goods_service];
                                                                       updatedGoods[index] = {
                                                                           ...updatedGoods[index],
                                                                           value_detail: count_detail,
                                                                           count: Number(count_detail) * Number(part.count) * Number(product_count),
                                                                       }
                                                                       setValues({...values, goods_service: updatedGoods})

                                                                   }
                                                                   }
                                                                   disabled={!goods?.is_edit}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor={`goods_service.${index}.count`}
                                                                   className={"label"}>{getT("Кол-во")}</label>
                                                            <input type="number"
                                                                   className={'form-input'}
                                                                   value={goods?.count}
                                                                   name={`goods_service.${index}.count`}
                                                                   // onChange={handleChange}
                                                                   disabled={true}
                                                            />
                                                        </div>
                                                    </>
                                                    }
                                                    {<div className="col">
                                                        <IconTrash onClick={() => remove(index)}/>
                                                    </div>}
                                                </div>
                                            )
                                        })}

                        </div>
                        )}
                    </FieldArray>
                    <div className="d-flex justify-center items-center" style={{gap: '20px', marginTop: "65px"}}>

                        <button type="submit" className={"btn m-0"}
                                style={{width: 'max-content'}}>{getT("Создать")}</button>
                    </div>
                </Form>
                }}
                />
        </div>
    );
};

export default CreateForm;