/**
 * cleanCncPartMapping.  должна удалять ЧПУ-операции для детали.
 * Если врезная ручка имеет торцевой паз, и он больше 0 (параметры paz_w и paz_z),
 * то необходимо удалить операцию пазования, связанную с этой деталью.
 * Если врезная ручка имеет срез торца и он больше 0 (параметры paz_w и paz_z),
 * и его значение больше 0 (параметр srez_torca_grad), то нужно удалить операцию среза.
 * Операции, связанные с пазом или срезом торца, должны быть очищены, если они присутствуют.
 * Сама операция ЧПУ для среза торца не должна удаляться, если она не связана с врезной ручкой.
 * @param {object} part  - деталь
 * @returns  {object} part - деталь после удаления чпу
 */

let _initial_operation = {
    bore: [],
    cut_to: [],
    frez: [],
    hem: [],
    paz: [],
    trough: []
}
let _srez = {
    b: {otstup: "", ugol: "", db_id: ""},
    l: {otstup: "", ugol: "", db_id: ""},
    r: {otstup: "", ugol: "", db_id: ""},
    t: {otstup: "", ugol: "", db_id: ""}
}

export const cleanCncPartMapping = (part) => {
    let edge_hand = part?.edge_edit?.hand ?? {};
    let edgeHandNotDelete =Object.keys(edge_hand)?.reduce((acc, side)=> {
        let item = edge_hand?.[side];
        if(!Number(item?.paz_w) && !Number(item?.paz_z)){
            acc[side] = item;
        }
        return acc
    }, {});

    let part_srez = part.srez;
    let srezUpdate = Object.keys(part_srez)?.reduce((acc, side)=> {
        let srez = part_srez[side];
        let findHand = edgeHandNotDelete?.[side] ?? null;
        if(findHand || !srez.hasOwnProperty('edge_edit_handle_id') ){
            acc[side] = srez;
        }
        return acc;
    }, _srez);

    return {
        ...part,
        srez: srezUpdate,
        operations: {
            ..._initial_operation,
        },
        operations_inputs: {
            ..._initial_operation
        },
        edge_edit: {
            ...part.edge_edit,
            hand: edgeHandNotDelete,

        }
    }
}