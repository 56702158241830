import * as React from "react";

function GoBackArrow(props) {
    return (
        <svg className={props?.cName ? `${props?.cName}` : 'back-arrow'} width="8" height="16" viewBox="0 0 8 16" fill="none"
             xmlns="http://www.w3.org/2000/svg"  {...props}>
            <path d="M7 1L0.999999 8L7 15" stroke="#050038" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    );
}

export default GoBackArrow;
