export const ORDER_SET_GOOD_SERVICE_PART = "ORDER/SET_GOOD_SERVICE_PART";
export const ORDER_PART_SET_GOODS_SERVICE_TYPE = "ORDER/PART_SET_GOODS_SERVICE_TYPE"
export const ORDER_TOGGLE_CURRENCY_GOODS_SERVICE_TYPE = "ORDER/TOGGLE_CURRENCY_ADDITIONAL_GOODS_SERVICE_TYPE"
export const ORDER_SET_ADDITIONAL_GOODS_SERVICE_FOR_ORDER = "ORDER/SET_ADDITIONAL_GOODS_SERVICE_FOR_ORDER"
export const ORDER_REMOVE_ADDITIONAL_GOODS_SERVICE_FOR_ORDER = "ORDER/REMOVE_ADDITIONAL_GOODS_SERVICE_FOR_ORDER"
export const ORDER_MULTI_CHOICE_MATERIAL_BAND_DELETE_IN_ORDER = "ORDER/MULTI_CHOICE_MATERIAL_BAND_DELETE_IN_ORDER";



/**
 * дополнительные услуги к заказу и деталям.
 * @param {object | null}  payload-
 */
export const orderSetGoodServicePartAC = (payload) => {
    return {
        type: ORDER_SET_GOOD_SERVICE_PART,
        payload: payload
    }
}
/**
 * Добавление услуги деталям.
 *
 * @param {number}  part_id-
 * @param {array} goods_service  -
 * @param {number} goods_service.id  -
 * @param {number} goods_service.count  -
 * @param {number} goods_service.price  -
 * @param {number} goods_service.currency  -
 * @param {date} goods_service.date  - yyyy-mm-dd hh-mm-ss
 */
export const orderPartSetGoodServiceTypeAC = ({part_id, goods_service}) => {

    return {
        type: ORDER_PART_SET_GOODS_SERVICE_TYPE,
        payload: {part_id: part_id, goods_service: goods_service}
    }
}
/**
 * Удаление  услуги деталям.
 *
 * @param {number}  part_id-
 * @param {array} goods_service_ids  - id's goods service
 */
export const toggleCurrencyAdditionalGoodsServiceType = ({
                                                             goods_service_added_part_firm,
                                                             goods_service_added_order_firm = []
                                                         }) => {
    return {
        type: ORDER_TOGGLE_CURRENCY_GOODS_SERVICE_TYPE,
        payload: {
            goods_service_added_part_firm: goods_service_added_part_firm,
            goods_service_added_order_firm: goods_service_added_order_firm
        }
    }
}

/**
 * Добавление улсуги к заказу
 *
 * @param {object}  payload-
 */
export const orderSetAdditionalGoodsServiceForOrderAC = (payload) => {
    return {
        type: ORDER_SET_ADDITIONAL_GOODS_SERVICE_FOR_ORDER,
        payload: payload
    }
}
/**
 * Удаление улсуги к заказу
 *
 * @param {number}  id-
 */
export const orderRemoveByIdAdditionalGoodsServiceForOrderAC = (id) => {

    return {
        type: ORDER_REMOVE_ADDITIONAL_GOODS_SERVICE_FOR_ORDER,
        payload: id
    }
}



/**
 * Удаление выбраных материалов и кромок в заказе
 *
 * @param {array}  part- filter actual part
 * @param {array}  band- filter actual band
 * @param {array}  material- filter actual material
 * @param {array}  bands_type- filter actual bands_type
 */
export const orderMultiChoiceMaterialAndBandDeleteInOrderAC = ({part, band, bands_type, material: material}) => {
    return {
        type: ORDER_MULTI_CHOICE_MATERIAL_BAND_DELETE_IN_ORDER,
        payload: {part: part, band: band, bands_type: bands_type, material:material}
    }
}
