import React from "react";

const Plus = ({cName = ''})=>{
    return(
        <svg className={cName}
            width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 5.8335V22.1668" stroke="#274CFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.83203 14H22.1654" stroke="#274CFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
export  default Plus;