import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import './UploadProjectModal.css';
import {changeImportProjectModalValue} from "../../../store/reducers/ModalReducer";
import {importProjectsChangeThunk} from "../../../store/reducers/CommonReducer";
import {useLang} from "../../../context/langProvider";

const ChangeData = () => {
  const {getT} = useLang();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal.importProject.data);
  const checkedArr = useSelector((state) => state.modal.importProject.arr);
  const name_part_put = useSelector((state) => state.modal.importProject.name_part_put);
  const sendImportData = () => {
    dispatch(importProjectsChangeThunk());
  };

  const materials = useSelector((state) => state.order.order.material);
  const bands = useSelector((state) => state.order.order.band);
  const goods = useSelector((state) => state.order.order.goods);
  const changeDataValue = (value) => dispatch(changeImportProjectModalValue('data', value));
  const changeNamePartPutValue = (value) => dispatch(changeImportProjectModalValue('name_part_put', value));
  const changeArrValue = (value) => dispatch(changeImportProjectModalValue('arr', value));
  const renderItems = (items, forValue, materials, type) => {
    return items.map( e => {
      let change_value = forValue.filter(m => m.code_project == e.CODE)[0].ifp_code;
      return (
        <div className="import_projects_change_data_item">
          <div className="import_projects_change_data_item_info">
            <div className={'import_projects_change_data_item_info_item'}>
              <b>{getT("ID/Код")}: </b>

              <span>{e.ID} ({e.CODE})</span>
            </div>
            <div className={'import_projects_change_data_item_info_item'}>
              <b>{getT("Название")}: </b>
              <span>{e.NAME}</span>
            </div>
            <div className={'import_projects_change_data_item_info_item'}>
              <b>{getT("Размеры")}: </b>
              <span>{e.L} x {e.W} x {e.T}</span>
            </div>
            <div className={'import_projects_change_data_item_info_item'}>
              <b>{getT("Количество")}: </b>
              <span>{e.COUNT}</span>
            </div>
            <div className={'import_projects_change_data_item_info_item'}>
              <b>{getT("Тип калькуляции")}: </b>
              <span>{getTypeName(e.TYPEID)}</span>
            </div>
          </div>
          <div className="import_projects_change_data_item_form">
            <div className="form-field">
              <select className="form-input"
                      placeholder=""
                      value={change_value}
                      defaultValue={change_value}
                      onChange={(event) => changeNewGoodsValue(e.CODE, event.target.value, type)}
                      required={type === 'material' ? 'required' : ''}
              >
                {/*<option value={''} disabled={'disabled'} selected={'selected'}>--- {getT('Выбрать')} ---</option>*/}
                <option value={'new_project'} selected={'selected'}> {getT('Создать')} </option>
                {materials.map( e => {
                  return <option key={e.goods_id} value={e.goods_id}>{e.name}</option>
                })}
              </select>
            </div>
          </div>
        </div>

      );
    });
  }

  const changeNewGoodsValue = (code, value, type) => {
    let array_change = null;
    switch (type) {
      case 'material':
        array_change = [...data.new_goods.new1.sheet];
        break;
      case 'band':
        array_change = [...data.new_goods.new1.band];
        break;
      case 'goods':
        array_change = [...data.new_goods.new1.simple];
        break;
    }
    const current_new_goods_m = array_change.map(e => {
      if(value == "new_project"){
        e.ifp_code = value;
      }
      if(e.code_project == code) {
        e.ifp_code = Number(value);
      }
      return e;
    });
    let current_new_goods = data.new_goods;
    switch (type) {
      case 'material':
        current_new_goods.new1.sheet = current_new_goods_m;
        break;
      case 'band':
        current_new_goods.new1.band = current_new_goods_m;
        break;
      case 'goods':
        current_new_goods.new1.simple = current_new_goods_m;
        break;
    }

    changeDataValue({
      ...data,
      new_goods: current_new_goods
    });
  }

  const getTypeName = (type) => {
    switch (type) {
      case 'sheet':
        return getT('Плитный материал');
      case 'band':
        return getT('Кромка');
      case 'simple':
        return getT('Товар');
    }
  }

  return <div className={'import_projects_change_data_s_container add-material forms'}>
    <div className={'import_projects_change_data_title'}>
      <h3 className={"mt-0"}>{getT('Необходимо заменить материалы из проектов')}:</h3>
    </div>
    <form onSubmit={(event) => {
      event.preventDefault();
      sendImportData();
    }}>
      <div className="import_projects_change_data_items">
        {renderItems(data.change_goods.material, data.new_goods.new1.sheet, materials, 'material')}
        {renderItems(data.change_goods.band, data.new_goods.new1.band, bands, 'band')}
        {renderItems(data.change_goods.goods, data.new_goods.new1.simple, goods, 'goods')}
      </div>
      <div className="form-field">
        <label className={'form-input-checkbox-label'} htmlFor={'import_project_modal_checkbox'}>
          <input type="checkbox"
                 className="form-input form-input-checkbox"
                 id={'import_project_modal_checkbox'}
                 onChange={(event) => changeArrValue(event.target.checked)}
                 checked={checkedArr ? 'checked' : ''}
          />
          <span>{getT('Добавлять ли в название детали исходное название файла?')}</span>
        </label>
      </div>
      <div className="form-field">
        <label className="label" htmlFor="material-type">
          {getT('Название для выдачи в производство')}:
        </label>
        <input type="text"
               className="form-input"
               value={name_part_put}
               onChange={(event) => changeNamePartPutValue(event.target.value)}
        />
      </div>
      <div className="form-field">
        <input className="form-input submit" type="submit" value={getT('Отправить')} />
      </div>
    </form>
  </div>;

}

export default ChangeData;
