import {useDispatch, useSelector} from "react-redux";
import {generateDataForPacked} from "../helpers/generateDataForPacked";
import useSubscribeIrfamePackage, {PackedType} from "./useSubscribeIrfamePackage";
import {changeAlertStatusAC, changeLoaderMessageAC, changeLoaderStatusAC} from "../store/reducers/CommonReducer";
import {saveOrderProjectDataThunk, setOrderCopyProject, setOrderProjectDataAC} from "../store/reducers/OrderReducer";
import ApiService from "../api/api";
import {getProjectVersionCode} from "../selectors/common-selector";
import {LS_CONNECTED_BOX3D} from "../constants";
import {getTranslateMessageAPI} from "../helpers/helpers";
import {getGroupCalculateAdditionalGoodsServiceOfParts} from "../helpers/goods_service_order_part";

const apiService = new ApiService();

/**
 * hook Реализация калькуляции упаковки для сохранения актуального  заказа
 *

 *
 * @method _getPointIFramePack -  private Калькуляция упаковки.
 * @method onUpdateStoreOrder  -  сохранение в сторе калькуляции упаковки.
 * @method onUpdateAndGetProject  -  Сохранить  и получить  проект для  отправки на калькуляцию упаковки
 * @return { object } .
 */
export default function () {
    const client_id = useSelector(state => state.auth.user.id);
    const goods_service_part_order = useSelector(state => state.order.goods_service_part_order);
    const version_code = useSelector(getProjectVersionCode)
    const {iframeCurrent, sendMessageAsyncToApi} = useSubscribeIrfamePackage({})
    const dispatch = useDispatch();
    const _getPointIFramePack = async (project) => {
        const response_errors = await apiService.checkProject(project);
        let goods_service_added_part_firm= getGroupCalculateAdditionalGoodsServiceOfParts(project, goods_service_part_order)
        if(!Number(localStorage.getItem(LS_CONNECTED_BOX3D)) || response_errors){

            return Promise.resolve({
                ...project,
                packing: null,
                goods_service_added_part_firm:goods_service_added_part_firm
            })
        }
        try {
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Генерируем данные упаковки...')));
            let data = await generateDataForPacked({
                    order: project,
                    settingsProduction: project.production_constants
                }
            );
            let response = null;
            if (data?.part?.length && data?.product?.length) {
                response = await sendMessageAsyncToApi(JSON.stringify(data), PackedType.sendDataNoPaint);
            }
            return Promise.resolve({
                ...project,
                packing: response,
                goods_service_added_part_firm:goods_service_added_part_firm
            })
        } catch (e) {
            let goods_service_added_part_firm= getGroupCalculateAdditionalGoodsServiceOfParts(project, goods_service_part_order)

            dispatch(changeAlertStatusAC(true, e?.message));
            return Promise.resolve({
                ...project,
                packing: null,
                goods_service_added_part_firm:goods_service_added_part_firm

            })
        }
    }

    const onUpdateStoreOrder = async (arg_order = null) => {
        if (!arg_order) throw  new Error('order empty')

        try {
            dispatch(changeLoaderStatusAC(true));

            let response_order =  await _getPointIFramePack(arg_order);

            dispatch(setOrderProjectDataAC(response_order));
            dispatch(setOrderCopyProject(response_order));
            dispatch(changeLoaderStatusAC(false));
            return Promise.resolve(response_order)
        } catch (e) {
            dispatch(changeAlertStatusAC(true, e?.message));
            dispatch(changeLoaderStatusAC(false));
            return Promise.resolve()
        }
    }

    const onUpdateAndGetProject = async () => {
        try {
            let project_order = null;
            const isUpdate = await dispatch(saveOrderProjectDataThunk(false, false, false, null));
            if (!isUpdate) return false;
            dispatch(changeLoaderStatusAC(true));
            const getProject = await apiService.getProject(version_code.version, version_code.code,
                client_id,
                localStorage.getItem("ifurn_service_main_user_firm"),
                true, null);
            project_order = getProject ? getProject : null;
            dispatch(changeLoaderStatusAC(false));
            return await onUpdateStoreOrder(project_order);

        } catch (e) {
            dispatch(changeAlertStatusAC(true, e?.message));
            return Promise.reject()
        }
    }


    return {
        onUpdateStoreOrder: onUpdateStoreOrder, iframeCurrent, onUpdateAndGetProject
    }

}