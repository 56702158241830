import React from 'react';
import {connect} from "react-redux";
import {changeWarningModalValue} from "../../../store/reducers/ModalReducer";

import WarningModal from "./WarningModal";


class WarningModalContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return this.props.isOpen ? <WarningModal open={this.props.isOpen}
                             onClose={this.props.closeModal}
                             onSuccessful={this.props.onSuccessful}
                             message={this.props.message}
                             title={this.props.title}
        /> : null;
    }
}

let mapStateToProps = (state) => {
    return {
        isOpen: state.modal.warningModal.isOpen,
        onSuccessful: state.modal.warningModal.onSuccessful,
        message: state.modal.warningModal.message,
        title: state.modal.warningModal.title,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        closeModal: () => {
            dispatch(changeWarningModalValue({isOpen:false, message:null, title:null, additional_data: null, onSuccessful: null}))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WarningModalContainer));
