import {PROCESSING_EDGE_GROUP_OPERATION_PAGE, PROCESSING_EDGE_SET_POINT} from "../actions/processing-edge-action";

const initialState = {
    processing_edge_point: {
        round: null,
        hand: null,
        curve: null,
        hand_paint: null
    },
    page_group_operation: {
        isOpen: false,
        filter_choice: {
            type_flange: null,
            flange: null,
            edge: null,
        }
    }
}
const ProcessingEdgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROCESSING_EDGE_GROUP_OPERATION_PAGE:{
            return {
                ...state,
                page_group_operation: {
                    ...state.page_group_operation,
                    ...action.payload
                }
            }
        }
        case PROCESSING_EDGE_SET_POINT: {
            return {
                ...state,
                processing_edge_point: {
                    ...state.processing_edge_point,
                    ...action.payload
                }
            }
        }
        default: {
            return state
        }
    }
}
export default  ProcessingEdgeReducer