import React, {useCallback, useState} from "react";
import Danger from "../../Icons/Danger";
import "./Footer.css";
import {useDispatch, useSelector} from "react-redux";
import {getCheckProjectErrorsThunk} from "../../store/reducers/CommonReducer";
import {successSaveOrderProject} from "../../store/reducers/OrderReducer";
import Save from "../../Icons/Dotts/Save";
import {getRuleSettingsProject} from "../../selectors/common-selector";
import imgSupport from "../../images/icons/support.png"
import TechnicalSupport from "../TechnicalSupport";
import {useLang} from "../../context/langProvider";
import IconQuestion from "../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../store/constats/help_article_constants";
import {helpArticleGetByIdToggleModalThunk} from "../../store/thunks/help-article-thunk";

const Footer = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const openModal = useCallback( () => dispatch(getCheckProjectErrorsThunk(true, true)), [dispatch]);
    const ruleSettingProject = useSelector(getRuleSettingsProject)
    const hasErrors = useSelector(state => state.commons.errors ? true : false);
    const firmName = useSelector(state => state?.order?.order?.firm_name)
    const successSaveOrder = useSelector(state => state.order.successSaveOrder);
    const [isOpenTechnical, setIsOpenTechnical] = useState(false);
    const firmContact = useSelector(state => state.order.order.firm_contact);
    const orderId = useSelector(state => state.order.order.id);
    if (successSaveOrder) {
        setTimeout(() => {
            dispatch(successSaveOrderProject(false));
        }, 3000);
    }
    const handlerHelpArticle = (id) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }
    return (
        <div className="footer-wrapper">
            <div className="footer-container">
                <Danger onClick={openModal} hasErrors={hasErrors} className={'footer-danger'}/>
                {successSaveOrder && <span className="success_save_order"><Save/></span>}
                <div className="footer-center-information-container">
                    <p style={{fontSize: '10px', fontWeight: '500', margin: '5px 0px'}}>
                       <strong>{orderId }
                           <IconQuestion
                               onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.submenu)}
                           />
                       </strong>
                         | {" "}
                        { firmName?.[localStorage.getItem('lang')] ? firmName?.[localStorage.getItem('lang')] + ' | ' : ' '} {' '}{ruleSettingProject?.copyright} </p>
                </div>
                {firmContact && <img src={imgSupport} alt={''} width={'20px'}
                                     style={{marginLeft: 'auto', marginRight: '8px', cursor: 'pointer'}}
                                     onClick={() => (setIsOpenTechnical(true))}/>}
                {firmContact && isOpenTechnical &&
                    <TechnicalSupport isOpen={isOpenTechnical} onClose={() => (setIsOpenTechnical(false))} getT={getT}
                                      key={'modal-technical'} data={
                        {
                            ...firmContact,
                            name: firmName?.[localStorage.getItem('lang')] ? firmName?.[localStorage.getItem('lang')] : ''
                        }
                    }/>}
                <a className="link questions" rel={'noreferrer'} href="https://help.ifurn.pro" target={'_blank'}>
                    {getT('Есть вопросы?')}
                </a>
            </div>
        </div>
    );
};

export default Footer;
