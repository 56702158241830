import ApiNewService from "../../api/apiNew";
import ApiService from "../../api/api";
import {
    CHANGE_FILTER_VALUE,
    CLEAR_FILTERS, SET_BOX_GENERAL, SET_BOX_PARAMS, SET_BOX_PARTS,
    SET_BOXES, SET_EDITOR_BOX_ID,
    TOGGLE_CONSTRUCTOR_IS_OPEN,
    TOGGLE_IS_LOADING, TOGGLE_LIST_IS_OPEN, UPDATE_BOX_GENERAL_VALUE, UPDATE_BOX_PARTS_CHECKED
} from "./actionTypes";
import store, {AppDispatch, RootState} from "../../store/store";
import {
    IBox,
    IBoxEditorFieldGeneral,
    IBoxEditorFieldParams,
    IBoxEditorFieldParts,
    INewBoxInterface
} from "./interfaces";
import {
    _getItemID,
    addBoxGoodsDataAC,
    addNewPartAC, addNewPartBoxAC,
    addOrderBoxAC,
    deleteBoxAC, deleteOrderGoodsDataAC, deleteOrderPartBoxDataAC,
    deleteOrderPartDataAC
} from "../../store/reducers/OrderReducer";
import {INITIAL_DEFAULT_PART_EDGE_EDIT} from "../../store/constats/processing-edge-const";
import {getUiIdForPart} from "../../helpers/helpers_part";

const api = new ApiService();

export const _transformTableParts = (part: any) => {
    if (!part.operations.bore) part.operations.bore = [];
    if (!part.operations.paz) part.operations.paz = [];
    if (!part.operations.frez) part.operations.frez = [];
    if (!part.operations.hem) part.operations.hem = [];
    if (!part.operations.cut_to) part.operations.cut_to = [];
    if (!part.operations.trough) part.operations.trough = [];
    part.disabled = true;
    return part;
}
export const onSetPartsDT = (parts: IBoxEditorFieldParts[]) => {
    return {
        type: SET_BOX_PARTS,
        payload: parts
    }
}
export const onUpdatePartsDTChecked = (partId: number, isChecked: boolean) => {
    return {
        type: UPDATE_BOX_PARTS_CHECKED,
        payload: {
            partId, isChecked
        }
    }
}

export const onSetGeneralDT = (generals: IBoxEditorFieldGeneral[]) => {
    return {
        type: SET_BOX_GENERAL,
        payload: generals
    }
}
export const onUpdateGeneralDTValue = (article_symbol_general_id: number, value: number) => {
    return {
        type: UPDATE_BOX_GENERAL_VALUE,
        payload: {
            article_symbol_general_id, value
        }
    }

}

export const toggleListOpen = (status: boolean) => {
    return {
        type: TOGGLE_LIST_IS_OPEN,
        status: status
    }
}

export const toggleConstructorOpen = (status: boolean) => {
    return {
        type: TOGGLE_CONSTRUCTOR_IS_OPEN,
        status: status
    }
}

export const toggleIsLoading = (status: boolean) => {
    return {
        type: TOGGLE_IS_LOADING,
        status: status
    }
}

export const setBoxes = (boxes: IBox[]) => {
    return {
        type: SET_BOXES,
        boxes: boxes
    }
}

export const setBoxesParams = (params: IBoxEditorFieldParams[]) => {
    return {
        type: SET_BOX_PARAMS,
        params: params
    }
}

export const setBoxEditorId = (id: number, projectId?: number) => {
    return {
        type: SET_EDITOR_BOX_ID,
        id: id,
        projectId: projectId,
    }
}

export const changeFilterValue = (input: string, value: any) => {
    return {
        type: CHANGE_FILTER_VALUE,
        input: input,
        value: value,
    }
}

export const clearFilters = () => {
    return {
        type: CLEAR_FILTERS
    }
}

export const openBoxConstructor = (boxId: number, projectId?: number) => (dispatch: AppDispatch) => {
    dispatch(setBoxEditorId(boxId, projectId));
    dispatch(toggleConstructorOpen(true));
}

export const fetchBoxes = () => (dispatch: AppDispatch) => {
    dispatch(toggleIsLoading(true));
    api.getBoxes()
        .then(res => {
            if (res) dispatch(setBoxes(_mapFetchedBoxes(res)));
            dispatch(toggleIsLoading(false));
        })

}

export const constructBox = (params: IBoxEditorFieldParams[], newBox: INewBoxInterface) => (dispatch: AppDispatch, getState: typeof store.getState) => {
    const boxes = getState().order.order.boxes;
    const deletePartsDetail = getState().boxes.boxParts.filter((p: IBoxEditorFieldParts) => {
        if (!p.isChecked) {
            return {
                id_part_article: p.id_part_article, name: p.name
            }
        }
    });
    const updateGeneral = getState().boxes.boxGeneral.map((general: IBoxEditorFieldGeneral) => ({
        article_symbol_general_id: general.article_symbol_general_id,
        value: general.value
    }))
    let box_id = _getItemID(boxes);
    dispatch(toggleIsLoading(true));
    api.constructBox(newBox, deletePartsDetail, updateGeneral)
        .then(res => {

            const {part, goods} = res;
            dispatch(addOrderBoxAC({
                ...res,
                id: _getItemID(boxes),
                article_id: Number(newBox.article_id),
                boxParam: params,
                new_box: {
                    ...newBox,
                    help: {
                        html: res.html,
                        link: res.help_pdf
                    }
                },
            }))

            if (part && goods) {
                const parts = getState().order.order.part;
                const partsInd = [...parts].length === 0 ? 1 : Math.max.apply(null, [...parts].map((e) => e.id)) + 1;
                const partsBox = part.map((part: any, ind: number) => {
                    if (!part.hasOwnProperty('srez')) {
                        part.srez = {
                            t: {
                                otstup: '',
                                ugol: '',
                                db_id: ''
                            },
                            b: {
                                otstup: '',
                                ugol: '',
                                db_id: ''
                            },
                            l: {
                                otstup: '',
                                ugol: '',
                                db_id: ''
                            },
                            r: {
                                otstup: '',
                                ugol: '',
                                db_id: ''
                            }
                        }
                    }
                    part.edge_edit = {...INITIAL_DEFAULT_PART_EDGE_EDIT};
                    part.name = `${newBox.box_article_id}. ` + part.name;
                    // part.box_id=partsInd + ind;
                    part.box_id = box_id;
                    part.id = partsInd + ind;
                    part.uuid = getUiIdForPart()?.uuid;
                    return _transformTableParts(part);
                    // dispatch(addNewPartAC(_transformTableParts(part)));
                });
                if (partsBox.length >= 1) {
                    dispatch(addNewPartBoxAC(partsBox))
                    // dispatch(addNewPartAC(partsBox))
                }

                goods.filter((g: any) => g.tovar === '1').forEach((g: any) => {
                    g.disabled = true;
                    dispatch(addBoxGoodsDataAC(g));
                })
                dispatch(toggleIsLoading(false));
            }
        })

};

export const deleteBoxThunk = (boxId: number) => (dispatch: AppDispatch, getState: typeof store.getState) => {
    const parts = getState().order.order.part || [];
    const goods = getState().order.order.goods || [];
    dispatch(deleteBoxAC(boxId));
    parts.forEach((part: any) => {

        if (Number(part.box_id === boxId)) {
            dispatch(deleteOrderPartBoxDataAC(part.box_id))
        }
        ;
    });
    goods.forEach((good: any) => {
        if (Number(good.box_id === boxId)) dispatch(deleteOrderGoodsDataAC(good.goods_id));
    });
};

export const _mapFetchedBoxes = (boxes: any) => {
    if (!Array.isArray(boxes)) return [];
    return boxes.map((box) => {
        return {
            id: Number(box.article_id),
            name: String(box.name),
            image: box.picture && box.picture.length > 23 ? String(box.picture) : null,
            picture_all: box.picture_all,
            code: `${String(box.article_id)}_${String(box.firm)}_${String(box.type)}`,
            description: String(box.furn_type_name),
            x: {
                min: Number(box.x_min),
                max: Number(box.x_max),
            },
            y: {
                min: Number(box.y_min),
                max: Number(box.y_max),
            },
            z: {
                min: Number(box.z_min),
                max: Number(box.z_max),
            },
        }
    });
};

export const fetchBoxParams = (boxId: number) => (dispatch: AppDispatch) => {
    dispatch(toggleIsLoading(true));
    api.getBoxParams(boxId)
        .then(res => {
            if (res) {
                dispatch(setBoxesParams(_mapFetchedBoxParams(res.symbols)));
                if (res.hasOwnProperty('parts')) {
                    dispatch(onSetPartsDT(_mapFetchedBoxParts(res.parts)))
                }
                if (res.hasOwnProperty('general')) {
                    dispatch(onSetGeneralDT(res.general))
                }
            }
            dispatch(toggleIsLoading(false));
        })
}
export const _mapFetchedBoxParts = (dt: any) => {
    return dt.map((part: any) => {
        return {
            ...part,
            isChecked: true
        }
    })
}
export const _mapFetchedBoxParams = (params: any) => {
    if (!Array.isArray(params)) return [];
    return params.map((param) => {
        return {
            article_symbol_id: Number(param.article_symbol_id),
            max: Number(param.max),
            min: Number(param.min),
            name: param.name,
            symbol: param.symbol,
            symbol_id: Number(param.symbol_id),
            type_input: param.type_input,
            type_mat: param.type_mat,
            type_material: param.type_material,
            type_material_name: param.type_material_name,
        }
    });
};