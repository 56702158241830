import getT from "../../getT"

export default function ({xnc, type_name=false}){
  return (
    ` <tr>
        <th></th>
        <th>${getT('количество деталей с фрезеровками')}</th>
                <th>${getT('количество фрезеровок')}</th>
                <th>${getT('длина фрезерного реза')}</th>
        <th></th>
      </tr>
      <tr>
        <th> ${type_name? type_name : ( getT(xnc?.type_name) ?? '')}</th>
      
                <td>${+Number(xnc?.part_frez_count ?? '').toFixed(2)}</td>
                <td>${+Number(xnc?.frez_count ?? '').toFixed(2)}</td>
                <td>${+Number(xnc?.frez_cut_count ?? '').toFixed(2)}</td>
        <td></td>
      </tr>
  `
  );
}