export const coordsMark = (x, y) => `
<svg 
    baseProfile="tiny"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:ev="http://www.w3.org/2001/xml-events"
    xmlns:xlink="http://www.w3.org/1999/xlink"
	id="coords-mark"
	x="${x}"
	y="${y}"
>
	<text
		x="10"
		y="15"
		font-size="15px"
		font-family="sans-serif"
	>
		y
	</text>
	<text
		x="23"
		y="30"
		font-size="15px"
		font-family="sans-serif"
	>
		x
	</text>
	<path 
		d="m 1 26 l 0 -19 l -1 0 l 2 -7 l 2 7 l -1 0 l 0 19 z m 2 -2 l 17 0 l 0 -1 l 7 2 l -7 2 l 0 -1 l -17 0 z z" 
		fill="black"
		transform="scale(1.5)"
	/>
</svg>
`
