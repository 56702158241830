import React from "react";
import "./Actions.css";
import Add from "../../../Icons/Actions/Add";
import AttachDrawings from "../../../Icons/Actions/AttachDrawings";
import Print from "../../../Icons/Actions/Print";
import SurfaceTreatment from "../../../Icons/Actions/SurfaceTreatment";
import Detail from "../../../Icons/HeaderActions/Detail";
import Material from "../../../Icons/HeaderActions/Material";
import {useDispatch, useSelector} from "react-redux";
import {
    changeAlertStatusAC,
    checkProjectAndSaveBeforeOpenPacking,
    checkProjectBeforeSaveProject,
    getGiblabDataThunk,
    getGiblablDataAPIThunk
} from "../../../store/reducers/CommonReducer";

import MaterialClient from "../../../Icons/HeaderActions/MaterialClient";
import {
    changeAddClientMaterialModalValue,
    changeBatchMachiningModalValue,
    changeImportProjectModalValue,
    changeMaterialsProjectModalValue,
    changePlaneOparetionsModalValue,
    getCompanyNameImportOffline,
    onChangeCombineMaterialModal,
    onTogglePackedModal,
    setFileManagerDT
} from "../../../store/reducers/ModalReducer";
import Download from "../../../Icons/Dotts/DownloadProject";
import MaterialProject from "../../../Icons/MaterialProject";
import {clearDetailNotesTh} from "../../../store/reducers/OrderReducer";
import {useDetectClickOutside} from "react-detect-click-outside";
import {checkOrderOperationsExist, setSubstrateBody} from "../../../helpers/helpers";
import CopyChup from "../../../Icons/OperationHistory/CopyChup";
import {isStateFilterCncOverride} from "../../../store/reducers/MaterialsReducer";
import ChpuGroup from "../../../Icons/OperationHistory/ChpuGroup";
import NounDeleteNote from "../../../Icons/Actions/NounDeleteNote";
import {toggleListOpen} from "../../Boxes/actions";
import GlDropdown from "../../../pages/CuttingChart/GlDropdown";
import {getIsProductionOldInterface} from "../../../selectors/production-selectors";
import IconSubmenu from "../../../Icons/Actions/IconSubmenu";
import IconXlsx from "../../../Icons/Actions/IconXlsx";


import {ExportToExcel} from "./ExportToExcel";
import {useLang} from "../../../context/langProvider";
import ExportLibraryMaterial from "../ui/ExportLibraryMaterial";
import IconPacked from "../../../Icons/IconPacked";
import {actionsPackagingPrinting} from "../../../store/reducers/PackagingPrinting";
import CuttingCartNavigation from "../ui/CuttingCartNavigation";
import {LS_CONNECTED_BOX3D} from "../../../constants";
import {getRuleSettingsProject} from "../../../selectors/common-selector";
import {generateShortOrderDetailingHtml} from "../../../store/thunks/cutting-thunk";
import IconQuestion from "../../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../store/constats/help_article_constants";
import {helpArticleGetByIdToggleModalThunk} from "../../../store/thunks/help-article-thunk";
import {processingEdgeGroupOperationPageAC} from "../../../store/actions/processing-edge-action";
import processinEdgeImg from "../../../images/edgeEditImg.png"

const GIBLAB_BLANK_ACTION = "blank_html";
const GIBLAB_BLANK_SHORT_WITHOUT_ACTION = "order_blank_short_without_html";
const Actions = (props) => {
    let {toggleAddPartModal, toggleMaterialsModal, triggerApiPackAndSaveStoreOrder} = props;
    // ----------------------------------------------------------------
    const dispatch = useDispatch();
    // ----------------------------------------------------------------
    const {getT} = useLang();

    const [add, toggleAdd] = React.useState(false);
    const [print, togglePrint] = React.useState(false);
    const [menu, toggleMenu] = React.useState(false);
    const setting_rules_project = useSelector(getRuleSettingsProject)

    // ----------------------------------------------------------------
    const store_order_root = useSelector(state => state.order);
    const order = store_order_root.order;
    const goods = store_order_root.goods;
    const part = order.part;
    const orderHaveOperations = checkOrderOperationsExist(order);
    const isSateFilterCnv = useSelector((state) => state.materials.filter_cnc_override);
    const isProductionOldInterface = useSelector(getIsProductionOldInterface);
    const firm = localStorage.getItem('ifurn_service_main_user_firm') || order.firm;

    const isEditStatusOrder = !!(order && (Number(order.status) === 1));
    // ----------------------------------------------------------------

    const refMenu = useDetectClickOutside({onTriggered: () => toggleMenu(false)});
    const refAdd = useDetectClickOutside({onTriggered: () => onToggleAdd(false)});
    const refPrint = useDetectClickOutside({onTriggered: () => togglePrint(false)});
    const productionCONST = useSelector((state) => state.order.order.production_constants);
    // ----------------------------------------------------------------

    const onToggleAdd = (add) => {
        setSubstrateBody(add, "add");
        toggleAdd(add);

    };
    const openImportOfflineProject = () => dispatch(getCompanyNameImportOffline({isOpen: true, key: firm}));
    const openPackedPage = async () => {
        await dispatch(checkProjectBeforeSaveProject(null, () => {
            dispatch(onTogglePackedModal({isOpen: true}))

        }));
    }
    const openPackingPrintingPage = async () => {
        await triggerApiPackAndSaveStoreOrder();
        const result = await dispatch(checkProjectAndSaveBeforeOpenPacking())
        if (result) {
            dispatch(actionsPackagingPrinting.onToggleOpenPage(true))
        }
    }

    const handlerGiblab = (type) => async event => {
        const response = await triggerApiPackAndSaveStoreOrder();

        switch (type) {
            case GIBLAB_BLANK_ACTION: {
                dispatch(getGiblabDataThunk(type));
                break
            }
            case GIBLAB_BLANK_SHORT_WITHOUT_ACTION:
            case "ifp_xnc": {
                dispatch(getGiblablDataAPIThunk(type));
                break;
            }
            case "short_order_form_html": {
                dispatch(getGiblabDataThunk(type, response, true))
                break;
            }
            default: {
                dispatch(changeAlertStatusAC(true, `type not undefined ${type}`));

            }
        }
    }
    const handlerShortOrderFormFullHtml = async () => {
        await props.onUpdateAndGetProject();
        dispatch(getGiblabDataThunk('short_order_form_html', null, false))
    }
    const openPlaneOperations = () => {
        dispatch(changePlaneOparetionsModalValue("isOpen", true));
    }
    const openProcessingEdgeGroupPage = () => {
        dispatch(processingEdgeGroupOperationPageAC({isOpen: true}))
    }
    const openImportProject = () => dispatch(changeImportProjectModalValue("isOpen", true));

    const shortOrderFormDetailing = async () => {
        await triggerApiPackAndSaveStoreOrder();
        dispatch(generateShortOrderDetailingHtml(order))
        // const content_html = new ShortOrderHtml().generateContentHtml({order: order, full: false, logo:setting_rules_project?.logoUrl, company_name: setting_rules_project?.name });
        //
        // shortOrderFormWindowHtml.openWindowPrint({content: content_html, order: order});

    }
    const handlerHelpArticle = (id) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }

    return (<div className="actions-container">
        {isEditStatusOrder && <div className="singl-action helper-article" ref={refAdd}>
            <div>

                <Add onClick={() => onToggleAdd(!add)}/>
                <IconQuestion
                    onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.input_initial_data)}
                />
            </div>
            {add && (<div className="add">
                <h3 className="add-title">{getT("Добавить")}</h3>
                <div className="wrapper-add__list">
                            <span className={"header_top_actions_item helper-article"}
                                  onClick={() => toggleAddPartModal(true)}>
                                                <Detail/>

                                 <span className={"header_top_actions_item_name"}>
                                   {getT("Деталь")}
                                 </span>
                                <IconQuestion
                                    onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.adding_part)}
                                />

                            </span>

                    <span
                        onClick={() => openImportProject()}
                        className={"header_top_actions_item helper-article"}
                    >
                                      <Download/>
                                      <span className={"header_top_actions_item_name "}>
                                        {getT("Импорт проекта")}
                                      </span>
                             <IconQuestion
                                 onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.project_import)}
                             />
                                </span>
                    {productionCONST["production.import_online_on"] === "1" && <span
                        onClick={() => openImportOfflineProject()}
                        className={"header_top_actions_item"}
                    >
                                      <Download/>
                                      <span className={"header_top_actions_item_name"}>
                                        {getT("Импорт офлайн")}
                                      </span>
                                </span>}
                    <span
                        onClick={() => {
                            toggleMaterialsModal(true);
                            onToggleAdd(false)
                        }}
                        className={"header_top_actions_item helper-article"}
                    >
                                      <Material/>
                                      <span className={"header_top_actions_item_name "}>
                                        {getT("Материал / товар")}
                                      </span>
 <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.material_good)}/>
                                </span>
                    <span className={"header_top_actions_item helper-article"}
                          onClick={() => dispatch(changeAddClientMaterialModalValue("isOpen", true))}>
                                    <MaterialClient/>
                                    <span className={"header_top_actions_item_name"}>
                                        {getT("Материал клиента")}
                                      </span>
                            <IconQuestion
                                onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.customer_material)}
                            />
                                </span>
                    <span
                        onClick={() => dispatch(onChangeCombineMaterialModal({key: "isOpen", value: true}))}
                        className={"header_top_actions_item helper-article"}
                    >
                                  <Material/>
                                  <span className={"header_top_actions_item_name"}>
                                    {getT("Составной материал")}
                                  </span>
                            <IconQuestion
                                onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.composite_material)}
                            />
                                </span>
                    <span
                        onClick={() => {
                            dispatch(toggleListOpen(true))
                            onToggleAdd(false)
                        }}
                        className={"header_top_actions_item helper-article"}
                    >
                                  <Material/>
                                  <span className={"header_top_actions_item_name"}>
                                    {getT("Изделия ")}
                                  </span>
                            <IconQuestion
                                onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.modules)}
                            />
                                </span>
                </div>
            </div>)}
        </div>}
        <>
            {menu && <div className="shadow"></div>}
            {isEditStatusOrder && <div className="singl-action helper-article" ref={refMenu} title={getT("")}>
                <div className="">
                    <IconSubmenu onClick={() => {
                        toggleMenu(!menu);
                    }}/>
                    <IconQuestion style={{top: '-2px', right: '-3px'}}
                                  onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.additional_features)}
                    />
                </div>

                {menu && (<div className="add prints-actions-container">

                    <div className={`prints-singles-actions-container `}>
                         <div className={`prints-singles-actions-container `}>
                             {(!!Number(productionCONST["production.edge_edit_on"]) && !!part?.length) &&  <div className="singl-action-item" title={getT("Обработка торцов")}
                                 onClick={openProcessingEdgeGroupPage}>
                                    <img width={35} src={processinEdgeImg}/>
                                    <span className={"singl-action-item-name"}>
                                    {getT("Обработка торцов")}
                                        </span>
                            </div>}
                        </div>
                        {Boolean(productionCONST["production.have_post_operations"]) && isEditStatusOrder && (Number(firm) !== 1) && (
                            <div className="singl-action-item" title={getT("Операции по плоскости")}
                                 onClick={() => openPlaneOperations()}>
                                <SurfaceTreatment/>
                                <span className={"singl-action-item-name"}>
                    {getT("Операции по плоскости")}
                        </span>
                            </div>)}
                        {isEditStatusOrder && <>
                            <div className="singl-action-item helper-article" title={getT("Файлы для заказа")}
                                 onClick={() => dispatch(setFileManagerDT({isOpen: true, idProject: order.code}))}>
                                <AttachDrawings/>
                                <span className={"singl-action-item-name"}>
                                    {getT("Файлы для заказа")}
                                  </span>
                                <IconQuestion
                                    onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.files_ordering)}
                                />
                            </div>
                            <div
                                className={`singl-action-item helper-article ${isSateFilterCnv ? " cnv inversions" : "cnv"}`}
                                title={getT("Скрыть детали без ЧПУ операций")}
                                onClick={() => dispatch(isStateFilterCncOverride(!isSateFilterCnv))}>
                                <CopyChup/>
                                <span className={"singl-action-item-name"}>
                    {getT("Скрыть детали без ЧПУ операций")}

                  </span>
                                <IconQuestion
                                    onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.hide_parts_without_cnc_operations)}
                                />
                            </div>
                            <div className="singl-action-item chpu-group helper-article"
                                 title={getT("Групповая обработка деталей")}
                                 onClick={() => dispatch(changeBatchMachiningModalValue({
                                     isOpen: true, processing_current_material: null
                                 }))}>
                                <ChpuGroup/>
                                <span className={"singl-action-item-name"}>
                    {getT("Групповая обработка деталей")}
                  </span>
                                <IconQuestion
                                    onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.group_processing_of_part)}
                                />
                            </div>
                            <div className="singl-action-item noun-notes helper-article"
                                 title={getT("Очистить заметки по деталям")}
                                 onClick={() => dispatch(clearDetailNotesTh())}>
                                <NounDeleteNote/>
                                <span className={"singl-action-item-name"}>
                    {getT("Очистить заметки по деталям")}
                  </span>
                                <IconQuestion
                                    onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.clear_part_notes)}
                                />
                            </div>

                            <div className="singl-action-item helper-article"
                                 title={getT("Экспорт .XLSX")}
                            >
                                <IconXlsx/>
                                <IconQuestion
                                    onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.export_xlsx)}
                                />
                                <ExportToExcel title={getT("Экспорт .XLSX")}
                                               fileName={`${order.id}`}/>
                            </div>
                            <ExportLibraryMaterial getT={getT}/>

                        </>}
                    </div>
                </div>)}
            </div>}
        </>

        <div className="singl-action helper-article" title={getT("Материалы в проекте")}>
            <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.material_project)}
                          style={{marginLeft: '6px'}}/>

            <MaterialProject
                onClick={() => dispatch(changeMaterialsProjectModalValue("isOpen", true))}
            />
        </div>
        <div className={`singl-action helper-article  ${!!part?.length ? "" : "disabled"}`}>

            <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.cutting_card)}
                          style={{marginLeft: '6px'}}/>

            <CuttingCartNavigation triggerApiPackAndSaveStoreOrder={triggerApiPackAndSaveStoreOrder} getT={getT}/>

        </div>
        <div
            className={`singl-action helper-article  ${!!part?.length && !!Number(localStorage.getItem(LS_CONNECTED_BOX3D)) ? "" : "disabled"}`}>
            <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.package)} style={{marginLeft: '6px'}}/>

            <span onClick={() => openPackedPage()} className={`packed_btn`}>
                    <IconPacked/> {" "}
                </span>
        </div>
        {isEditStatusOrder && <>
            {print && <div className="shadow"></div>}
            <div className="singl-action" ref={refPrint} title={getT("Печать документов по заказу")}>
                <Print
                    onClick={() => {
                        sessionStorage.removeItem("calculate");
                        togglePrint(!print);
                    }}
                />
                {print && (<div className="add prints-actions-container">
                    <h3 className="add-title">{getT("Печать")}</h3>
                    <div className={`prints-singles-actions-container `}>
                        {!Number(isProductionOldInterface) && <>
                            <div
                                className={`singl-action-item  ${!!goods?.length || !!part?.length ? "" : "disabled"}`}
                                onClick={handlerGiblab(GIBLAB_BLANK_ACTION)}
                            >
                                <Print/>{" "}
                                <span className={"singl-action-item-name"}>
                                     {getT("Бланк заказа")}
                                 </span>
                            </div>
                        </>}
                        <div className={`singl-action-item  ${!!part?.length ? "" : "disabled"}`}
                             onClick={handlerGiblab(GIBLAB_BLANK_SHORT_WITHOUT_ACTION)}
                        >
                            <Print/>{" "}
                            <span className={"singl-action-item-name"}>
                            {getT("Бланк заказа (только деталировка)")}
                        </span>
                        </div>
                        <div
                            className={`singl-action-item  ${!!goods?.length || !!part?.length ? "" : "disabled"}`}
                            // onClick={handlerGiblab('short_order_form_html')}
                            onClick={handlerShortOrderFormFullHtml}
                        >
                            <Print/>{" "}
                            <span className={"singl-action-item-name"}>
                                     {getT("Спецификация (полная)")}
                                 </span>
                        </div>
                        <div
                            className={`singl-action-item  ${!!goods?.length || !!part?.length ? "" : "disabled"}`}
                            onClick={shortOrderFormDetailing}
                        >
                            <Print/>{" "}
                            <span className={"singl-action-item-name"}>
                                     {getT("Спецификация (деталировка)")}
                                 </span>
                        </div>

                        {orderHaveOperations && !Number(isProductionOldInterface) && <>
                            <div
                                className={"singl-action-item"}
                                onClick={handlerGiblab("ifp_xnc")}
                            >
                                <Print/>{" "}
                                <span className={"singl-action-item-name"}>{getT("Чертежи ЧПУ операций")}</span>
                            </div>

                        </>}


                        <GlDropdown getT={getT} order={order} key={"gl-print"} cName={"print-gl-actions"}/>
                        <div
                            className={"singl-action-item"}
                            onClick={openPackingPrintingPage}
                        >
                            <Print/>{" "}
                            <span className={"singl-action-item-name"}>
                                {getT("Упаковка")}
                            </span>
                        </div>
                    </div>
                </div>)}
            </div>
        </>}
    </div>);
};

export default Actions;
