import React from 'react'
import BoreForm from "./BoreForm";
import {useDispatch} from 'react-redux';
import {onOpenInstructionModal} from "../../../../store/reducers/ModalReducer";
import {Sides} from "../../../../constants";
import Delete from "../../../../Icons/Actions/Delete";
import Edit from "../../../../Icons/TableActions/Edit";
import {checkExclusiveDrilling} from "../../help";
import {
    actions,
    deleteToolsSettingsFirm,
    onSendTools,
    setEditBoreForms
} from "../../../../store/reducers/SettingsReducer";

const Bore = ({getT, boreTools, isAccessAction, type}) => {
    const dispatch = useDispatch();
    const onToggleInstModal = (data) => {
        dispatch(onOpenInstructionModal(data));
        dispatch(actions.onResetFormValue('bore'))
    }
    // ({Number(b?.bore_in) == 1 && getT('Глухое')} {Number(b?.bore_through) == 1 && getT('Сквозное')} )

    const getSide = (operation) => {
        const sides = Sides.map(s => {
            if (operation.hasOwnProperty(Object.keys(s)[0]) && Number(operation[Object.keys(s)[0]]) == 1) {
                return <span> {getT(s[Object.keys(s)[0]])} </span>
            }

        })
        return sides;
    };
    const onBeforeSubmitCU = (valuesForm) => {
        let id = checkExclusiveDrilling(boreTools, {
                d: valuesForm.d,
                bore_through: valuesForm.typeSide.bore_through,
                bore_in: valuesForm.typeSide.bore_in
            }
        );
        dispatch(onSendTools(valuesForm, id, 'bore'));

    };
    const onUpdateToolsSubmit = (valuesForm, id)=>{
        dispatch(onSendTools(valuesForm, id, 'bore'));
    }
    const onDeleteTools = (id)=>{
        dispatch(deleteToolsSettingsFirm(id));
    }
    const onUpdateTools = (data)=>{
        const initialValue = setEditBoreForms(data);
        dispatch(actions.setInitialValuesForm({form: 'bore', initialValue: initialValue}));
        dispatch(onOpenInstructionModal({key: 'bore', dt: true}));
    }
    return <div className={'tools-wrap'}>
        <div className="section-header">
            <h2 className="title">{getT('Сверление')} {type == 'bore_in' ? getT('глухое'): getT('сквозное')}</h2>
            {isAccessAction &&  <button className="btn"
                    onClick={() => {
                        let reverseOption = type == 'bore_in' ? 'bore_through' :'bore_in';
                        onToggleInstModal({key: 'bore', dt: true});
                        dispatch(actions.onChangeInstrumentsBoreForm({ key: 'typeSide',
                            dt: {[type]: 1, [reverseOption]: 0}}))
                    }}>{getT('Создать')}</button>}

        </div>
        <div className="table">

            {Array.isArray(boreTools) && boreTools.length >= 1 ?
                <table className={'table-wrapper main-parts-table'}>
                    <thead>
                    <th>{getT('Вкл')}/{getT('Выкл')}</th>
                    <th>{getT('Диаметр')}</th>
                    <th>{getT('Максимальная глубина')}</th>
                    <th>{getT('Сторона')}</th>
                    {isAccessAction &&  <th>{getT('Действие')}</th>}
                    </thead>
                    {boreTools.map((b, idx) => {
                     if(Number(b[type]) == 1) {
                         return <tr key={idx}>
                             <td>{Number(b.on) == 1 ? getT('Вкл') : getT('Выкл')}</td>
                             <td>{Number(b.d)} </td>
                             <td>{Number(b.z_max)}</td>
                             <td>{getSide(b)}</td>
                             {isAccessAction && <td>
                                 <div className="settings-actions">
                                     <span className={'edit'} onClick={() => onUpdateTools(b)}><Edit/> </span>
                                     <span className="delete"
                                           onClick={() => onDeleteTools(b.user_xnc_tools_id)}><Delete/></span>
                                 </div>
                             </td>
                             }
                         </tr>
                     }
                    })}
                </table>
                : ''}
        </div>
        <BoreForm getT={getT}
                  onToggleInstModal={onToggleInstModal}
                  onBeforeSubmit={onBeforeSubmitCU}
                  onUpdateToolsSubmit={onUpdateToolsSubmit}
                  type={type}
        />
    </div>
}

export default Bore;