import React, {useState} from 'react';
import GoBackArrow from "../../../../Icons/Actions/GoBackArrow";

const Position = ({index_bind, index_pack, part_color, part_x, part_y, getT}) => {
    const [isShowPosition, setIsShowPosition] = useState(false)
    return (
        <>
            <tr className={'tr-collapse'}>
                <th colSpan={9} style={{textAlign: 'left'}}>{getT('Расположение')}
                    <span className="" onClick={() => (setIsShowPosition(!isShowPosition))}>
                        <GoBackArrow cName={`icon-down ${isShowPosition ? 'show' : ''}`}/>
                    </span>
                </th>
            </tr>
            {isShowPosition && <>
                <tr>
                    {/*<th colSpan={9}>детали</th>*/}
                    <th></th>
                    <th>{getT('Коробка')}</th>
                    <th>{getT('Слой')}</th>
                    <th>{getT('Цвет')}</th>
                    <th>x</th>
                    <th>y</th>
                </tr>
                <tr>
                    <td></td>
                    <td>{index_pack +1}</td>
                    <td>{index_bind}</td>
                    <td>
                        <div className="" style={{background: `${part_color}`, width: '100%', height: '25px'}}></div>
                    </td>
                    <td>{part_x}</td>
                    <td>{part_y}</td>
                </tr>
            </>}

        </>
    );
};

export default Position;