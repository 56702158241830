export const packaging_style = `
 <style type="text/css">
          @media print {
            body {-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}
        }
         strong {
            color: #605d81;
        }
        .section-table-part, .section-table-pack {
                font-size: 0px;
            }
        .header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0;
            margin-bottom: 25px;
        }
        table,  th,   td {
            border: 1px solid rgb(154 152 174 / 30%);
            border-collapse: collapse;
            padding: 10px;
            text-align: center;
        }
         table th {
            box-sizing: initial;
            background-color: #f0f0f3;
        }
        .section-gap {
            margin: 55px 0px;
        }
        
        .tr-bg-green {
            background-color: #28a745 !important;
            color: white;
        }
        
        .tr-bg-parts {
            background-color: #6c757d !important;
            color: white;
        }
        
        .tr-bg-pack {
            background-color: #007bff0f !important;
        }
        
        .tr-bg-parts .icon-down path {
            stroke: white;
        }
        
        
        .amount {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 18px;
        }
        
        .tr-collapse,
        .tr-collapse th {
            background-color: rgba(66, 98, 255, .1);
        }
        
        
        .section-data-project {
            column-count: 3;
            gap: 12px;
                font-size: 16px;
        }
        
        .section-data-project .item {
            line-height: 28px;
            font-size: 16px;
        }
        
        .section-data-project .item strong {
            margin-right: 8px;
        }
        table{
        width: 100%;
        }
        h2{
        margin-bottom: 25px;
        }
        </style>
`