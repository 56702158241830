import {useDispatch, useSelector} from "react-redux";
import {getPlanModal, getStockOrdersDT} from "../../../selectors/cells-leftovers-selector";
import React from "react";
import {onTogglePlanModal} from "../../../store/reducers/ModalReducer";
import Modal from "../../../components/Modals/BasicModal";
import Container from "../../../components/Container";

const PlaneListModal = ({getT}) => {

    const dispatch = useDispatch();
    const {planeListOrder} = useSelector(getPlanModal);
    const stockOrders = useSelector(getStockOrdersDT);
    const listOrdersCurrent = stockOrders.filter(s => Number(s.id) == Number(planeListOrder.order));
    const onClose = () => {
        dispatch(onTogglePlanModal({key: 'planeListOrder', dt: {isOpen: false, idOrder: null}}))
    }

    return <>
        <div className="container-stock">
            <Modal open={planeListOrder.isOpen} title={getT('Планируемые поступления')} onClose={onClose}>
                <Container>
                    <div className="w-100">
                        {Array.isArray(listOrdersCurrent) && listOrdersCurrent.length >= 1 && listOrdersCurrent[0].hasOwnProperty('goods_stock_plan') && Array.isArray(listOrdersCurrent[0].goods_stock_plan) && listOrdersCurrent[0].goods_stock_plan.length >= 1 ?
                            <div className={'w-100'}>
                                <h3 style={{margin: '0px 0px 25px 0px'}} className="">{getT('Заказ')}
                                    <p style={{margin:'0px'}}>{listOrdersCurrent[0].mame}</p></h3>
                                <div className={'table'}>
                                    <table className={"stock-table"}>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>№ {getT("Заказа")}</th>
                                            <th>{getT("Материал")}</th>
                                            <th>{getT("Размер (мм)")}</th>
                                            <th>{getT("Площадь")}</th>
                                            <th>{getT("Комментарий")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {listOrdersCurrent[0].goods_stock_plan.map((l, idx) => {
                                            return (<tr>
                                                <td>{idx + 1}</td>
                                                <td>{l.order}</td>
                                                <td>{l.name}</td>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <span title={'x'}> {l.x}</span>
                                                    <strong> x </strong>
                                                    <span title={'y'}> {l.y}</span>
                                                    {l.z && <strong> x </strong>}
                                                    <span title={'z'}> {l.z}</span>

                                                </td>
                                                <td>{l.area_plan}</td>
                                                <td>{l.reason_why_not}</td>

                                            </tr>)
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : <h3 style={{margin: "0 auto", textAlign: 'center'}}>{getT('Планируемые остатки' +
                                ' отсутствуют ')}</h3>}
                    </div>

                </Container>

            </Modal>
        </div>
    </>
}
export default PlaneListModal;