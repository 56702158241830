import React from 'react';
import {useLang} from "../../../context/langProvider";

const Privacy = () => {
    const {getT} = useLang();

    return <div className="info-page-inner-content">
        <h2 className="privacy-title"> {getT('Политика конфиденциальности')} </h2><p className="privacy-text">{getT('Команда ')} iFurn.pro
        {getT(" беспокоится об обеспечении безопасности данных своих клиентов. Именно поэтому мы составили настоящую Политику" +
            " конфиденциальности, чтобы вы не беспокоились о том, что происходит с вашими персональными данными, которые вы" +
            " предоставили нам для регистрации. Регистрируясь на веб-сайте, вы предоставляете IFurn.pro согласие на обработку" +
            " всех введенных для регистрации данных с целью надлежащего предоставления IFurn.pro услуг в помощи размещения" +
            " заказов на мебельные комплектующие. Регистрируясь на веб-сайте, вы также подтверждаете, что ознакомились с" +
            " перечнем своих прав как субъекта данных согласно статье 8 Закона Украины «О защите персональных данных». Этот" +
            " перечень доступен по ссылке")}:&nbsp;<a href="http://zakon2.rada.gov.ua/laws/show/2297-17" className="privacy-link"
                                             target="_blank">http://zakon2.rada.gov.ua/laws/show/2297-17</a>.</p>
        <ul><h4 className="privacy-subtitle">1.{getT('СБОР ЛИЧНОЙ ИНФОРМАЦИИ')}</h4>
            <li>{getT("1.1. Во время регистрации на сайте мы просим вас сообщить необходимую личную информацию в" +
                " объеме," +
                " который является необходимым для предоставления услуг")}
            </li>
            <li>{getT("1.2. Файлы cookie - когда вы заходите на наш сайт, мы отправляем один или несколько файлов" +
                " cookie на ваш" +
                " компьютер или другое устройство. Файлы cookie используются для того, чтобы повышать качество" +
                " предоставляемых услуг: сохранять настройки пользователя.")}
            </li>
            <li>{getT("1.3. Информация о посещении - при доступе на сайт наши серверы автоматически записывают" +
                " определенную" +
                " информацию. Эти журналы сервера могут содержать такую информацию, как веб-запрос, IP-адрес, тип и язык" +
                " браузера, дату и время запроса.")}
            </li>
            <li>{getT("1.4. IFurn.pro может обрабатывать личную информацию на серверах в разных странах. В некоторых" +
                " случаях личная информация пользователей обрабатывается за пределами страны пользователя.")}
            </li>
        </ul>
        <ul><h4 className="privacy-subtitle">2.{getT("ПРЕДОСТАВЛЕНИЕ ДОСТУПА К ИНФОРМАЦИИ")}</h4>
            <li> {getT("2.1. Мы предоставляем доступ к вашей личной информации другим лицам только при следующих" +
                " ограниченных обстоятельствах:")}
            </li>
            <li> {getT("2.1.1. У нас есть на это ваше разрешение. Для передачи любой конфиденциальной информации нам" +
                " требуется ваше явное согласие.")}
            </li>
            <li> {getT("2.1.3. У нас есть достаточные основания полагать, что доступ, использование, сохранение или" +
                " раскрытие такой информации необходимо для:")}
            </li>
            <li>{getT("2.1.3.1. соблюдения законодательства Украины, в том числе предоставления информации по" +
                " требованию" +
                " физических и юридических лиц, учреждений, организаций любой формы собственности или государственных" +
                " органов, которые имеют право на получение такой информации согласно законодательству Украины;")}
            </li>
            <li> {getT("2.1.3.2. расследования потенциальных нарушений Публичной оферты, что предполагает" +
                " предоставление" +
                " информации о вас в ответ на надлежащим образом оформленный адвокатский запрос с информацией о" +
                " нарушениях;")}
            </li>
            <li> {getT("2.1.3.3. выявления и предотвращения мошеннических действий, а также решения проблем" +
                " безопасности и" +
                " устранения технических неисправностей;")}</li>
            <li> {getT("2.1.3.4. защиты от непосредственной угрозы правам, имуществу или безопасности компании" +
                " IFurn.pro, ее" +
                " пользователей или общественности, в рамках законодательства Украины.")}
            </li>
        </ul>
        <ul><h4 className="privacy-subtitle">3. {getT("ЗАЩИТА ИНФОРМАЦИИ")}</h4>
            <li> {getT("3.1. Мы принимаем все необходимые меры для защиты данных от неавторизованного доступа," +
                " изменения," +
                " раскрытия или уничтожения. К этим мерам относятся, в частности, внутренняя проверка процессов сбора," +
                " хранения и обработки данных и мер безопасности, включая соответствующее шифрование и меры по обеспечению" +
                " физической безопасности данных для предотвращения неавторизованного доступа.")}
            </li>
        </ul>
        <ul><h4 className="privacy-subtitle">4. {getT("ДОСТУП К ЛИЧНОЙ ИНФОРМАЦИИ И ЕЕ ОБНОВЛЕНИЕ")}</h4>
            <li> {getT("4.1. Мы делаем все от нас зависящее, чтобы предоставить вам доступ к вашим личным данным и" +
                " либо" +
                " исправить их, если они неверны, либо удалить по вашему запросу, если только их хранение не требуется" +
                " законом или не обусловлено законными деловыми целями. Перед обработкой запросов мы просим пользователей" +
                " подтвердить свою личность и информацию, которую они хотят получить, исправить или удалить.")}
            </li>
        </ul>
        <ul><h4 className="privacy-subtitle">5.{getT(" ИЗМЕНЕНИЕ НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ")}</h4>
            <li> {getT("5.1. Настоящая политика конфиденциальности может время от времени меняться. Изменения," +
                " вносимые в политику конфиденциальности, будут опубликованы на этой странице.")}
            </li>
        </ul>
    </div>
};

export default Privacy;