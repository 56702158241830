function MyOrders(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.334 3.333h1.667A1.667 1.667 0 0116.667 5v11.666a1.667 1.667 0 01-1.666 1.667H5a1.667 1.667 0 01-1.667-1.667V5a1.667 1.667 0 011.667-1.667h1.666"
        stroke="#4262FF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 1.667h-5a.833.833 0 00-.834.833v1.667c0 .46.373.833.833.833h5c.46 0 .834-.373.834-.833V2.5a.833.833 0 00-.834-.833zM13.333 11.666H6.666M13.333 15H6.666M8.333 8.333H6.666"
        stroke="#4262FF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MyOrders;
