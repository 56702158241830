import React from 'react';
import {useSelector} from "react-redux";
import {getAuthNetworkSocialLoginSelected} from "../../../selectors/auth-selector";
import LoginSocialForm from "./LoginSocialForm";
//** login google and fb confirm user */
const LoginSocialModal = () => {
    const auth_network_social_login = useSelector(getAuthNetworkSocialLoginSelected);
    return (
        auth_network_social_login?.modal?.isOpen ? <LoginSocialForm/> : null
    );
};

export default LoginSocialModal;