import React from 'react';

const OperationsFormChoice = ({plane_operations, getT, handlerSetOperations, choice_operation}) => {

    return (
        <div className={"operations-plane-choice-wrap"}>
            <div className="operations-plane-choice_list">
                {plane_operations.map(e => {
                    let activeCN = `${Number(choice_operation.id) === Number(e.id) ? "active" : ""}`
                    return <div
                        className={"materials_nav_item plane_operations_nav_item " + (activeCN)}
                        onClick={handlerSetOperations({
                            name: e?.name, id: e?.id
                        })}
                    >
                        <div className="plane_operations_item_image">
                            <img src={"https://admin.ifurn.pro" + e.image} alt=""/>
                        </div>
                        <div className={"plane_operations_item_name"}>
                            {e.name} <br/>
                            <small>{getT("Код 1С")}: <b>{e.code_1c}</b></small>
                        </div>
                    </div>;
                })}
            </div>
        </div>
    );
};

export default OperationsFormChoice;