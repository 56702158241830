import React from "react";
import Modal from "../BasicModal";
import Container from "../../Container";
import {useDispatch, useSelector} from "react-redux";
import {getCuttingLabelsForPrint} from "../../../store/reducers/CuttingChartReducer";
import {onChangeLabelsPrintGirdModal} from "../../../store/reducers/ModalReducer";
import {useLang} from "../../../context/langProvider";

const LabelsPrintCount = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const modal = useSelector(state => state?.modal?.labelsPrint);

    if (!modal?.isOpen) return <></>
    const onHandlerSubmit = () => {
        dispatch(getCuttingLabelsForPrint({}));
    }
    const onChangeValueModal = (values) => {
        dispatch(onChangeLabelsPrintGirdModal(values))

    }
    return (
        <Modal onClose={() => onChangeValueModal({isOpen: false, action: '', grid: 1})} open={modal?.isOpen}
               title={getT("Этикетки печать")}>
            <Container>
                <div className="" style={{width: '100%'}}>
                    <div className="form-field">
                        <label className={'label'}>{getT('Выберите количество на листе')}</label>
                        <ul className="filter-switch">
                            <li>
                                <input type="radio" id="def" name="grid" value="1" checked={Number(modal?.grid) === 1}
                                       onChange={(e) => onChangeValueModal({grid: 1})}/>
                                <label htmlFor="def">1</label>
                            </li>
                            <li>
                                <input type="radio" id="small" name="grid" value="8" checked={Number(modal?.grid) === 8}
                                       onChange={(e) => onChangeValueModal({grid: 8})}/>
                                <label htmlFor="small">8</label>
                            </li>
                            <li>
                                <input type="radio" id="big" name="grid" value="32" checked={Number(modal?.grid) === 32}
                                       onChange={(e) => onChangeValueModal({grid: 32})}/>
                                <label htmlFor="big">32</label>
                            </li>
                        </ul>

                    </div>

                    <button onClick={() => onHandlerSubmit()} className={'btn'}
                            style={{margin: '15px 0px 0px 0px'}}>{getT('Отправить')}</button>
                </div>

            </Container>
        </Modal>
    );
};

export default LabelsPrintCount;