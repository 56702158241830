import React from 'react';
import {LoginSocialFacebook, LoginSocialGoogle} from "reactjs-social-login";
import {FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import {useDispatch} from "react-redux";
import {facebookLoginThunk, googleLoginThunk} from "../../../../../store/thunks/social-network-thunk";
import {changeAlertStatusAC} from "../../../../../store/reducers/CommonReducer";
import LoginSocialModal from "../../../../../components/Modals/LoginSocialModal";

const SocialLogin = () => {
    const dispatch = useDispatch();

    return (
        <div className="d-flex justify-center flex-wrap gap-10 social-login">
            <LoginSocialGoogle key={'login-social-google'}
                               client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                               isOnlyGetToken={true}
                               isOnlyGetCode={true}
                               auto_select={false}
                               scope={'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'}
                               cookie_policy={false}
                               onResolve={async ({provider, data}) => {
                                   dispatch(googleLoginThunk(data.access_token))
                                   // dispatch(accountConnectedGoogle(data.access_token))
                               }}
                               onReject={(err) => {
                                   console.log(err)
                                   // dispatch(changeAlertStatusAC(true, err))
                               }}>
                <GoogleLoginButton text={"Google"} key={'login-social-google-btn'}/>
            </LoginSocialGoogle>
            <LoginSocialFacebook
                fieldsProfile={"id,name,email"}
                fields="first_name,last_name,email,name"
                scope="public_profile,email"
                returnScopes={true}
                appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                onResolve={(response) => {
                    dispatch(facebookLoginThunk(response.data))
                }}
                onReject={(error) => {
                    console.log(error);
                    // dispatch(changeAlertStatusAC(true, error))
                }}
            >
                <FacebookLoginButton text={"Facebook"}/>
            </LoginSocialFacebook>
            <LoginSocialModal/>
        </div>
    );
};

export default SocialLogin;