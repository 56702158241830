const LANDING_URL = process.env.REACT_APP_IFURN_PRO_URL;
const HASH_PAUSE_SITE = "#technical-works"

function watcherApiPauseSite(response) {
    let pause_site = Number(response?.pause_site)
    if (!pause_site) return;

    window.location.href = LANDING_URL + HASH_PAUSE_SITE;


}

export {watcherApiPauseSite}