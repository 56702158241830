import { tools } from './tools.js';

export const srezes = {
  postformingOnEdges(edges, side, partX, partY, partZ) {
    let endSides;

    if ('tb'.includes(side)) {
      endSides = ['r', 'l'];
    } else {
      endSides = ['t', 'b'];
    }

    let a = '';

    for (let es of endSides) {
      let edge = edges[es];

      if (!edge) continue;

      let pf = edge.pf;

      if (!pf) continue;

      let closeLine, d;

      if ('lr'.includes(side)) {
        if (es === 't') {
          closeLine = `M 0 ${partZ / 2} A 1 1 0 0 1 ${partZ} ${partZ / 2}`;
          d = `${closeLine} L ${partZ + 1} ${partZ / 2} L ${partZ + 1} -1 L -1 -1 L -1 ${partZ / 2} Z`;
        } else if (es === 'b') {
          closeLine = `M 0 ${partY - partZ / 2} A 1 1 0 0 0 ${partZ} ${partY - partZ / 2}`;
          d = `${closeLine} L ${partZ + 1} ${partY - partZ / 2} L ${partZ + 1} ${partY + 1} L -1 ${partY + 1} L -1 ${partY - partZ / 2} Z`
        }
      } else if ('tb'.includes(side)) {
        if (es === 'l') {
          closeLine = `M ${partZ / 2} 0 A 1 1 0 0 0 ${partZ / 2} ${partZ}`;
          d = `${closeLine} L ${partZ / 2} ${partZ + 1} L -1 ${partZ + 1} L -1 -1 L ${partZ / 2} -1 Z`;
        } else if (es === 'r') {
          closeLine = `M ${partX - partZ / 2} 0 A 1 1 0 0 1 ${partX - partZ / 2} ${partZ}`
          d = `${closeLine} L ${partX - partZ / 2} ${partZ + 1} L ${partX + 1} ${partZ + 1} L ${partX + 1} -1 L ${partX - partZ / 2} -1 Z`
        }
      }

      let params = {
        stroke: 'none',
        fill: tools.srezEdgeColor,
      };
      let closeLineParams = {
        stroke: 'black',
        'stroke-width': '1px',
        fill: 'none',
      };
      a += tools.path(d, params);
      a += tools.path(closeLine, closeLineParams);
    }

    return a;
  },
  postformingOnFront(edges, partX, partY, partZ) {
    let a = '';

    for (let [side, edge] of Object.entries(edges)) {
      if (!tools.objectIsTrue(edge)) continue;

      let pf = edge.pf;

      if (!pf) continue;

      let fill = tools.srezFrontColor;
      let halfZ = partZ / 2;
      let kwargs = {
        fill: fill,
        stroke: 'none'
      };

      if (side === 't') {
        a += tools.rect(0, 0, partX, halfZ, kwargs);
      } else if (side === 'l') {
        a += tools.rect(0, 0, halfZ, partY, kwargs);
      } else if (side === 'b') {
        a += tools.rect(0, partY - halfZ, partX, halfZ, kwargs);
      } else if (side === 'r') {
        a += tools.rect(partX - halfZ, 0, halfZ, partY, kwargs);
      }
    }
    return a;
  },
  prepareSrez(srez) {
    let [otstup, ugol, dbId] = ['otstup', 'ugol', 'db_id'].map(a => srez[a] || '0');
    let isBack = ugol.toString()[0] === '-';
    ugol = ugol.toString().replace('+', '');

    if (!ugol) return false;

    [otstup, ugol] = tools.toFloat(otstup, ugol);
    ugol *= -1;
    return [otstup, ugol, dbId, isBack];
  },
  srezOnSide(srez, bands, side, partX, partY, partZ) {
    let preparedSrez = this.prepareSrez(srez);

    if (!preparedSrez) return '';

    let [otstup, _, dbId, isBack] = preparedSrez;
    let srezColor = tools.getEdgeColor(bands, dbId);

    let a = '';

    if (side === 't') {
      a = tools.rect(0, otstup, partX, partZ - otstup, {
        fill: srezColor,
        stroke: 'none'
      });
    } else if (side === 'r') {
      a = tools.rect(0, 0, otstup, partY, {
        fill: srezColor,
        stroke: 'none'
      });
    } else if (side === 'b') {
      a = tools.rect(0, 0, partX, partZ - otstup, {
        fill: srezColor,
        stroke: 'none'
      });
    } else if (side === 'l') {
      a = tools.rect(otstup, 0, partZ - otstup, partY, {
        fill: srezColor,
        stroke: 'none'
      })
    }
    return a;
  },
  srezOnEdges(srezes, bands, side, partX, partY, partZ) {
    let a = '';
    let endSides;

    if ('rl'.includes(side)) {
      endSides = ['t', 'b'];
    } else if ('tb'.includes(side)) {
      endSides = ['l', 'r'];
    }

    for (let es of endSides) {
      let preparedSrez = this.prepareSrez(srezes[es]);

      if (!preparedSrez) continue;

      let [otstup, ugol, _, isBack] = preparedSrez;
      let rDeg = ugol * Math.PI / 180;
      let leg = (partZ - otstup) * Math.tan(-rDeg);
      let params = {
        stroke: 'none',
        fill: tools.srezEdgeColor,
      };
      let closeLineParams = {
        stroke: 'black',
        'stroke-width': '1px'
      };
      let d = '', closeLine = '';

      if (side === 'l') {
        if (es === 't') {
          if (leg > 0) {
            closeLine = `M ${otstup} -1 ${partZ} ${Math.ceil(leg)}`;
            d = `M ${otstup - 1} -1 ${partZ + 1} ${leg + 1} ${partZ + 1} -1 Z`;
          } else {
            closeLine = `M ${partZ - otstup} 0 0 ${Math.ceil(-leg)}`;
            d = `M ${partZ - otstup + 1} -1 -1 ${-leg + 1} -1 -1 Z`
          }
        } else {
          if (leg > 0) {
            closeLine = `M ${otstup} ${partY} ${partZ} ${Math.ceil(partY - leg)}`
            d = `M ${otstup - 1} ${partY + 1} ${partZ + 1} ${partY - leg - 1} ${partZ + 1} ${partY + 1} Z`
          } else {
            closeLine = `M ${partZ - otstup} ${partY} 0 ${Math.ceil(partY + leg)}`
            d = `M ${partZ - otstup + 1} ${partY + 1} -1 ${partY + leg - 1} -1 ${partY + 1} Z`
          }
        }
      } else if (side === 't') {
        if (es === 'l') {
          if (leg > 0) {
            closeLine = `M 0 ${otstup} ${Math.ceil(leg)} ${partZ}`;
            d = `M -1 ${otstup - 1} ${leg + 1} ${partZ + 1} -1 ${partZ + 1} Z`
          } else {
            closeLine = `M 0 ${partZ - otstup} ${Math.ceil(-leg)} 0`;
            d = `M -1 ${partZ - otstup + 1} ${-leg + 1} -1 -1 -1 Z`
          }
        } else {
          if (leg > 0) {
            closeLine = `M ${partX} ${otstup} ${Math.ceil(partX - leg)} ${partZ}`;
            d = `M ${partX + 1} ${otstup - 1} ${partX - leg - 1} ${partZ + 1} ${partX + 1} ${partZ + 1} Z`;
          } else {
            closeLine = `M ${partX} ${partZ - otstup} ${Math.ceil(partX + leg)} 0`;
            d = `M ${partX + 1} ${partZ - otstup + 1} ${partX + leg - 1} -1 ${partX + 1} -1 Z`;
          }
        }
      } else if (side === 'r') {
        if (es === 't') {
          if (leg > 0) {
            closeLine = `M ${partZ - otstup} 0 0 ${Math.ceil(leg)}`;
            d = `M ${partZ - otstup + 1} -1 -1 ${leg + 1} -1 -1 Z`;
          } else {
            closeLine = `M ${otstup} 0 ${partZ} ${Math.ceil(-leg)}`;
            d = `M ${otstup - 1} -1 ${partZ + 1} ${-leg + 1} ${partZ + 1} -1 Z`;
          }
        } else {
          if (leg > 0) {
            closeLine = `M ${partZ - otstup} ${partY} 0 ${Math.ceil(partY - leg)}`;
            d = `M ${partZ - otstup + 1} ${partY + 1} -1 ${partY - leg - 1} -1 ${partY + 1} Z`;
          } else {
            closeLine = `M ${otstup} ${partY} ${partZ} ${Math.ceil(partY + leg)}`;
            d = `M ${otstup - 1} ${partY + 1} ${partZ + 1} ${partY + leg - 1} ${partZ + 1} ${partY + 1} Z`;
          }
        }
      } else if (side === 'b') {
        if (es === 'l') {
          if (leg > 0) {
            closeLine = `M 0 ${partZ - otstup} ${Math.ceil(leg)} 0`;
            d = `M -1 ${partZ - otstup + 1} ${leg + 1} -1 -1 -1 Z`;
          } else {
            closeLine = `M 0 ${otstup} ${Math.ceil(-leg)} ${partZ}`;
            d = `M -1 ${otstup - 1} ${-leg + 1} ${partZ + 1} -1 ${partZ + 1} Z`;
          }
        } else {
          if (leg > 0) {
            closeLine = `M ${partX} ${partZ - otstup} ${Math.ceil(partX - leg)} 0`;
            d = `M ${partX + 1} ${partZ - otstup + 1} ${partX - leg - 1} -1 ${partX + 1} -1 Z`;
          } else {
            closeLine = `M ${partX} ${otstup} ${Math.ceil(partX + leg)} ${partZ}`;
            d = `M ${partX + 1} ${otstup - 1} ${partX + leg - 1} ${partZ + 1} ${partX + 1} ${partZ + 1} Z`;
          }
        }
      }

      a += tools.path(d, params);
      a += tools.path(closeLine, closeLineParams);
    }
    return a
  },
  srezesFront(srezes, partX, partY, partZ) {
    let a = '';

    for (let [side, srez] of Object.entries(srezes)) {
      let preparedSrez = this.prepareSrez(srez);

      if (!tools.objectIsTrue(preparedSrez)) continue;

      let [otstup, ugol, _, isBack] = preparedSrez;
      let color = isBack ? tools.srezBackColor : tools.srezFrontColor;
      let rDeg = ugol * Math.PI / 180;
      let leg = Math.abs((partZ - otstup) * Math.tan(rDeg));

      let sideSrezesCoords = {
        l: [0, 0, leg, partY],
        t: [0, 0, partX, leg],
        r: [partX - leg, 0, leg, partY],
        b: [0, partY - leg, partX, leg],
      };
      let coords = sideSrezesCoords[side];
      a += tools.rect(...coords, { fill: color, stroke: 'none' });
    }

    return a;
  },
  srezes(srezes, bands, partX, partY, partZ, side) {
    if (!tools.objectIsTrue(srezes)) {
      return '';
    }

    let srez = srezes[side];
    return [
      this.srezOnSide(srez, bands, side, partX, partY, partZ),
      this.srezOnEdges(srezes, bands, side, partX, partY, partZ)
    ].join('')
  }
}
