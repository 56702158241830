import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getLocalLang} from "../../../selectors/settings-selector";

const FieldChangePriceOption = ({currency, service, onChange, drop, getT}) => {
    const [currencyV, setCurrency] = useState(service.price.currency)
    const [price, setPrice] = useState(service.price.price)
    const Submit = () => {
        let payload = {
            price: price,
            service: service.goods_service_id,
            // drop: service.price.drop,
            drop: 1,
            currency: currencyV,
        }
        onChange(payload);
    }
    const lang = useSelector(getLocalLang);
    return <div className="service-price-option" key={service.goods_service_id}>
        <input type="number" className={'input-val'} value={price}
               onChange={(e) => setPrice( e.target.value)}/>

        <select name="" id="" className={'material-select'} value={currencyV}
                onChange={(e) => setCurrency( e.target.value)}
        >
            <option value={''} disabled={true} selected={true}>---{getT("Выбрать")}---</option>
            {Array.isArray(currency) && currency.map(cur => {
                let isSelected = service.price.hasOwnProperty('currency') && (Number(cur.currency_id) === Number(currencyV));
                return <option
                    selected={isSelected}
                    value={Number(cur.currency_id)}>{cur.name[lang]}</option>
            })}
        </select>
        <button style={{height: '41px', lineHeight: '8px', margin:'0px', width:'max-contentnpm run '}} className={'btn '} onClick={Submit}>{getT('Сохранить')}</button>

        {/*<select name="" id="" className={'material-select'} value={service.price.drop}*/}
        {/*        style={{maxWidth:'180px'}}*/}
        {/*        onChange={(e) => onChangeValues({key: 'drop', value: e.target.value})}*/}
        {/*>*/}
        {/*    <option value={''} disabled={true}>---{getT("Выбрать")}---</option>*/}
        {/*    {Array.isArray(drop) && drop.map(drop => {*/}
        {/*        if(Number(drop.material_drop_id )=== 1 ) {*/}
        {/*            return <option*/}
        {/*                selected={(Number(drop.material_drop_id) === Number(service.price.drop)) ? true : false}*/}
        {/*                value={Number(drop.material_drop_id)}>{drop.name[lang]}</option>*/}
        {/*        }*/}
        {/*    })}*/}
        {/*</select>*/}
    </div>
}
export default FieldChangePriceOption;