import React, {useMemo} from 'react';
import {useSelector} from "react-redux";

/**
 * Custom hook for routing a display choice component .
 *
 * @returns {object} - Return object pages.
 */

export default function () {
    const material = useSelector(state => state?.modal?.materials?.isOpen || false);
    const material_info_edit = useSelector(state => state?.modal?.materialInfo?.isOpen || false)
    const operation = useSelector(state => state?.modal?.operations?.isOpen || false);
    const shopping = useSelector(state => state?.modal?.shopingCard?.isOpen || false);
    const box = useSelector(state => state?.boxes?.isOpenConstructor || state?.boxes?.isOpenList || false);
    const cutting = useSelector(state => state?.cuttingChart?.isOpenPage || false);
    const order = useSelector(state => state?.modal?.confirmOrder?.isOpen || false);
    const plane_operations = useSelector(state => state?.modal?.planeOperations?.isOpen || false);
    const packed = useSelector(state => state?.modal?.packedModal?.isOpen || false);
    const packagingPrinting = useSelector(state => state?.packagingPrinting?.isOpen || false)
    const material_selected_filter = useSelector(state=> state.material_selected_filter?.isOpenPage || false)
    const processing_edge_group_page = useSelector(state=> state?.processing_edge?.page_group_operation?.isOpen || false)
    const search_goods_page = useSelector(state=> state?.search_goods?.isOpenPage || false)
    const home = useMemo(() => {
        return !search_goods_page && !material_selected_filter && !material && !operation && !shopping && !box && !cutting && !order && !plane_operations && !packed && !packagingPrinting && !material_info_edit && !processing_edge_group_page
    }, [search_goods_page, material_selected_filter, material, operation, cutting, shopping, operation, box, order, plane_operations, packed, packagingPrinting, material_info_edit, processing_edge_group_page])
    return {
        material: material,
        box: box,
        cutting: cutting,
        shopping: shopping,
        operation: operation,
        home: home,
        order: order,
        plane_operations: plane_operations,
        packed: packed,
        packagingPrinting: packagingPrinting,
        material_info_edit: material_info_edit,
        material_selected_filter: material_selected_filter,
        processing_edge_group_page: processing_edge_group_page,
        search_goods_page: search_goods_page
    }
};

