import React from 'react';
import "./input-style.css"
const InputSwitch = ({disabled=false, label=null, onChange, ...reset}) => {
    return (
        <label className={`toggle ${ disabled? 'disabled' : ' '}`}>
            <input className="toggle-checkbox" type="checkbox" onChange={onChange} {...reset}/>
            <div className="toggle-switch"></div>
            {label && <span className="toggle-label">{label}</span>}
        </label>
    );
};

export default InputSwitch;