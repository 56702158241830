import React, {useState} from 'react';
import {parsePhoneNumber} from "../../../../../helpers/helpersListCountryCode";
import SelectPhone from "../../../../../components/SelectPhone";
import InputMask from "react-input-mask";
import {maskPhoneCountry} from "../../../../../constants";
import {useDispatch} from "react-redux";
import {accountUpdateUserDataThunk} from "../../../../../store/reducers/AuthReducer";
import {messageChannelsThunk} from "../../../../../store/thunks/message-channels-thunk";

const UserDataEditForm = ({userDT, getT, onClose}) => {
    const dispatch = useDispatch();
    const [fields, handlerChangeField] = useState({
        name: userDT.name,
        email: userDT.email,
        phone_number: parsePhoneNumber(userDT.phone)?.phoneNumberWithoutCode,
        code: parsePhoneNumber(userDT.phone)?.countryCode ?? "+38 ",
    });
    const handlerSubmit = async e => {
        e.preventDefault();
        let api_data = {
            name: fields.name,
            email: fields.email,
            client: userDT.id,
            phone: fields.code + fields.phone_number
        }
        await dispatch(accountUpdateUserDataThunk(api_data))
        onClose();
    }
    const handlerChange = (key) => event => {
        handlerChangeField(prev => ({
            ...prev, [key]: event.target.value
        }))
    }
    return (
        <form onSubmit={handlerSubmit} className={'user-data-form-edit'}>
            <div className={"form-field "}>
                <div className="label">
                    <strong className="">
                        {getT("Пользователь")}:
                    </strong>
                </div>
                <input className={'form-input'} type="text" value={fields.name} required={true}
                       onChange={handlerChange('name')}/>
            </div>

            <div className={"form-field  "}>
                <div className="label">
                    <strong className="">
                        {getT("Email")}:
                    </strong>
                </div>
                <input className={'form-input'} type="email" value={fields.email} onChange={handlerChange('email')}/>
            </div>
            <div className={"form-field"}>
                <div className="label">
                    <strong className="">
                        {getT("Телефон")}:
                    </strong>
                </div>
                <div className="field-phone">
                    <SelectPhone value={fields.code} rusHide={false}
                                 setValue={(value) => handlerChange(prev => ({...prev, code: value}))}/>
                    <InputMask
                        value={fields.phone_number}
                        onChange={handlerChange('phone_number')}
                        mask={maskPhoneCountry[fields.code]}
                        type="tel"
                        className="form-input "
                        required={"required"}
                    />
                </div>
            </div>
            <div className="d-flex">
                <button className={"btn"}>{getT("Отправить")}</button>
            </div>
        </form>
    );
};

export default UserDataEditForm;