import React from 'react';
import "./input-style.css"
const InputRadio = ({label = null, onChange, className, id = null, checked = false, disabled=false}) => {
    const cName = `${className} filed-radio`

    return (
        <label className={cName} htmlFor={id}>{label}
            <input id={id} type="radio" checked={checked} onChange={onChange} />
            <span className="checkmark"></span>
        </label>
    )
};

export default InputRadio;