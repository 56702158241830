import React from 'react';
import Edit from "../../Icons/TableActions/Edit";
import {onSaveAndOpenCuttingEdit} from "../../store/reducers/CuttingChartReducer";
import {useDispatch, useSelector} from "react-redux";
import {getIsProductionOldInterface} from "../../selectors/production-selectors";

const ActionCuttingEdit = ({cName='', children, title='', icon=false}) => {
    const dispatch = useDispatch();
    const isProductionOldInterface = useSelector(getIsProductionOldInterface)
    const openCuttingEdit = () => {
        dispatch(onSaveAndOpenCuttingEdit());
    };
    return (
        Number(isProductionOldInterface) ?
              <div onClick={openCuttingEdit}
                   className={`cutting-edit-card panel__item ${cName}`}
                   style={{ marginRight: "10px", cursor: 'pointer' }}>
                  {icon ? icon  : <Edit />}
                  {title ?  <span>{title}</span> : ''}
              </div> : children
    );
};

export default ActionCuttingEdit;