import React, {useState, useEffect, useContext, createContext} from 'react';
import md5 from 'md5';
import Translate from "../languages/translation.json";
import ApiService from "../api/api";
import {useSelector, useDispatch} from 'react-redux';
import {actions} from "../store/reducers/TranslationReducer";
import {languagesList} from "../constants";
import {getProductionLang} from "../selectors/production-selectors";
import {getCollectionWordLang, getLang} from "../selectors/common-selector";

const LangContext = createContext();

export const useLang = () => {
    return useContext(LangContext);
};

export const LangProvider = ({children}) => {
    const [words, setWords] = useState([]);
    const collectionWord = useSelector(getCollectionWordLang);
    const lang = useSelector(getLang);
    const productionLan = useSelector(getProductionLang);
    const dispatch = useDispatch();
    const api = new ApiService();

    useEffect(() => {
        if (languagesList.includes(productionLan?.toLowerCase()) && (productionLan?.toLowerCase() !== localStorage.getItem('lang'))) {
            localStorage.setItem('lang', productionLan?.toLowerCase());
            dispatch(actions.changeLang(productionLan?.toLowerCase()));
        }
    }, [productionLan, dispatch]);

    const getTranslate = (key, category = null) => {
        let keyTrim = key ? key.trim() : '';
        let mdKey = md5(keyTrim);
        let localLang = lang ? lang : localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ua';
        localStorage.setItem('lang', localLang);

        if (Translate[0][mdKey]) {
            if (Translate[0][mdKey][localLang]) {
                return Translate[0][mdKey][localLang];
            } else {
                return key;
            }
        } else {
            if (!!keyTrim && !collectionWord.includes(keyTrim)) {

                dispatch(actions.addWord(keyTrim));
            }
        }
        return key;
    };

    const value = {
        getT: getTranslate,
        lang: lang,
        words,
        setWords,
    };

    return (
        <LangContext.Provider value={value}>
            {children}
        </LangContext.Provider>
    );
};
