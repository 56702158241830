import React from 'react';
import {useLang} from "../../../context/langProvider";

const About = () => {
    const {getT} = useLang();

    return <div className="info-page-inner-content">
        <h2 className="privacy-title"> {getT("О проекте.")}</h2>
        <p className="privacy-text">{getT("Проект iFurn.pro Сайт")}
            <a className="privacy-link" href="https://go.ifurn.pro/" target="_blank"> ifurn.pro</a> {getT("создан для принятия заказов на порезку плитных материалов и/или готовых к сборке тумбочек (модулей корпусной мебели).")}
 </p>
        <ul><h4 className="privacy-subtitle">{getT("У нас есть:")}</h4>
            <li>{getT("• Дружественный интерфейс, понятный для мебельщика – профессионала и для конечного покупателя мебели.")}
            </li>
            <li>{getT("• Параметрические тумбочки, мебель. Пользователю достаточно выставить размеры, выбрать материалы, количество изделий. После подтверждения все детали и товары для сборки тумбочки добавятся в деталировку и спецификацию заказа.")}
            </li>
            <li>{getT("• Возможность продавать материал 2 способами: поллиста, лист либо в м2. Доступны методы продажи:")}</li>
            <ul>
                <li>{getT("◦ Лист")}</li>
                <li>{getT("◦ Поллиста по длинной (горизонтальной) стороне")}</li>
                <li>{getT("◦ Поллиста по короткой (вертикальной) стороне")}</li>
                <li>{getT("◦ м2 с указанием дополнительного отхода в %.")}</li>
                {getT("На каждый материал можно выставлять свой % перерасхода. Клиент при калькуляции заказа может" +
                    " выбрать какой из вариантов расчёта материала ему подходит больше.")}
            </ul>
            <li>{getT("• Учёт не целых листовых материалов:")}</li>
            <ul>
                <li>{getT("◦ планирование ожидаемых остатков по заказу")}</li>
                <li>{getT("◦ автоматизированный приём на склад (QR коды)")}</li>
                <li>{getT("◦ автоматическое бронирование остатка под заказ")}</li>
                <li>{getT("◦ ячеечная система хранения и учёта остатков")}</li>
                <li>{getT("◦ подбор ячейки при оприходовании товара")}</li>
                <li>{getT("◦ генерация складских документов для сбора сырья на заказ")}</li>
            </ul>
            <li> {getT("• Показ клиенту только тех материалов, на которые Компания-производитель выставила свои" +
                " кода.")}</li>
            <li>{getT("• Возможность добавить свой материал или свою кромку при вводе заказа либо использовать давальческое  сырьё. Работа с листами и кусками материала.")}
            </li>
            <li> {getT("• Операции обработки детали:")}</li>
            <ul>
                <li>{getT("◦ Порезка")}</li>
                <li>{getT("◦ Кромкование ПУР/ЕВА/ЛАЗЕР с прифуговкой и без неё")}</li>
                <li>{getT("◦ Срез торца под угол от -45 до 45 градусов.")}</li>
                <li>{getT("◦ Криволинейное кромкование")}</li>
                <li>{getT("◦ Сшивка (7 видов) с автоматическим расчётом необходимых частей для заданной готовой детали")}</li>
                <li>{getT("◦ Урезка детали после обработки ЧПУ")}</li>
                <li>{getT("◦ Обработка ЧПУ:")}</li>
                <ul>
                    <li>{getT("▪ Сверление по 6 сторонам детали")}</li>
                    <li> {getT("▪ Автоматический подбор сверел и пил под заданную глубину сверления из внесенных" +
                        " Компанией-производителем в админ панели.")}
                    </li>
                    <li>{getT("▪ Пазы по 6 сторонам детали, открытые (после кромкования) и закрытые (до" +
                        " кромкования).")}</li>
                    <li>{getT("▪ Выемка:")}</li>
                    <ul>
                        <li>{getT("• Прямоугольник")}</li>
                        <li>{getT("• Скруглённый прямоугольник")}</li>
                        <li>{getT("• Круг")}</li>
                    </ul>
                </ul>
                <li>{getT("▪ Фрезерование по лицевой и обратной сторонам по параметрическим шаблонам. Все шаблоны" +
                    " созданы с учётом прифуговки при фрезеровке. На выходе фрезера будет деталь без ступеньки для" +
                    " качественного криволинейного кромкования.")}
                </li>
                <li> {getT("▪ Возможность задать какая часть фрезерного реза в шаблоне не войдёт в расчёт" +
                    " стоимости")}.</li>
            </ul>
            <li> {getT("• Параметричность ЧПУ операций. При задании точек обработки доступны параметры:")}</li>
            <ul>
                <li> {getT("◦ XYZ детали")}</li>
                <li>{getT("◦ Толщины кромок по сторонам, размеры прифуговок по сторонам")}</li>
                <li>{getT("◦ Параметры производственных ограничений либо параметры, задаваемые в админпанели" +
                    " фирмой-производителем услуги (например: отступ от стороны для паза под ДВП, глубина паза под" +
                    " ДВП и т.д.). ")}
                </li>
            </ul>
            <li>{getT("• Возможность вводить/редактировать деталировку в 3 режимах (с кромкой, перед прифуговкой, после прифуговки).")}
            </li>
            <li> {getT("• Производственные ограничения. Любой проект постоянно проверяется в фоновом режиме. В" +
                " случае обнаружения ошибки, пользователь видит извещение об этом. Действия с проектом невозможны до исправления" +
                "ошибки. Сейчас доступно для внесения более 100 параметров, в дальнейшем их количество вырастет минимум" +
                "до 200. Т.е. система делается максимально гибкая для настройки Компанией-производителем «под себя»." +
                " При необходимости дополнительных настроек, они легко вносятся в систему.")}
            </li>
            <li>{getT("• Конвертация четвертей, широких пазов во фрезерные резы при генерации программ.")}</li>
            <li>{getT("• Калькуляция широких пазов как выемок.")}</li>
            <li>{getT("• Админ-панель.")}</li>
            <ul>
                <li>{getT("◦ Включение/выключение операций с деталью")}</li>
                <li>{getT("◦ Ввод параметров для производственных ограничений")}</li>
                <li>{getT("◦ Ввод параметров для параметрических тумбочек")}</li>
                <li>{getT("◦ Ввод и редактирование данных о товарах и услугах Компанией – производителем")}</li>
                <li>{getT("◦ Ввод техпроцесса создания каждой услуги (в дальнейшем он выводится на этикетку")}</li>
                <li>{getT("◦ Ввод информации о торговых и производственных точках Компании - производителя")}</li>
            </ul>
            <li> {getT("• Выгрузка программ")}.</li>
            <ul>
                <li>{getT("◦ Для пильных центров выгржаются программы в нужном Компании-производителю формате.")}</li>
                <li>{getT("◦ Выгрузка программ в автоматическом и ручном (редактирование кроя) режимах.")}</li>
                <li>{getT("◦ Для ЧПУ выгружается нужный формат в 2 размерах (закромкованная деталь, заготовка после" +
                    " распила). В дальнейшем оператор станка может использовать одну из программ в зависимости от" +
                    " стадии технологического процесса.")}
                </li>
            </ul>
            <li>{getT("• Сетевое защщённое хранилище программ для каждого клиента. Возможна настройка выгрузки на" +
                " станки программ без вмешательства во внутреннюю сеть клиента.")}
            </li>
            <li>{getT("• Этикетка. На этикетке содержится 100% информации, нужной для обработки детали, также печатается" +
                "порядок обработки (техпроцесс) – по каким станкам нужно передавать деталь (указывается" +
                "последовательность). Указывается сайт Компании-производителя. Возможна гибкая настройка полей" +
                " этикетки " +
                "под Компанию-производителя.")}
            </li>
            <li>{getT("• Этикетки и документы имеют QR-код. При сканировании в интернете откроется полная информация" +
                " по детали или по документу. QR код также используется для приходования остатков по заказу.")}
            </li>
            <li>{getT("• «Гиблаб». Наш сервис совместим с онлайн-комплексом «Гиблаб». Конвертация форматов в обе стороны" +
                "происходит «на лету» незаметно для пользователя. Можно работать в нашем интерфейсе или в интерфейсе" +
                "Гиблаб. В обоих случаях вводимые данные проверяются и вносятся корректно в проект. В интерфейсе «Гиблаб»" +
                "синхронизируется:")}
            </li>
            <ul>
                <li>{getT("◦ Библиотека материалов")}</li>
                <li>{getT("◦ Проверка производственных ограничений")}</li>
                <li>{getT("◦ Конвертация графических пазов «Гиблаб» в ЧПУ операции")}</li>
                <li>{getT("◦ Конвертация любых XNC операций «Гиблаб» в формат IFP")}</li>
                <li>{getT("◦ Библиотека инструментов также подтягивается из админпанели Компании-производителя")}</li>
                <li>{getT("◦ Параметрические шаблоны (без учёта прифуговки), совпадающие с шаблонами IFP")}</li>
            </ul>
            <li>{getT("• Отправка заказа клиенту прямо с сайта.")}</li>
            <li>{getT("• Статусы заказов.")}</li>
            <li>{getT("• Сбор всей логистической информации для доставки заказа при подтверждении.")}</li>
            <li>{getT("• В заказах содержится версионирование. Всегда можно вернутся к сохраненной ранее версии.")}</li>
            <li>{getT("• Отчёты для клиента:")}</li>
            <ul>
                <li>{getT("◦ Бланк заказа (краткий отчёт для согласования)")}</li>
                <li>{getT("◦ Полный отчёт с ЧПУ – максимально детализированный отчёт, с расшифровкой всех операций")}</li>
                <li>{getT("◦ Стандартные отчёты сервиса «Гиблаб»")}</li>
            </ul>
            <li>{getT("• Экспорт:")}</li>
            <ul>
                <li>{getT("◦ Программы для станков")}</li>
                <li>{getT("◦ Файлы в формате project («Гиблаб») в пильном и габаритном размерах")}</li>
                <li>{getT("◦ Файл в формате project в формате IFP")}</li>
            </ul>
            <li>{getT("• Импорт:")}<br/> {getT("Импорт возможен как одного проекта, так и группы проектов с объединением деталей в нужных клиенту материалах.")}</li>
            <ul>{getT("Форматы:")}
                <li>{getT("◦ Project («Гиблаб») с авто корректировкой инструмента под пазы и фрезерование, авто" +
                    " корректировкой круговых дуг.")}
                </li>
                <li>{getT("◦ BCAD")}</li>
                <li>{getT("◦ Базис")}</li>
                <li>{getT("◦ Woody")}</li>
                <li>{getT("◦ WoodWop")}</li>
                <li>{getT("◦ 3D-Constructor (с корректным импортом сшивок)")}</li>
            </ul>
            <li>{getT("• Мультивалютность (выдача стоимости и ввод цены материала в выбранной валюте)")}</li>
            <li>{getT("• Синхронизация:")}</li>
            <li>{getT("• 1С8:")}</li>
            <ul>
                <li>{getT("◦ Синхронизация товаров")}</li>
                <li>{getT("◦ Синхронизация заказов покупателя")}</li>
                <li>{getT("◦ Синхронизация контрагентов (получение нужной информации)")}</li>
                <li>{getT("◦ Ценообразование")}</li>
                <li>{getT("◦ Управление доступом пользователей")}</li>
            </ul>
            <li>{getT("• API")}</li>
            <p className="privacy-text">{getT("К сервису возможен доступ по API. На сайте")} <a className="privacy-link"
                                                                                      href="https://help.ifurn.pro/"
                                                                                      target="_blanck">help.ifurn.pro </a>{getT(" есть " +
                "подробное описание возможностей. С помощью API с сервисом можно синхронизировать любую БД, нужную" +
                " клиенту.")} </p></ul>
    </div>
};

export default  About;