import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changePlaneOparetionsModalValue} from "../../store/reducers/ModalReducer";
import "./PlaneOperations.css";
import Search from "../../Icons/Search";
import GoBackArrow from "../../Icons/Actions/GoBackArrow";
import {getModalPlaneOperation} from "../../selectors/modal-selector";
import {useLang} from "../../context/langProvider";
import {getOrder} from "../../selectors/order-selector";
import OperationsPartTable from "./components/OperationsPartTable";
import OperationsFormChoice from "./components/OperationsFormChoice";
import Close from "../../Icons/Actions/Close";

const INITIAL_FORM_VALUE = {
    name: "",
    id: null
}

const PlaneOperations = () => {
    const {getT} = useLang()
    const modalDt = useSelector(getModalPlaneOperation);
    return modalDt?.isOpen ? <Component getT={getT}/> : null;
};
const Component = ({getT}) => {
    const dispatch = useDispatch();
    const modalDt = useSelector(getModalPlaneOperation);
    const store = useSelector(getOrder);
    const [active, setActive] = useState({...INITIAL_FORM_VALUE});
    const [material_active, setMaterialActive] = useState(null)

    const materials = useMemo(() => {
        return store?.material?.filter(m => store?.part?.some(p => Number(p?.material) === Number(m?.id)))
    }, [store])

    const parts = useMemo(() => {
        return store?.part?.filter(p => Number(p?.material) === Number(material_active))?.sort((a, b) => a.id - b.id)
            ?.map(part => {
                let product = store?.product?.find(e => Number(e?.id) === Number(part?.product))
                return {
                    ...part, product_name: product?.name?? null
                }
            }, [])
    }, [store.part, material_active])
    const operations = useMemo(() => {
        return store?.plane_operations?.filter(item => modalDt?.search ? item?.name.includes(modalDt.search) : true);
    }, [modalDt.search]);

    const close = useCallback(() => dispatch(changePlaneOparetionsModalValue("isOpen", false)), [dispatch]);

    const resetOperations = () => {
        setActive(prev => ({...INITIAL_FORM_VALUE}))
    }
    const handlerSetActivePlane = (data) => (e) => {
        setActive(prev => ({...data}));
        setMaterialActive(materials?.[0]?.id ?? null)
    }
    const handlerChangeMaterial = (e) => {
        setMaterialActive(e?.target?.value)
    }
    return <div className="nightstands-wrapper modal-page plane-operations-modal">
        <div className="nightstands-container">
            <div className="nightstands-header">

                <div className="go-back-arrow cursor-pointer" onClick={() => close()}>
                    <GoBackArrow/>
                    <span style={{fontWeight: '600'}}>{getT("Обработки плоскости")}</span>
                </div>


                {/*<h3 className="" onClick={close}></h3>*/}
                {!active?.id  &&
                    <div className="search-bar-field">
                        <Search/>
                        <input type="text"
                               placeholder={getT("Поиск")}
                               onChange={(event) => dispatch(changePlaneOparetionsModalValue("search", event.target.value))}
                               value={modalDt.search}
                        />
                    </div>}

            </div>


            <div className="main_content">

                <div>
                    {active?.id ?
                        <div className=" mb-2 nightstands-content-panel">
                            <h3 className="nightstands-content-title m-0">{active.name}
                                <Close title={getT("Сбросить")} onClick={resetOperations}/>
                            </h3>
                            <select name="" id="" onChange={handlerChangeMaterial} className={'select-control'}>
                                {materials?.map(m => {
                                    return <option value={m?.id}>{m?.name}</option>
                                })}
                            </select>
                        </div> :   null}
                    <p className="nightstands-content-subtitle">
                        {active.id ? getT("Выберите детали для которых необходимо применить обработку") : getT("Выберите одну из доступных операций")}
                    </p>
                </div>
                <div className="card-container">
                    <div className="table-wrapper">
                        {active.id ? <>

                                <OperationsPartTable getT={getT} key={'plane-operation-table'} parts={parts}
                                                     current_operation={operations?.find(e=> Number(e.id) === Number(active.id))}
                                />
                            </>
                            : <OperationsFormChoice getT={getT} key={'plane-operation-choice'} choice_operation={active}
                                                    handlerSetOperations={handlerSetActivePlane}
                                                    plane_operations={operations}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>;
}


export default PlaneOperations;
