import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {useDetectClickOutside} from "react-detect-click-outside";
import {useDispatch, useSelector} from "react-redux";
import LogOut from "../../Icons/LogOut";
import MyOrders from "../../Icons/MyOrders";
import {logoutThunk} from "../../store/reducers/AuthReducer";
import "./Cabinet.css";
import CabinetContainer from "./CabinetContainer";
import {getAuthUserDT, getCabinetUsserFirm} from "../../selectors/cabinet-sector";
import {isAccessEdit} from "../SettingsFirm/help";
import Instruments from "../SettingsFirm/Instruments";
import Cells from "../SettingsFirm/CellsStorage";
import Remains from "../SettingsFirm/Remains";
import Plane from "../SettingsFirm/Plan";
import Services from "../SettingsFirm/Services";
import Furniture from "../Furniture";
import {RequestInvoice} from "../../components/Modals/RequestInvoice";
import {onPayRequestInvoiceModal} from "../../store/reducers/ModalReducer";
import {withAuth} from "../../HOC/withAuth";
import Logo from "../../Icons/Logo";
import {getNewsSelected, getRuleSettingsProject} from "../../selectors/common-selector";
import {getProductionLang, getProductionLogo} from "../../selectors/production-selectors";
import Language from "../../components/Language";
import BasicMenu from "../../components/BasicStaticMenu/BasicMenu";
import {useLang} from "../../context/langProvider";
import {useHistory} from "react-router-dom";
import Profile from "./component/Profile";
import {LS_IS_AUTH} from "../../constants";
import CabinetSwitchFirms from "./CabinetSwitchFirms";
import {getNewsThunk} from "../../store/reducers/CommonReducer";
import NewsContainer from "./NewsContainer";
import IconQuestion from "../../Icons/IconQuestion";
import {helpArticleGetByIdToggleModalThunk} from "../../store/thunks/help-article-thunk";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../store/constats/help_article_constants";
import HelpArticleModal from "../../components/Modals/HelpArticle/HelpArticleModal";
import {initialSettingsFirm} from "../../store/reducers/SettingsReducer";

const PAGES_INITIAL = {
    order: false,
    instruments: false,
    plane: false,
    cell: false,
    remains: false,
    stock: false,
    stockAdd: false,
    service: false,
    furniture: false,
    profile: false,
    client_firms: false,
    firms: false,
    news: false
}
const LayoutCabinet = () => {
    const dispatch = useDispatch();
    const {getT} = useLang();
    const history = useHistory();
    const userDT = useSelector(getAuthUserDT);
    // const client_firms_state = useSelector(getAuthClientFirmsDT);
    const news_state = useSelector(getNewsSelected);
    const ruleSettingsProject = useSelector(getRuleSettingsProject);
    const productionLang = useSelector(getProductionLang) || ruleSettingsProject?.lang || "";
    const logoProduction = useSelector(getProductionLogo);
    const onOpenPayModal = () => dispatch(onPayRequestInvoiceModal({key: "requestInvoice", dt: {isOpen: true}}));
    const [pagesState, setPagesState] = useState({
        ...PAGES_INITIAL,
        order: true,

    });
    const onChangeTabShow = (key) => {
        setPagesState(prev => {
            let dt = {
                ...PAGES_INITIAL
            };
            return {...dt, [key]: true};
        });
    };
    useEffect(() => {
        if (history?.location?.state?.isOpenUserData) {
            onChangeTabShow('profile')
        }
        dispatch(getNewsThunk());
        dispatch(initialSettingsFirm())
    }, [])


    const [subMenu, setSubMenu] = useState(false);
    const refSubMenu = useDetectClickOutside({onTriggered: () => setSubMenu(false)});
    const idFirm = useSelector(getCabinetUsserFirm);
    const logout = () => dispatch(logoutThunk());

    const handlerHelpArticle = (id)=>(e)=>{
        e.preventDefault();
        e.stopPropagation();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }

    if (!userDT?.id || !userDT?.client_id) {
        return <div className={"cabinet-wrapper"}>
            <div className="container">
                <div className="row w-100">
                    <h3 className="header-title text-center">{getT("Доступ запрещен!")}</h3>
                    <h4 className={'text-center'}>{getT("Это портал для клиентов, перейти на")} <br/> <a
                        href={process.env.REACT_APP_CRM_IFRURN_PRO_URL}>{process.env.REACT_APP_CRM_IFRURN_PRO_TITLE}</a>
                    </h4>
                </div>

            </div>

        </div>
    }
    return <>
        <div className="cabinet-wrapper">
            <div className="header-container">
                <h3 className="header-title">{getT("Личный кабинет")} {ruleSettingsProject?.name} </h3>

                <div className="user-avatar" ref={refSubMenu}>

                    {userDT && userDT?.name ? <span onClick={() => setSubMenu(!subMenu)}
                                                    className={"user-name-block"}>{userDT.name}</span> : ""}

                    <Language production={productionLang} isSaveProject={false}/>

                    <FontAwesomeIcon onClick={() => setSubMenu(!subMenu)} icon={faUser} style={{
                        width: "25px",
                        height: "25px"
                    }}/>
                    {subMenu &&
                        <div className="logout-tb">
                            <div onClick={() => logout()} className="logout">
                                <LogOut/> <span className="logout-text">{getT("Выйти")}</span>
                            </div>
                        </div>}

                </div>

            </div>

            <div className="content">
                <aside className="col sidebar">
                    <div className={pagesState.order ? "open item_nav" : "item_nav"}
                         onClick={() => onChangeTabShow("order")}>
                        <MyOrders/> <span className="">{getT("Мои заказы")}</span>
                        <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.order_list)} style={{marginLeft: '6px'}}/>
                    </div>
                    {/*<div className={pagesState.settings ? "open item_nav" : "item_nav"} onClick={()=> onChangeTabShow('settings')}>*/}
                    {/*<Setting/><span className="">{getT('Настройки')}</span>*/}
                    {/*</div>*/}
                    <div className={pagesState.instruments ? "open item_nav" : "item_nav"}
                         onClick={() => onChangeTabShow("instruments")}><span></span><span
                        className="">{getT("Инструменты")}</span>
                        <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.tools)} style={{marginLeft: '6px'}}/>

                    </div>
                    <div className={pagesState.service ? "open item_nav" : "item_nav"}
                         onClick={() => onChangeTabShow("service")}><span></span><span>{getT("Услуги")}</span>
                        <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.services)} style={{marginLeft: '6px'}}/>

                    </div>
                    {isAccessEdit(Number(idFirm)) && <>
                        <div className={pagesState.cells ? "open item_nav" : "item_nav"}
                             onClick={() => onChangeTabShow("cells")}><span></span><span
                            className="">{getT("Ячейки хранения")}</span></div>
                        <div className={pagesState.remains ? "open item_nav" : "item_nav"}
                             onClick={() => onChangeTabShow("remains")}>
                            <span></span><span>{getT("Не целые остатки листовых" +
                            " материалов")}</span></div>
                        <div className={pagesState.plane ? "open item_nav" : "item_nav"}
                             onClick={() => onChangeTabShow("plane")}><span></span><span>{getT("Планируемые остатки" +
                            " не целых листовых материалов")}</span></div>

                        <div className={pagesState.furniture ? "open item_nav" : "item_nav"}
                             onClick={() => onChangeTabShow("furniture")}>
                            <span></span><span>{getT("Мебельный модуль")}</span></div>


                        {/*<div className={pagesState.stockAdd ? 'open item_nav' : 'item_nav'}*/}
                        {/*onClick={() => onChangeTabShow('stockAdd')}><span></span><span>{getT('Добавить остаток')}</span></div>*/}
                    </>}
                    <div className={pagesState.profile ? "open item_nav" : "item_nav"}
                         onClick={() => onChangeTabShow("profile")}>
                        <span></span><span
                        className={`${(!userDT?.phone_confirmed || !userDT?.email_confirmed) ? 'clr-red' : ''}`}>{getT("Основная информация")}</span>
                        <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.basic_information)} style={{marginLeft: '6px'}}/>

                    </div>
                    {!!(Array.isArray(userDT?.firms) && userDT?.firms?.length > 1) &&
                        <div className={pagesState.firms ? "open item_nav" : "item_nav"}
                             onClick={() => onChangeTabShow("firms")}>
                            <span></span><span>{getT("Фирмы")}
                        </span>
                            <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.firms)} style={{marginLeft: '6px'}}/>
                        </div>
                    }
                    {!!(news_state?.pages) &&
                        <div className={pagesState.news ? "open item_nav" : "item_nav"}
                             onClick={() => onChangeTabShow("news")}>
                            <span></span><span>{getT("Новости")}</span>
                        </div>
                    }

                    {/*{client_firms_state?.not_connected?.length &&*/}
                    {/*    <div className={pagesState.client_firms ? "open item_nav" : "item_nav"}*/}
                    {/*         onClick={() => onChangeTabShow("client_firms")}>*/}
                    {/*        <span></span><span>{getT("Филиалы")}</span></div>}*/}

                    <div onClick={() => logout()} className="logout">
                        <LogOut/> <span className="logout-text">{getT("Выйти")}</span>
                    </div>
                    <br/>
                    <div className="w-100"></div>
                    {/*{ userDT &&*/}
                    <div className="info-user-data">
                        <ul style={{padding: "0px"}}>
                            <li className={"label"}><strong>{getT("Пользователь")}:</strong> {userDT?.name}</li>
                            <li className={"label"}><strong>{getT("Email")}:</strong> {userDT?.email}</li>
                            <li className={"label"}><strong>{getT("Телефон")}:</strong> {userDT?.phone}</li>
                        </ul>

                    </div>
                    {/*}*/}

                    <br/>
                    <div className="w-100"></div>
                    {isAccessEdit(Number(idFirm)) && JSON.parse(localStorage.getItem(LS_IS_AUTH)) &&
                        <div className="payment-block">
                            {userDT.hasOwnProperty("payment") && Array.isArray(userDT.payment) && userDT.payment.length == 0 && <>
                                <div className={""}>
                                    <p>{getT("Пробная версия")}:</p>
                                    <ul className={"payment-list"}>
                                        <li>{getT("Нельзя создать более одного заказа")}</li>
                                        <li>{getT("Нельзя внести более 10 деталей в проект")}</li>
                                        <li>{getT("Нельзя создавать параметрическую мебель")}</li>
                                    </ul>
                                </div>
                                <button className={"btn btn-reverse"}
                                        onClick={onOpenPayModal}>{getT("Запросить счёт")}</button>
                            </>
                            }
                            {isAccessEdit(Number(idFirm)) && userDT.hasOwnProperty("payment") && !Array.isArray(userDT.payment) && <>
                                <p
                                    style={{margin: "0px"}}>{getT("Оплачено до")} {new Date(userDT.payment.payment_period_end).toISOString().split("T")[0]}</p>
                            </>}
                        </div>}
                    {ruleSettingsProject && !ruleSettingsProject.infoText &&
                        <div className={"cabinet__static-page"}>
                            <BasicMenu getT={getT}/>
                        </div>
                    }
                    <div className="cabinet-logo">
                        <a href={ruleSettingsProject?.www} target={"_blank"} className="">
                            {ruleSettingsProject && ruleSettingsProject.logoUrl ?
                                <img src={ruleSettingsProject.logoUrl} alt=""/> : logoProduction ?
                                    <img src={logoProduction} alt={''}/>
                                    : <Logo/>}
                        </a>
                    </div>
                </aside>
                {pagesState.order && <CabinetContainer/>}
                {pagesState.instruments &&
                    <div className="col main" style={{marginTop: "25px"}}><Instruments getT={getT} firmId={idFirm}/>
                    </div>}
                {isAccessEdit(Number(idFirm)) && pagesState.cells &&
                    <div className="col main" style={{marginTop: "25px"}}><Cells getT={getT}/></div>}
                {isAccessEdit(Number(idFirm)) && pagesState.remains &&
                    <div className="col main" style={{marginTop: "25px"}}><Remains getT={getT}/></div>}
                {isAccessEdit(Number(idFirm)) && pagesState.plane &&
                    <div className="col main" style={{marginTop: "25px"}}><Plane getT={getT}/></div>}
                {pagesState.service &&
                    <div className="col main" style={{marginTop: "25px"}}><Services getT={getT} firmId={idFirm}/></div>}
                {isAccessEdit(Number(idFirm)) && pagesState.furniture &&
                    <div className="col main" style={{marginTop: "25px"}}>
                        <Furniture getT={getT}/>
                    </div>}

                {isAccessEdit(Number(idFirm)) && <RequestInvoice getT={getT}/>}
                {pagesState.firms && <div className="col main" style={{marginTop: "25px"}}>
                    <CabinetSwitchFirms firms={userDT?.firms} firmId={idFirm} getT={getT}/>
                </div>
                }
                {pagesState.profile &&
                    <div className="col main" style={{marginTop: "25px"}}>
                        <Profile getT={getT} userDT={userDT}/>
                    </div>}
                {pagesState.news &&
                    <div className="col main" style={{marginTop: "25px"}}>
                        <NewsContainer getT={getT} news_state={news_state}/>
                    </div>}

                {/*{pagesState.client_firms &&*/}
                {/*    <div className="col main" style={{marginTop: "25px"}}><ClientFirms getT={getT} firmId={idFirm} client_firms_state={client_firms_state}/>*/}
                {/*    </div>}*/}
                {/*{isAccessEdit(Number(idFirm)) && pagesState.stock && <div className="col main" style={{marginTop:'25px'}}><CustomerBalances getT={getT}/></div>}*/}
                {/*{isAccessEdit(Number(idFirm)) && pagesState.stockAdd && <div className="col main" style={{marginTop:'25px'}}><CustomerBalancesAdd getT={getT}/></div>}*/}
                {/*{pagesState.settings &&   <div className="col main" style={{marginTop:'25px'}}><CabinetSettingPage getT={getT}/> </div>}*/}
            </div>
        </div>

    </>;
};

export default withAuth(LayoutCabinet);