export const TOGGLE_LIST_IS_OPEN = 'TOGGLE_LIST_IS_OPEN';
export const TOGGLE_CONSTRUCTOR_IS_OPEN = 'TOGGLE_CONSTRUCTOR_IS_OPEN';
export const TOGGLE_IS_LOADING = 'TOGGLE_IS_LOADING';
export const SET_BOXES = 'SET_BOXES';
export const CHANGE_FILTER_VALUE = 'CHANGE_FILTER_VALUE';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_BOX_PARAMS = 'SET_BOX_PARAMS';
export const SET_EDITOR_BOX_ID = 'SET_EDITOR_BOX_ID';
export const DELETE_BOX = 'DELETE_BOX';
export const SET_BOX_PARTS = 'BOX/SET_PARTS_DT'
export const UPDATE_BOX_PARTS_CHECKED = 'BOX/UPDATE_PARTS_DT_CHECKED'
export const SET_BOX_GENERAL = 'BOX/SET_GENERAL_DT'
export const UPDATE_BOX_GENERAL_VALUE = 'BOX/UPDATE_GENERAL_DT_VALUE'
