import Modal from "../BasicModal";
import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAuthUserDT, getPayRequestInvoiceModal} from "../../../selectors/cabinet-sector";
import {onPayRequestInvoiceModal, onSendPayRequestInvoice} from "../../../store/reducers/ModalReducer";

export const RequestInvoice = ({getT}) => {
    const dispatch = useDispatch();
    const {requestInvoice} = useSelector(getPayRequestInvoiceModal);
    const userDt = useSelector(getAuthUserDT);
    const onClose = () => dispatch(onPayRequestInvoiceModal({
        key: 'requestInvoice',
        dt: {isOpen: false, isSuccessful: false}
    }));
    const [dateTo, setDateTo] = useState('');
    const onSubmit = (e) => {
        e.preventDefault();
        let text =  'Фирма' +userDt.firm + ' Клиент' + userDt.id + ' просит счёт до ' + dateTo;
        dispatch(onSendPayRequestInvoice(text))
    }
    return <Modal open={requestInvoice.isOpen} onClose={onClose} title={getT('Запросить счёт')}>
        {requestInvoice.isSuccessful ?
            <h3 className={'text-center inst__bore-form'} style={{textAlign:'center'}}>{getT('Ваш запрос успешно отправлен.')} <br/>
                {getT('Он будет обработан в сжатые сроки.')}</h3>
            :
            <form action="" onSubmit={onSubmit} className={"inst__bore-form"}>
                <div className="field w-100">
                    <label htmlFor=""
                           className={"field-title label"}>{getT('До какой даты планируете пользоваться')}</label>
                    <input className={'input-val w-100'} type="date" value={dateTo}
                           onChange={(e) => setDateTo(e.target.value)}
                           required={true}
                    />
                    <button className={'btn'}>{getT('Отправить')}</button>
                </div>
            </form>
        }
    </Modal>
}