import React from 'react';
import {connect} from "react-redux";
import UploadProjectModal from "./UplodaProjectModal";
import {changeUploadProjectModalValue} from "../../../store/reducers/ModalReducer";
import {sendProjectFileDataThunk} from "../../../store/reducers/OrderReducer";

class UploadProjectModalContainer extends React.Component {

    constructor(props) {
        super(props);
        this.getFiles = this.getFiles.bind(this);
        this.sendFileData = this.sendFileData.bind(this);
        this.state = {
            file: null
        }
    }

    componentDidMount() {

    }

    getFiles(files) {
        this.setState({
            file: files
        })
    }

    sendFileData() {
        this.props.sendFiles(this.props.order, this.state.file, this.props.client_id);
    }

    render() {
        return !this.props.isOpen ? null :
            <UploadProjectModal isOpen={this.props.isOpen}
                                toggleOpen={() => this.props.changeValue('isOpen', false)}
                                getFiles={this.getFiles}
                                file={this.state.file}
                                sendFileData={this.sendFileData}
            />
    }
}

let mapStateToProps = (state) => {
    return {
        isOpen: state.modal.uploadProject.isOpen,
        order: state.order.order,
        client_id: state.auth.user.id
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeUploadProjectModalValue(input, value)),
        sendFiles: (order, files) => dispatch(sendProjectFileDataThunk(order, files)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadProjectModalContainer);
