import React, {useCallback,  useId, useMemo, useState} from 'react';
import {maskPhoneCountry, SELECT_OPTIONS_PHONE} from "../../../../constants";
import InputMask from "react-input-mask";
import {compareAndFindCodePhoneByKey} from "../../../../helpers/helpersListCountryCode";

const FieldPhone = ({
                        onChange,
                        onChangePhoneCode,
                        value_phone_code,
                        label = null,
                        value = '',
                        setting_code_country = null,
                        setting_hide_code_country = null,
                        ...resetInput
                    }) => {
    const [inputMask, setInputMask] = useState(value)

    const isHasSettingCodeCountry = useMemo(() => {
        let find_code = Object.keys(maskPhoneCountry)?.find(key => key.trim().includes(`+${setting_code_country}`?.trim()))

        return (setting_code_country && maskPhoneCountry[find_code]);
    }, [setting_code_country]);



    const options_phone_list = useMemo(() => {
        if (!setting_hide_code_country) {
            return SELECT_OPTIONS_PHONE
        }
        return Object.entries(SELECT_OPTIONS_PHONE).reduce((acc, [key, value]) => {
            if (key !== setting_hide_code_country) {
                acc[key] = value;
            }
            return acc;
        }, {});
    }, [setting_hide_code_country])

    const onChangeTel = useCallback((event) => {
        setInputMask(event.target.value);
        onChange(`${event.target.value}`)
    }, [onChange, value_phone_code, setInputMask, inputMask]);
    return (
        <div className={' form-field form-group  '}>
            {label && <label className={"label"}>{label}</label>}
            <div className=" filed-phone-mask">
                <select name="phone_code" id=""  className={''} key={''}
                        onChange={(e) => onChangePhoneCode(e.target.value)}
                        disabled={isHasSettingCodeCountry}>
                    {Object.entries(options_phone_list).map(([key, value]) => {
                        return <option value={value.code} key={key} selected={!!(value_phone_code?.trim() === value.code?.trim())}>({value.label}) {value.code}</option>
                    })}
                </select>
                <InputMask
                    className={''}
                    value={inputMask}
                    mask={maskPhoneCountry[compareAndFindCodePhoneByKey(value_phone_code, maskPhoneCountry)]}
                    placholder={maskPhoneCountry[compareAndFindCodePhoneByKey(value_phone_code, maskPhoneCountry)]}
                    onChange={onChangeTel}
                    type={'tel'}
                    {...resetInput}
                />
            </div>
        </div>
    );
};

export default FieldPhone;