import ApiService from "../../api/api";
import {changeAlertStatusAC, changeLoaderStatusAC, checkErrorsAPI} from "./CommonReducer";
import {setMaterialsDeleteProductPrice, setMaterialsProductPrice} from "./MaterialsReducer";
import {onToggleMaterialProductPriceModal} from "./ModalReducer";
import {dispatcherErrorThunk} from "../thunks/common-thunk";

const api = new ApiService();
export const setServicePriceFiledDT = (dt) => {
    return {
        goods: dt?.goods ?? null,
        service: dt?.service ?? null,
        drop: dt.drop,
        currency: dt.currency,
        price: dt.price
    }
}
const initialState = {
    currency: [],
    drop: [],
    drop_units_m2: [],
    service: [],
    unit: [],
    productPriceForm: {
        currency: '',
        drop: '',
        price: ''
    }
}
const actionType = {
    initialService: 'SERVICES/INITIAL_PROPERTY',
    onUpdateServicePrice: 'SERVICE/UPDATE_SERVICE_PRICE',
    onChangeMaterialProductForm: 'PRODUCT/CHANGE_VALUE_FORM',
    resetFormValue: 'RESET_FORM'
}
export const actionsServices = {
    onInitialService: (dt) => {
        return {
            type: actionType.initialService,
            payload: dt
        }
    },
    onSuccessChangeServicePriceOptions: (id, dt) => {
        return {
            type: actionType.onUpdateServicePrice,
            payload: {
                goods_service_id: id,
                dt
            }
        }
    },
    onChangeMaterialProductForm: ({key, value}) => {
        return {
            type: actionType.onChangeMaterialProductForm,
            payload: {key, value}
        }
    },
    onResetForm: ({form, dt}) => {
        return {
            type: actionType.resetFormValue,
            payload: {
                form, dt
            }
        }
    }
}
const ServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.initialService: {
            return {
                ...state,
                ...action.payload
            }
        }
        case actionType.onUpdateServicePrice: {
            const updateService = state.service.service.map(service => {
                if (Number(service.goods_service_id) === Number(action.payload.goods_service_id)) {
                    service.price = {
                        ...service.price,
                        ...action.payload.dt
                    }
                }
                return service
            })
            return {
                ...state,
                service: {
                    ...state.service,
                    service: updateService
                }
            }
        }
        case actionType.onChangeMaterialProductForm: {
            return {
                ...state,
                productPriceForm: {
                    ...state.productPriceForm,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case actionType.resetFormValue: {
            return {
                ...state,
                [action.payload.form]: initialState[action.payload.form]
            }
        }
        default: {
            return {
                ...state
            }
        }
    }

}

export const onSetInitialServiceDT = (firm) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._getSettingsFirmProduction(firm);

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.hasOwnProperty('base_data')) {
            dispatch(actionsServices.onInitialService(response.base_data))
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        checkErrorsAPI('', 'api:/settings/firm, M:G')
        dispatch(changeLoaderStatusAC(false));

        console.log(e)
    }
    dispatch(changeLoaderStatusAC(false));
}

export const onServicePriceCUCabinet = (dt) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const payload = setServicePriceFiledDT(dt);
        const response = await api._servicePriceCU(payload);

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.res == 'ok') {
            dispatch(actionsServices.onSuccessChangeServicePriceOptions(dt.service, response.model))
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        checkErrorsAPI('', 'api:/goods_firm_user/, M:P')
        dispatch(changeLoaderStatusAC(false));


        console.log(e)
    }

}

async function requestMaterialProductPrice(dt) {
    try {
        const payload = setServicePriceFiledDT(dt);
        const response = await api._servicePriceCU(payload);
        if (api._errors) {
            return Promise.reject(api._getErrors())
        }
        if(response.res === 'ok') return Promise.resolve(response)
        return Promise.resolve(false)
    } catch (e) {
        return Promise.reject(e)
    }
}

export const onMaterialProductPriceCU = (dt) => async (dispatch, getState) => {
    let materials_store = getState().materials.materials;
    dispatch(changeLoaderStatusAC(true));
    try {
        let response = await requestMaterialProductPrice(dt)
        if(!response){
            dispatch(changeLoaderStatusAC(false));
            return
        }
        dispatch(setMaterialsProductPrice(dt.goods, response.model));
            const material = materials_store.data.find(m => Number(m.goods_id) === Number(dt.goods));

            dispatch(onToggleMaterialProductPriceModal({
                key: 'materialProductPrice', dt: {
                    goods: material?.goods,
                    title: material?.translate[localStorage.getItem('lang')] ?? material[0].name,
                    price: material?.drop_labels_user,
                    part: material?.part,
                    isOpen: true
                }
            }));
            dispatch(actionsServices.onResetForm({
                form: 'productPriceForm'
            }));
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }

}
export const onMaterialProductPriceDelete = (goods, goods_firm_user_id) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));

    try {
        const response = await api._materialProductPriceDelete(goods_firm_user_id);
        const material = getState().materials.materials.data.filter(m => Number(m.goods_id) === Number(goods));
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()))
        } else if (response.result == 'good') {
            dispatch(setMaterialsDeleteProductPrice(goods, goods_firm_user_id));
            dispatch(onToggleMaterialProductPriceModal({
                key: 'materialProductPrice', dt: {
                    goods: material[0].goods,
                    title: material[0].translate[localStorage.getItem('lang')] ?? material[0].name,
                    price: material[0].drop_labels_user ?? [], part: material[0].part,
                    isOpen: true
                }
            }));
            dispatch(actionsServices.onResetForm({
                form: 'productPriceForm'
            }));
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        checkErrorsAPI('', 'api:/goods_firm_user/, M:DELETE')

        dispatch(changeLoaderStatusAC(false));
        console.error(e);
    }


}
export default ServiceReducer;