import React from 'react';
import MainApi from './MainApi';

class ApiNewService extends MainApi {

    //TODO: ссылка под замену (АПИ)
    constructor() {
        super();
    }
    // _baseUrl = 'https://test2-api.ifurn.pro/api';
    // _baseUrl = 'https://test-api.ifurn.pro/api';
    // _baseUrl = 'https://api.ifurn.pro/api';
    // _baseUrl = 'http://api-local.ifurn.pro/api';

    _apiKey = '123456789';

    getAuth = async (email, password) => {
        if (!email || !password) {
            return false;
        }
        const res = await this._send(
            `/authn`,
            'POST',
            {
                email: email,
                password: password
            },
            {
                'Point-Action': 'client'
            },
            'json'
        );
        if (res && res.data) {
            return this._transformAuth(res.data);
        } else {
            return false;
        }
    }
    _transformAuth = (data) => {
        return {
            status: data.status,
            token: data.token,
            firms: data.firms.map(e => {
                return {
                    id: e.firm,
                    name: e.firm_name
                }
            }),
        }
    }
    logout = async ()=>{
        return await this._send('/authn', "DELETE")
    }
    userVerification = async (firm='')=>{
        return await this._send('/authn?firm='+firm, "GET");
    }
    isRedirectAuntification = async (token)=>{
        return await this._send('/ping?auth_token='+token +'&no_redirect=1', "GET");
    }
    getAuthData = async (token = localStorage.getItem('ifurn_service_main_user_token')) => {
        if (!token || !localStorage.getItem('ifurn_service_main_user_firm')) {
            return false;
        }
        const res = await this._send(
            `/authn`,
            'POST',
            {
                token: token,
                user_token: localStorage.getItem('ifurn_service_main_client_token') ?? ''
            },
            {
                'Point-Action': 'token'
            },
            'json'
        );
        if (res && res.data) {
            return this._transformAuthData(res.data);
        } else {
            return false;
        }
    }
    getManagerAuthData = async (client, firm, manager) => {
        if (!client || !firm || !manager) {
            return false;
        }
        const res = await this._send(
            `/authn`,
            'POST',
            {
                client: client,
                firm: firm,
                manager: manager
            },
            {
                'Point-Action': 'manager_auth'
            },
            'json'
        );
        if (res && res.data) {
            return {
                client: this._transformAuthData(res.data.client),
                manager: res.data.user,
                firm: res.data.firm,
                client_token : res.data.client_token,
                user_token: res.data.user.token
            };
        } else {
            return false;
        }
    }
    _transformAuthData = (data) => {

        return {
            id: data.client ? Number(data.client) : null,
            name: data.client_data ? data.client_data.name : '',
            email: data.client_data ? data.client_data.email : null,
            phone: data.client_data ? data.client_data.phone : null,
            firm: localStorage.getItem('ifurn_service_main_user_firm'),
            client_token: data.client_token,
            token: data.token,
            isManager: true,
            auth_client: data.auth_client,
            payment: data.payment ?? [],
            firms: data?.firms ?? [],
            email_confirmed: Number(data?.email_confirmed) ?? 0,
            phone_confirmed: Number(data?.phone_confirmed) ?? 0,
        }
    }
    getAccountOrders = async ( page = 1, filters = {}, token =(localStorage.getItem('ifurn_service_main_client_token') || localStorage.getItem('ifurn_service_main_user_token')), firm = localStorage.getItem('ifurn_service_main_user_firm')) => {

        if (!token || !firm) {
            return false;
        }
        const res = await this._send(
            `/ordersn`,
            'POST',
            {
                token: token,
                firm: firm,
                page: page,
                ...filters
            },
            {
                'Point-Action': 'filter'
            },
            'json'
        );
        if (res && res.data) {
            return {
                count: res.data.count,
                data: this._transformOrdersAccountData(res.data.data)
            };
        } else {
            return false;
        }
    }
    _transformOrdersAccountData = (data) => {
        return data.map(e => {
            return {
                id: Number(e.id),
                name: e.name,
                code: e.code,
                code1c: e.code1c,
                confirmed: e.confirmed,
                goods_stock: e.goods_stock ?? [],
                status: {
                    id: e.status.id,
                    name: e.status.name
                },
                phones: e.phone,
                email: e.email,
                manager: e.user,
                date: e.date,
                version: e.versions
            }
        });
    }
    getMaterialsTree = async ( isTreeSheet, firm = localStorage.getItem('ifurn_service_main_user_firm')) => {
        if (!firm) {
            return false;
        }
        const res = await this._send(
            `/materialsn`,
            'POST',
            {
                firm: firm,
                tree_sheet: isTreeSheet
            },
            {
                'Point-Action': 'tree'
            },
            'json'
        );
        if (res && res.data) {
            return this._transformMaterialsTree(res.data);
        } else {
            return false;
        }
    }
    _transformMaterialsTree = (data) => {
        return data.map(e => {
            return e;
        });
    }
    getMaterials = async ( category, filters, search, page = 1, userId) => {

        const firm = localStorage.getItem('ifurn_service_main_user_firm');
        const user = localStorage.getItem('ifurn_service_main_user_token');

        if (!firm) {
            return false;
        }
        const res = await this._send(
            `/materialsn`,
            'POST',
            {
                firm: firm,
                user: user,
                page: page,
                type_material: category,
                search: search,
                client: userId,
                ...filters
            },
            {
                'Point-Action': 'filter',
                // 'Language': 'ru'
            },
            'json'
        );
        if (res && res.data) {
            return {
                count: res.data.count,
                data: this._transformMaterials(res.data.data)
            };
        } else {
            return false;
        }
    }
    _transformMaterials = (data) => {
        return data.map(e => {
            if(e.type === 'part') e.part = 1;
            if(e.type === 'product') e.tovar = 1;
            if(e.type === 'band') e.band = 1;
            if(Number(e.part === 1)) {
                if(!e.hasOwnProperty('additional') || Array.isArray(e?.additional)) {
                    e.additional = {
                        // triml: '',
                        // trimw: '',
                        // direction: '',
                        trimL: '',
                        trimW: '',
                        rez_napr: '',
                        active: 1,
                        turn: '',
                        hard_cut: ''
                    };
                }else {
                    e.additional={
                        ...e.additional,
                        active: e.additional?.hasOwnProperty('active') ? e.additional.active : 1
                    }
                }
            };
            if(Number(e.band === 1)) {
                if(!e.hasOwnProperty('additional') ||  Array.isArray(e?.additional)) {
                    e.additional = {
                        per: '',
                        rest: '',
                        min: ''
                    };
                }
            }
            return e;
        });
    }
    // getProductsCategory = async (category, page = 1) => {
    //     const res = await this._getData(
    //         `/products`,
    //         'POST',
    //         {
    //             "category": category,
    //             "page": page,
    //         },
    //         {'Point-Action': 'category'},
    //         'json'
    //     );
    //     if (res && res.data) {
    //         return {
    //             data: this._transformProducts(res.data.data),
    //             count: res.data.count
    //         };
    //     } else {
    //         return false;
    //     }
    // }


}

export default ApiNewService;