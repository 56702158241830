import React, {useState} from "react";
import InputForm from "../../InputFields/InputForm/InputForm";
import InputMask from "react-input-mask";
import {maskPhoneCountry} from "../../../constants";
import {useDispatch} from "react-redux";
import Upload from "../../../Icons/Dotts/UploadProject";
import SelectPhone from "../../SelectPhone";

export const OfflineImportForm = ({
                                      getT,
                                      onSubmit,
                                      productionCodePhone,
                                      prodRusHide,
                                      phoneCodeInitial
                                  }) => {
    const [phoneCode, setPhoneCode] = useState(phoneCodeInitial);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [count, setCount] = useState('');


    const [files, getFiles] = useState([]);
    const dispatch = useDispatch();
    const renderFilesName = () => {
        return getT('Загружено') + ' ' + files.length + ' ' + getT('файлов');
    }

    return <form className={"offline-form"} encType={'multipart/form-data'}
                 method={'POST'}
                 onSubmit={(event) => {
                     event.preventDefault();
                     onSubmit({email, phone: phoneCode + phone, username, files, count})
                 }}>

        <InputForm label={getT('Введите имя')} name={"username"}
                   type={'text'}
                   value={username} required={'required'}
                   onChange={(e) => setUsername(e.target.value)}
        />

        <div className="form-field">
            <label className="label">
                {getT('Введите номер телефона')}*
            </label>
            <div className="field-phone">
                <div className="form-group">
                    {Number(productionCodePhone) !== 9999 && productionCodePhone !== '' ?
                        <input className={'form-input phone-code-disabled'}
                            // value={`${regions[form.region].code}`}
                               value={phoneCode}
                               disabled={true}/>
                        : <SelectPhone value={phoneCode} setValue={(value)=> setPhoneCode(value)} rusHide={!prodRusHide}/>
                    }
                </div>

                <InputMask
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    mask={maskPhoneCountry[phoneCode]}
                    type="tel"
                    className="form-input "
                    required={'required'}
                />
            </div>
        </div>
        <InputForm label={getT('Введите email')} name={"email"} type={'email'}
                   value={email} required={'required'}
                   onChange={(e) => setEmail(e.target.value)}
        />
        <InputForm label={getT('Количество деталей')} name={"count"} type={'number'}
                   value={count}
                   onChange={(e) => setCount(e.target.value)}
        />

        <div className="input__wrapper">
            <input type="file"
                   name={'project'}
                   className="input input__file"
                   onChange={(event) => getFiles(event.target.files)}
                   id={'input__file'}
                   multiple
            />
            <label htmlFor={'input__file'} className="input__file-button">
                        <span className="input__file-icon-wrapper">
                            <Upload/>
                        </span>
                {files.length > 0 ?
                    <span className="input__file-button-text">{renderFilesName()}</span>
                    :
                    <span className="input__file-button-text">{getT('Выберите файл(ы)')}</span>
                }
            </label>
        </div>
        {files.length >= 1 &&
            <button className={"btn"} type={"submit"}>{getT("Отправить")}</button>
        }
    </form>
}