import React from "react";
import "./GiblabSendForm.css";
import ApiService from "../../api/api";

const apiService = new ApiService();

const GiblabSendForm = (props) => {
    let action = localStorage.getItem('ifurn_service_main_giblab_domen') || apiService._glLink;
    return (
        <form action={action} id={'giblab_send_form'} method={'POST'}>
            <input type="hidden" value={''} name={'project_data'} id={'giblab_input_project'}/>
            <input type="hidden" value={props.code} name={'order_code'}/>
            <input type="hidden" value={props.version} name={'order_version'}/>
            <input type="hidden" value={''} name={'session_id'} id={'session_id'}/>
            <input type="hidden" value={''} name={'session_data'} id={'session_data'}/>
            <input type="hidden" value={localStorage.getItem('ifurn_service_main_user_firm')} name={'firm'}/>
        </form>
    );
};

export default GiblabSendForm;

