import React from 'react';
import Modal from "../BasicModal";
import {useDispatch, useSelector} from "react-redux";
import {getCellsStorage, getRemainFormInitialValue, getRemainsModal} from "../../../selectors/cells-leftovers-selector";
import {onToggleRemainModal} from "../../../store/reducers/ModalReducer";
import {actionsCellsLeftovers, onSendRemainCU} from "../../../store/reducers/StorageCellsLeftovers";

const RemainsAddForm = ({getT}) => {
    const {formAdd} = useSelector(getRemainsModal);
    const dispatch = useDispatch();
    const valueForm = useSelector(getRemainFormInitialValue);
    const cells = useSelector(getCellsStorage);
    const onToggleModal = (dt) => {
        dispatch(onToggleRemainModal({key: 'formAdd', dt: {isOpen: false, goods: null, order_in: null}}));
        dispatch(actionsCellsLeftovers.onResetFormValue({form:'remainFrom'}));
    }
    const onChangeValues = ({key, dt}) => {
        dispatch(actionsCellsLeftovers.onChangeRemainForms({key, dt}))
    }

    return <>
        <Modal title={getT('Добавить остаток ') +' '+ formAdd.name} open={formAdd.isOpen} onClose={() => onToggleModal(false)}>
            <form action="" className={"inst__bore-form"} onSubmit={(e) => {
                e.preventDefault();
                dispatch(onSendRemainCU({...valueForm, goods: valueForm.goods ?? formAdd.goods}, (valueForm.goods_stock_id ?? null) , (formAdd.order_in ?? null)))

            }}>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>x</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.x}
                               required={'required'}
                               onChange={(e) => {
                                   // let value = Number(e.target.value) > 100 ? 100 : e.target.value
                                   onChangeValues({key: 'x', dt: e.target.value})
                               }}/>
                    </div>
                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>y</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.y}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'y', dt: e.target.value})
                               }}/>
                    </div>
                </div>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>{getT('Количество')}</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.units}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'units', dt: Number(e.target.value)})
                               }}/>
                    </div>
                    {Array.isArray(cells) &&
                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>{getT('Имя ячейки')}</label>
                        <select className={"material-select w-100"} name="" id="" value={valueForm.cell}
                                required={'required'}
                        onChange={(e) => onChangeValues({key: 'cell', dt: e.target.value})}
                        >
                            <option value={null}>{getT('Выбрать')}</option>
                            {cells.map(cell => <option selected={(Number(cell) === Number(cell.stock_cell_id))? 'selected': ''}  value={cell.stock_cell_id}>{cell.name}</option>)}
                        </select>
                    </div>
                    }
                </div>
                <button className={"btn"} type={"submit"}>{getT('Отправить')}</button>
            </form>

        </Modal>
    </>
}

export default RemainsAddForm;