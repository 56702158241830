import React from "react";

import "./Ordering.css";
import {useSelector} from "react-redux";
import OrderForm from "./OrderForm/OrderForm";
import OrderSuccess from "./OrderSuccess/OrderSuccess";


const Ordering = () => {
    const status = useSelector((state) => state.order.confirm.step)

    return (
        <div className="nightstands-wrapper modal-page confirm-order-container-main">
            <div className="nightstands-container">
                {status === 'success' ? <OrderSuccess /> : <OrderForm/>}
            </div>
        </div>
    );
};

export default Ordering;
