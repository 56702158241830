import React, {useState} from 'react';
import Bind from "./Bind";
import Parts from "./Parts";
import GoBackArrow from "../../../../Icons/Actions/GoBackArrow";
import {formatNumbersThousands} from "../../../../helpers/helpers";

const PackItem = ({pack, index, getT}) => {

    const [isShowBinds, setIsShowBinds] = useState(false);
    let {
        packWidth = 0,
        packHeight = 0,
        packDepth = 0,
        packLayers = 0,
        packParts = 0,
        packWeight = 0,
        innerPackWeight = 0,
        fullSumm = 0,
        bins = []
    } = pack;
    const sqBins = (Number(packHeight) * Number(packWidth) )/ 1000000
    return <>
        <tr className={'tr-bg-pack'}>
            <td>{index + 1}
                <span onClick={() => (setIsShowBinds(!isShowBinds))}>
                        <GoBackArrow cName={`icon-down ${isShowBinds ? 'show' : ' '}`}/>
                    </span>
            </td>
            <td>{packHeight}</td>
            <td>{packWidth}</td>
            <td>{packDepth}</td>
            <td>{packLayers}</td>
            <td>{packParts}</td>
            <td>{formatNumbersThousands(packWeight)}</td>
            <td>{formatNumbersThousands(innerPackWeight)}</td>
            <td>{formatNumbersThousands(fullSumm)}</td>
        </tr>
        {isShowBinds && Array.isArray(bins) && bins?.length && bins?.map((bind, bind_index) => {
            return <>
                <Bind bind={bind} getT={getT} index={bind_index} key={`pack_${index}_bind_${bind_index}`} sqBins={sqBins}
                      component={
                          <Parts getT={getT} bind={bind} key={`pack_${index}_bind_${bind_index}_parts`} bind_index={bind_index}/>
                      }/>

            </>
        })

        }

    </>

};

export default PackItem;