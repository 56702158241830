import React from 'react';

const IconCutting = () => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="487.688px" height="487.688px" viewBox="0 0 487.688 487.688"
         >
<g>
	<g id="iconos_52_">
		<path d="M289.83,18.121l-5.23,35.926c-1.864-0.985-3.806-1.97-5.718-2.878L216.409,0H196.06l14.707,37.265
			c-3.366,0.125-6.732,0.296-10.041,0.65l-68.965-13.053l-17.939,9.61l28.018,23.266c-3.701,2.209-7.296,4.552-10.777,7.067
			L64.336,87.832l-10.806,17.241l35.802,6.263c-2.783,4.906-5.355,9.936-7.564,15.176L36.95,180.301l-0.325,20.349l33.947-13.196
			c0.134,4.733,0.478,9.419,1.052,14.028L59.88,271.011l9.955,17.748l22.682-28.478c2.372,3.797,4.906,7.469,7.603,11.017
			l3.806,25.14h123.863h42.075h42.333l58.828-22.108l10.337-17.528l-35.907-5.269c1.817-3.414,3.5-6.904,5.05-10.48l45.345-53.541
			l0.488-20.339l-34.023,12.909c-0.077-3.796-0.211-7.583-0.574-11.303l13.703-68.812l-9.429-18.025l-23.495,27.789
			c-3.089-5.297-6.502-10.356-10.213-15.194l-24.929-66.087L289.83,18.121z M216.409,203.519c-11.235,0-20.349-9.113-20.349-20.349
			c0-11.236,9.113-20.349,20.349-20.349c11.236,0,20.35,9.113,20.35,20.349C236.758,194.406,227.645,203.519,216.409,203.519z"/>
        <polygon points="277.705,315.562 205.039,315.562 154.721,315.562 144.709,315.562 106.823,315.562 4.581,315.562 4.581,487.688
			425.331,487.688 425.331,315.562 288.883,315.562 		"/>
        <polygon points="400.229,237.867 459.995,190.686 433.258,178.102 		"/>
        <polygon points="471.327,244.991 407.066,268.085 483.107,272.092 		"/>
	</g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
</svg>

    );
};

export default IconCutting;