import React, {useState} from 'react';
import IconArrow from "../../../Icons/IconArrow";

const CombineInfo = ({getT}) => {
    const [isOpen, setOpen] = useState(false)
    if (!getT) return false;
    return (
        <div className={'combine-material_info'}>
            <div className={"combine-material_info-head" + `${isOpen ? '' : ' close '}`}>
                <h5>{getT('Составной материал может состоять из любого количества слоёв. Слоем является деталь без кромкования, возможно сшивка. Детали можно соединять в составной материал если')}
                    :</h5>
                <button><IconArrow top={false} onCLickHandler={() => setOpen(!isOpen)}/></button>
            </div>
            {isOpen ? <ul>
                <li> {getT('детали без кромкования')}</li>
                <li>{getT('детали одного размера')}</li>
                <li>{getT('количество деталей одинаково')}</li>
                <li>{getT('детали относятся к одной группе или без группы.')}</li>
            </ul> : null}

        </div>
    );
};

export default CombineInfo;