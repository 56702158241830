export const isOpenInstBore = (state)=>{
    return state.modal.instrumentsModal.bore;
}
export const isOpenInstPaz = (state)=>{
    return state.modal.instrumentsModal.paz;
}
export const isOpenInstMill = (state)=>{
    return state.modal.instrumentsModal.mill;
}
export  const BoreForms = state=>{
    return state.settingFirm.instrumentsForms.bore
}
export  const PazForms = state=>{
    return state.settingFirm.instrumentsForms.paz
}
export  const MillForms = state=>{
    return state.settingFirm.instrumentsForms.mill
}
export const getTools = (state) =>{
    return state.settingFirm.tools;
}