export const RefundTerms = ({getT})=>{
    return <div className="info-page-inner-content">
        <h1>{getT('Условия возврата денег')}:</h1>
        <ul>
            <li>1. {getT('Возврат денег предоставляется в течение 14 дней')}</li>
            <li>2. {getT('Возврат денег осуществляется только за впервые заказанные услуги. Например: если вы заказали услугу подписки, после чего получили возврат денег и заказали у нас подписку второй раз, то вы не сможете получить обратно деньги за второй заказ.')}</li>
            <li>3. {getT('Возврат средств производится за вычетом комиссии платежных систем.')}</li>
            <li>4. {getT('При платежах картой VISA и MasterCard деньги возвращаются на ту же карту, с которой был произведен платеж. После того, как мы вернем платеж деньги появляются на карте в течении 3-х банковских дней. Обратите внимание на то, что этот платеж не появляется в выписках по карте, а просто увеличивается сумма на карте. Такой тип возврата доступен только в течении 30 дней после оплаты.')}</li>
            <li>5. {getT('Деньги возвращаются по тем же реквизитам, с которых была произведена оплата услуг.')}</li>
            <li>6. {getT('Возврат оплаты производится в течение 10 дней с момента подачи заявки.\n')}</li>
            <li>7. {getT('При возврате вычитаются все бонусы и скидки.')}</li>
        </ul>
    </div>
}