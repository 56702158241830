import {useDispatch} from "react-redux";
import Upload from "../../../Icons/Dotts/UploadProject";
import React, {useState} from "react";
import {addedPhotosFurniture} from "../../../store/reducers/FurnitureReducer";


const AddPhotos = ({getT, article_id}) => {
    const [files, getFiles] = useState([]);
    const dispatch = useDispatch();
    const renderFilesName = () => {
        return getT('Загружено') + ' ' + files.length + ' ' + getT('файлов');
    }


    const sendFiles = () => {
        dispatch(addedPhotosFurniture(files, article_id))
    }

    return <form className="add-material upload_file_project_form"
                 encType={'multipart/form-data'}
                 method={'POST'}
        // action={api._baseUrl + '/files/'}
                 onSubmit={(event) => {
                     event.preventDefault();
                     sendFiles();
                 }}
    >
        <div className="input__wrapper">
            <input type="file"
                   name={'project'}
                   className="input input__file"
                   onChange={(event) => getFiles(event.target.files)}
                   id={'input__file'}
                   multiple
            />
            <label htmlFor={'input__file'} className="input__file-button">
                        <span className="input__file-icon-wrapper">
                            <Upload/>
                        </span>
                {files.length > 0 ?
                    <span className="input__file-button-text">{renderFilesName()}</span>
                    :
                    <span className="input__file-button-text">{getT('Выберите файл(ы)')}</span>
                }
            </label>
        </div>
        {/*<p style={{textAlign: 'center'}}>*/}
        {/*    {getT('Для загрузки подходят файлы проектов .ifp или архивы, содержащие такие файлы. Можно загружать одновременно один или несколько проектов. Все загруженные проекты будут сведены в один')}*/}
        {/*</p>*/}
        <input className="create-project" type="submit" value={getT('Загрузить')}
               disabled={files.length == 0 ? 'disabled' : ''}/>
    </form>
}
export default AddPhotos;