import React from 'react';
const CopyChup = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             version="1.1" id="Слой_1"
             x="0px" y="0px"
             viewBox="0 0 100 100"
             >
            <g>
	<path className="st1" style={
    {fill:"none",stroke:"#000000",strokeWidth:"3",strokeMiterlimit:"10"}
    }
          d="M61.93,98.41H18.97c-4.43,0-8.01-3.59-8.01-8.01V22.55c0-4.43,3.59-8.01,8.01-8.01h42.96   c4.43,0,8.01,3.59,8.01,8.01V90.4C69.95,94.82,66.36,98.41,61.93,98.41z"/>
                <path className="st1"
                      d="M69.95,85.46h11.07c4.42,0,8.02-3.6,8.02-8.02V9.61c0-4.45-3.6-8.02-8.02-8.02H38.06   c-4.42,0-8.02,3.57-8.02,8.02v4.94"/>
</g>
            <g>
	<line className="st2"
          style={
              {fill:"none",stroke:"#000000",strokeWidth:"3",strokeLinecap:"round", strokeMiterlimit: "10"}
          }
          x1="23.28" y1="33.87" x2="57.63" y2="33.87"/>
                <line className="st2" x1="17.84" y1="79.08" x2="63.07" y2="79.08"/>
                <rect x="34.02" y="33.87" className="st2" width="12.87" height="5.53"/>
                <rect x="35.52" y="39.4" className="st2" width="9.86" height="7.99"/>
                <polygon className="st2"
                         points="47.79,47.39 47.79,52.56 45.01,55.89 35.9,55.89 33.11,52.56 33.11,47.39  "/>
                <g>
		<polygon className="st2"   style={
            {fill:"none",stroke:"#000000",strokeWidth:"3",strokeLinecap:"round", strokeMiterlimit: "10"}
        }
                 points="42.94,55.89 42.94,67.91 40.45,69.96 37.96,67.91 37.96,55.89   "/>
                    <line className="st0" x1="42.95" y1="62.81" x2="37.96" y2="66.68"/>
                    <line className="st0" x1="42.95" y1="58.93" x2="37.96" y2="62.81"/>
	</g>
                <path className="st2"  style={
                    {fill:"none",stroke:"#000000",strokeWidth:"3",strokeLinecap:"round", strokeMiterlimit: "10"}
                }
                      d="M63.07,68.18H49.68v0c0,2.35-1.9,4.25-4.25,4.25h-10.2c-2.35,0-4.25-1.9-4.25-4.25v0H17.84"/>
                <line className="st0" x1="33.11" y1="51.64" x2="44.7" y2="51.64"/>
</g>
</svg>
    )
}
export default CopyChup;