import React from 'react'
import {useDispatch} from 'react-redux';
import {onOpenInstructionModal} from "../../../../store/reducers/ModalReducer";
import Delete from "../../../../Icons/Actions/Delete";
import Edit from "../../../../Icons/TableActions/Edit";
import {checkExclusivePaz} from "../../help";
import {
    deleteToolsSettingsFirm,
    onSendTools,
    setEditPazForms
} from "../../../../store/reducers/SettingsReducer";
import {actions} from '../../../../store/reducers/SettingsReducer'
import PazForm from "./PazForm";

const Paz = ({getT, pazTools, isAccessAction}) => {
    const dispatch = useDispatch();

    const onToggleInstModal = (data) => {
        dispatch(onOpenInstructionModal(data));
        dispatch(actions.onResetFormValue('paz'))
    }

    const onBeforeSubmitCU = (valuesForm) => {
        let id = checkExclusivePaz(pazTools, {
                w: valuesForm.w,
                cut_back: valuesForm.cut_back,
            }
        );
        dispatch(onSendTools({...valuesForm, cut: 1}, id, 'paz'));

    };
    const onUpdateToolsSubmit = (valuesForm, id) => {
        dispatch(onSendTools(valuesForm, id, 'paz'));
    }
    const onDeleteTools = (id) => {
        dispatch(deleteToolsSettingsFirm(id));
    }
    const onUpdateTools = (data) => {
        const initialValue = setEditPazForms(data);
        dispatch(actions.setInitialValuesForm({form: 'paz', initialValue: initialValue}));
        dispatch(onOpenInstructionModal({key: 'paz', dt: true}));
    }
    return <div className={'tools-wrap'}>
        <div className="section-header">
            <h2 className="title">{getT('Пазование')}</h2>
            {isAccessAction && <button className="btn"
                    onClick={() => onToggleInstModal({key: 'paz', dt: true})}>{getT('Создать')}</button>}

        </div>
        <div className="table">

            {Array.isArray(pazTools) && pazTools.length >= 1 ?
                <table className={'table-wrapper main-parts-table'}>
                    <thead>
                    <th>{getT('Вкл')}/{getT('Выкл')}</th>
                    <th>{getT('Ширина ')}</th>
                    <th>{getT('Максимальная глубина')}</th>
                    <th>{getT('Сторона пазования')}</th>
                    {isAccessAction && <th>{getT('Действие')}</th>}
                    </thead>
                    {pazTools.map((b, idx) => {
                        return <tr key={idx}>
                            <td>{Number(b.on) == 1 ? getT('Вкл') : getT('Выкл')}</td>
                            <td>{Number(b.w)}</td>
                            <td>{Number(b.z_max)}</td>
                            <td>{b.cut_back != null && (Number(b.cut_back) == 1) ? getT('Обратная') : getT('Лицевая')}</td>
                            {isAccessAction && <td>
                                <div className="settings-actions">
                                    <span className={'edit'} onClick={() => onUpdateTools(b)}><Edit/> </span>
                                    <span className="delete"
                                          onClick={() => onDeleteTools(b.user_xnc_tools_id)}><Delete/></span>
                                </div>

                            </td>
                            }
                        </tr>
                    })}
                </table>
                : ''}
        </div>
        <PazForm getT={getT}
        onToggleInstModal={onToggleInstModal}
        onBeforeSubmit={onBeforeSubmitCU}
        onUpdateToolsSubmit={onUpdateToolsSubmit}
        />
    </div>
}

export default Paz;