import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getProductionDetailsLightDefault, getSettingDefaultOrderDisplay} from "../selectors/production-selectors";
import {useCookies} from "react-cookie";
import {onChangeOrderProduction} from "../store/reducers/OrderReducer";


let setting_def_order_display_keys = {
    // Быстрый режим кромки
    band: 'production.is_light_details_default',
    // Материал
    material: 'production.paramtres_interface_group_by_material',
    band_column: 'production.paramtres_interface_edge_is_needed',
    //'Материал и изделие'
    material_column: 'production.paramtres_interface_material_and_product_in_detail',
    //Название
    names: 'production.paramtres_interface_name_detail',
    //Корректировка ЧПУ
    cnc_override: 'production.paramtres_xnc_is_needed',
    picture: 'production.paramtres_interface_draw',
    //Использовать ли значки обработок торцов в кратком режиме
    edge_edit_in_brief_mode: 'production.edge_edit_in_brief_mode_details_list',
    //
    material_product_in_small_view: 'production.material_product_in_small_view_details_list',


}

export default function () {
    const isLightDetails = useSelector(getProductionDetailsLightDefault);
    const setting_production_default_order = useSelector(getSettingDefaultOrderDisplay)
    const [cookies, setCookie, removeCookie] = useCookies(['settings']);
    const dispatch = useDispatch();
    const onChangeCookieSetting = ({key}) => {

        if (setting_production_default_order?.hasOwnProperty(key)) {
            let production_key = setting_def_order_display_keys[key];
            let value = Number(!settings[key])
            dispatch(onChangeOrderProduction({[`${production_key}`]: value}))
        }
        setCookie('settings', {
            ...settings, [key]: !settings[key]
        })
    };
    const getValues = useCallback((key) => {
        let has_key = setting_production_default_order?.hasOwnProperty(key);
        if (has_key) return !!setting_production_default_order[key];
        return cookies?.settings?.[key] ?? true
    }, [setting_production_default_order, cookies])

    const settings = useMemo(() => {
        return cookies.settings
    }, [cookies, setting_production_default_order]);

    const getItemCookie = (key = '') => {
        return cookies?.settings?.[`${key}`]
    };
    return [
        () => {
            setCookie('settings', {
                names: getValues('names'),
                material: getValues('material'),
                material_column: true,
                // material_column: getValues('material_column'),
                picture: getValues('picture'),
                band_column: getValues('band_column'),
                band: getValues('band'),
                cnc_override: getValues('cnc_override'),
                paz_template: cookies?.settings?.paz_template ?? true,
                edge_edit_in_brief_mode: getValues('edge_edit_in_brief_mode'),
                material_product_in_small_view: getValues('material_product_in_small_view'),
            });
        },
        settings,
        onChangeCookieSetting,
        getItemCookie,
    ]

};

