import * as React from "react";

function Setting(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 17.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
        stroke="#050038"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.633 17.5a1.924 1.924 0 00.385 2.124l.07.07a2.333 2.333 0 01-1.65 3.986 2.334 2.334 0 01-1.651-.685l-.07-.07a1.926 1.926 0 00-2.124-.385 1.925 1.925 0 00-1.166 1.762v.198a2.333 2.333 0 11-4.667 0v-.105a1.925 1.925 0 00-1.26-1.761 1.924 1.924 0 00-2.123.385l-.07.07a2.333 2.333 0 11-3.302-3.302l.07-.07a1.925 1.925 0 00.385-2.123 1.925 1.925 0 00-1.762-1.167H3.5a2.333 2.333 0 110-4.667h.105a1.925 1.925 0 001.762-1.26 1.925 1.925 0 00-.386-2.123l-.07-.07a2.333 2.333 0 012.545-3.808c.283.117.54.29.757.506l.07.07a1.925 1.925 0 002.123.385h.094A1.925 1.925 0 0011.666 3.7V3.5a2.333 2.333 0 114.667 0v.105A1.925 1.925 0 0017.5 5.367a1.925 1.925 0 002.123-.385l.07-.07a2.333 2.333 0 013.808.757 2.334 2.334 0 01-.506 2.545l-.07.07a1.926 1.926 0 00-.385 2.123v.093a1.925 1.925 0 001.762 1.167h.198a2.333 2.333 0 110 4.667h-.105a1.925 1.925 0 00-1.762 1.166v0z"
        stroke="#050038"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Setting;
