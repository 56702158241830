import React, {useEffect, useState} from 'react';
import GoBackArrow from "../../../../Icons/Actions/GoBackArrow";

const Parts = ({bind,bind_index, getT}) => {
    const [isShowParts, setIsShowParts] = useState(false);

    if (!bind.hasOwnProperty('parts') || !Array.isArray(bind?.parts)) return null;
    let {parts = []} = bind;

    return (
        <>
            <tr className={'tr-bg-parts'}>
                <th className={'tr-bg-parts'} colSpan={9} style={{textAlign: 'left'}}>{getT('Детали')} ({bind?.binParts})
                    <span onClick={() => (setIsShowParts(!isShowParts))}>
                        <GoBackArrow cName={`icon-down ${isShowParts ? 'show' : ' '}`}/>
                    </span>
                </th>
            </tr>
            {isShowParts && <>
                <tr>
                    <th rowSpan={2}>{getT('Детали')}</th>
                    <th colSpan={3}>{getT('Размер')}</th>
                    <th colSpan={1} rowSpan={2}>id</th>
                    <th colSpan={1} rowSpan={2}>{getT('Название')}</th>
                    <th rowSpan={2} colSpan={3}></th>
                </tr>
                <tr>
                    <th>{getT('Высота')}</th>
                    <th>{getT('Ширина')}</th>
                    <th>{getT('Глубина')}</th>
                </tr>
                {parts?.map((part, index) => {
                    let {id_ifurn, name = '', w = 0, h = 0, z = 0} = part;
                    return (
                        <tr key={`bind_${bind_index}_part_${id_ifurn}`}>
                            <td>{index + 1}</td>
                            <td>{h}</td>
                            <td>{w}</td>
                            <td>{z}</td>
                            <td>{id_ifurn}</td>
                            <td>{name}</td>
                        </tr>
                    )
                })}
            </>}
        </>
    );
};

export default Parts;