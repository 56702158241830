import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getNetworkLoginSelected} from "../../../../selectors/cabinet-sector";
import IconGoogle from "../../../../Icons/Social/IconGoogle";
import IconFb from "../../../../Icons/Social/IconFb";
import {
    accountConnectedFacebook,
    accountConnectedGoogle,
    deleteNetworkLoginSocial
} from "../../../../store/thunks/social-network-thunk";
import IconTrash from "../../../../Icons/IconTrash";
import {LoginSocialFacebook, LoginSocialGoogle} from "reactjs-social-login";
import {useLang} from "../../../../context/langProvider";
import Card from "../../../../components/Card";
import {changeAlertStatusAC} from "../../../../store/reducers/CommonReducer";

const CONNECTED_DEFAULT_ICONS = {
    google: IconGoogle,
    fb: IconFb
}

const SocialNetwork = () => {
    const {getT} = useLang()
    const dispatch = useDispatch();
    const network_login = useSelector(getNetworkLoginSelected);
    const isNotConnected = useMemo(() => {
        if (!network_login || (Array.isArray(network_login) && !network_login.length)) {
            return {
                google: true,
                fb: true
            }
        }
        let google = !network_login.find(item => item.name === 'google');
        let fb = !network_login.find(item => item.name === 'fb');
        return {
            google: google,
            fb: fb
        }
    }, [network_login]);
    const handlerDeleteConnected = (item) => {
        let api_data = {
            network: item.name,
            id: item.id
        }
        dispatch(deleteNetworkLoginSocial(api_data))
    }

    return (
        <div className="" key={'social-network'}>

            <Card label={getT('Профили соцсетей')} key={'social-network-card'}>
                <ul className={'list-none'}>
                    {!!(network_login && (Array.isArray(network_login) && network_login.length)) &&
                        <>
                            {
                                network_login.map(item => {
                                    let Icon = CONNECTED_DEFAULT_ICONS.hasOwnProperty(item.name) ? CONNECTED_DEFAULT_ICONS[item.name] : null;
                                    return <li className={'d-flex items-center justify-between'}>
                                    <span className="d-flex items-center">
                                    {Icon && <Icon width={'25px'} height={'25px'} style={{marginRight: '8px'}}/>}
                                        {item.name}
                                    </span>
                                        <span className="d-flex items-center">
                                        <span className="clr-green  fs-12 ">{getT("Подключено")}</span>
                                          <IconTrash onClick={() => {
                                              handlerDeleteConnected(item)
                                          }}/>
                                    </span>

                                    </li>

                                })
                            }
                        </>
                    }
                    {isNotConnected.google && <li> <LoginSocialGoogle key={'login-social-google'}
                                                                      client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                                      isOnlyGetToken={true}
                                                                      isOnlyGetCode={true}
                                                                      auto_select={false}
                                                                      scope={'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'}
                                                                      cookie_policy={false}
                                                                      onResolve={async ({provider, data}) => {
                                                                          dispatch(accountConnectedGoogle(data.access_token))
                                                                      }}
                                                                      onReject={(error) => {
                                                                          console.log(error)
                                                                          dispatch(changeAlertStatusAC(true, error?.message || `google error`));
                                                                      }}>
                        <div className="d-flex items-center d-flex cursor-pointer clr-blue text-underline fs-14">
                            <CONNECTED_DEFAULT_ICONS.google width={'25px'} height={'25px'}
                                                            style={{marginRight: '8px'}}/> {getT("Подключить")}
                        </div>
                    </LoginSocialGoogle> </li>}
                    {
                        isNotConnected.fb && <li> <LoginSocialFacebook
                            fieldsProfile={"id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"}
                            fields="first_name, last_name, email, picture, birthday"
                            scope="public_profile,email,user_birthday"
                            returnScopes={true}
                            appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                            onResolve={(response) => {
                                dispatch(accountConnectedFacebook({id: response.data.id, network: 'fb'}))
                            }}
                            onReject={(error) => {
                                console.log(error);
                                dispatch(changeAlertStatusAC(true, error?.message || `fb error`));
                            }}
                        >
                            <div className="d-flex items-center d-flex cursor-pointer clr-blue text-underline fs-14">
                                <CONNECTED_DEFAULT_ICONS.fb width={'25px'} height={'25px'}
                                                            style={{marginRight: '8px'}}/> {getT("Подключить")}
                            </div>
                        </LoginSocialFacebook></li>
                    }
                </ul>

            </Card>
        </div>
    );
};

export default SocialNetwork;