import getT from "../getT";

export default function({ order }) {
  const { qr, link_order } = order.cutting_card;
  const { id, title, client_name, date } = order;
  return `
        <div class="form-qr-container">
            <img src="${qr}" alt="" width="70px" height="70px">

            <div class="qr-info">

                <div style="">
                    <strong>${getT("Заказ")} № </strong> ${id} "${title}" <br/>
                    <strong>${getT("Дата заказа")}  </strong>${date} <br/>
                    <strong>${getT("Клиент")}  </strong>${client_name}
                </div>
                <div class="">
                    <strong>${getT("Деталировка")}  </strong>
                    <a href="${link_order}" target={"_blank"}>${link_order}</a>
                </div>
            </div>
        </div>
        <style>
.form-qr-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 25px auto;
}

.form-qr-container img {
    margin-right: 10px;
}

.form-qr-container div {
    line-height: 20px;
}

.form-qr-container strong {
    color: #605d81;
}

</style>
  `
}