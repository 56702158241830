import apiGiblab from "../../api/apiGiblab";
import ApiService from "../../api/api";
import { changeAlertStatusAC, changeLoaderStatusAC, checkErrorsAPI, typeErrorApi } from "./CommonReducer";

import { CuttingHtml } from "../../printHtml/cuttingHtml";
import { cuttingWindowHtml, labelsWindowHtml } from "../../printHtml";
import Labels from "../../printHtml/Labels";
import { isObject } from "../../helpers/helpers";


export const TYPES_BASE64_LABELS = {
  png: "data:image/png;base64,", svg: "data:image/svg+xml;base64,"
};
const api = new ApiService();
const apiGb = new apiGiblab();
const initial = {
  isOpenPage: false, cuttingPreviewModal: {
    isOpen: false, src: [], type: TYPES_BASE64_LABELS.svg
  }, cuttingPrintModal: {
    isOpen: false
  }, cuttingDataForHtml: {
    cutting_card: {}, general: 1, xnc: 1
  }

};
const actionType = {
  toggleCuttingPage: "CUTTING/TOGGLE_PAGE",
  cuttingPreviewCU: "CUTTING/CU_PREVIEW_MODAL",
  cuttingPrintCU: "CUTTING/CU_PRINT_MODAL",
  updateCuttingDataForHtml: "CUTTING/UPDATE_DATA_FOR_HTML"
};

export const actionsCuttingChart = {
  onToggleCuttingPage: (value) => {
    return {
      type: actionType.toggleCuttingPage, payload: value
    };
  }, cuttingPreviewModalCU: ({ isOpen, src, type }) => {
    return {
      type: actionType.cuttingPreviewCU, payload: { isOpen, src, type }
    };
  }, cuttingPrintModalCU: ({ isOpen }) => {
    return {
      type: actionType.cuttingPrintCU, payload: { isOpen }
    };
  }, cuttingDataForHtml: (payload) => {
    return {
      type: actionType.updateCuttingDataForHtml, payload
    };
  }
};
const CuttingChartReducer = (state = initial, action) => {
  switch (action.type) {
    case actionType.toggleCuttingPage: {
      return {
        ...state, isOpenPage: action.payload
      };
    }
    case actionType.cuttingPreviewCU: {
      return {
        ...state, cuttingPreviewModal: { ...action.payload }
      };
    }
    case actionType.cuttingPrintCU: {
      return {
        ...state, cuttingPrintModal: { ...action.payload }
      };
    }
    case actionType.updateCuttingDataForHtml: {
      return {
        ...state, cuttingDataForHtml: action.payload
      };
    }
    default: {
      return state;
    }
  }

};
export const getCuttingPartLabel = ({ partId, isOpen }) => async (dispatch, getState) => {
  const order = getState().order.order;
  dispatch(changeLoaderStatusAC(true));
  api._fetchIfurnLables(order.id, partId)
    .then(imageObjectURL => {
      if (imageObjectURL) {

        dispatch(actionsCuttingChart.cuttingPreviewModalCU({
          isOpen, src: JSON.parse(imageObjectURL), type: TYPES_BASE64_LABELS.svg
        }));
      } else if (api._errors) {
        console.log(api._errors);
      }
      dispatch(changeLoaderStatusAC(false));
    })
    .catch(e => {
      console.log(e);
      dispatch(changeLoaderStatusAC(false));
    });
};

export const LABELS_CUTTING_PRINT = {
  giblab: "giblab",
  ifurn: "ifurn"
};
export const getCuttingLabelsForPrint = ({
                                           // action, grid=''
                                         }) => (dispatch, getState) => {

  let order = getState().order.order;
  dispatch(changeLoaderStatusAC(true));
  const {action, grid} = getState().modal.labelsPrint;
  try {
    switch (action) {
      case LABELS_CUTTING_PRINT.ifurn: {
        api._fetchIfurnLables(order.id, "all")
          .then(imageObjectURL => {
            if((Array.isArray(imageObjectURL) && !imageObjectURL?.length) || (isObject(imageObjectURL) && !Object.keys(imageObjectURL)?.length)){
              dispatch(changeAlertStatusAC(true,`Этикеток нет в проекте`, true));
              dispatch(changeLoaderStatusAC(false));
            }

            if (imageObjectURL) {
              let content = Labels({ src: JSON.parse(imageObjectURL), type: TYPES_BASE64_LABELS.svg , grid: grid});
              labelsWindowHtml.openWindowPrint({ content, order });
            } else if (api._errors) {
              dispatch(checkErrorsAPI("", `${apiGb._errors}`));
            }
            dispatch(changeLoaderStatusAC(false));

          }).catch(e => {
          return e;
        });
        break;
      }
      case LABELS_CUTTING_PRINT.giblab: {
        order={
          ...order,
          all: 1
        }
        api._fetchGiblabLabels(order)
          .then(imageObjectURL => {
            if((Array.isArray(imageObjectURL) && !imageObjectURL?.length) || (isObject(imageObjectURL) && !Object.keys(imageObjectURL)?.length)){
              dispatch(changeAlertStatusAC(true,`Этикеток нет в проекте`, true));
              dispatch(changeLoaderStatusAC(false));
              return;
            }
            if (imageObjectURL) {
              let content = Labels({ src: imageObjectURL, type: TYPES_BASE64_LABELS.png, grid: grid });
              labelsWindowHtml.openWindowPrint({ content, order });
            } else if (api._errors) {
              dispatch(checkErrorsAPI("", `${api._errors}`));

            }
            dispatch(changeLoaderStatusAC(false));

          })
          .catch(e => {
            return e;
          });
        break;
      }
      default: {
        console.info("action not found", action);
        dispatch(changeLoaderStatusAC(false));

      }
    }

  } catch (e) {
    dispatch(checkErrorsAPI("", `Generate html for labels, [message: ${e.message}]`));
    dispatch(changeLoaderStatusAC(false));
  }

};
export const getGiblabLabelCurrentPart = ({ partId, isOpen }) => async (dispatch, getState) => {
  const order = getState().order.order;
  const actualOrder = {
    ...order, part: order.part.filter(part => Number(partId) === Number(part.id))
  };
  dispatch(changeLoaderStatusAC(true));
  api._fetchGiblabLabels(actualOrder)
    .then(imageObjectURL => {
      if (imageObjectURL) {
        dispatch(actionsCuttingChart.cuttingPreviewModalCU({
          isOpen, src: imageObjectURL, type: TYPES_BASE64_LABELS.png
        }));
      } else if (api._errors) {
        console.log(api._errors);
      }
      dispatch(changeLoaderStatusAC(false));
    })
    .catch(e => {
      console.log(e);
      dispatch(changeLoaderStatusAC(false));
    });
};



export const onSaveAndOpenCuttingEdit = () => async (dispatch, getState) => {
  const order = getState().order.order;
  const version = getState().commons.data.version;
  dispatch(changeLoaderStatusAC(true));

  api.saveProject(version, order)
    .then(res => {
      if (res?.is_save) {
        const url = `${api._hostName}/api/cutting/?order=${order.code}`;
        window.location = url;
      }
      if (api._errors) {
        dispatch(changeAlertStatusAC(true, api._getErrors()));
        dispatch(changeLoaderStatusAC(false));
      } else {
        dispatch(checkErrorsAPI(typeErrorApi.save, "api:  /projects/${version}, M:PUT", `Message = ${res?.res}, api_errors=${api._getErrors()}`))
        dispatch(changeLoaderStatusAC(false));
      }
    }).catch(e => {
    dispatch(checkErrorsAPI(typeErrorApi.save, "api:  /projects/${version}, M:PUT", `Message=${e?.message}`))
  });

};

export const updateDataCuttingForHtml = (payload) => async (dispatch, getState) => {
  const order = getState().order.order;
  const cuttingCard = getState().order.order.cutting_card;
  const projectDetail = getState().order;
  const cuttingHtml = new CuttingHtml();

  dispatch(changeLoaderStatusAC(true));
  try {
    const cards = cuttingCard?.cards.map(card => {
      const findCard = payload.cards.find(dto => Number(dto.card_id) === Number(card.card_id));
      return {
        ...card, visible_xnc: !!(findCard?.parts_info ?? false), visible_card: !!(findCard?.card ?? false)
      };
    });
    let cutting_card = {
      ...cuttingCard, cards: cards
    };

    dispatch(actionsCuttingChart.cuttingDataForHtml({
      cutting_card, general: payload.general, xnc: payload.xnc
    }));

    const content = cuttingHtml.generateContentHtml({
      order, setting: {
        cutting_card, general: payload.general, xnc: payload.xnc, mode: payload.mode, modeDetailing: payload.modeDetailing
      }, projectDetail
    });

    cuttingWindowHtml.openWindowPrint({ content, order });
    dispatch(changeLoaderStatusAC(false));

  } catch (e) {
    console.log(e);
    dispatch(checkErrorsAPI("", `Cutting card -> create html [message error ${e.message}]`))
  }
  dispatch(changeLoaderStatusAC(false));
};
export default CuttingChartReducer;