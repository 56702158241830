import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../../components/Modals/BasicModal";
import {getCellsFormInitialValue, getCellsModal} from "../../../selectors/cells-leftovers-selector";
import {actionsCellsLeftovers, onCellsStorageCU} from "../../../store/reducers/StorageCellsLeftovers";

const CellForm = ({getT, onToggleModal}) => {

    const dispath = useDispatch();

    const {isOpen} = useSelector(getCellsModal);

    const valueForm = useSelector(getCellsFormInitialValue);
    const onChangeValues = ({key, dt})=>{
        dispath(actionsCellsLeftovers.onChangeCellsForms({key, dt}))
    }

    const onSubmitCell = (e)=>{
        e.preventDefault();
        dispath(onCellsStorageCU(valueForm));
    }
    return <div className={"settings-section"}>
        <Modal open={isOpen} title={getT('Создание/обновление')} onClose={()=> onToggleModal(false)}>
            <form action="" className={"inst__bore-form"} onSubmit={onSubmitCell}>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>x</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.x}
                               required={'required'}
                               onChange={(e) => {
                                   // let value = Number(e.target.value) > 100 ? 100 : e.target.value
                                   onChangeValues({key: 'x', dt: e.target.value})
                               }}/>
                    </div>
                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>y</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.y}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'y', dt: e.target.value})
                               }}/>
                    </div>
                </div> <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>z</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.z}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'z', dt: e.target.value})
                               }}/>
                    </div>
                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>{getT('Имя ячейки')}</label>
                        <input className={"input-val"} type="text"
                               value={valueForm.name}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'name', dt: e.target.value})
                               }}/>
                    </div>
                </div>
                <button className={"btn"} type={"submit"}>{getT('Отправить')}</button>
            </form>

        </Modal>
    </div>
}

export default CellForm;