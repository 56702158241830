function Bottom(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={2} fill="#F3F4F8" />
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M19.2 19.2l-6.493-6.493a1 1 0 00-1.414 0L4.8 19.2h14.4z"
          fill="#4262FF"
        />
      </g>
      <path
        d="M5.8 5.8h12.4v-2H5.8v2zm12.4 0v12.4h2V5.8h-2zm0 12.4H5.8v2h12.4v-2zm-12.4 0V5.8h-2v12.4h2zm0 0h-2a2 2 0 002 2v-2zm12.4 0v2a2 2 0 002-2h-2zm0-12.4h2a2 2 0 00-2-2v2zm-12.4-2a2 2 0 00-2 2h2v-2z"
        fill="#4262FF"
      />
      <defs>
        <clipPath id="prefix__clip0">
          <path
            d="M4.8 5.8a1 1 0 011-1h12.4a1 1 0 011 1v12.4a1 1 0 01-1 1H5.8a1 1 0 01-1-1V5.8z"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Bottom;
