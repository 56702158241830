import {useEffect, useState} from "react";
import ApiService from "../api/api";
import {LS_FIRM} from "../constants";

const API = new ApiService();

/**
 * useValidLUrl. Test the resource for connectivity
 *
 * @param {string} url  - resource under test
 * @param {string} ls_naming  - key for written result LocalStorage
 * @param {boolean} isSendMail  - send  message on email about mistake connection by resource
 * @param {string} message_text  - text mistake
 * @returns  {object} {isConnected, loading, error}  -
 */
export function useValidLUrl({url, ls_naming = null, isSendMail = false, message_text = null}) {
    const [loading, setLoading] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                if (ls_naming) localStorage.setItem(ls_naming, '1')
                setIsConnected(response.ok);
                setError(null)
            } catch (error) {
                if (ls_naming) localStorage.setItem(ls_naming, '0')

                setError(error);
                if (isSendMail) {
                    API._sendLetter({text: `${message_text}. Firm: ${localStorage.getItem(LS_FIRM)}. Url: ${url}`})
                        .then((res) => {
                        })
                        .catch(e => {
                            console.log("catch e", e);
                        });
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    return {isConnected, loading, error};
}