import React, {useState} from 'react';
import Globe from "../../images/icons/Globe";
import {languagesList} from "../../constants";
import {useDetectClickOutside} from "react-detect-click-outside";

const LanguageSelect = ({onChange, lang}) => {
    const [isSelect, setIsSelect] = useState(false);
    const refSelect = useDetectClickOutside({onTriggered: () => setIsSelect(false)});

    return (
        <div className="select-custom" ref={refSelect}>
            <div className="select__head"
                 onClick={() => setIsSelect(!isSelect)}>
                <Globe/>
                {lang}
            </div>
            {isSelect && <ul className="select__body">
                {languagesList.map((code, index) => {
                    return <li
                        onClick={(e) => onChange(code)}
                        key={index} value={code}
                        className={lang === code ? 'disabled' : ''}
                    >
                        {code}
                    </li>
                })}
            </ul>}
        </div>
    );
};

export default LanguageSelect;