import * as React from "react";

function Add(props) {
  const {className = '', animate= false,  ...reset}= props;
  const cName = `prefix__feather prefix__feather-plus ${className} ${animate ? "animated-add-part" : ""}`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cName}
      style={animate ? {
        strokeWidth: "3",
        animation: "blink_path 1s infinite"
      }: {}}
      {...reset}
    >
      <path d="M12 5v14M5 12h14"   />
    </svg>
  );
}

export default Add;
