import './icons-style.css';

function LogoNew(props) {
    return (
        <svg
            width={113}
            className={props.CName ?? 'main-logo'}
            height={''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            viewBox="0 0 1920 1080"
            style={{enableBackground: "new 0 0 1920 1080"}}>

            <g id="Слой_2">
                <path className="logo-st0"
                      d="M478.1,468.46c0-12.45,9.09-20.31,21.74-20.31c12.76,0,21.85,7.86,21.85,20.31c0,12.35-9.09,20.21-21.85,20.21   C487.19,488.68,478.1,480.82,478.1,468.46z"/>
                <rect x="481.57" y="496.84" className="logo-st1" width="36.65" height="110.76"/>
                <path className="logo-st0"
                      d="M544.66,607.6V464.07h88.71v35.01h-49.61v19.6h47.06v33.58h-47.06v55.33H544.66z"/>
                <path className="logo-st0"
                      d="M650.12,562.38v-65.54h36.65v66.76c0,7.55,5.51,14.29,14.19,14.29c9.8,0,17.05-9.19,17.05-19.19v-61.86h36.65   V607.6h-33.48v-7.45h-1.43c-5.82,6.12-16.64,10.72-27.56,10.72C665.53,610.87,650.12,591.78,650.12,562.38z"/>
                <path className="logo-st0"
                      d="M780.89,607.6V496.84h33.58v8.06h1.33c5.82-6.12,16.64-10.72,27.66-10.72c3.06,0,6.02,0.2,8.78,0.71v35.42   h-14.7c-11.54,0-19.91,9.39-19.91,21.54v55.74H780.89z"/>
                <path className="logo-st0"
                      d="M869.6,607.6V496.84h33.48v7.45h1.43c5.82-6.12,16.64-10.72,27.56-10.72c26.64,0,42.06,19.09,42.06,48.49   v65.54h-36.65v-66.76c0-7.55-5.51-14.29-14.19-14.29c-9.8,0-17.05,9.19-17.05,19.19v61.86H869.6z"/>
                <path className="logo-st0"
                      d="M997.41,589.63c0-12.35,8.98-21.23,21.64-21.23c12.76,0,21.74,8.88,21.74,21.23   c0,12.45-8.98,21.23-21.74,21.23C1006.4,610.87,997.41,602.09,997.41,589.63z"/>
                <path className="logo-st0"
                      d="M1065.2,651.5V496.84h33.58v7.45h1.33c6.64-6.84,17.46-10.72,28.28-10.72c31.24,0,55.63,24.3,55.63,58.6   c0,34.3-24.4,58.7-55.63,58.7c-10.82,0-19.8-3.47-26.44-9.39v50.02H1065.2z M1147.27,552.17c0-15.93-10.11-24.91-23.27-24.91   c-13.17,0-23.38,8.98-23.38,24.91c0,15.92,10.21,24.91,23.38,24.91C1137.16,577.08,1147.27,568.1,1147.27,552.17z"/>
                <path className="logo-st0"
                      d="M1203.32,607.6V496.84h33.58v8.06h1.33c5.82-6.12,16.64-10.72,27.66-10.72c3.06,0,6.02,0.2,8.78,0.71v35.42   h-14.7c-11.54,0-19.91,9.39-19.91,21.54v55.74H1203.32z"/>
                <path className="logo-st2"
                      d="M1285.09,552.27c0-34.71,25.42-58.7,60.13-58.7c34.61,0,60.13,23.99,60.13,58.7   c0,34.61-25.52,58.59-60.13,58.59C1310.51,610.87,1285.09,586.88,1285.09,552.27z M1368.69,552.27c0-14.29-10.31-24.4-23.48-24.4   c-13.17,0-23.48,10.11-23.48,24.4c0,14.19,10.31,24.19,23.48,24.19C1358.38,576.47,1368.69,566.46,1368.69,552.27z"/>
            </g>

        </svg>
    )

}

export default LogoNew;
// return (
//     <svg
//         width={113}
//         height={61}
//         viewBox="0 0 273 162.67"
//         xmlns="http://www.w3.org/2000/svg"
//         {...props}
//     >
//         <g>
//             <path d="M30.89,90.73V67.36c0-0.85,0.19-1.81,1-2.57c0.92-0.88,1.96-0.96,2.88-0.96h17.52v4.03H37.23c-0.35,0-0.65-0.04-0.96,0.31
// 		c-0.31,0.31-0.31,0.73-0.31,1.08v5.84h11.91v4.04H35.96v11.6H30.89z"/>
//             <path d="M71.78,85.27c1.23-1.34,1.27-3.34,1.27-4.69V63.83h5.07v17.44c0,2.27-0.31,4.8-2.34,6.88c-2.69,2.77-6.88,2.88-9.34,2.88
// 		c-2.46,0-6.65-0.12-9.34-2.88c-2.04-2.07-2.34-4.61-2.34-6.88V63.83h5.07v16.75c0,1.5,0.04,3.3,1.31,4.69
// 		c1.42,1.54,3.73,1.73,5.3,1.73C67.89,87,70.39,86.81,71.78,85.27"/>
//             <path d="M82.73,68.4c0-1.11,0.04-2.46,1.11-3.5c0.96-0.92,2.23-1.08,3.23-1.08h12.76c1.88,0,4.19,0.12,5.76,2.46
// 		c1.27,1.84,1.35,3.96,1.35,5.92c0,1.96-0.08,4.07-1.15,6.07c-0.69,1.31-2,2.77-5.07,2.77h-3.46v0.19l10.22,9.49h-6.38l-8.84-8.72
// 		c-0.54-0.54-1.27-1.5-1.27-2.27v-0.62c0-1.07,0.81-1.84,2.27-1.84h5.07c0.88,0,2.07-0.12,2.77-1.19c0.54-0.85,0.61-2.77,0.61-3.69
// 		c0-2.11-0.42-3.19-0.96-3.73c-0.81-0.81-2.04-0.81-2.84-0.81h-8.22c-0.54,0-0.92,0.04-1.35,0.42c-0.54,0.46-0.54,0.96-0.54,1.5
// 		v20.94h-5.07V68.4z"/>
//             <path d="M136.68,87.23c0,2.73-1.81,3.81-4,3.81c-1.61,0-3.15-0.38-4.34-2.27L117,70.86c-0.39-0.58-0.54-0.65-0.62-0.65
// 		c-0.19,0-0.54,0.23-0.54,0.77v19.75h-4.92V67.17c0-2.3,1.58-3.65,4.11-3.65c2.38,0,3.54,0.96,4.27,2.11l11.41,18.02
// 		c0.19,0.27,0.27,0.65,0.62,0.65c0.42,0,0.42-0.46,0.42-0.65V63.83h4.92V87.23z"/>
//             <rect x="141.29" y="85.77" width="4.69" height="4.96"/>
//             <path d="M179.62,90.73V67.86c0-1,0-2.08,0.92-3c0.88-0.88,2.11-1.04,3.04-1.04h12.8c1.42,0,3.69,0,5.34,1.61
// 		c1.85,1.77,2.04,4.8,2.04,7.07c0,2.19-0.23,5.65-2.34,7.38c-1,0.81-2.54,1.31-4.11,1.31H184.7v9.53H179.62z M194.34,77.16
// 		c1.11,0,2.42,0,3.3-1c0.81-0.92,0.88-2.65,0.88-3.69c0-1.19-0.12-2.81-0.96-3.69c-0.88-0.88-2.31-0.92-3.15-0.92h-8.18
// 		c-0.35,0-0.81-0.04-1.19,0.35c-0.35,0.35-0.35,0.81-0.35,1.27v7.69H194.34z"/>
//             <path d="M207.6,68.4c0-1.11,0.04-2.46,1.11-3.5c0.96-0.92,2.23-1.08,3.23-1.08h12.76c1.88,0,4.19,0.12,5.76,2.46
// 		c1.27,1.84,1.35,3.96,1.35,5.92c0,1.96-0.08,4.07-1.15,6.07c-0.69,1.31-2,2.77-5.07,2.77h-3.46v0.19l10.22,9.49h-6.38l-8.84-8.72
// 		c-0.54-0.54-1.27-1.5-1.27-2.27v-0.62c0-1.07,0.81-1.84,2.27-1.84h5.07c0.88,0,2.07-0.12,2.77-1.19c0.54-0.85,0.61-2.77,0.61-3.69
// 		c0-2.11-0.42-3.19-0.96-3.73c-0.81-0.81-2.04-0.81-2.84-0.81h-8.22c-0.54,0-0.92,0.04-1.35,0.42c-0.54,0.46-0.54,0.96-0.54,1.5
// 		v20.94h-5.07V68.4z"/>
//             <path d="M259.59,66.36c2.8,2.69,3.07,7.38,3.07,10.91c0,3.54-0.31,8.22-3.07,10.91c-2.92,2.84-7.61,2.84-10.72,2.84
// 		c-3.19,0-7.76,0-10.72-2.84c-3.11-3-3.11-7.88-3.11-10.91c0-3.65,0.19-8.15,3.11-10.91c3-2.84,7.46-2.84,10.72-2.84
// 		C252.06,63.52,256.63,63.52,259.59,66.36 M241.92,84.77c1.58,2.04,4.11,2.23,6.92,2.23c2.77,0,5.34-0.19,6.96-2.23
// 		c0.88-1.11,1.65-3.15,1.65-7.49c0-4.65-0.81-6.49-1.65-7.53c-1.58-1.96-4.23-2.19-6.96-2.19c-2.8,0-5.34,0.23-6.92,2.19
// 		c-1.04,1.31-1.65,3.42-1.65,7.53C240.26,81.66,241.03,83.66,241.92,84.77"/>
//             <path d="M21.52,64.21h4.43v3.52h-4.43V64.21z M21.52,70.65h4.43v20.08h-4.43V70.65z"/>
//             <path d="M89.72,60.04H15.58c-0.51,0-0.92-0.43-0.92-0.96c0-0.53,0.41-0.96,0.92-0.96h74.14c0.51,0,0.92,0.43,0.92,0.96
// 		C90.64,59.61,90.23,60.04,89.72,60.04"/>
//             <path d="M91.25,52.62h-55.5c-0.51,0-0.92-0.43-0.92-0.96c0-0.53,0.41-0.96,0.92-0.96h55.5c0.51,0,0.92,0.43,0.92,0.96
// 		C92.17,52.19,91.76,52.62,91.25,52.62"/>
//             <path d="M78.22,102.79H31.88c-0.51,0-0.92-0.43-0.92-0.96s0.41-0.96,0.92-0.96h46.34c0.51,0,0.92,0.43,0.92,0.96
// 		S78.73,102.79,78.22,102.79"/>
//             <path d="M80.26,95.37H15.58c-0.51,0-0.92-0.43-0.92-0.96c0-0.53,0.41-0.96,0.92-0.96h64.67c0.51,0,0.92,0.43,0.92,0.96
// 		C81.17,94.94,80.76,95.37,80.26,95.37"/>
//             <path d="M177.75,61.69c-0.2-0.67-0.51-1.25-0.97-1.56c-0.42-0.28-0.8-0.64-1.17-1.04l-0.58-4.91c-0.17-1.42,1-3.33,2.1-3.43
// 		l0.14-0.01l2.5-0.23c0,0-0.1-2.59-1-4.25c-0.36-0.69-0.87-1.22-1.56-1.33c-0.5-0.09-0.98-0.25-1.47-0.44l-1.55-3.97
// 		c-0.43-1.14,0.48-3.23,1.66-3.73l1.14-0.49l1.69-0.73c0,0-1.14-4.14-3.63-3.82c-0.06,0-0.14,0.02-0.2,0.03
// 		c-0.56,0.11-1.11,0.17-1.68,0.19l-2.42-2.81c-0.69-0.79-0.07-2.92,1.11-3.81l1.73-1.29l1.11-0.84c0,0-1.32-2.04-3.21-2.17
// 		c-0.51-0.03-1.07,0.08-1.65,0.4c-0.59,0.33-1.19,0.58-1.79,0.8l-3.15-1.47c-0.91-0.43-0.64-2.47,0.48-3.68l2.67-2.85l0.05-0.05
// 		c0,0-1.02-0.7-2.42-0.78c-0.97-0.06-2.11,0.18-3.22,1.13c-0.57,0.48-1.18,0.95-1.79,1.35l-3.72-0.04
// 		c-1.06-0.01-1.14-1.87-0.14-3.32l2.29-3.32l0.01,0l0.12-0.19c0,0-1.51-0.25-3.26,0.42c-0.93,0.36-1.93,0.98-2.82,2.03
// 		c-0.53,0.64-1.1,1.26-1.69,1.84l-4.05,1.38c-1.16,0.39-1.59-1.17-0.77-2.79l0.15-0.29L148.6,8c0,0-0.95,0.2-2.19,0.83
// 		c-1.27,0.66-2.84,1.77-3.96,3.58c-0.46,0.75-0.97,1.51-1.5,2.22l-4.14,2.74c-1.2,0.77-1.95-0.4-1.37-2.09l0.4-1.17l1.01-2.93
// 		c0,0-4.14,2.24-5.86,6.09c-0.25,0.57-0.52,1.14-0.82,1.69c-0.12,0.27-0.27,0.52-0.41,0.78l-4.01,3.91
// 		c-1.15,1.12-2.19,0.42-1.86-1.25l0.77-4.05c0,0-4.09,3.5-5.24,7.46c-0.24,0.84-0.54,1.71-0.86,2.57l-3.64,4.86
// 		c-0.69,0.94-1.49,1.16-1.94,0.73c-0.21-0.21-0.33-0.57-0.32-1.09l0.09-3.75c0,0-2.49,2.97-3.71,6.14
// 		c-0.29,0.75-0.51,1.51-0.61,2.24c-0.11,0.82-0.28,1.67-0.46,2.54c0,0-8.03,10.01-11.21,18.87h8.37
// 		c16.18-25.55,36.9-40.49,48.36-33.96c7.98,4.55,9.53,18.42,5.32,35.66c-2.4,9.84-6.69,20.78-12.74,31.7
// 		c-0.59,1.08-1.2,2.14-1.82,3.19c-16.49,28.18-38.91,45.19-51,38.31c-8.64-4.93-9.71-20.93-4.12-40.19h-4.19
// 		c-0.01,0.03-0.01,0.06-0.02,0.09c-1.78,6.98-2.45,14.18-2.05,21.35c0.14,2.54,0.39,5.29,0.81,8.08l-2.74,1.42l-1.2,0.53
// 		c0,0,0.49,1.77,1.54,2.91c0,0.01,0,0.01,0.01,0.01c0.58,0.63,1.34,1.07,2.29,0.85c0.56-0.11,1.13-0.17,1.67-0.18l2.43,2.81
// 		c0.69,0.8,0.08,2.92-1.11,3.8l-2.6,1.94l-0.26,0.2c0,0,0.34,0.52,0.93,1.05c0.01,0,0.01,0.01,0.01,0.01
// 		c0.88,0.78,2.3,1.59,3.95,0.7c0.58-0.32,1.18-0.58,1.78-0.8l3.16,1.47c0.9,0.41,0.62,2.47-0.5,3.68l-1.47,1.58l-1.23,1.32
// 		c0,0,2.44,1.67,4.94,0.14c0.23-0.13,0.45-0.29,0.68-0.49c0.58-0.49,1.19-0.95,1.8-1.37l3.71,0.05c0.87,0,1.08,1.24,0.59,2.48
// 		c-0.1,0.29-0.25,0.57-0.43,0.83l-2.41,3.51c0,0,3.54,0.6,6.05-2.44c0.55-0.65,1.11-1.26,1.7-1.84l4.05-1.37
// 		c1.16-0.4,1.59,1.15,0.78,2.77l-1.97,3.92c0,0,3.95-0.85,6.15-4.41c0.46-0.74,0.97-1.49,1.5-2.22l4.15-2.72
// 		c1.18-0.78,1.93,0.37,1.35,2.07l-1.41,4.11c0,0,4.14-2.23,5.86-6.11c0.37-0.82,0.78-1.65,1.22-2.47l4.01-3.91
// 		c1.15-1.1,2.19-0.4,1.88,1.28l-0.78,4.03c0,0,4.09-3.49,5.24-7.45c0.24-0.84,0.55-1.71,0.88-2.59l3.63-4.86
// 		c1.04-1.39,2.45-0.58,2.42,0.95l-0.1,3.75c0,0,1.32-1.59,2.51-3.66c0.83-1.45,1.6-3.13,1.81-4.7c0.11-0.82,0.13-2.28,0.33-3.15
// 		l3.05-5.53c0.12-0.2,0.24-0.39,0.37-0.55c0.86-1.09,2.79,0.96,3.01,2.12l0.6,3.22c0,0,3.29-5.32,3.14-8.77
// 		c-0.04-0.74-0.86-3.67-0.81-4.49l0.04-0.11l2.24-5.77c0.5-1.29,1.47-2.06,2.13-1.89c0.21,0.05,1.19,1.92,1.32,2.18l1.25,2.54
// 		c0,0,2.59-5.81,1.78-8.71c-0.17-0.61-1.09-3.05-1.19-3.76l1.39-5.9c0.83-2.41,2.66-2.33,3.41-1.64l1.84,1.68
// 		c0,0,1.74-5.94,0.32-8.12c-0.29-0.46-1.35-1.6-1.6-2.17l0.11-1.5l0.3-4.08c0.09-1.16,0.85-2.37,1.62-2.78
// 		c0.28-0.17,0.56-0.23,0.81-0.14l2.31,0.74c0,0,0.45-3.32-0.18-5.48V61.69z"/>
//         </g>
//     </svg>
// );
