import React from "react"
import Modal from "../BasicModal";
import {useDispatch, useSelector} from "react-redux";
import {onChangeImportOfflineModal, onImportOfflineCompany} from "../../../store/reducers/ModalReducer";
import {OfflineImportForm} from "./OfflineImportForm";
import "./offline-import.css"
import {useLang} from "../../../context/langProvider";

const OfflineImport = () => {
    const {getT, lang: local} = useLang();
    const dispatch = useDispatch();

    const {isOpen, nameCompany, isSuccessful} = useSelector(({
                                                                 modal: {
                                                                     importOffline: {
                                                                         isOpen,
                                                                         nameCompany,
                                                                         isSuccessful
                                                                     }
                                                                 }
                                                             }) => ({isOpen, nameCompany, isSuccessful}));
    // const {isOpen, nameCompany, isSuccessful} = useSelector(state => state.modal.importOffline);
    const close = () => dispatch(onChangeImportOfflineModal({isOpen: false, isSuccessful: false}))
    const productionConst_phoneCode = useSelector(state => state.order.order.production_constants['react.phone_code_one']);
    const productionConst_rusHide = useSelector(state => state.order.order.production_constants['production.rus_hide']) || ''
    const production = useSelector(state => state.order.order.production_constants);

    const prodConfig = {
        isCompany: Boolean(Number(production['production.import_offline_by_Company'])),
        price: production['production.import_ofline_price'],
        email: production['production.import_offline_email'],
        companyName: nameCompany.hasOwnProperty('data') && nameCompany.data[0]['translate_name'] ? nameCompany.data[0]['translate_name'][local] : ''
    }
    const onSubmit = (data) => {
        if (prodConfig.isCompany) {
            dispatch(onImportOfflineCompany(data))
        } else {
            dispatch(onImportOfflineCompany(data))
        }
    }
    if (!isOpen) return null
    return <>
        <Modal
            title={`${getT('Услуга оказывается Компанией')} ${prodConfig.isCompany ? prodConfig.companyName : 'iFurn.pro'}`}
            open={isOpen} onClose={close}>
            <div className="import-offline-container">
                {!isSuccessful ? <>
                        <div className="info">
                            <p>{getT('Импорт из различных программ можно сделать автоматически. Подробно всё описано на')}
                                <a href=" https://help.ifurn.pro" target={"_blank"}> help.ifurn.pro</a>.</p>
                            <p>{getT('Если по каким-либо причинам у Вас не получается сделать импорт самостоятельно,' +
                                ' заполните анкету, приложите файлы и Компания - производитель либо IFurn.pro поможет Вам' +
                                ' с' +
                                ' импортом.')}</p>
                        </div>
                        {prodConfig.isCompany && prodConfig?.price ?
                            <div className="price">{getT('Стоимость')}: <span>{prodConfig.price}</span> /{getT('деталь')}

                                {/*{getT(' Минимальная стоимость работы')} - <span>100 uah</span>.*/}
                            </div> : null}

                        <OfflineImportForm getT={getT} onSubmit={onSubmit} productionCodePhone={productionConst_phoneCode}
                                           prodRusHide={productionConst_rusHide}
                                           phoneCodeInitial={Number(productionConst_phoneCode) !== 9999 && productionConst_phoneCode !== '' ? `+${productionConst_phoneCode} ` : '+38 '}/>
                    </>
                    : <div className="massage-success">{getT("С Вами свяжутся в ближайшее время")}.</div>
                }
            </div>
        </Modal>
    </>
}

export default OfflineImport;