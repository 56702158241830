import React from 'react';
import Part from "./Part";
import Position from "./Position";


const TableOfPart = ({packing, getT, currency}) => {
    if (!Array.isArray(packing) || !packing?.length) return null
    return (
        packing?.map((packing, root_index) => {
            return <div className={'section-table-part section-gap'}>
                <h2>{getT('Детали')}</h2>
                <table>
                    <tr>
                        <th rowSpan={2}>id</th>
                        <th rowSpan={2}>{getT('Название')}</th>
                        <th colSpan={3}>{getT('Размер')}</th>
                        <th rowSpan={2}>{getT('Материал')}</th>
                    </tr>
                    <tr>
                        <th>{getT('Высота')}</th>
                        <th>{getT('Ширина')}</th>
                        <th>{getT('Глубина')}</th>
                    </tr>
                    {packing?.packs?.map((pack, index_pack) => {
                        return pack?.bins?.map((bind, index_bind) => {
                            return bind?.parts?.map((part, index_part) => {

                                return <>
                                    <Part product_id={part.product_id} name={part.name} h={part.h} w={part.w}
                                          id_furn={part.id_ifurn} key={`part_${index_part}`}

                                    />
                                    <Position getT={getT} index_bind={part?.bin + 1} part_y={part.y} part_x={part.x}
                                              part_color={part.color} index_pack={index_part}
                                    />

                                </>
                            })
                        })
                    })}


                </table>
            </div>
        })
    );
};

export default TableOfPart;