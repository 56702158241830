import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../../store/reducers/SettingsReducer";
import Modal from "../../../../components/Modals/BasicModal";
import {isOpenInstPaz, PazForms} from "../../../../selectors/instruction-selector";

const PazForm = ({getT, onToggleInstModal, onBeforeSubmit, onUpdateToolsSubmit}) => {
    const dispatch = useDispatch();
    const onChangeValues = ({key, dt}) => {
        dispatch(actions.onChangeInstrumentsPazForm({key, dt}))
    };

    const valuesForm = useSelector(PazForms)
    const isOpen = useSelector(isOpenInstPaz);




    return <><Modal title={getT('Форма добавления/редактирование инструмента')} open={isOpen}
                    onClose={() => onToggleInstModal({key: 'paz', dt: false})}>
        <form action="" className={"inst__bore-form"} onSubmit={(e) => {
            e.preventDefault();
            if (valuesForm.isEdit.edit) {
                onUpdateToolsSubmit(valuesForm, valuesForm.isEdit.id);
            } else {
                onBeforeSubmit(valuesForm)
            }

        }}>
            <div className="field-section">
                <div className="field field-on">
                    <label className={"form-input-checkbox-label"} htmlFor="OnOff">
                        <input type="checkbox"
                               id={"OnOff"}
                               className={"form-input-checkbox"}
                               checked={Boolean(valuesForm.on) ? 'checked' : ''}
                               value={valuesForm.on}
                               onChange={(e) => {
                                   onChangeValues({key: 'on', dt: Number(e.target.checked)})
                               }}/>
                        <span>{getT('Вкл')}/{getT('Выкл')}</span>
                    </label>
                </div>
                <div className="field">
                    <div>   <label style={{paddingTop: '0px', marginTop: '0px'}} htmlFor="" className={"field-title" +
                    " label"}>{getT('Сторона')}</label></div>
                    <label className="field-radio" htmlFor={"face"}
                           onChange={(e) => onChangeValues({
                               key: 'cut_back',
                               dt:  0
                           })}>
                        <input type="radio" id="face"
                               name="cut_back"
                               value="0"
                               checked={Number(valuesForm.cut_back) === 0 ? 'checked' : ''}
                        />
                        <span className={"checkmark "}>{getT('Лицевая')}</span>
                    </label>
                    <label className="field-radio" htmlFor={"boreIn"}
                           onChange={(e) => onChangeValues({
                               key: 'cut_back',
                               dt:  1
                           })}>
                        <input type="radio" id="boreIn"
                               name="cut_back"
                               value="1"
                               checked={Number(valuesForm.cut_back) === 1 ? 'checked' : ''}
                        />
                        <span className={"checkmark "}>{getT('Обратная')}</span>
                    </label>
                </div>

            </div>

            <div className="field-section">

                <div className="field">
                    <label htmlFor="" className={"field-title label"}>{getT('Ширина')}</label>
                    <input className={"input-val"} type="number"
                           step={0.01}
                           min={2} max={5}
                           value={valuesForm.w}
                           required={'required'}
                           onChange={(e) => {
                               let value = Number(e.target.value) > 5 ? 5 : e.target.value

                               onChangeValues({key: 'w', dt: value})
                           }}/>
                </div>
                <div className="field">
                    <label htmlFor="" className={"field-title label"}>{getT('Максимальная глубина')}</label>
                    <input className={"input-val"} type="number"
                           min={0} max={100}
                           step={0.01}
                           value={valuesForm.z_max}
                           required={'required'}
                           onChange={(e) => {
                               let value = Number(e.target.value) > 100 ? 100 : e.target.value
                               onChangeValues({key: 'z_max', dt: value})
                           }}/>
                </div>
            </div>
            <button className={"btn"} type={"submit"}>{getT('Отправить')}</button>

        </form>
    </Modal>
    </>
}

export default PazForm;