
export const MODAL_TOGGLE_CREATE_PROJECT = "MODAL/TOGGLE_CREATE_PROJECT";
export const MODAL_TOGGLE_ORDER_PART_GOODS_SERVICE = "MODAL/TOGGLE_ORDER_PART_GOODS_SERVICE";

export const modalToggleCreateProjectAC = ({isOpen=false, user_id= null, firm=null}) => {
    return {
        type: MODAL_TOGGLE_CREATE_PROJECT,
        payload: {isOpen:isOpen, user_id: user_id, firm: firm}
    }
}
/**
 * дополнительные услуги к деталям.
 * @param {boolean} isOpen  -
 * @param {object | null} part  - добавление к детали услуг
 */
export const modalToggleOrderPartGoodsServiceAC = ({isOpen=false,part=null, sizeType='saw', product_count=1}) => {
    return {
        type: MODAL_TOGGLE_ORDER_PART_GOODS_SERVICE,
        payload: {isOpen:isOpen, part: part, sizeType: sizeType, product_count:product_count}
    }
}