import React, {useState} from 'react';

const Form = ({bandsList, onHandlerSend, getT}) => {
    const [bands, setBand] = useState([])
    if (!Array?.isArray(bandsList) || !onHandlerSend || !getT) return false;
    const onToggleBands = (band) => {
        const isFind = bands?.find(item => Number(item?.goods_id) === Number(band?.goods_id));
        if (isFind) {
            setBand(prev => prev?.filter(item => Number(item?.goods_id) !== Number(band?.goods_id)))
        } else {
            setBand(prev => {
                return [...prev, band]
            })
        }
    }
    return (
        <div className={'connection-bands-form'}>
            {bandsList?.length ? bandsList?.map(band => {
                const isAdded = bands?.filter(item => Number(item?.goods_id) === Number(band?.goods_id))
                return <div className="connection-baned__item" key={band?.goods_id}>
                    <input type={'checkbox'} onChange={() => onToggleBands(band)} checked={isAdded?.length}/>
                    <p><span>[{band?.goods_id}]</span> {band?.translate?.[localStorage?.getItem('lang')]}</p>

                </div>
            }) : <h3>{getT('Кромки уже существуют в проекте')}</h3>}
            {bands?.length ?
                <div className="text-center">
                    <button className={'btn mt-2 mx-auto w-max-content'}
                            onClick={() => onHandlerSend(bands)}>{getT('Добавить')}</button>
                </div> : null
            }
        </div>
    );
};

export default Form;