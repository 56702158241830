import React from 'react';
import {
    actionsCellsLeftovers,
    onSendRemainCreateNotOrder,
} from "../../../../store/reducers/StorageCellsLeftovers";
import {useDispatch, useSelector} from "react-redux";
import {
    getCellsStorage,
    getRemainFormInitialValue,
} from "../../../../selectors/cells-leftovers-selector";


const RemainCreateForm = ({getT, goods, name, onSuccessStep}) => {
    // const ordersStock = useSelector(getStockOrdersDT);
    const valueForm = useSelector(getRemainFormInitialValue);
    const dispatch = useDispatch();
    const cells = useSelector(getCellsStorage);
    // const lang = useSelector(getLocalLang)
    // dispatch(onToggleRemainModal({key: 'create', dt: {isOpen: false, goods: null, order_in: null}}));

    const onChangeValues = ({key, dt}) => {
        dispatch(actionsCellsLeftovers.onChangeRemainForms({key, dt}))
    }
    return <form action="" className={"inst__bore-form"} onSubmit={(e) => {
        e.preventDefault();
        dispatch(onSendRemainCreateNotOrder({...valueForm, goods: goods}, null, null, onSuccessStep, name));


    }}>
        <div className="field-section">

            <div className="field">
                <label htmlFor="" className={"field-title label"}>x</label>
                <input className={"input-val"} type="number"
                       value={valueForm.x}
                       required={'required'}
                       onChange={(e) => {
                           // let value = Number(e.target.value) > 100 ? 100 : e.target.value
                           onChangeValues({key: 'x', dt: e.target.value})
                       }}/>
            </div>
            <div className="field">
                <label htmlFor="" className={"field-title label"}>y</label>
                <input className={"input-val"} type="number"
                       value={valueForm.y}
                       required={'required'}
                       onChange={(e) => {
                           onChangeValues({key: 'y', dt: e.target.value})
                       }}/>
            </div>
        </div>
        <div className="field-section">

            <div className="field">
                <label htmlFor="" className={"field-title label"}>{getT('Количество')}</label>
                <input className={"input-val"} type="number"
                       value={valueForm.units}
                       required={'required'}
                       onChange={(e) => {
                           onChangeValues({key: 'units', dt: Number(e.target.value)})
                       }}/>
            </div>
            {Array.isArray(cells) &&
                <div className="field">
                    <label htmlFor="" className={"field-title label"}>{getT('Имя ячейки')}</label>
                    <select className={"material-select w-100"} name="" id="" value={valueForm.cell}
                            required={'required'}
                            onChange={(e) => onChangeValues({key: 'cell', dt: e.target.value})}
                    >
                        <option value={null}>{getT('Выбрать')}</option>
                        {cells.map(cell => <option
                            selected={(Number(cell) === Number(cell.stock_cell_id)) ? 'selected' : ''}
                            value={cell.stock_cell_id}>{cell.name}</option>)}
                    </select>
                </div>
            }
        </div>

        <button className={"btn btn-maxCCenter"} type={"submit"}>{getT('Создать')}</button>
    </form>
}
export default RemainCreateForm;