import React, {useState} from 'react';
import "./card.css"
import IconArrow from "../../Icons/IconArrow";
const Card = ({label = null, children = null, className = '', footer = null, isCollapse = false, ...reset}) => {
    const [isOpen, setIsOpen] = useState(false);
    if (!children && !label && !footer) return null;
    const handlerIsOpen = () => {
        if (!isCollapse) return;
        setIsOpen(!isOpen)
    }
    const renderContent = () => {
        return <>
            {children && <div className="card_body">
                {children}
            </div>}
            {footer && <div className="card-footer">
                {footer}
            </div>} </>
    }
    const cNameHeader = `card_header  ${isCollapse && !isOpen ? 'border-none' : ' '} `
    return (
        <div className={'card ' + className } {...reset}>
            {label && <div className={cNameHeader} onClick={handlerIsOpen}>{label}
                {isCollapse && <IconArrow className={isOpen ? 'icon-arrow-open icon-arrow' : ' icon-arrow'} />}
            </div>}
            {isCollapse ?
                isOpen ?
                    renderContent()
                    : null
                :
                renderContent()
            }

        </div>
    );
};

export default Card;