import React, {useCallback} from 'react';
import Modal from "../BasicModal";
import Container from "../../Container";
import Form from "./Form";
import {useLang} from "../../../context/langProvider";
import "./main.css"
import {useDispatch} from "react-redux";
import {packagingPrintingHtml} from "../../../store/reducers/PackagingPrinting";

const PackagingPrintingModal = ({isOpen, onClose}) => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const onSendPrint = (form_data)=>{

        dispatch(packagingPrintingHtml(form_data))
    }
    return (
        <Modal title={getT('Печать')}  onClose={onClose} open={isOpen} key={'packaging-print-modal'}>
            <Container>
                <Form getT={getT} onSendPrint={onSendPrint}/>
            </Container>
        </Modal>
    );
};

export default PackagingPrintingModal;
