export const checkExclusiveDrilling = (DT, {bore_through, bore_in, d}) => {
    const findExclusive = Array.isArray(DT) && DT.length >= 1 ?
        DT.find(drilling => (Number(drilling.d) == Number(d) && (Number(drilling.bore_through) == Number(bore_through) || (Number(drilling.bore_in) == Number(bore_in)))))
        :null;

    return  findExclusive && findExclusive.hasOwnProperty('user_xnc_tools_id') ? findExclusive.user_xnc_tools_id : null;
}
export const checkExclusivePaz = (DT, {w, cut_back}) => {
    const findExclusive = Array.isArray(DT) && DT.length >= 1 ?
        DT.find(paz => ( (Number(paz.w) == Number(w)) && (Number(paz.cut_back) == Number(cut_back) )))
        :null;

    return  findExclusive && findExclusive.hasOwnProperty('user_xnc_tools_id') ? findExclusive.user_xnc_tools_id : null;
}

export  const isAccessEdit = (idFirm)=>{
    const firmAccess = [1]
    return firmAccess.includes(Number(idFirm))

}

