import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import IconProjectVersion from "../../../Icons/Actions/IconProjectVersion";
import {getSaveVersions} from "../../../selectors/order-selector";
import {useLang} from "../../../context/langProvider";
import {setOrderProjectDataThunk} from "../../../store/reducers/OrderReducer";
import UploadProject from "../../../Icons/Dotts/UploadProject";
import IconQuestion from "../../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../store/constats/help_article_constants";

const SavedVersion = ({handlerHelpArticle}) => {
    const dispatch = useDispatch();
    const {getT} = useLang();

    const versionList = useSelector(getSaveVersions);

    if (versionList.length < 2) return null;
    const onChangeVersion = (get_version) => {
        dispatch(setOrderProjectDataThunk(false, false, get_version));
    }

    return (
        <div className={'version-project-select helper-article'}>
            <IconProjectVersion/>
            <IconQuestion style={{top: '-6px'}}
                          onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.last_preservation)}
            />
            <ul>
                {versionList?.map(v => (
                    <li className={'current_version' === v.id ? 'selected' : ''} onClick={() => onChangeVersion(v.id)}>
                        {getT('Дата')} {v.date} {'current_version' !== v.id && <UploadProject/>}
                    </li>))}
            </ul>
        </div>)
};

export default SavedVersion;



