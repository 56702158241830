import React, {Suspense, useEffect} from "react";

import "./MainService.css";

import CheckProjectModal from "../../components/Modals/CheckProjectModal/CheckProjectModal";
import RemainsModal from "../../components/Modals/RemainsModal";
import PlaneModal from "../../components/Modals/PlaneModal";
import LabelsPrintCount from "../../components/Modals/LabelsPrintCount";
import useRoutingPage from "../../HOOK/useRoutingPage";
import HomeContainer from "../Home/HomeContainer";
import useSettingsCookies from "../../HOOK/useSettingsCookies";
import PlaneOperations from "../PlaneOperations/PlaneOperations";
import {useSelector} from "react-redux";
import {getProductionDetailsLightDefault} from "../../selectors/production-selectors";
import PackagingPrinting from "../PackagingPrinting";
import ErrorBoundary from "../../components/ErrorBoundary";
import LockWindow from "../../components/Modals/LockWindow";

const MaterialsContainer = React.lazy(() => import('../Materials/MaterialsContainer'))
const BoxesConstructor = React.lazy(() => import('../../components/Boxes/BoxesConstructor'));
const CuttingChartPage = React.lazy(() => import("../CuttingChart"));
const OperationsContainer = React.lazy(() => import("../Operations/OperationsContainer"));
const ShopingCard = React.lazy(() => import("../ShopingCard/ShopingCard"));
const Ordering = React.lazy(() => import("../Ordering"));
const PackedPage = React.lazy(() => import("../PackadPage"));
const MaterialPageInfo = React.lazy(() => import("../MaterialInfo/MaterialInfo"));
const MaterialSelectedPage = React.lazy(() => import("../MaterialSelectedFilter"));
const ProcessingEdgeGroupPage = React.lazy(() => import("../ProcessingEdgeGroupPage"));
const SearchGoodsPage = React.lazy(() => import("../SearchGoodsPage"));


const MainService = () => {
    const routerPages = useRoutingPage();
    const isLightDetails = useSelector(getProductionDetailsLightDefault);
    const [initCookies, settings] = useSettingsCookies();

    useEffect(() => {
        initCookies();
    }, [isLightDetails]);

    if (!settings) return <></>
    return (
        <>
            {routerPages.home && <HomeContainer/>}
            {routerPages.plane_operations && <PlaneOperations key={'PlaneOperations'}/>}
            {routerPages.processing_edge_group_page && <Suspense fallback={<div>Loading...</div>}>
                <ErrorBoundary><ProcessingEdgeGroupPage key={'processing_edge_group_page'}/></ErrorBoundary>
            </Suspense>
            }

            {routerPages.box &&
                <Suspense fallback={<div>Loading...</div>}>
                    <ErrorBoundary>
                        <div className={"home home-box"} key={'home-box-constructor'}>
                            <BoxesConstructor key={'BoxesConstructor'}/>
                        </div>
                    </ErrorBoundary>
                </Suspense>}
            {routerPages.cutting &&
                <Suspense fallback={<div>Loading...</div>}><CuttingChartPage key={'CuttingChartPage'}/></Suspense>}

            {routerPages.material && <Suspense fallback={<div>Loading...</div>}>
                <ErrorBoundary>
                    <MaterialsContainer key={'materials_container'}/>
                </ErrorBoundary>
            </Suspense>}
            {routerPages.operation && <Suspense fallback={<div>Loading...</div>}>
                <ErrorBoundary>
                    <OperationsContainer key={'OperationsContainer'}/>
                </ErrorBoundary>
            </Suspense>}


            {routerPages.shopping &&
                <Suspense fallback={<div>Loading...</div>}> <ErrorBoundary> <ShopingCard
                    key={'ShoppingCard'}/></ErrorBoundary></Suspense>}
            {routerPages.order &&
                <Suspense fallback={<div>Loading...</div>}> <ErrorBoundary> <Ordering key={'Ordering'}/></ErrorBoundary></Suspense>}

            {routerPages.packed &&
                <Suspense fallback={<div>Loading...</div>}> <ErrorBoundary><PackedPage
                    key={'PackedPage'}/></ErrorBoundary></Suspense>}

            {routerPages.packagingPrinting && <Suspense fallback={<div>Loading...</div>}>
                <ErrorBoundary>
                    <PackagingPrinting/>
                </ErrorBoundary>
            </Suspense>}

            {routerPages.material_info_edit && <Suspense fallback={<div>Loading...</div>}>
                <ErrorBoundary>
                    <MaterialPageInfo/>
                </ErrorBoundary>
            </Suspense>}
            {routerPages.material_selected_filter && <Suspense fallback={<div>Loading...</div>}>
                <ErrorBoundary>
                    <MaterialSelectedPage/>
                </ErrorBoundary>
            </Suspense>}
            {routerPages.search_goods_page && <Suspense fallback={<div>Loading...</div>}>
                <ErrorBoundary>
                    <SearchGoodsPage/>
                </ErrorBoundary>
            </Suspense>}


            {/*//::TODO перенести модалки в родительские компоненты*/}
            <RemainsModal key={'RemainsModal'}/>
            <PlaneModal key={'PlaneModal'}/>

            <LabelsPrintCount key={'gird-before-print'}/>
            <CheckProjectModal key={'CheckProjectModal'}/>
            <LockWindow/>

        </>
    );
};

export default MainService;
