import React from 'react';

const IconArrow = ({top = true, cName = 'circle-arrow', onCLickHandler = false, ...reset}) => {
    const className = `icon-arrow ${top ? 'arrow-top ' : 'arrow-down '} `
    return (
        <div className={`cursor-pointer ${cName}`} onClick={() => {
            if (onCLickHandler) onCLickHandler();
        }}>
            <svg className={className} width="8" height="16" viewBox="0 0 8 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg" {...reset}>
                <path d="M7 1L0.999999 8L7 15" stroke="#050038" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"></path>
            </svg>
        </div>
    );
};

export default IconArrow;