import React from 'react';
import Globe from "../../images/icons/Globe";

const LanguageProduction = ({lang}) => {
    return (
        <div className="select-custom" >
            <div className="select__head" style={{cursor: 'auto'}}>
                <Globe/>
                {lang}
            </div>
        </div>
    );
};

export default LanguageProduction;