import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getConnectionBandSelector, onChangeConnectionBand} from "../../../store/reducers/ModalReducer";
import Modal from "../BasicModal";
import Container from "../../Container";
import Form from "./Form";
import {addOrderMaterialAC} from "../../../store/reducers/OrderReducer";
import {useLang} from "../../../context/langProvider";

const ConnectionBandModal = () => {
    const {getT}= useLang();
    const dispatch = useDispatch();
    const modal = useSelector(getConnectionBandSelector);
    const bands = useSelector(state => state?.order?.order?.band);
    if (!modal?.isOpen) return null;

    const onClose = () => dispatch(onChangeConnectionBand({isOpen: false, connectionBands: []}))
    const onAddBandsProject = (bands) => {
        bands?.forEach(band => {
            dispatch(addOrderMaterialAC({type: "band", ...band}))
        });
        onClose()

    }
    return (
        <Modal open={modal?.isOpen} onClose={onClose} title={getT(
            'Кромка к материалу'
        )}>
            <Container>
                <Form getT={getT} bandsList={modal?.connectionBands?.filter(item => {
                    const isFind = bands?.some(band => Number(band?.goods_id) === Number(item?.goods_id));
                    if (!isFind) {
                        return item
                    }
                })} onHandlerSend={onAddBandsProject} key={'connection-bands-list'}/>
            </Container>
        </Modal>
    );
};
export default ConnectionBandModal;