import React from 'react';


const HistoryDots = ()=>{
    return<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0013 6.85711C10.4615 6.85711 10.8346 6.47335 10.8346 5.99997C10.8346 5.52658 10.4615 5.14282 10.0013 5.14282C9.54106 5.14282 9.16797 5.52658 9.16797 5.99997C9.16797 6.47335 9.54106 6.85711 10.0013 6.85711Z" fill="#050038" stroke="#050038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.8333 6.85711C16.2936 6.85711 16.6667 6.47335 16.6667 5.99997C16.6667 5.52658 16.2936 5.14282 15.8333 5.14282C15.3731 5.14282 15 5.52658 15 5.99997C15 6.47335 15.3731 6.85711 15.8333 6.85711Z" fill="#050038" stroke="#050038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.16536 6.85711C4.6256 6.85711 4.9987 6.47335 4.9987 5.99997C4.9987 5.52658 4.6256 5.14282 4.16536 5.14282C3.70513 5.14282 3.33203 5.52658 3.33203 5.99997C3.33203 6.47335 3.70513 6.85711 4.16536 6.85711Z" fill="#050038" stroke="#050038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

}
export default HistoryDots;