export const mainCss = `
.hidden{visibility: hidden;}
.extra-hidden{visibility: hidden;}
.visible{visibility: visible !important;}
g[id]{visibility: hidden;}
.none{display: none;}
*{margin: 0; padding: 0;}
body{width: auto; height: auto;}
div{overflow: auto; width: 100%; height: 100%;}
.vertical-mirror-blink {
    animation: 1.5s linear infinite vertical-mirror-blink;
}
@keyframes vertical-mirror-blink {
  50% {
    opacity: .3;
  }
}
.blink {
  animation: 1s linear infinite blink;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
/* scrollbar */
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey;*/
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #007bff;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #007bff;
}
.inactive {
  cursor: not-allowed;
}
.inactive text {
  fill: #cecaca;
}
.active {
  color: black;
  cursor: pointer;
}
`
