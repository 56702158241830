import React from "react";
import BasicMenu from "./BasicMenu";

const BasicStaticMenu = ({getT, firm_code}) => {
    return <div className={"basic-menu"} style={{position: "relative"}}>
        <div className="burger-menu-wrap basic-burger">
            <button className="burger-btn"><i className="bars"></i><i className="bars"></i><i className="bars"></i></button>
        </div>
      <BasicMenu getT={getT}/>
    </div>
}

export default BasicStaticMenu;