import React, {useMemo} from 'react';
import QRCode from "react-qr-code";
import IconInfo from "../../../../../../../Icons/IconInfo";
import {filterObjectByKeys, unFilterObjectByKeys} from "../../../../../../../helpers/utils";
import {checkConnectBot} from "../../../../../../../store/reducers/AuthReducer";
import {useFormikContextProviderRegister} from "../../../../../SignUp";
import {useLang} from "../../../../../../../context/langProvider";
import {useDispatch} from "react-redux";
import IconTelegram from "../../../../../../../Icons/Social/IconTelegram";
import IconViber from "../../../../../../../Icons/Social/IconViber";
import IconWA from "../../../../../../../Icons/Social/IconWA";
import BotUrl from "../../../../../../../images/bot.jpeg";

const ICONS_MESSAGE = {
    telegram: IconTelegram,
    viber: IconViber,
    whatsapp: IconWA,
}
const styleIcon = {
    marginRight: '8px'
}
const styleQr = {height: "auto", maxWidth: "55px", width: "55px"}
const StepRegisterBot = ({
                             handleNext,
                             available_message_channel_list,
                             full_message_channel_list,
                         }) => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const {values, handleChange, setFieldValue, submitForm} = useFormikContextProviderRegister();

    const onCheckConnectBot = () => {
        let login = values.email || values.phone;
        dispatch(checkConnectBot(
            {login: login, send_password: 0}));

    }
    const connected_channel = useMemo(() => {
        if (!full_message_channel_list || !available_message_channel_list) return {}
        if (Object.keys(full_message_channel_list).length && Object.keys(available_message_channel_list)) {
            let keys = Object.keys(available_message_channel_list);
            return filterObjectByKeys(full_message_channel_list, keys)
        }
        return {}
    }, [available_message_channel_list, full_message_channel_list])
    const un_connected_channel = useMemo(() => {
        if (!full_message_channel_list || !available_message_channel_list) return {}
        if (Object.keys(full_message_channel_list).length && Object.keys(available_message_channel_list)) {
            let keys = Object.keys(available_message_channel_list);
            return unFilterObjectByKeys(full_message_channel_list, keys)
        } else if (Object.keys(full_message_channel_list).length) {
            return full_message_channel_list
        }
        return {}
    }, [available_message_channel_list, full_message_channel_list]);
    if (!available_message_channel_list && !full_message_channel_list) {
        handleNext();
        return null
    }
    return (
            <div className={'step-register-bot'}>
                <button type={'button'} style={{margin: '0 auto 35px auto'}} className={"btn"}
                        onClick={() => {
                            submitForm()
                        }}>{getT("Зарегистрироваться")}</button>
                <div className="content form-group mb-2 card-form">
                    {!!Object.keys(un_connected_channel).length && <div className={'un-connected-channel-wrap form-group'}>
                        <label htmlFor="" className={'d-flex align-items-center bot-info'} style={{marginBlock:'15px'}}>
                            <span style={{fontWeight: 'bold', fontSize: '16px'}}>{getT("Подключение чат-бота (опционально)")}</span>
                            {/*<span>{getT("Активируйте Ваш телефон в чат-боте")}:</span>*/}
                            <a href={BotUrl} target={'_blank'} className="info d-flex align-items-center"><IconInfo
                                width={'25px'} height={'25px'}/></a>
                            <img src={BotUrl} alt=""/>
                        </label>
                        <div className={'un-connected-channel-entry d-flex message-channel-entry'}>
                            {Object.keys(un_connected_channel).map(key => {
                                const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                                const name = un_connected_channel[key].name;
                                const link = un_connected_channel[key].link;
                                return (
                                    <div className={`item d-flex flex-column`}>
                                        <div className="d-flex align-items-center">
                                            {Icon ? <Icon style={styleIcon}/> : name ? name : ''}
                                            <a href={link} className="d-flex" target={'_blank'}>
                                                <QRCode
                                                    size={55}
                                                    style={styleQr}
                                                    value={link}
                                                    viewBox={`0 0 55 55 `}
                                                />
                                            </a>
                                        </div>
                                    </div>)

                            })}
                        </div>
                        <spna className="resend-action cursor-pointer"
                              onClick={() => onCheckConnectBot({send_password: 0})}> {getT("Проверить регистрацию")}
                        </spna>
                    </div>}
                    {!!Object.keys(connected_channel).length && <div className={'connected-channel-wrap form-group'}>
                        <label htmlFor="">{getT("Выберете мессенджер по-умолчанию")}:</label>
                        <div className={`connected-channel d-flex message-channel-entry`}>
                            {Object.keys(connected_channel).map(key => {
                                const isActive = key === values.message_channel;
                                const isDefault = connected_channel[key].default;
                                const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                                const name = connected_channel[key].name;
                                return (
                                    <div className={`item   ${isActive ? 'active' : ' '}`}
                                         onClick={() => setFieldValue('message_channel', key)}
                                    >
                                        {Icon ? <Icon/> : name ? name : ''}
                                    </div>)

                            })}
                        </div>
                    </div>}
                </div>


            </div>

    );
}


export default StepRegisterBot;