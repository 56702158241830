import {
  changeCalculateDataDiscountsAC,
  changeMaterialCalculateResToClientAC,
  changeMaterialCalculateTypeAC,
  changeOrderCurrencyDataAC,
  changeOrderUniversalStatusAC,
  changeOrderUrgentStatusAC
} from "./reducers/OrderReducer";
import {getGiblabDataThunk} from "./reducers/CommonReducer";
import {orderSetGoodServicePartThunk} from "./thunks/order-thunk";

export const changeCalculateMaterialTypeThunk = (material_id, calculate_type) => (dispatch, getState) => {
  dispatch(changeMaterialCalculateTypeAC(material_id, calculate_type));
  dispatch(getGiblabDataThunk('calc'))
};

export const changeCalculateMaterialRestsThunk = (material_id, status) => (dispatch, getState) => {
  dispatch(changeMaterialCalculateResToClientAC(material_id, status));

  dispatch(getGiblabDataThunk('calc'))
};
export const changeCalculateDataDiscountsThunk = (input, value, id, data_type) => (dispatch, getState) => {
  dispatch(changeCalculateDataDiscountsAC(input, value, id, data_type));
  dispatch(getGiblabDataThunk('calc'))
};
export const changeCalculateDataUrgentThunk = (status) => (dispatch, getState) => {
  dispatch(changeOrderUrgentStatusAC(Number(status)))
  dispatch(getGiblabDataThunk('calc'));
};
export const changeCalculateDataUniversalThunk = (status) => (dispatch, getState) => {
  dispatch(changeOrderUniversalStatusAC(Number(status)))
  dispatch(getGiblabDataThunk('calc'));
};

export const changeCalculateCurrencyThunk = (currency) => (dispatch, getState) => {
  dispatch(changeOrderCurrencyDataAC(currency));
  dispatch(orderSetGoodServicePartThunk(currency, true))
  dispatch(getGiblabDataThunk('calc'));
};
