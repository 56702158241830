import React, {useEffect} from 'react';
import UserData from "./UserData";
import {useDispatch} from "react-redux";
import {messageChannelsThunk} from "../../../../store/thunks/message-channels-thunk";
import MessageChannels from "./MessageChannels";
import "./main.css"
import {networkLoginUserThunk} from "../../../../store/thunks/social-network-thunk";
import SocialNetwork from "./SocialNetwork";

const Profile = ({getT, userDT}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(messageChannelsThunk());
        dispatch(networkLoginUserThunk());
    }, [dispatch])
    return (
        <div className={"settings-section w-100 "}>
            <div className="filed"><h2 className={"d-flex items-center"}>{getT("Основная информация")}</h2></div>
            <div className="cabinet-user-data">
                <UserData userDT={userDT} getT={getT} key={'user-data'}/>
                <MessageChannels key={'message-channels'} getT={getT}/>
                <SocialNetwork/>
            </div>

        </div>
    );
};

export default Profile;