import { tools } from './tools.js';
import { translateLib } from './translate.js';
import { measurementsLib } from './measurements.js';

export const troughs = {
  verticalTrough(cx, cy, w, h, r, side, troughId) {
    let colors = {
      f: tools.troughFront,
      bb: tools.troughBack,
    };
    let x = cx - w / 2;
    let y = cy - h / 2;
    return tools.rect(x, y, w, h, {
      fill: colors[side],
      rx: r,
      hover_show: troughId
    });
  },
  troughInfoHighlight(
    cx, cy, cx1, cy1, l, w, z, r, partX, partY, partY1, partZ,
    parentTranslate, sideTranslates, troughId, translateFunc,
    operationId, pazId, startPoint, verticalMirror
  ) {
    let T = translateFunc;

    let x = cx - l / 2;
    let y = cy - w / 2;

    let [lx, ly] = sideTranslates.left;
    let [tx, ty] = sideTranslates.top;
    let [rx, ry] = sideTranslates.right;
    let [bx, by] = sideTranslates.bottom;
    let [px, py] = parentTranslate;

    let fontSize = 15;
    let troughDisplayId = `ID: ${operationId}`;

    if (pazId) {
      troughDisplayId = `${troughDisplayId} [${pazId}]`;
    }

    let imageDiffersTspans = '';

    if (verticalMirror) {
      let texts = T(translateLib.IMAGE_DIFFERS_FOR_USER_CONVENIENCE);
      let imageDiffersTspansParams = {
        x: 10,
        dy: '1.3em',
        style: 'fill: red;',
      };
      imageDiffersTspans = (
        tools.tspan(texts[0], imageDiffersTspansParams)
        + tools.tspan(texts[1], imageDiffersTspansParams)
      );
    }

    let cxText = tools.rnd(cx);

    if (tools.rnd(cx) !== tools.rnd(cx1)) {
      cxText = `${tools.rnd(cx)} / ${tools.tspan(tools.rnd(cx1), { fill: tools.xCoordColor })}`;
    }

    let cyT = partY - cy; // for correct display
    let cy1T = partY1 - cy1; // for correct display
    let cyText = tools.rnd(cyT);

    if (tools.rnd(cyT) !== tools.rnd(cy1T)) {
      cyText = `${tools.rnd(cyT)} / ${tools.tspan(tools.tspan(cy1T), { fill: tools.yCoordColor })}`;
    }

    if (verticalMirror) {
      let redTspan = (t) => tools.tspan(t, { fill: 'red' });

      if (tools.rnd(cyT) !== tools.rnd(cy1T)) {
        cyText = (
          `${cyText} / `
          + `[[${redTspan(partY - cyT)}/${redTspan(partY1 - cy1T)}]]`
        );
      } else {
        cyText = `${cyText} / [[${redTspan(partY - cyT)}]]`;
      }
    }

    return tools.g([
      tools.operationIdText(operationId, cx, cy),
      tools.rect(x, partZ - z, l, z, {
        fill: tools.troughFront,
        transform: `translate(${-px + tx} ${-py + ty})`,
      }), // top
      tools.rect(0, y, z, w, {
        fill: tools.troughFront,
        transform: `translate(${-px + rx} ${-py + ry})`
      }), // right
      tools.rect(x, 0, l, z, {
        fill: tools.troughFront,
        transform: `translate(${-px + bx} ${-py + by})`
      }), // bottom
      tools.rect(partZ - z, y, z, w, {
        fill: tools.troughFront,
        transform: `translate(${-px + lx} ${-py + ly})`
      }),
      tools.line(cx, -20, cx, partY + 20, {
        stroke: 'black',
        'stroke-width': '1px',
        'stroke-dasharray': '8 4',
        'class': 'dash-line',
      }),
      tools.line(-20, cy, partX + 20, cy, {
        stroke: 'black',
        'stroke-width': '1px',
        'stroke-dasharray': '8 4',
        'class': 'dash-line',
      }),
      tools.operationTable([
        tools.text([
          (
            operationId !== null
              ? tools.operationIdTspan(troughDisplayId, { x: 10 })
              : ''
          )
          + imageDiffersTspans
          + tools.tspan(
            T(translateLib.TROUGH_POPUP_NAME), {
            x: 10,
            dy: operationId !== null ? '1.3em' : ''
          }
          )
          + tools.tspan(
            `${T(translateLib.TROUGH_X)}: ${cxText}`, { x: 10, dy: '1.2em' }
          )
          + tools.tspan(
            `${T(translateLib.TROUGH_Y)}: ${cyText}`, { x: 10, dy: '1.2em' }
          )
          + tools.tspan(
            `${T(translateLib.TROUGH_L)}: ${tools.rnd(l)}`, { x: 10, dy: '1.2em' }
          )
          + tools.tspan(
            `${T(translateLib.TROUGH_W)}: ${tools.rnd(w)}`, { x: 10, dy: '1.2em' }
          )
          + tools.tspan(
            `${T(translateLib.TROUGH_Z)}: ${tools.rnd(z)}`, { x: 10, dy: '1.2em' }
          )
          + tools.tspan(
            `${T(translateLib.TROUGH_R)}: ${tools.rnd(r)}`, { x: 10, dy: '1.2em' }
          )
        ], { x: 0, y: 0, 'font-size': `${fontSize}px` })
      ], { operation_id_bg: true, table_id: troughId })
    ], { transform: `translate(${parentTranslate.join(", ")})`, id: troughId });
  },
  troughMeasurementCoords(
    x0, y0, x1, y1, w, h, partX, partY, partX1, partY1, partZ, side,
    parentTranslate, sideTranslates, measurementOffset, troughId
  ) {
    let coords = [];
    let funcArgs = [
      partX, partY, partX1, partY1, partZ, side, parentTranslate,
      sideTranslates, measurementOffset
    ];
    let funcKwargs = { measureId: troughId };

    // main corner coord, has two axes
    let x01 = x0;
    let x11 = x1;

    let topIsNearer = y0 < partY - y0 && y0 + h < partY - y0 - h;
    let y01 = topIsNearer ? y0 : y0 + h;
    let y11 = topIsNearer ? y1 : y1 + h;

    coords.push(
      measurementsLib.measurementCoords(
        x01, y01, x11, y11, ...funcArgs, funcKwargs
      )
    );

    // parallel to main's x axis
    let x02 = x1 === x0 + w ? x0 : x0 + w;
    let y02 = y01;
    let x12 = x1 === x1 + w ? x1 : x1 + w;
    let y12 = y11;
    coords.push(
      measurementsLib.measurementCoords(
        x02, y02, x12, y12, ...funcArgs, { ...funcKwargs, axis: 'x' }
      )
    )

    // parallel to main's y axis
    let x03 = x01;
    let y03 = (y1 === y0 + w || !topIsNearer) ? y0 : y0 + h;
    let x13 = x11;
    let y13 = (y1 === y1 + w || !topIsNearer) ? y1 : y1 + h;
    coords.push(
      measurementsLib.measurementCoords(
        x03, y03, x13, y13, ...funcArgs, { ...funcKwargs, axis: 'y' }
      )
    );

    // center of trough
    let [x04, y04] = [x0 + w / 2, y0 + h / 2];
    let [x14, y14] = [x1 + w / 2, y1 + h / 2];
    coords.push(
      measurementsLib.measurementCoords(
        x04, y04, x14, y14, ...funcArgs, funcKwargs
      )
    );

    return coords;
  },
  troughs(
    troughs, partX, partY, partX1, partY1, partZ, parentTranslate,
    sideTranslates, measurementOffset, language, partSizesType
  ) {
    let a = '';
    let hovers = '';
    let measurementsCoords = [];
    let translateFunc = translateLib.translate(language);

    for (let trough of troughs) {
      let verticalMirror = trough.virtual_vertical_mirror;
      let operationId = trough.id;
      let startPoint = trough.start_point;
      // let cx = trough.x;
      // let cy = partY - trough.y;
      // let cx1 = trough.x1;
      // let cy1 = partY1 - trough.y1;

      let { x: cx, y: cy } = tools.getXY(trough, partSizesType)
      let cx1 = cx;
      let cy1 = cy;

      cy = partY - cy;
      cy1 = partY1 - cy1;

      if (verticalMirror) {
        cy = partY - cy;
        cy1 = partY1 - cy1;
      }

      [cx, cy] = tools.toFloat(cx, cy);
      let [l, w] = tools.toFloat(trough.l, trough.w);
      let z = parseFloat(trough.z || 0);
      let r = parseFloat(trough.r || 0);
      let pazId = trough.paz_id;

      let side = trough.side;
      let troughId;

      if (['f', 'bb'].includes(side)) {
        if (pazId) {
          troughId = `paz_${pazId}`;
        } else {
          troughId = `trough_${operationId}`;
        }

        a += this.verticalTrough(cx, cy, l, w, r, side, troughId);
        hovers += this.troughInfoHighlight(
          cx, cy, cx1, cy1, l, w, z, r, partX, partY, partY1, partZ,
          parentTranslate, sideTranslates, troughId,
          translateFunc, operationId, pazId, startPoint, verticalMirror,
        );

        measurementsCoords = measurementsCoords.concat(
          this.troughMeasurementCoords(
            cx - l / 2, cy - w / 2, cx1 - l / 2, cy1 - w / 2, l, w, partX, partY, partX1,
            partY1, partZ, side, parentTranslate, sideTranslates,
            measurementOffset, troughId
          )
        );
      }
    }

    return [a, hovers, measurementsCoords];
  }
}
