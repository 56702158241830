import getT from "../../getT"

export default function ({xnc, type_name=false}){
  return (
    ` <tr>
        <th></th>
        <th>${getT('количество деталей со сшивками')}</th>
        <th>${getT('количество сшивок')}</th>
        <th></th>
        <th></th>
      </tr>
      <tr>
        <th> ${type_name? type_name :(  getT(xnc?.type_name) ?? '')}</th>
        <td>${+Number(xnc?.part_hem_count ?? '').toFixed(2)}</td>
        <td>${+Number(xnc?.hem_count ?? '').toFixed(2)}</td>
        <td></td>
        <td></td>
      </tr>
  `
  );
}