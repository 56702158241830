import React, {useEffect, useState} from "react";
import Modal from "../BasicModal";
import Container from "../../Container/Container";
import './UploadProjectModal.css';
import {useDispatch, useSelector} from "react-redux";
import {changeImportProjectModalValue} from "../../../store/reducers/ModalReducer";
import ImportForm from "./ImportForm";
import ChangeData from "./ChangeData";
import InfoUploadExel from "./InfoUploadExel";
import {useLang} from "../../../context/langProvider";

const UploadImportModal = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.modal.importProject.isOpen);
    const changeValue = (input, value) => dispatch(changeImportProjectModalValue(input, value));
    const [files, getFiles] = useState([]);
    const data = useSelector((state) => state.modal.importProject.data);

    useEffect(()=>{
        if(!isOpen && !Array.isArray(files)){
            getFiles([])
        }

    }, [isOpen])
    if (!isOpen) return null
    return (
        <Modal title={getT('Импортировать проект')} open={isOpen} onClose={() => changeValue('isOpen', false)}>
            <Container>
                <div className={"w-100"}>
                    {data ? <ChangeData/> : <ImportForm files={files} getFiles={getFiles}/>}
                </div>
            </Container>

        </Modal>
    );
};

export default UploadImportModal;

