import React from 'react';
import SurfaceTreatment from "../../../Icons/Actions/SurfaceTreatment";

const OperationConfirmDelete = ({ delete_operation, getT, handlerDelete}) => {
    return (
        <div className="delete-operation">
            <SurfaceTreatment/>
            <div className="delete-operation-info">
                <p>{getT("Применена обработка")}[{delete_operation?.operation?.id}] {delete_operation.operation?.name}. {getT("Снять?")}</p>
                <button className={"btn m-0 "} onClick={handlerDelete}>{getT("Да")}</button>
            </div>
        </div>
    );
};

export default OperationConfirmDelete;