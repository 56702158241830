import React, {useState} from 'react';
import {useLang} from "../../../context/langProvider";
import IconArrow from "../../../Icons/IconArrow";
const styleTextarea ={
    border: `1px solid #cdccd7`,
    width: `100%`
}

const Comment = ({handlerSubmit}) => {
    const {getT} = useLang()
    const [comment, setComment] = useState('');
    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handlerSubmit(comment, ()=>setComment(''))
    }
    const handlerChange = (e) => setComment(e.target.value);
    const [isOpen, setIsOpen] = useState(false)
    return (
        <form onSubmit={onSubmit} className={'mt-2'}>
            <label onClick={()=>setIsOpen(!isOpen)} className={"comment-accordion-toggle"}>{getT('Комментарий')} <IconArrow className={`${!isOpen ? 'arrow-down' : ' arrow-top '}`}/></label>
            {isOpen && <> <div className="user-data" style={{marginTop:"6px"}}>
                <div className={'field form-group'}>
                    <textarea onChange={handlerChange} className={''} style={styleTextarea} rows={4}>{comment}</textarea>
                </div>
            </div>
            <button className={"btn"} type={'submit'}>{getT('Отправить')}</button>
            </>}
        </form>
    );
};

export default Comment;