import React, {useCallback} from "react";
import Modal from "../BasicModal";
import Container from "../../Container";
import TemplatePazDetailForm from "../../TemplatePazUi/TemplatePazDetailForm";
import {useDispatch, useSelector} from "react-redux";
import {onChangeTemplatePazModal} from "../../../store/reducers/ModalReducer";
import {useLang} from "../../../context/langProvider";
import {actionTemplatePaz, initialDetailTemplatePaz} from "../../../store/reducers/OrderReducer";
import {getModalTemplatePazIsOpen, getModalTemplatePazPart} from "../../../selectors/modal-selector";
import {getTemplatePaz} from "../../../selectors/order-selector";

const TemplatePazMaterial = () => {
    const isOpen = useSelector(getModalTemplatePazIsOpen)
    return isOpen ? <Component isOpen={isOpen} key={'template-component'}/> : null

};

const Component = ({isOpen}) => {
    const dispatch = useDispatch();
    const {getT} = useLang();

    const part = useSelector(getModalTemplatePazPart);
    const template = useSelector(getTemplatePaz);

    const onHandlerClose = useCallback((cb = null) => {

        dispatch(onChangeTemplatePazModal({isOpen: false, part: null}));
        if (cb) cb()

    }, [dispatch]);
    const pazPartTemplate = part?.operations?.paz?.filter(item => item?.hasOwnProperty("id_template_paz"));
    const onCreatePazHandler = useCallback((formCreate) => {
        dispatch(actionTemplatePaz?.pazInDetailCU({form: formCreate, partId: part?.id}));
        onHandlerClose(() => dispatch(initialDetailTemplatePaz()));

    }, [dispatch, part]);

    const tSide = pazPartTemplate?.find(item => item?.side_from === "t") || {};
    const botSide = pazPartTemplate?.find(item => item?.side_from === "b") || {};
    const lSide = pazPartTemplate?.find(item => item?.side_from === "l") || {};
    const rSide = pazPartTemplate?.find(item => item?.side_from === "r") || {};
    if (!getT || !part || !botSide || !tSide || !lSide || !rSide) return null;
    return (
        <Modal title={part?.name} onClose={onHandlerClose} open={isOpen}>
            <Container>
                <TemplatePazDetailForm onCreatePazHandler={onCreatePazHandler}
                                       template={template} part={part} getT={getT} tSide={tSide} bSide={botSide}
                                       lSide={lSide} rSide={rSide}/>
            </Container>
        </Modal>
    );
}
export default TemplatePazMaterial;