import React, {Suspense, useCallback, useState} from 'react';
import HeaderContainer from "../../components/Header/HeaderContainer";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader/Loader";
import ErrorBoundary from "../../components/ErrorBoundary";
import AdditionGoodsServicePartModal from "../../components/Modals/AdditionGoodsServicePartModal";

const ProductsContainer = React.lazy(() => import("../../components/Products/ProductsContainer"))
const GoodsSale = React.lazy(() => import("../../components/GoodsSale/GoodsSale"))
const Wares = React.lazy(() => import("../../components/Wares/Wares"))
const BoxesTable = React.lazy(() => import("../../components/Boxes/BoxesTable"))
const SettingsFirm = React.lazy(() => import("../SettingsFirm"))
const TemplatePazContainer = React.lazy(() => import("../TemplatePaz/TemplatePazContainer"));

const TabContainer = () => {
    const [activeTab, setActiveTab] = useState("details");
    const onChangeTab = useCallback((newTab) => {
        setActiveTab(newTab)
    }, [activeTab])

    function renderTab(tab) {
        switch (tab) {
            case "details":
                return <Suspense fallback={<Loader/>}>
                    <ErrorBoundary>
                        <ProductsContainer key={'products-container'}/>
                        <AdditionGoodsServicePartModal key={'AdditionGoodsServicePartModal'}/>

                    </ErrorBoundary>
                </Suspense>
            case "goods":
                return <Suspense fallback={<Loader/>}>
                    <ErrorBoundary>
                        <GoodsSale/>
                    </ErrorBoundary>
                </Suspense>
            case "products":
                return <Suspense fallback={<Loader/>}>
                    <ErrorBoundary>
                        <Wares/>
                    </ErrorBoundary>
                </Suspense>;
            case "boxes":
                return <Suspense fallback={<Loader/>}> <ErrorBoundary>
                    <BoxesTable/></ErrorBoundary></Suspense>;
            case "settings":
                return <Suspense fallback={<Loader/>}> <ErrorBoundary><SettingsFirm/></ErrorBoundary></Suspense>;
            case "template_paz":
                return <Suspense fallback={<Loader/>}>
                    <ErrorBoundary>
                        <TemplatePazContainer/>
                    </ErrorBoundary>
                </Suspense>
            default:
                return null;
        }
    }

    return (
        <div className={"home"} key={'home-box'}>
            <HeaderContainer activeTab={activeTab}
                             key={'HeaderContainer'}
                             onTabChange={onChangeTab}

            />
            <div className="main" key={'main'}>{renderTab(activeTab)}</div>
            <Footer key={'footer'}/>

        </div>
    );
};

export default TabContainer;