import React from 'react';
import {useLang} from "../../../../../../context/langProvider";
import InputForm from "../../../../../../components/InputFields/InputForm/InputForm";
import Edit from "../../../../../../Icons/TableActions/Edit";
import AuthAccountPassword from "../../AuthAccountPassword";
import {useFormikContextProviderRegister} from "../../../../SignUp";
import {useDispatch} from "react-redux";
import {changeAlertStatusAC} from "../../../../../../store/reducers/CommonReducer";

const EmailRegistration = ({onReqAccountAccess, onHandlerEdit, isButtonDisabled, seconds}) => {
    const dispatch = useDispatch()
    const {getT} = useLang()
    const {values, handleChange, setFieldValue, validateForm, submitForm} = useFormikContextProviderRegister();
    if (!values.isRadioLoginEmail) return null
    const handleValidate = () => {
        validateForm().then((errors) => {
            if (!Object.keys(errors).includes('email')) {
                onReqAccountAccess({send_password: 1})

            } else if (Object.keys(errors).includes('email')) {
                let error_msg = `${errors['email'] || ''}`
                dispatch(changeAlertStatusAC(true, error_msg));
            }

        });
    };
    return (
        <div>
            <div className="d-flex items-end mb-2 w-100">
                <InputForm label={<>{getT('Email')} <sub style={{color:'red', cursor:"pointer",verticalAlign: "super"}} title={getT("Обязательно для заполнения.")}>*</sub></>}
                           cName={'filed-border'} id="email"
                           disabled={!values.isEditEmailOrPhone}
                           readonly={!values.isEditEmailOrPhone}
                           autoComplete={"off"}
                           name="email"
                           type="email" value={values.email} onChange={handleChange}/>

                {!values.isEditEmailOrPhone &&
                    <Edit style={{margin: '0 0 25px 8px', cursor: "pointer"}} onClick={onHandlerEdit} width={'22px'}
                          height={'18px'}/>}
            </div>
            {values.isEditEmailOrPhone &&
                <button type={'button'} className={"btn m-0"} style={{margin: '0 auto'}}
                        onClick={handleValidate}>{getT("Отправить")}</button>

            }
            {!values.isEditEmailOrPhone && <>
                <AuthAccountPassword handleChange={handleChange} setFieldValue={setFieldValue} values={values}
                                     submitForm={submitForm}
                                     resendChildrenEmail={
                                         <span onClick={() => onReqAccountAccess({send_password: 1})}
                                               className={`resend-password ${isButtonDisabled ? 'disabled' : ''}`}>
                        {getT("Отправить повторно пароль")} {isButtonDisabled && <span className="seconds">{seconds}
                        </span>}</span>
                                     }/>

            </>}

        </div>
    );
};

export default EmailRegistration;