import React from "react";
import {
    actionsCellsLeftovers,
    onRemoveRemainCabinet,
    onSetRemainInfo,
    setEditRemainForm
} from "../../../store/reducers/StorageCellsLeftovers";
import {onToggleRemainModal} from "../../../store/reducers/ModalReducer";
import {useDispatch} from "react-redux";
import RemainInfo from "./RemainInfo";
import RemainsModal from "../../../components/Modals/RemainsModal";
import Edit from "../../../Icons/TableActions/Edit";
import Delete from "../../../Icons/Actions/Delete";
import ShowOrder from "../../../Icons/ShowOrder";

const RemainStockTable = ({getT, remainsDT})=>{
    const dispatch = useDispatch();
    const onDeleteRemains = (id) => {
        dispatch(onRemoveRemainCabinet(id));
    }
    const onEditRemains = (dt, name) => {
        const data = setEditRemainForm(dt);
        dispatch(actionsCellsLeftovers.setInitialValuesForm({form: 'remainFrom', initialValue: data}))
        dispatch(onToggleRemainModal({
            key: 'formAdd', dt: {
                isOpen: true, goods: dt.goods_id, name: name
            }
        }))
    };
    const onOpenFullInfo = (id, name) => {
        dispatch(onSetRemainInfo(id));
        dispatch(onToggleRemainModal({key: 'fullInfo', dt: {isOpen: true, title: name}}));
    };

    return <>
        {Array.isArray(remainsDT) && remainsDT.length >= 1 ? (<>
                <div className="table">
                    <table className={"table-wrapper main-parts-table"}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{getT("Название")}</th>
                            <th>{getT("Размер (мм)")}</th>
                            <th>{getT("Кол-во")}</th>
                            <th>{getT("Действие")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {remainsDT.map((l, idx) => {
                            return (<tr>
                                <td>{idx + 1}</td>
                                <td>[{l.stock_cell_id} {l.goods_id}]{l.name}</td>
                                <td>
                                    <span title={'x'}> {l.x}</span>
                                    <strong> x </strong>
                                    <span title={'y'}> {l.y}</span>
                                    {l.z && <strong> x </strong>}
                                    <span title={'z'}> {l.z}</span>

                                </td>
                                <td style={{whiteSpace:'nowrap'}}>{l.units}/{l.count} m2</td>
                                <td>
                                    <div className={'settings-actions '}>
                                        <span onClick={() => onOpenFullInfo(l.goods_stock_id, l.name)}><ShowOrder/></span>

                                        {(l.order == null || l.order !== '') && <>
                                <span className={'cursor-pointer edit'}
                                      onClick={() => onEditRemains(l, l.name)}><Edit/></span>
                                            <span className={'cursor-pointer'}
                                                  onClick={() => onDeleteRemains(l.goods_stock_id)}><Delete/></span>
                                        </>
                                        }
                                    </div>
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
                <RemainInfo getT={getT}/>
                <RemainsModal getT={getT}/>
            </>
        ) : (
            <h3 style={{textAlign: 'center'}}>{getT("Остатков на складе нет")}</h3>
        )}
    </>
}

export default RemainStockTable;