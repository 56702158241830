import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import MainService from "./MainService.jsx";
import {initialProjectDt} from "../../store/reducers/OrderReducer";
import ParametersContext from '../../context/parametrs.js';
import {changeMainOrderDataAC, fetchingMaterialExportBasis} from '../../store/reducers/CommonReducer';
import {useValidLUrl} from "../../HOOK/useValidLUrl";
import {BASE_PATH_URL_PACKAGE_BOX} from "../../components/PackageIframe/package_const";
import {LS_CONNECTED_BOX3D} from "../../constants";

const MainServiceContainer = (props) => {
    const {uri} = props;
    const dispatch = useDispatch();
    const initialProject = ({isManager, firm, client, manager}) => {
        dispatch(initialProjectDt({
            isManager,
            firm,
            client,
            manager
        }));
        dispatch(fetchingMaterialExportBasis());
    }
    const changeMainOrderData = (input, value) => dispatch(changeMainOrderDataAC(input, value));

    const {
        isConnected: isConnectedPackageBox,
        loading: isLoadingPackageBox,
        error: errorConnectedPackageBoc
    } = useValidLUrl({
        url: BASE_PATH_URL_PACKAGE_BOX,
        ls_naming: LS_CONNECTED_BOX3D,
        isSendMail: true,
        message_text: 'Не работает ссылка для упаковки'
    })

    useEffect(() => {
        let version = uri.match.params.version || 1;
        changeMainOrderData('version', version);
        changeMainOrderData('code', uri.match.params.code);
        console.log('root main service contaienr')
        if (uri.match.params.client && uri.match.params.firm) {
            initialProject({
                isManager: true,
                client: uri.match.params.client,
                firm: uri.match.params.firm,
                manager: uri.match.params.manager
            });
        } else {
            initialProject({isManager: false});
        }
        // console.clear();
    }, []);


    return (
        <ParametersContext.Provider value={{
            version: uri.match.params.version,
            code: uri.match.params.code
        }}>
            <MainService/>
        </ParametersContext.Provider>
    );
}
export default MainServiceContainer;

