import React from 'react';
import {logoutAC} from "../store/reducers/AuthReducer";
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";

const mapDispatchToProps = (dispatch) => {
    return {
        logount: () => dispatch(logoutAC())
    }
}
const mapDTP = (state) => {
    return {
        user: state.auth.user
    }
}

export function withAuth(Component) {
    const AuthComponent = (props) => {
        if (!localStorage.getItem('ifurn_service_main_user_firm')) {
            props.logount();
            <Redirect to={'/login'}/>
            // window.location.href = "/login";
        }
        return <Component {...props}/>
    }
    return connect(mapDTP, mapDispatchToProps)(AuthComponent)
}


