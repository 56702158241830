import Modal from "../../../components/Modals/BasicModal";
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getRemainInfoDT, getRemainsModal} from "../../../selectors/cells-leftovers-selector";
import {onToggleRemainModal} from "../../../store/reducers/ModalReducer";


const RemainInfo = ({getT}) => {
    const dispatch = useDispatch();
    const {fullInfo} = useSelector(getRemainsModal);

    const remainIfoDT = useSelector(getRemainInfoDT);

    return <div className="container-stock remain-modal">
        <Modal open={fullInfo.isOpen} cName={'remain-modal'}
               title={getT('Полная информация по остатку')+"  " + fullInfo.title}
               onClose={() => dispatch(onToggleRemainModal({key: 'fullInfo', dt: {isOpen: false, title: null}}))}>
            <div className={"inst__bore-form"}>
                <div className="overflow-scrolled">
                {Array.isArray(remainIfoDT) && remainIfoDT.length >= 1 ? (
                    <table className={"stock-table"}>
                        <thead>
                        <tr>
                            <th>{getT("Размер (мм)")}</th>
                            <th>{getT("Кол-во")}</th>
                            <th>{getT("Площадь")}</th>

                            <th>{getT("Резервирование")}</th>
                            <th>{getT("Приход")}</th>
                            <th>{getT("Продажа без услуг")}</th>
                            <th>{getT("Пользователь")}</th>
                            {/*<td>{getT("Дата")}</td>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {remainIfoDT.map(l => {
                            return (<tr>
                                <td>
                                    <span title={'x'}> {l.x}</span>
                                    <strong> x </strong>
                                    <span title={'y'}> {l.y}</span>
                                    {l.z && <strong> x </strong>}
                                    <span title={'z'}> {l.z}</span>

                                </td>
                                <td>{l.units}</td>
                                <td>{l.count}</td>
                                <td>{l.order}</td>
                                <td>{l.order_in_info}</td>
                                <td>{l.forSale ? <> ✔ </> : <> </>}</td>
                                <td>{l.user_in}</td>
                                {/*<td>{l.date_in}</td>*/}

                            </tr>)
                        })}
                        </tbody>
                    </table>
                ) : ('')}
                </div>
        </div>
        </Modal>
    </div>
}
export default RemainInfo;