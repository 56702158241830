import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCellsStorage, getRemainFormInitialValue, getRemainsModal} from "../../../selectors/cells-leftovers-selector";
import {onToggleRemainModal} from "../../../store/reducers/ModalReducer";
import {actionsCellsLeftovers, onSendRemainCreate} from "../../../store/reducers/StorageCellsLeftovers";
import Modal from "../../../components/Modals/BasicModal";
import {getLocalLang} from "../../../selectors/settings-selector";

const RemainsCreateModal = ({getT}) => {
    const {create} = useSelector(getRemainsModal);
    const dispatch = useDispatch();
    const valueForm = useSelector(getRemainFormInitialValue);
    const cells = useSelector(getCellsStorage);
    const lang = useSelector(getLocalLang)
    const onToggleModal = (dt) => {
        dispatch(onToggleRemainModal({key: 'create', dt: {isOpen: false, goods: null, order_in: null}}));
        dispatch(actionsCellsLeftovers.onResetFormValue({form:'remainFrom'}));
    }
    const onChangeValues = ({key, dt}) => {
        dispatch(actionsCellsLeftovers.onChangeRemainForms({key, dt}))
    }

    return <>
        <Modal title={getT('Создать остаток ')} open={create.isOpen} onClose={() => onToggleModal(false)}>
            <form action="" className={"inst__bore-form"} onSubmit={(e) => {
                e.preventDefault();
                dispatch(onSendRemainCreate({...valueForm, }, null , (create.order_in ?? null)))

            }}>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>x</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.x}
                               required={'required'}
                               onChange={(e) => {
                                   // let value = Number(e.target.value) > 100 ? 100 : e.target.value
                                   onChangeValues({key: 'x', dt: e.target.value})
                               }}/>
                    </div>
                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>y</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.y}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'y', dt: e.target.value})
                               }}/>
                    </div>
                </div>
                <div className="field-section">

                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>{getT('Количество')}</label>
                        <input className={"input-val"} type="number"
                               value={valueForm.units}
                               required={'required'}
                               onChange={(e) => {
                                   onChangeValues({key: 'units', dt: Number(e.target.value)})
                               }}/>
                    </div>
                    {Array.isArray(cells) &&
                    <div className="field">
                        <label htmlFor="" className={"field-title label"}>{getT('Имя ячейки')}</label>
                        <select className={"material-select w-100"} name="" id="" value={valueForm.cell}
                                required={'required'}
                                onChange={(e) => onChangeValues({key: 'cell', dt: e.target.value})}
                        >
                            <option value={null}>{getT('Выбрать')}</option>
                            {cells.map(cell => <option selected={(Number(cell) === Number(cell.stock_cell_id))? 'selected': ''}  value={cell.stock_cell_id}>{cell.name}</option>)}
                        </select>
                    </div>
                    }
                </div>
                {Array.isArray(create.materials) && create.materials.length >= 1 &&
                <div className="field">
                    <label htmlFor="" className={"field-title label"}>{getT('Материал')}</label>
                    <select style={{padding: '9px', height: 'auto'}} className="form-input input-val w-100" name=""
                            required={'required'}
                            id=""
                            onChange={(e) => onChangeValues({key: 'goods', dt: e.target.value})}>
                        <option value="" disabled={'disabled'} selected={'selected'}>{getT('Выбрать')}</option>
                        {create.materials.map(m => <option value={m.goods}>{m.goods_name[lang]}</option>)}
                    </select>
                </div>
                }
                <button className={"btn"} type={"submit"}>{getT('Отправить')}</button>
            </form>

        </Modal>
    </>
}

export default RemainsCreateModal;