function Gitlab(props) {
  return (
      <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9657 12.996C11.4361 13.5972 10.6612 14.0815 9.6412 14.4489C8.62117 14.8163 7.50307 15 6.28689 15C4.41684 15 2.92276 14.4155 1.80466 13.2465C0.686555 12.0775 0.0882714 10.4509 0.00980793 8.36673L0 7.10421C0 5.668 0.248468 4.4155 0.745403 3.34669C1.24234 2.27121 1.95178 1.44623 2.87372 0.871744C3.80221 0.290581 4.87454 0 6.09073 0C7.86923 0 9.24888 0.417502 10.2297 1.25251C11.217 2.08083 11.7924 3.31997 11.9559 4.96994H8.64079C8.5231 4.15498 8.26809 3.57381 7.87577 3.22645C7.48345 2.87909 6.92767 2.70541 6.20842 2.70541C5.34532 2.70541 4.67838 3.07949 4.2076 3.82766C3.73682 4.57582 3.49816 5.64462 3.49162 7.03407V7.91583C3.49162 9.37208 3.73355 10.4676 4.21741 11.2024C4.70781 11.9305 5.4761 12.2946 6.52228 12.2946C7.41807 12.2946 8.08501 12.0908 8.5231 11.6834V9.41884H6.12996V7.00401H11.9657V12.996Z" fill="#0023CF"/>
        <path d="M17.0074 12.0942H23V14.7996H13.5648V0.210421H17.0074V12.0942Z" fill="#0023CF"/>
      </svg>
  );
}

export default Gitlab;
