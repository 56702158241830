import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getOrder} from "../../../selectors/order-selector";
import {getCombineModalDTSelector, onChangeCombineMaterialModal} from "../../../store/reducers/ModalReducer";
import Modal from "../BasicModal";
import {isEmptyEdgePart, random} from "../../../helpers/helpers";
import CreateCombine from "./CreateCombine";
import Container from "../../Container";
import {_constructorMaterial} from "../../../store/reducers/MaterialsReducer";
import {onCreateMaterialCombineThunk} from "../../../store/reducers/OrderReducer";
import CombineInfo from "./CombineInfo";
import {useLang} from "../../../context/langProvider";

const initialLocalState = {
    group: [],
    selectGroup: null,
    titleMaterial: ''
}

export const SIDE_DETAIL = {
    'up': 'up',
    'down': 'down'
}
const CombineMaterialModal = () => {
    const combineMaterial = useSelector(getCombineModalDTSelector)

    return combineMaterial.isOpen ? <Component/> : null
};

const Component = () =>{
    const {getT} = useLang()
    const {part: partOrder = [], material: materialOrder = []} = useSelector(getOrder);
    let material = materialOrder?.filter(e => {
        return Number(e?.additional?.no_hem_in_material) !== 1
    });
    let part = partOrder?.map(part => ({
        ...part,
        x: part.x ?? 0,
        x1: part.x1 ?? 0,
        x2: part.x2 ?? 0,
        y: part.y ?? 0,
        y1: part.y1 ?? 0,
        y2: part.y2 ?? 0,
    }))?.filter(item => {
        let materialFind = materialOrder.find(e => Number(e?.id) === Number(item?.material));
        if (Number(materialFind?.additional?.no_hem_in_material) !== 1) {
            return {...item}
        }
    });

    const dispatch = useDispatch();
    const [combineDt, setCombineDt] = useState({
        group: [],
        selectGroup: null,
        titleMaterial: getT('Составной материал') + '# ' + random(2, 2, 1)
    });
    const onClose = () => {
        dispatch(onChangeCombineMaterialModal({key: 'isOpen', value: false}));
        setCombineDt(initialLocalState)
    }

    const setCombineValue = ({key, value}) => {
        setCombineDt((prev) => {
            return {
                ...prev,
                [key]: value
            }
        })
    }
    const createCombineGroup = () => {
        if (!part?.length || !Array.isArray(part)) {
            setCombineValue({key: 'group', value: []})
            return;
        }

        const partsEdgeEmpty = part?.filter(partItem => {
            const isMaterialCombineDetail = material?.find(item => Number(item?.goods_id) === Number(part.material));
            return isEmptyEdgePart(partItem?.edge) && !(partItem?.combined_material) && isMaterialCombineDetail?.combined_material !== 1
        });
        let partGroup = partsEdgeEmpty?.reduce((accumulator, current) => {
            if (!accumulator.find(({
                                       x1, x, x2,
                                       y1, y, y2,
                                       count,
                                       product
                                   }) => (Number(x1 || x || x2) === Number(current?.x1 || current?.x || current?.x2)) && (Number(y1 || y || y2) === Number(current?.y1 || current?.y || current?.y2)) && (Number(count) === Number(current?.count)) && (product) === current?.product)) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        if (!partsEdgeEmpty?.length || !partGroup?.length) {
            setCombineValue({key: 'group', value: []})
            return
        }

        const groupCombine = partGroup?.map(part => {
            let filterGroup = partsEdgeEmpty?.filter(partFilterItem => {
                return (Number(part?.x1 || part?.x || part?.x2) === Number(partFilterItem?.x1 || partFilterItem?.x || partFilterItem?.x2)) && (Number(part?.y1 || part?.y || part?.y2) === Number(partFilterItem?.y1 || partFilterItem?.y || partFilterItem?.y2)) && (Number(part?.count) === Number(partFilterItem?.count)) && part?.product === partFilterItem?.product

            });
            return filterGroup?.map(item => ({...item, added: false, side: null}))
        })?.filter(item => item?.length >= 2)

        setCombineValue({
            key: 'group', value: Array.isArray(groupCombine) ? groupCombine : []
        })
    }
    const onCreateCombineMaterial = () => {
        const partsAdded = combineDt.group[combineDt.selectGroup]?.filter(item => item?.added);
        let materialsId = [];
        const parts_by_step = partsAdded?.map(part => {
            let materialFind = material?.find(item => item?.goods_id === part?.material);
            materialsId.push({partId: part?.id, type_material: materialFind?.type_material})

            return {
                id: part?.id,
                side: part?.side
            }
        });
        const specData = {
            typeMaterial: 'part',
            digitCode: '',
            name: combineDt.titleMaterial,
            x: partsAdded[0]?.x1 || partsAdded?.[0]?.x || partsAdded?.[0]?.x2,
            y: partsAdded[0]?.y1 || partsAdded?.[0]?.y || partsAdded?.[0]?.y2,
            z: partsAdded.reduce((current, next) => Number(current?.z ?? current) + Number(next?.z), 0),
            usd: 0,
            drop: 7,
            combined_material: 1,
            mat_calc_type: 2,
            count: partsAdded[0]?.count,
            parts_by_step: parts_by_step,
            addedType: materialsId?.find(({
                                              partId,
                                              materialId
                                          }) => Number(partId) === Number(parts_by_step?.[0]?.id))?.type_material
        }
        const combineMaterial = _constructorMaterial(specData, parseInt(Math.random() * 100000000000, 12));
        dispatch(onCreateMaterialCombineThunk(combineMaterial, onClose))
    }
    useEffect(() => {
        createCombineGroup();
    }, [])


    if (!getT || !Array.isArray(part) || !Array.isArray(material)) {
        return <Modal open={true} onClose={onClose} title={getT('')}>
            <p>{getT('Произошел збой обратитесь в службу поддержки')} <br/> {getT('сообщение об ошибки')}</p>
        </Modal>
    }

    return (
        <div>
            <Modal open={true} onClose={onClose} title={getT('Создать составной материал')}>
                <Container>
                    <div className={'client-materials-adds-main-container'}>
                        <CombineInfo getT={getT}/>
                        {combineDt?.group?.length && Array.isArray(combineDt?.group) ?
                            <><CreateCombine material={material} group={combineDt?.group} getT={getT}
                                             onCreateCombineMaterial={onCreateCombineMaterial}
                                             selectGroup={combineDt?.selectGroup} onChange={setCombineValue}
                                             titleMaterial={combineDt?.titleMaterial}/></>
                            :
                            <h4 style={{textAlign: 'center'}}>{getT('Детали для создания составного материала отсутствуют')}</h4>}
                    </div>
                </Container>
            </Modal>
        </div>
    );
}

export default CombineMaterialModal;